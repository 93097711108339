import React, { useState } from "react";
import { Text, Box, Center, SimpleGrid, Button } from "@chakra-ui/react";

import { createCampaignAtom } from "../../../atoms/campaigns";
import { useRecoilState } from "recoil";
// import CustomTemplate from "../../UI/form/CustomTemplate";
import { useQuery } from "@tanstack/react-query";
import { getTemplates } from "../../../API/account/templates";
import { TemplateSMSCard } from "../../UI/Cards/templateSMSCard";
import ProgressLoader from "../../UI/ProgressLoader";
// import NoEventsIcons from "../../../icons/NoEventsIcons";
import { ButtonPrimary } from "../../UI/Buttons";
import { EmptyTemplateIcon } from "../../../icons/EmptyTemplateIcon";
// import { useNavigate } from "react-router-dom";

const Templates = () => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  // const [isHighlighted, setIsHighlighted] = useState(false)
  // const [temp4Shadow, setTemp4Shadow] = useState(true);
  // const [temp1Shadow, setTemp1Shadow] = useState(true);
  // const [temp2Shadow, setTemp2Shadow] = useState(true);
  // const [temp3Shadow, setTemp3Shadow] = useState(true);
  const [smsTemplates, setSmsTemplates] = useState([]);

  // const navigate = useNavigate();
  const { isLoading } = useQuery(["user_templates"], () => getTemplates(), {
    onSuccess: (data) => {
      setSmsTemplates(data.smsTemplates?.docs);
    },
  });

  // const template = {
  //   welcome: "Welcome, its great to have you on board",
  //   blank: "Hey there, this slate is your stage",
  //   updates: "Hey there, there’s been some new things you might have missed",
  //   sales: "Hello there, I’m following up after the meeting the other day",
  // };

  return (
    <Box w="full">
      {isLoading ? <ProgressLoader /> : null}

      <Box maxW={"460px"} mx="auto" mb="28px">
        <Text fontSize={"20px"} fontWeight={700} color="grays.8">
          Templates
        </Text>
        <Text color={"grays.12"} fontSize="xs" mt="8px" fontWeight={600}>
          Please select a template you’d like to use
        </Text>
      </Box>

      <Box
        width={"500px"}
        mx={"auto"}
        maxHeight={"50vh"}
        overflowX={"visible"}
        overflowY={"auto"}
        className="thinSB"
        borderColor={"borders.100"}
        // borderRightWidth={"1px"}
      >
        <SimpleGrid p={"24px"} gridTemplateColumns="repeat(2, 1fr)" gap="24px">
          <Center
            flexDir={"column"}
            py={"6px"}
            borderWidth={"1px"}
            borderColor={"borders.100"}
          >
            <Box pt={"4px"} mb={"8px"}>
              <EmptyTemplateIcon height={"38px"} />
            </Box>
            <Text
              mb={"12px"}
              maxWidth={"140px"}
              textAlign={"center"}
              fontWeight={700}
              fontSize={"12px"}
              color={"grays.9"}
            >
              {smsTemplates.length > 0
                ? `Create a new template`
                : `You haven’t created any templates yet`}
            </Text>
            <ButtonPrimary
              buttonProps={{ mb: "6px" }}
              onClick={() => {
                // navigate(`/account/templates/SMS/create`);
                // setCurrentStageData((prevData) => {
                //   return {
                //     ...prevData,
                //     currentStage: "Campaign Builder",
                //   };
                // });
                setCurrentStageData((prevValue) => {
                  return {
                    ...prevValue,
                    data: {
                      ...prevValue?.data,
                      template_uid: "",
                      sms_body: "",
                    },
                    currentStage: "Campaign Builder",
                  };
                });
              }}
            >
              Create Template
            </ButtonPrimary>
          </Center>

          {/* <Center flexDir={"column"}>
            <CustomTemplate
              noHighlight={temp1Shadow}
              onClick={() => {
                setTemp1Shadow(!temp1Shadow);
                setTemp2Shadow(true);
                setTemp3Shadow(true);
                setTemp4Shadow(true);
                if (temp1Shadow) {
                  setCurrentStageData((prevValue) => {
                    return {
                      ...prevValue,
                      data: {
                        ...prevValue?.data,
                        template_uid: template.blank,
                      },
                    };
                  });
                }
              }}
            />
          </Center>
          <Center flexDir={"column"}>
            <CustomTemplate
              variant="welcome-customers"
              noHighlight={temp2Shadow}
              onClick={() => {
                setTemp2Shadow(!temp2Shadow);
                setTemp1Shadow(true);
                setTemp3Shadow(true);
                setTemp4Shadow(true);
                if (temp2Shadow) {
                  setCurrentStageData((prevValue) => {
                    return {
                      ...prevValue,
                      data: {
                        ...prevValue.data,
                        template_uid: template.welcome,
                      },
                    };
                  });
                }
              }}
            />
          </Center>
          <Center flexDir={"column"}>
            <CustomTemplate
              variant="updates"
              noHighlight={temp3Shadow}
              onClick={() => {
                setTemp2Shadow(true);
                setTemp1Shadow(true);
                setTemp3Shadow(!temp3Shadow);
                setTemp4Shadow(true);
                if (temp3Shadow) {
                  setCurrentStageData((prevValue) => {
                    return {
                      ...prevValue,
                      data: {
                        ...prevValue.data,
                        template_uid: template.updates,
                      },
                    };
                  });
                }
              }}
            />
          </Center>
          <Center flexDir={"column"}>
            <CustomTemplate
              variant="sales-followup"
              noHighlight={temp4Shadow}
              onClick={() => {
                setTemp2Shadow(true);
                setTemp1Shadow(true);
                setTemp4Shadow(!temp4Shadow);
                setTemp3Shadow(true);
                if (temp4Shadow) {
                  setCurrentStageData((prevValue) => {
                    return {
                      ...prevValue,
                      data: {
                        ...prevValue.data,
                        template_uid: template.sales,
                      },
                    };
                  });
                }
              }}
            />
          </Center> */}

          <Center flexDir={"column"}>
            <TemplateSMSCard
              uid={"blank"}
              title={"Blank Template"}
              content={"Hey there, this slate is your stage..."}
            />
          </Center>

          {smsTemplates.length > 0
            ? smsTemplates.map((item: any, index: number) => (
                <Center flexDir={"column"} key={index}>
                  <TemplateSMSCard
                    uid={item.uid}
                    title={item.name}
                    content={item.template_body}
                  />
                </Center>
              ))
            : null}
        </SimpleGrid>
      </Box>

      <Box maxW={"460px"} mx="auto">
        <Button
          isDisabled={!currentStageData?.data?.template_uid}
          onClick={() => {
            setCurrentStageData((prevData) => {
              return {
                ...prevData,
                currentStage: "Campaign Builder",
              };
            });
          }}
          w="full"
          mt="40px"
          mb="10px"
          colorScheme={"primary"}
        >
          Continue to campaign builder
        </Button>
      </Box>
    </Box>
  );
};

export default Templates;
