import { Box, chakra, Flex, ListItem, Text, List } from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import { hasCookie } from "cookies-next";
import { useEffect } from "react";
import { PageAnimate } from "../components/animations/pageAnimate";

const NotFound = () => {
  const auth = hasCookie("USER_TOKEN");
  const navigate = useNavigate();
  useEffect(() => {
    auth === false && navigate("/login");
  }, [auth, navigate]);

  return (
    <PageAnimate>
      <Flex
        mt="24px"
        // bg="white"
        rounded="4px"
        w="full"
        h="88vh"
        maxW="1360px"
        mx="auto"
        // py='20px'
      >
        <Box
          m="auto"
          // textAlign={'center'}
          flexDir="column"
          h="50vh"
          maxW="440px"
        >
          <chakra.h1
            textAlign={"center"}
            fontSize={"64px"}
            color="grays.12"
            fontWeight={700}
            mb={"16px"}
            lineHeight={"62px"}
          >
            404
          </chakra.h1>

          <Text
            textAlign={"center"}
            fontSize={"20px"}
            color="grays.8"
            fontWeight={700}
          >
            Page not found...
          </Text>
          <Text
            textAlign={"center"}
            mt="12px"
            mb={"8px"}
            fontSize={"sm"}
            fontWeight={500}
            color="grays.12"
          >
            It looks like we couldn't find the the page you were looking for.
            You can go{" "}
            <Text
              as="span"
              cursor={"pointer"}
              onClick={() => {
                navigate(-1);
              }}
              color="primary.500"
              textDecor={"underline"}
            >
              back
            </Text>
            , or select one of the navigations to redirect to.
          </Text>

          <List>
            <ListItem display={"flex"} alignItems="center">
              <Box boxSize={"5px"} bg="primary.500" rounded="full" mr={2}></Box>
              <Link to="/conversations">
                <Text
                  cursor={"pointer"}
                  color="primary.500"
                  textDecor={"underline"}
                  fontWeight={500}
                >
                  Conversations
                </Text>
              </Link>
            </ListItem>
            <ListItem display={"flex"} alignItems="center">
              <Box boxSize={"5px"} bg="primary.500" rounded="full" mr={2}></Box>
              <Link to="/campaigns">
                <Text
                  cursor={"pointer"}
                  color="primary.500"
                  textDecor={"underline"}
                  fontWeight={500}
                >
                  Campaign
                </Text>
              </Link>
            </ListItem>
            <ListItem display={"flex"} alignItems="center">
              <Box boxSize={"5px"} bg="primary.500" rounded="full" mr={2}></Box>
              <Link to="/contacts">
                <Text
                  cursor={"pointer"}
                  color="primary.500"
                  textDecor={"underline"}
                  fontWeight={500}
                >
                  Contacts
                </Text>
              </Link>
            </ListItem>
            <ListItem display={"flex"} alignItems="center">
              <Box boxSize={"5px"} bg="primary.500" rounded="full" mr={2}></Box>
              <Link to="/reports">
                <Text
                  cursor={"pointer"}
                  color="primary.500"
                  textDecor={"underline"}
                  fontWeight={500}
                >
                  Reports
                </Text>
              </Link>
            </ListItem>
          </List>
        </Box>
      </Flex>
    </PageAnimate>
  );
};

export default NotFound;
