import React, { FC, useMemo } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import dayjs from "dayjs";
import { fuzzyFilter } from "../../utils/table";
// import Loader from "../UI/Loader";
import { SwipeIn } from "../animations";

interface listProps {
  id: number | string;
  uid: string;
  company_uid: string;
  total_contacts_scanned: number;
  contacts_uploaded: number;
  contacts_with_errors: number;
  created_at: Date;
  updated_at: Date;
}

const ImportHistoryList: FC<{
  data: listProps[];
  globalFilter: any;
  setGlobalFilter: any;
}> = ({ data, globalFilter, setGlobalFilter }) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    return [
      {
        header: () => <Text>Date Uploaded</Text>,
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: () => <Text>Contacts uploaded</Text>,
        accessorKey: "contacts_uploaded",
        id: "contacts_uploaded",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: () => <Text>Contacts with error</Text>,
        accessorKey: "contacts_with_errors",
        id: "contacts_with_errors",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: () => <Text>Status</Text>,
        accessorKey: " data_source",
        id: " data_source",
        cell: (props: any) => {
          return (
            <Box>
              {Number(props?.row?.original?.contacts_with_errors) === 0 ? (
                <Text
                  color="#159D6F"
                  borderRadius={"12px"}
                  borderWidth={"0.5px"}
                  borderColor={"#159D6F"}
                  fontSize={"xs"}
                  width={"fit-content"}
                  px={"8px"}
                  py={"4px"}
                >
                  Completed
                </Text>
              ) : (
                <Text
                  color="red.500"
                  borderRadius={"12px"}
                  borderWidth={"0.5px"}
                  borderColor={"red.500"}
                  fontSize={"xs"}
                  width={"fit-content"}
                  px={"8px"}
                  py={"4px"}
                >
                  Not completed
                </Text>
              )}
            </Box>
          );
        },
      },
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
  });

  return (
    <TableContainer
      overflowX="unset"
      overflowY="unset"
      borderWidth={"1px"}
      borderBottomWidth="0px"
      borderColor="borders.200"
    >
      <Table variant={"simple"} position={"relative"}>
        <Thead position={"sticky"} top={0} bg={"white"} boxShadow={"sm"}>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      textTransform="none"
                      color={"grays.12"}
                      fontSize="xs"
                      letterSpacing={"unset"}
                      fontWeight={500}
                      colSpan={header.colSpan}
                    >
                      <Box display={"flex"}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                        {header.column.getCanSort() && (
                          <VStack spacing="2px" my="auto" ml="2">
                            <chakra.button
                              onClick={() => {
                                header.column.toggleSorting();
                              }}
                            >
                              <SortArrowUp width="8px" />
                            </chakra.button>

                            <chakra.button
                              onClick={() => {
                                header.column.toggleSorting();
                              }}
                            >
                              <SortArrowDown width="8px" />
                            </chakra.button>
                          </VStack>
                        )}
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>

        <Tbody>
          {table.getRowModel().rows.map((row, index) => (
            <Tr key={row.id}>
              {row.getVisibleCells().map((cell, index) => (
                <Td
                  key={cell.id}
                  fontSize="xs"
                  color="grays.12"
                  fontWeight={"medium"}
                >
                  <SwipeIn delay={0.2 * index}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </SwipeIn>
                </Td>
              ))}
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default ImportHistoryList;
