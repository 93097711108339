import dayjs from "dayjs";

export function getDate(str: string) {
  if (str === "this_week") {
    let start = dayjs().startOf("w").format("YYYY-MM-DD");
    let end = dayjs().endOf("w").format("YYYY-MM-DD");

    return {
      start,
      end,
    };
  }

  if (str === "last_week") {
    let startOfThisWeek = dayjs().startOf("w");
    let lastweek = startOfThisWeek.subtract(1, "w").format("YYYY-MM-DD");
    let end = startOfThisWeek.subtract(1, "d").format("YYYY-MM-DD");

    return {
      start: lastweek,
      end,
    };
  }

  if (str === "last_month") {
    let month = dayjs().startOf("M");
    let start = month.subtract(1, "M").format("YYYY-MM-DD");
    let end = month.subtract(1, "D").format("YYYY-MM-DD");

    return {
      start,
      end,
    };
  }

  if (str === "last_year") {
    let year = dayjs().startOf("y");
    let start = year.subtract(1, "y").format("YYYY-MM-DD");
    let end = year.subtract(1, "D").format("YYYY-MM-DD");

    return {
      start,
      end,
    };
  }

  // TODO: implement switch statement
  // if all fails, just return
  let start = dayjs().startOf("w").format("YYYY-MM-DD");
  let end = dayjs().endOf("w").format("YYYY-MM-DD");

  return {
    start,
    end,
  };
}

export function removeUnderscores(str: string): string {
  let res2 = str.replaceAll("_", " ");
  let res = res2[0].toUpperCase() + res2.slice(1);
  return res;
}

export function removeSpaces(str: string): string {
  let res2 = str.replaceAll(" ", "_");
  let res = res2[0].toLowerCase() + res2.slice(1);
  return res;
}

export function removeSpacesAndJoin(str: string): string {
  let res3 = str
    .split(" ")
    .map((word, index) => {
      if (index === 0) {
        let fst = word[0].toLowerCase() + word.slice(1);
        return fst;
      } else {
        let newWord = word[0].toUpperCase() + word.slice(1);
        return newWord;
      }
    })
    .join("");
  return res3;
}

export function removeHTMLTags(str: string): string {
  // let stripped = str.replace(/(<([^>]+)>)/gi, "")
  let stripped = new DOMParser().parseFromString(str, "text/html");

  return stripped.body.textContent || "";
}
