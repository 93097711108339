import API from "..";

export const getSenderIDLists = async () => {
  const response = await API.get(
    `/campaign/sms/sender-id/list?page=1&limit=15`
  );

  return response.data.data;
};

export const createSenderID = async (data: { name: string }) => {
  const response = await API.post(`/campaign/sms/sender-id/create`, data);

  return response.data;
};
