import { useState } from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import {
  getContactOpenAPIEvents,
  getSingleContact,
  getSingleContactSegments,
} from "../../API/contacts";
import ContactInfo from "../../components/contacts/SingleContactPage/ContactInfo";
import ConversationHistory from "../../components/contacts/SingleContactPage/ConversationHistory";
import ProgressLoader from "../../components/UI/ProgressLoader";
import { getSingleContactLists } from "../../API/account/list";

const SingleContactPage = () => {
  const [contactEvents, setContactEvents] = useState([]);
  const { id } = useParams();

  const getOpenAPIEvents = useQuery(
    ["get_openapi_events"],
    () => {
      return getContactOpenAPIEvents(id!);
    },
    {
      onSuccess: (data) => {
        setContactEvents(data?.docs);
      },
      enabled: !!id,
    }
  );

  const { isLoading, data } = useQuery(
    ["singleContact", id],
    () => {
      return getSingleContact(id);
    },
    {
      enabled: !!id,
    }
  );
  // console.log("contact events are", contactEvents);

  const contactLists = useQuery(
    ["singleContact_lists", id],
    () => getSingleContactLists(id!),
    {
      enabled: !!id,
    }
  );
  const contactSegments = useQuery(
    ["singleContact_segments", id],
    () => getSingleContactSegments(id!),
    {
      enabled: !!id,
    }
  );

  return (
    <Box pb="24px" h="full">
      <Flex
        w="full"
        minH="full"
        py="12px"
        flexDirection={{ base: "column", md: "row" }}
      >
        {/* 1 */}
        <Box
          bg="white"
          rounded="4px"
          w="full"
          maxW={{ base: "", md: "360px" }}
          h="full"
          pt="16px"
          pb="24px"
          mb={{ base: 10, md: 0 }}
        >
          {!isLoading && data && (
            <ContactInfo
              data={data}
              query={["singleContact", id]}
              refetchConversationHistory={getOpenAPIEvents.refetch}
            />
          )}
          {isLoading && <ProgressLoader />}
        </Box>

        <ConversationHistory
          contactLists={contactLists?.data}
          contactSegments={contactSegments?.data}
          contactEvents={contactEvents}
          isLoading={getOpenAPIEvents.isLoading}
        />
      </Flex>
    </Box>
  );
};

export default SingleContactPage;
