import API from ".";
import { UserAdditionalDataProps } from "../utils/generalProps";

export const sendLoginOTP = async (email: string) => {
  const response = await API.post(`/auth/otp/send`, {
    email,
  });

  return response.data;
};

export const verifyLoginOTP = async (data: {
  email: string;
  code: string | number;
}) => {
  const response = await API.post(`/auth/otp/verify`, {
    ...data,
  });

  return response.data;
};

export const saveAdditionalData = async (data: UserAdditionalDataProps) => {
  const response = await API.post(`/company/info/save`, {
    ...data,
  });

  return response.data;
};
