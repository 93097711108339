import {
  chakra,
  Box,
  Text,
  HStack,
  useDisclosure,
  Button,
  useToast,
  Tag,
} from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FC, ReactNode } from 'react';
import { disconnectSocialAccount } from '../../../API/account/channels';
import { toaster } from '../../../utils/toast';
import CustomModal from '../../UI/CustomModal';

interface Props {
  title: string;
  description: string;
  icon: ReactNode;
  type: 'twitter' | 'facebook' | 'instagram' | 'whatsapp';
  uid: string;
  showBadge?: boolean;
}

const ConnectedSocialCard: FC<Props> = ({
  title,
  description,
  icon,
  type,
  uid,
  showBadge,
}) => {
  const disclosure = useDisclosure();

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation(disconnectSocialAccount, {
    onSuccess(data) {
      const success = { ...data }
      queryClient.invalidateQueries([`${type}Data`]);
      toaster(toast, success)
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors)
    },
  });

  return (
    <>
      <chakra.button
        onClick={disclosure.onOpen}
        textAlign={'left'}
        w='full'
        borderWidth={'1px'}
        borderColor={'rgba(0, 102, 255, 0.24)'}
        rounded='4px'
        pl='23px'
        pt='23px'
        pb='20px'
        pr='18px'
        display={'flex'}
        _hover={{
          borderColor: 'primary.500',
        }}
      >
        {icon}

        <Box ml='15px' mt={-0.4} mr='2'>
          <HStack>
            <Text color='grays.8' fontWeight={700}>
              {title}
            </Text>

            {showBadge && (
              <Tag
                rounded='full'
                bg='grays.12'
                color='white'
                fontSize={'xs'}
                fontWeight={700}
                py={0}
                px='8px'
              >
                PRO
              </Tag>
            )}
          </HStack>

          <Text
            maxW={'243px'}
            fontWeight={500}
            color='grays.12'
            fontSize={'xs'}
            mt='2px'
          >
            {description}
          </Text>
        </Box>

        <HStack
          my='auto'
          ml='auto'
          fontSize={'xs'}
          bg='rgba(80, 85, 92, 0.08)'
          py='4px'
          px='8px'
        >
          <svg
            width='16'
            height='16'
            viewBox='0 0 16 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M9.93398 15.1673H6.06732C5.47399 15.1673 4.71398 14.854 4.30065 14.434L1.56732 11.7006C1.14732 11.2806 0.833984 10.5206 0.833984 9.93398V6.06732C0.833984 5.47399 1.14732 4.71399 1.56732 4.30066L4.30065 1.56732C4.72065 1.14732 5.48066 0.833984 6.06732 0.833984H9.93398C10.5273 0.833984 11.2873 1.14732 11.7007 1.56732L14.434 4.30066C14.854 4.72066 15.1673 5.48066 15.1673 6.06732V9.93398C15.1673 10.5273 14.854 11.2873 14.434 11.7006L11.7007 14.434C11.2807 14.854 10.5273 15.1673 9.93398 15.1673ZM6.06732 1.83398C5.74066 1.83398 5.23398 2.04065 5.00731 2.27399L2.27399 5.00732C2.04732 5.24066 1.83398 5.74066 1.83398 6.06732V9.93398C1.83398 10.2606 2.04065 10.7673 2.27399 10.994L5.00731 13.7273C5.24065 13.954 5.74066 14.1673 6.06732 14.1673H9.93398C10.2606 14.1673 10.7673 13.9606 10.994 13.7273L13.7273 10.994C13.954 10.7606 14.1673 10.2606 14.1673 9.93398V6.06732C14.1673 5.74066 13.9606 5.23399 13.7273 5.00732L10.994 2.27399C10.7607 2.04732 10.2606 1.83398 9.93398 1.83398H6.06732Z'
              fill='#50555C'
            />
            <path
              d='M3.29325 13.2199C3.16659 13.2199 3.03992 13.1732 2.93992 13.0732C2.74659 12.8799 2.74659 12.5599 2.93992 12.3666L12.3666 2.93992C12.5599 2.74659 12.8799 2.74659 13.0733 2.93992C13.2666 3.13326 13.2666 3.45325 13.0733 3.64659L3.64659 13.0732C3.54659 13.1732 3.41992 13.2199 3.29325 13.2199Z'
              fill='#50555C'
            />
          </svg>

          <Text color={'#50555C'} fontWeight={500}>
            Disconnect
          </Text>
        </HStack>
      </chakra.button>

      <CustomModal disclosure={disclosure}>
        <Box mt='42px' pb='40px' textAlign={'center'} w='full'>
          <Text color='grays.8' fontSize={'20px'} fontWeight='bold'>
            Disconnect Channel
          </Text>
          <Text
            mt='5px'
            fontSize={'sm'}
            fontWeight='medium'
            color='grays.12'
            maxW={'240px'}
            mx='auto'
          >
            Are you sure you want to disconnect this channel?
          </Text>

          <Button
            w='full'
            isLoading={isLoading}
            onClick={() => {
              mutate({
                uid,
                social: type,
              });
            }}
            colorScheme={'red'}
            mt='40px'
          >
            Disconnect
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default ConnectedSocialCard;
