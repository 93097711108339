import { chakra } from '@chakra-ui/react';
import { FC } from 'react';

const FullLogo: FC<any> = (props) => {
  return (
    <chakra.svg
      {...props}
      width='169px'
      height='32px'
      viewBox='0 0 169 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1431_21393)'>
        <path
          d='M41.8867 21.4851V5.61352H47.7735C48.4741 5.60843 49.1729 5.68444 49.855 5.83994C50.4853 5.98758 51.0887 6.22813 51.6441 6.55321C52.2075 6.8866 52.6617 7.36876 52.9536 7.9434C53.2881 8.60612 53.4538 9.33715 53.4368 10.075C53.4368 11.4757 52.7905 12.5023 51.4979 13.1545C53.3961 13.8185 54.3452 15.0386 54.3452 16.8148C54.3478 17.2171 54.3098 17.6186 54.2317 18.0137C54.1304 18.4457 53.9595 18.8594 53.7255 19.2397C53.4702 19.6819 53.1239 20.0684 52.7083 20.3753C52.2904 20.6881 51.7101 20.9501 50.9676 21.1613C50.1184 21.3885 49.2401 21.4964 48.3594 21.4815L41.8867 21.4851ZM44.8307 12.0834H47.9414C49.6399 12.0834 50.4892 11.4445 50.4892 10.1665C50.4892 8.76421 49.5678 8.06306 47.7252 8.06306H44.8259L44.8307 12.0834ZM44.8307 18.9616H48.2132C50.3345 18.9616 51.3952 18.1952 51.3952 16.6622C51.3952 15.2912 50.2983 14.6053 48.1045 14.6045H44.8307V18.9616Z'
          fill='#0066FF'
        />
        <path
          d='M57.0581 20.0914C56.0618 19.0379 55.5633 17.5273 55.5625 15.5595C55.5617 13.5917 56.0602 12.0811 57.0581 11.0276C58.0551 9.97413 59.485 9.44739 61.3478 9.44739C63.2106 9.44739 64.6401 9.97413 65.6364 11.0276C66.6334 12.0811 67.1319 13.5913 67.1319 15.5583C67.1319 17.5253 66.6334 19.0359 65.6364 20.0902C64.6401 21.1437 63.2106 21.67 61.3478 21.6693C59.485 21.6685 58.0551 21.1425 57.0581 20.0914ZM59.206 12.5738C58.7396 13.0924 58.5065 14.0895 58.5065 15.5654C58.5065 17.0412 58.7396 18.0364 59.206 18.551C59.6723 19.0664 60.3882 19.3241 61.3538 19.3241C62.3195 19.3241 63.0334 19.0664 63.4957 18.551C63.9572 18.0364 64.1883 17.0412 64.1891 15.5654C64.1899 14.0895 63.9588 13.0924 63.4957 12.5738C63.0318 12.0553 62.3179 11.7956 61.3538 11.7949C60.3898 11.7941 59.6739 12.0537 59.206 12.5738Z'
          fill='#0066FF'
        />
        <path
          d='M68.5209 15.4082C68.5209 13.4764 69.0073 11.9962 69.9802 10.9678C70.953 9.93933 72.3528 9.4251 74.1793 9.4251C74.8618 9.42781 75.5426 9.48984 76.2137 9.61045V5.04456H79.1564V17.6688C79.1564 18.7638 78.732 19.7023 77.8832 20.4844C77.0343 21.2665 75.7361 21.6599 73.9884 21.6646C73.5819 21.6648 73.1759 21.6338 72.7744 21.5719C72.2809 21.4791 71.8047 21.3142 71.3622 21.0827C70.834 20.827 70.3576 20.4809 69.956 20.0609C69.5533 19.6424 69.2143 19.0277 68.9388 18.2167C68.6453 17.3083 68.5041 16.36 68.5209 15.4082ZM71.4636 15.2568C71.4636 16.6552 71.6513 17.6653 72.0266 18.2871C72.4019 18.9088 73.0558 19.2217 73.9884 19.2256C75.4719 19.2256 76.2137 18.7982 76.2137 17.9433V11.8488C75.5781 11.7184 74.9304 11.6519 74.2808 11.6506C73.3627 11.6506 72.6644 11.9169 72.186 12.4495C71.7077 12.9821 71.4669 13.9179 71.4636 15.2568Z'
          fill='#0066FF'
        />
        <path
          d='M80.834 13.1393C80.834 11.945 81.2665 11.028 82.1314 10.3883C82.9964 9.74851 84.0752 9.42863 85.3678 9.42863C86.5383 9.41785 87.6905 9.71066 88.7056 10.2768V12.4471C87.82 12.0553 86.8554 11.8605 85.8824 11.877C84.4786 11.877 83.7768 12.211 83.7768 12.8789C83.7768 13.2132 83.9761 13.4748 84.3808 13.666C84.8488 13.8799 85.3377 14.0477 85.8401 14.167C86.4284 14.3161 87.003 14.5123 87.5579 14.7536C88.1572 15.0224 88.6638 15.4533 89.0173 15.9947C89.4199 16.5766 89.6213 17.3196 89.6213 18.2237C89.6213 19.2178 89.2045 20.0421 88.371 20.6967C87.5374 21.3514 86.4349 21.6783 85.0633 21.6775C83.7037 21.6886 82.3648 21.3529 81.1807 20.7038V18.1709C82.1974 18.8521 83.4029 19.2178 84.6381 19.2197C85.4837 19.2197 86.0342 19.1243 86.2895 18.9335C86.5448 18.7427 86.6724 18.4572 86.6724 18.0771C86.6724 17.7275 86.4711 17.4459 86.0684 17.2324C85.6043 16.995 85.115 16.8074 84.6091 16.6728C84.0389 16.5164 83.4687 16.3279 82.8985 16.1074C82.3199 15.884 81.8153 15.5111 81.4392 15.0292C81.0349 14.5287 80.8332 13.8987 80.834 13.1393Z'
          fill='#0066FF'
        />
        <path
          d='M91.5893 15.7108C91.572 14.7569 91.7115 13.8064 92.0025 12.8952C92.2771 12.0865 92.6158 11.473 93.0184 11.0545C93.7814 10.2877 94.7635 9.76055 95.838 9.54117C96.2375 9.47927 96.6415 9.44828 97.046 9.44849C98.7905 9.44849 100.089 9.83954 100.942 10.6216C101.795 11.4037 102.221 12.345 102.22 13.4454V25.8878H99.2821V21.5296C98.616 21.6718 97.9358 21.7426 97.2538 21.7408C95.4249 21.7408 94.0235 21.2207 93.0498 20.1805C92.0762 19.1403 91.5893 17.6504 91.5893 15.7108ZM94.5321 15.8504C94.5321 17.2136 94.781 18.1642 95.2787 18.7023C95.7764 19.2404 96.4984 19.5094 97.4447 19.5094C98.0597 19.5042 98.6736 19.458 99.2821 19.371V13.1779C99.2821 12.3262 98.55 11.9 97.0859 11.8992H97.0509C96.1191 11.8992 95.4663 12.2093 95.0926 12.8295C94.719 13.4497 94.5321 14.4567 94.5321 15.8504Z'
          fill='#0066FF'
        />
        <path
          d='M104.691 16.1062V9.61047H107.637V16.4722C107.637 17.4225 107.838 18.1064 108.241 18.5241C108.643 18.9417 109.295 19.1501 110.195 19.1494C111.115 19.1494 111.775 18.9409 112.174 18.5241C112.573 18.1072 112.775 17.4233 112.778 16.4722V9.61047H115.722V16.1062C115.722 17.8487 115.235 19.2119 114.262 20.1958C113.288 21.1797 111.934 21.6712 110.2 21.6705C108.465 21.6705 107.116 21.1789 106.151 20.1958C105.186 19.2127 104.699 17.8495 104.691 16.1062Z'
          fill='#0066FF'
        />
        <path
          d='M117.483 17.6042C117.483 17.3566 117.508 17.1096 117.555 16.8663C117.634 16.5348 117.762 16.216 117.934 15.9196C118.142 15.5361 118.427 15.1964 118.77 14.9201C119.123 14.6338 119.647 14.3878 120.341 14.1822C121.135 13.964 121.957 13.8605 122.782 13.8748C123.501 13.8783 124.219 13.9328 124.93 14.0379C124.93 12.5268 124.067 11.7713 122.341 11.7713C120.963 11.7713 119.766 11.9727 118.749 12.3755V10.0292C119.955 9.61393 121.228 9.40938 122.508 9.42503C124.253 9.42503 125.587 9.85518 126.512 10.7155C127.436 11.5758 127.898 12.8248 127.897 14.4625V17.6699C127.902 18.1988 127.792 18.7227 127.575 19.2077C127.358 19.6928 127.039 20.1281 126.637 20.4855C125.796 21.2715 124.504 21.6645 122.759 21.6645C120.955 21.6645 119.626 21.2695 118.773 20.4796C118.361 20.1159 118.033 19.6717 117.811 19.1761C117.588 18.6805 117.477 18.1448 117.483 17.6042ZM120.427 17.2452C120.427 18.5154 121.194 19.1504 122.729 19.1504H122.753C124.217 19.1504 124.948 18.5502 124.948 17.3497V15.8504C124.367 15.7657 123.782 15.7152 123.194 15.699C121.36 15.699 120.437 16.2144 120.427 17.2452Z'
          fill='#0066FF'
        />
        <path
          d='M130.094 21.485V10.6674C130.293 10.5759 130.567 10.4668 130.919 10.3401C131.614 10.1228 132.321 9.94229 133.037 9.79928C134.073 9.56771 135.131 9.44228 136.194 9.42505V11.8769C134.967 11.8926 133.914 12.0244 133.037 12.2723V21.485H130.094Z'
          fill='#0066FF'
        />
        <path
          d='M137.234 15.6756C137.234 13.7704 137.67 12.2485 138.543 11.1097C139.416 9.97098 140.802 9.40161 142.702 9.40161C144.497 9.40161 145.854 9.95534 146.775 11.0628C147.696 12.1702 148.156 13.5334 148.157 15.1524C148.157 15.8187 148.141 16.3107 148.109 16.6282H140.177C140.257 17.6582 140.575 18.3477 141.129 18.6965C141.683 19.0453 142.402 19.2197 143.287 19.2197C144.729 19.2187 146.151 18.9048 147.452 18.3011V20.6017C146.095 21.3149 144.56 21.6712 142.845 21.6704C142.332 21.673 141.819 21.6302 141.313 21.5425C140.791 21.4425 140.289 21.2623 139.825 21.0088C139.321 20.7459 138.87 20.3952 138.496 19.974C138.127 19.5564 137.823 18.9776 137.585 18.2378C137.336 17.4058 137.218 16.542 137.234 15.6756ZM140.177 14.6538H145.107C145.054 13.9129 144.796 13.1998 144.359 12.5902C144.166 12.3207 143.907 12.1025 143.604 11.9556C143.302 11.8086 142.967 11.7376 142.629 11.7491C141.895 11.7491 141.323 11.9775 140.912 12.4342C140.502 12.8909 140.258 13.6308 140.18 14.6538H140.177Z'
          fill='#0066FF'
        />
        <path
          d='M15.7329 18.8173C13.7776 18.8176 11.8534 18.342 10.1361 17.4342C9.78622 17.2486 9.5266 16.9356 9.41434 16.5641C9.30208 16.1927 9.34639 15.7931 9.5375 15.4533C9.72861 15.1136 10.0509 14.8614 10.4334 14.7524C10.8159 14.6434 11.2274 14.6864 11.5773 14.872C12.8527 15.5458 14.2816 15.8988 15.7335 15.8988C17.1855 15.8988 18.6143 15.5458 19.8898 14.872C20.2397 14.6864 20.6511 14.6434 21.0337 14.7524C21.4162 14.8614 21.7385 15.1136 21.9296 15.4533C22.1207 15.7931 22.165 16.1927 22.0527 16.5641C21.9405 16.9356 21.6809 17.2486 21.331 17.4342C19.6133 18.3422 17.6886 18.8177 15.7329 18.8173Z'
          fill='#0066FF'
        />
        <path
          d='M8.01264 32C7.66072 31.9993 7.31304 31.9253 6.99306 31.783C6.56298 31.5977 6.19796 31.2946 5.9428 30.9107C5.68765 30.5268 5.55345 30.0789 5.5567 29.6221C5.55651 29.562 5.56055 29.5021 5.56878 29.4426L6.02904 25.8351C6.08146 25.4538 6.28677 25.108 6.60034 24.873C6.9139 24.6379 7.31036 24.5326 7.70347 24.5799C8.09658 24.6272 8.45456 24.8234 8.69955 25.1256C8.94454 25.4279 9.05672 25.8119 9.01169 26.1941L8.77009 28.0712L11.8578 24.9975C11.9955 24.8601 12.1598 24.7503 12.3411 24.6746C12.5225 24.5988 12.7175 24.5584 12.9149 24.5558C13.1123 24.5532 13.3084 24.5884 13.4918 24.6593C13.6752 24.7303 13.8425 24.8356 13.984 24.9693C14.1255 25.1031 14.2385 25.2625 14.3165 25.4387C14.3946 25.6148 14.4361 25.8042 14.4388 25.9959C14.4415 26.1876 14.4053 26.378 14.3322 26.5561C14.2592 26.7343 14.1507 26.8967 14.013 27.0341L9.69786 31.3313C9.66887 31.3595 9.63987 31.3865 9.60847 31.4123C9.16787 31.7902 8.6005 31.9992 8.01264 32Z'
          fill='#0066FF'
        />
        <path
          d='M25.3193 27.474H12.9393C12.5408 27.474 12.1585 27.3202 11.8767 27.0465C11.5948 26.7729 11.4365 26.4016 11.4365 26.0146C11.4365 25.6275 11.5948 25.2563 11.8767 24.9826C12.1585 24.709 12.5408 24.5552 12.9393 24.5552H25.3193C26.1517 24.5533 26.9495 24.2314 27.5382 23.6598C28.1268 23.0881 28.4583 22.3134 28.4602 21.505V5.9725C28.4583 5.16412 28.1268 4.38937 27.5382 3.81775C26.9495 3.24613 26.1517 2.92417 25.3193 2.92232H6.15013C5.3177 2.92417 4.51991 3.24613 3.93129 3.81775C3.34267 4.38937 3.01114 5.16412 3.00923 5.9725V21.5015C3.01114 22.3099 3.34267 23.0846 3.93129 23.6562C4.51991 24.2279 5.3177 24.5498 6.15013 24.5517H7.52367C7.92224 24.5517 8.30449 24.7054 8.58632 24.9791C8.86815 25.2528 9.02648 25.624 9.02648 26.0111C9.02648 26.3981 8.86815 26.7693 8.58632 27.043C8.30449 27.3167 7.92224 27.4705 7.52367 27.4705H6.15013C4.52053 27.4677 2.95843 26.838 1.80578 25.7193C0.653136 24.6006 0.00383185 23.084 0 21.5015L0 5.9725C0.00287435 4.38936 0.651756 2.87185 1.80451 1.75239C2.95726 0.632932 4.5199 0.00279134 6.15013 0L25.3193 0C26.9496 0.00279134 28.5122 0.632932 29.6649 1.75239C30.8177 2.87185 31.4666 4.38936 31.4694 5.9725V21.5015C31.4666 23.0846 30.8177 24.6021 29.6649 25.7216C28.5122 26.8411 26.9496 27.4712 25.3193 27.474Z'
          fill='#0066FF'
        />
        <path
          d='M105.797 28.9463V29.4965H104.971V24.5587H105.797V26.1906C106.048 25.8281 106.565 25.577 107.126 25.577C108.254 25.577 109.037 26.3583 109.037 27.5643C109.037 28.7703 108.254 29.5528 107.119 29.5528C106.558 29.5528 106.048 29.3018 105.797 28.9463ZM106.995 28.8618C107.707 28.8618 108.203 28.3386 108.203 27.5643C108.203 26.79 107.708 26.261 106.995 26.261C106.283 26.261 105.797 26.7912 105.797 27.5643C105.797 28.3374 106.292 28.8595 106.995 28.8595V28.8618Z'
          fill='#0066FF'
        />
        <path
          d='M110.472 29.4683L108.914 25.6333H109.804L110.892 28.4489L111.979 25.6333H112.847L110.778 30.7658H109.917L110.472 29.4683Z'
          fill='#0066FF'
        />
        <path
          d='M129.785 28.2412H130.589C130.625 28.653 130.934 28.897 131.415 28.897C131.846 28.897 132.112 28.6941 132.112 28.3738C132.112 27.5444 129.893 28.0735 129.893 26.6587C129.893 26.0451 130.452 25.577 131.278 25.577C132.19 25.577 132.758 26.017 132.829 26.7502H132.038C131.981 26.3982 131.716 26.2058 131.292 26.2058C130.929 26.2058 130.688 26.3666 130.688 26.6246C130.688 27.3708 132.921 26.8264 132.921 28.3468C132.921 29.0718 132.303 29.547 131.42 29.547C130.474 29.5528 129.835 29.0366 129.785 28.2412Z'
          fill='#0066FF'
        />
        <path
          d='M133.117 27.569C133.117 26.4052 133.942 25.5817 135.078 25.5817C136.327 25.5817 137.103 26.5202 136.944 27.7156H133.935C133.986 28.4547 134.452 28.8806 135.098 28.8806C135.623 28.8806 135.99 28.6365 136.083 28.2459H136.929C136.764 28.9779 136.125 29.5575 135.097 29.5575C133.885 29.5528 133.117 28.7292 133.117 27.569ZM136.097 27.0997C136.083 26.5554 135.673 26.214 135.078 26.214C134.482 26.214 134.101 26.5284 133.971 27.0997H136.097Z'
          fill='#0066FF'
        />
        <path
          d='M137.391 25.6333H138.216V26.2198C138.457 25.8573 138.97 25.5781 139.545 25.5781C140.39 25.5781 141.032 26.1225 141.032 26.9448V29.4976H140.205V27.1396C140.205 26.6234 139.874 26.2891 139.36 26.2891C138.693 26.2891 138.218 26.7912 138.218 27.7602V29.4964H137.393L137.391 25.6333Z'
          fill='#0066FF'
        />
        <path
          d='M141.426 27.569C141.426 26.363 142.209 25.5817 143.336 25.5817C143.896 25.5817 144.413 25.8328 144.665 26.1953V24.5587H145.491V29.4965H144.665V28.9463C144.413 29.2982 143.903 29.5528 143.343 29.5528C142.209 29.5528 141.426 28.7715 141.426 27.569ZM143.465 28.8595C144.169 28.8595 144.665 28.3363 144.665 27.562C144.665 26.7877 144.169 26.2586 143.465 26.2586C142.761 26.2586 142.257 26.7889 142.257 27.562C142.257 28.3351 142.755 28.8595 143.465 28.8595Z'
          fill='#0066FF'
        />
        <path
          d='M145.914 27.569C145.914 26.4052 146.76 25.5817 147.904 25.5817C148.902 25.5817 149.613 26.119 149.728 26.9894H148.894C148.801 26.5566 148.411 26.2644 147.904 26.2644C147.243 26.2644 146.748 26.7947 146.748 27.5678C146.748 28.3409 147.25 28.8723 147.904 28.8723C148.45 28.8723 148.859 28.5591 148.923 28.1403H149.769C149.675 28.9498 148.964 29.5563 147.909 29.5563C146.755 29.5528 145.914 28.7222 145.914 27.569Z'
          fill='#0066FF'
        />
        <path
          d='M150.121 24.5587H150.946V26.2187C151.098 26.016 151.299 25.8519 151.53 25.7402C151.761 25.6285 152.017 25.5725 152.275 25.577C153.121 25.577 153.762 26.1214 153.762 26.9437V29.4965H152.937V27.1396C152.937 26.6235 152.606 26.2891 152.091 26.2891C151.425 26.2891 150.95 26.7912 150.95 27.7673V29.4965H150.125L150.121 24.5587Z'
          fill='#0066FF'
        />
        <path
          d='M154.193 28.4501C154.193 27.7462 154.71 27.3062 155.874 27.1807L156.829 27.0763V26.9719C156.829 26.5026 156.467 26.2187 155.939 26.2187C155.411 26.2187 155.062 26.4979 155.005 26.9649H154.201C154.308 26.1706 154.998 25.577 155.939 25.577C156.937 25.577 157.647 26.1214 157.647 27.0622V29.4965H156.829V28.9252C156.649 29.3018 156.147 29.5528 155.572 29.5528C154.725 29.5528 154.193 29.127 154.193 28.4501ZM155.679 28.9193C156.355 28.9193 156.829 28.4794 156.829 27.7122V27.6863L155.888 27.7908C155.299 27.8529 155.02 28.0829 155.02 28.4254C155.02 28.7152 155.291 28.9182 155.679 28.9182V28.9193Z'
          fill='#0066FF'
        />
        <path
          d='M164.333 26.9438V29.4965H163.507V27.1326C163.507 26.6235 163.205 26.2891 162.724 26.2891C162.092 26.2891 161.697 26.8605 161.697 27.8142V29.4954H160.871V27.1326C160.871 26.6235 160.576 26.2891 160.095 26.2891C159.464 26.2891 159.068 26.8534 159.068 27.8142V29.4954H158.242V25.6333H159.068V26.2117C159.27 25.8703 159.744 25.577 160.312 25.577C160.9 25.577 161.36 25.8562 161.553 26.3654C161.693 26.1265 161.896 25.9277 162.141 25.7893C162.385 25.6508 162.663 25.5776 162.946 25.577C163.751 25.5747 164.333 26.1214 164.333 26.9438Z'
          fill='#0066FF'
        />
        <path
          d='M164.936 25.6333H165.762V26.1906C166.013 25.8281 166.53 25.577 167.091 25.577C168.218 25.577 169.001 26.3583 169.001 27.5643C169.001 28.7703 168.218 29.5528 167.083 29.5528C166.523 29.5528 166.013 29.3018 165.762 28.9463V30.7659H164.936V25.6333ZM166.961 28.8595C167.672 28.8595 168.169 28.3363 168.169 27.562C168.169 26.7877 167.673 26.2586 166.961 26.2586C166.25 26.2586 165.762 26.7889 165.762 27.562C165.762 28.3351 166.257 28.8595 166.961 28.8595Z'
          fill='#0066FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M120.255 26.2645C120.351 26.1964 121.55 26.0299 121.68 25.9994C122.026 25.9219 122.804 25.8246 123.172 25.7729C123.293 25.7553 123.239 25.7506 123.335 25.7307C123.879 25.6134 125.823 25.3893 126.062 25.2837C126.08 24.2279 126.182 23.8161 125.274 23.9933L122.087 24.6538C121.597 24.7558 119.241 25.2075 118.892 25.3119C118.508 25.4292 118.364 25.638 118.365 26.0592V27.0998C118.581 27.1922 118.803 27.2706 119.03 27.3344L120.371 27.7861C120.605 27.8576 120.821 27.955 121.025 28.0207C121.344 28.1239 123.58 28.8419 123.695 28.9264C123.637 28.9686 123.567 28.9913 123.495 28.9909C123.415 29.0026 123.335 29.0085 123.253 29.0179L121.956 29.2032C121.045 29.3288 116.737 29.9153 116.696 29.9552C116.632 30.0631 116.662 30.5125 116.658 30.679C116.639 31.4627 117.229 31.2469 118.229 31.0721C118.66 30.997 119.134 30.9289 119.558 30.8691C120.388 30.7518 121.353 30.5453 122.143 30.4397L124.123 30.1253C124.861 29.9611 125.518 30.1148 125.519 29.2748V28.0735C125.309 27.9793 125.093 27.8982 124.872 27.8306C124.657 27.7544 124.42 27.6734 124.224 27.6101C123.752 27.4564 123.333 27.3086 122.895 27.1655C122.683 27.0951 122.442 27.0024 122.229 26.9308C122.015 26.8593 121.777 26.7936 121.571 26.7126C121.261 26.5883 120.502 26.3795 120.255 26.261V26.2645Z'
          fill='#0066FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M127.151 27.921C127.118 27.817 127.07 27.7182 127.008 27.6277C126.464 26.4545 126.365 26.9613 127.209 26.2199C127.374 26.0744 127.802 25.7694 127.838 25.6462L125.121 26.0439C125.001 26.0099 122.858 26.3161 122.592 26.3959L122.756 26.4768C123.609 26.7478 126.941 27.9268 127.151 27.9304V27.921Z'
          fill='#0066FF'
        />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M116.492 27.1513C116.506 27.2769 117.076 28.2494 117.071 28.3245C117.065 28.4313 115.849 29.2736 115.781 29.4578L117.179 29.2654C117.404 29.2313 117.649 29.1915 117.864 29.1633C118.951 29.019 120 28.897 121.065 28.694C120.539 28.4804 119.999 28.3006 119.449 28.1556L119.264 28.0734C118.359 27.8048 117.4 27.4435 116.486 27.1478L116.492 27.1513Z'
          fill='#0066FF'
        />
      </g>
      <defs>
        <clipPath id='clip0_1431_21393'>
          <rect width='169' height='32' fill='white' />
        </clipPath>
      </defs>
    </chakra.svg>
  );
};

const IconLogo: FC<any> = (props) => {
  return (
    <chakra.svg
      {...props}
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_1163_21799)'>
        <rect width='32' height='32' rx='4.5' fill='#0066FF' />
        <path
          d='M13.4609 16.0786C14.2198 16.4927 15.0894 16.729 16.0151 16.729C16.9408 16.729 17.8105 16.494 18.5694 16.0786'
          stroke='white'
          strokeWidth='1.57406'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5514 21.3965L12.2907 23.7144C11.9716 23.9921 11.4746 23.7656 11.4746 23.3429L11.7158 21.3977'
          stroke='white'
          strokeWidth='1.57406'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M14.5516 21.3965H21.0351C22.3786 21.3965 23.4687 20.3063 23.4687 18.9628V10.5877C23.4687 9.24419 22.3786 8.15405 21.0351 8.15405H10.9961C9.65264 8.15405 8.5625 9.24419 8.5625 10.5877V18.9628C8.5625 20.3063 9.65264 21.3965 10.9961 21.3965H11.716'
          stroke='white'
          strokeWidth='1.57406'
          strokeMiterlimit='10'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_1163_21799'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </chakra.svg>
  );
};

export { FullLogo, IconLogo };
