import { Box } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { FC } from 'react';
import { singleOptin } from '../../../API/account/optin';
import Loader from '../../UI/Loader';
import DataComp from './DataComp';
import Share from './Share';

interface optinProps {
    id: number;
    uid: string;
    optin_name: string;
    list: { name: string };
    created_at: Date;
    updated_at: Date;
    channel: string;
    keyphrase: string;
    auto_message: string;
}

const MoreOptinDetails: FC<{ data: optinProps }> = ({
    data
}) => {
    const { isLoading, data: viewmore } = useQuery(
        ["singleOptin", data?.id],
        () => {
            return singleOptin(data?.id);
        }
    );

    return (
        <Box mt='28px'>
            {/* Top Box */}
            {isLoading && <Loader />}
            {!isLoading && viewmore && (
                <>
                    <Box borderWidth={'1px'} borderColor='borders.100' rounded='4px' w='full'>
                        <DataComp name='Opt-In Name' value={viewmore?.name} />
                        <DataComp name='Created on' value={dayjs(viewmore?.created_at).format("MMM DD YYYY h:mm A")} />
                        <DataComp name='List assigned' value={viewmore?.list?.name} />
                        <DataComp name='Channel' value={viewmore?.channel} />
                        <DataComp name='Keyphrase' value={viewmore?.keyword} />
                        <DataComp name='Automated Message' value={viewmore?.auto_message} />
                    </Box>
                    <Share shareData={viewmore} fnt={"small"} />
                </>
            )}

        </Box>
    );
};

export default MoreOptinDetails;
