import API from '.';

interface conversationsReturnProps {
  total_conversations: number;
  open_conversations: number;
  closed_conversations: number;
}
interface messagesReturnProps {
  total_messages: number;
  sent_messages: number;
  received_messages: number;
}
interface contactsReturnProps {
  total_contacts: number;
}

export const getConversationReports = async (data: {
  startDate?: string;
  endDate?: string;
  filterBy?: string;
  filterByDuration?: string;
}) => {
  const response = await API.get(
    `/report/conversation/show?start_date=${data?.startDate}&end_date=${data?.endDate}&channel=${data?.filterBy}&date_by=${data?.filterByDuration}`
  );

  return response.data.data as conversationsReturnProps;
};

export const getMessagesReports = async (data: {
  startDate?: string;
  endDate?: string;
  filterBy?: string;
  filterByDuration?: string;
}) => {
  const response = await API.get(
    `/report/message/show?start_date=${data?.startDate}&end_date=${data?.endDate}&channel=${data?.filterBy}&date_by=${data?.filterByDuration}`
  );

  return response.data.data as messagesReturnProps;
};

export const getContactsReports = async (data: {
  startDate?: string;
  endDate?: string;
  filterBy?: string;
  filterByDuration?: string;
}) => {
  const response = await API.get(
    `/report/contact/show?start_date=${data?.startDate}&end_date=${data?.endDate}&channel=${data?.filterBy}&date_by=${data?.filterByDuration}`
  );

  return response.data.data as contactsReturnProps;
};




// export const getTransactionR