import React, { FC } from "react";

const KeyphraseErrorIcon: FC<any> = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="10" fill="#E16B61" />
            <path
                d="M10.1284 11.3333C9.66582 11.3333 9.28223 10.9497 9.28223 10.4872V4.84615C9.28223 4.38359 9.66582 4 10.1284 4C10.5909 4 10.9745 4.38359 10.9745 4.84615V10.4872C10.9745 10.9497 10.5909 11.3333 10.1284 11.3333Z"
                fill="white"
            />
            <path
                d="M10.1282 15.0008C9.98154 15.0008 9.83487 14.9669 9.69949 14.9105C9.5641 14.8541 9.44 14.7751 9.32718 14.6736C9.22564 14.5608 9.14667 14.4479 9.09026 14.3013C9.03385 14.1659 9 14.0192 9 13.8726C9 13.7259 9.03385 13.5792 9.09026 13.4438C9.14667 13.3085 9.22564 13.1844 9.32718 13.0715C9.44 12.97 9.5641 12.891 9.69949 12.8346C9.97026 12.7218 10.2862 12.7218 10.5569 12.8346C10.6923 12.891 10.8164 12.97 10.9292 13.0715C11.0308 13.1844 11.1097 13.3085 11.1662 13.4438C11.2226 13.5792 11.2564 13.7259 11.2564 13.8726C11.2564 14.0192 11.2226 14.1659 11.1662 14.3013C11.1097 14.4479 11.0308 14.5608 10.9292 14.6736C10.8164 14.7751 10.6923 14.8541 10.5569 14.9105C10.4215 14.9669 10.2749 15.0008 10.1282 15.0008Z"
                fill="white"
            />
        </svg>
    );
};

export default KeyphraseErrorIcon;
