import React from 'react';

const ChannelsIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M14.918 17.959H7.41797C4.63464 17.959 3.04297 16.3673 3.04297 13.584V9.08398C3.04297 6.30065 4.63464 4.70898 7.41797 4.70898H9.66797C9.86797 4.70898 10.0596 4.80903 10.1763 4.96737C10.293 5.1257 10.3263 5.34231 10.2596 5.53398C10.168 5.79231 10.1263 6.07565 10.1263 6.37565V9.62567C10.1263 10.259 10.318 10.784 10.6846 11.1506C11.0513 11.5173 11.5763 11.709 12.2096 11.709C12.5513 11.709 12.8346 11.9923 12.8346 12.334V13.334L15.1096 11.809C15.2096 11.7423 15.3346 11.7007 15.4596 11.7007H17.6263C17.9263 11.7007 18.2096 11.659 18.468 11.5673C18.6596 11.5006 18.868 11.5339 19.0346 11.6506C19.2013 11.7673 19.293 11.9589 19.293 12.1589V13.5757C19.293 16.3673 17.7013 17.959 14.918 17.959ZM7.41797 5.95898C5.31797 5.95898 4.29297 6.98398 4.29297 9.08398V13.584C4.29297 15.684 5.31797 16.709 7.41797 16.709H14.918C17.018 16.709 18.043 15.684 18.043 13.584V12.934C17.9096 12.9506 17.768 12.959 17.6263 12.959H15.6513L13.4013 14.4589C13.0346 14.6923 12.5763 14.7173 12.2013 14.509C11.8263 14.309 11.593 13.9174 11.593 13.4924V12.909C10.893 12.8006 10.2763 12.4924 9.80964 12.034C9.21797 11.4424 8.88464 10.584 8.88464 9.62567V6.37565C8.88464 6.23398 8.89297 6.09232 8.9013 5.95898H7.41797Z'
        fill='currentColor'
      />
      <path
        d='M12.75 14.6494C12.5583 14.6494 12.3667 14.5994 12.1917 14.5077C11.8167 14.3077 11.5833 13.916 11.5833 13.491V12.9077C10.8833 12.7993 10.2667 12.491 9.8 12.0327C9.20833 11.441 8.875 10.5827 8.875 9.62437V6.37435C8.875 5.93268 8.94167 5.51602 9.075 5.13269C9.54167 3.82436 10.7083 3.04102 12.2083 3.04102H17.625C19.5833 3.04102 20.9583 4.40768 20.9583 6.37435V9.62437C20.9583 11.1244 20.1833 12.291 18.875 12.7577C18.475 12.891 18.0583 12.9577 17.625 12.9577H15.65L13.4 14.4576C13.2 14.591 12.975 14.6494 12.75 14.6494ZM12.2083 4.29102C11.25 4.29102 10.5417 4.74935 10.2583 5.54102C10.175 5.79102 10.125 6.07435 10.125 6.37435V9.62437C10.125 10.2577 10.3167 10.7827 10.6833 11.1493C11.05 11.516 11.575 11.7077 12.2083 11.7077C12.55 11.7077 12.8333 11.991 12.8333 12.3327V13.3327L15.1083 11.8077C15.2083 11.741 15.3333 11.6994 15.4583 11.6994H17.625C17.925 11.6994 18.2083 11.6577 18.4667 11.566C19.2583 11.2827 19.7083 10.5744 19.7083 9.61603V6.36601C19.7083 5.08267 18.9083 4.28267 17.625 4.28267H12.2083V4.29102Z'
        fill='currentColor'
      />
      <path
        d='M14.168 20.959H8.16797C7.8263 20.959 7.54297 20.6757 7.54297 20.334C7.54297 19.9923 7.8263 19.709 8.16797 19.709H14.168C14.5096 19.709 14.793 19.9923 14.793 20.334C14.793 20.6757 14.5096 20.959 14.168 20.959Z'
        fill='currentColor'
      />
      <path
        d='M11.168 20.958C10.8263 20.958 10.543 20.6747 10.543 20.333V17.333C10.543 16.9913 10.8263 16.708 11.168 16.708C11.5096 16.708 11.793 16.9913 11.793 17.333V20.333C11.793 20.6747 11.5096 20.958 11.168 20.958Z'
        fill='currentColor'
      />
      <path
        d='M14.8672 8.66602C14.5172 8.66602 14.2422 8.38268 14.2422 8.04102C14.2422 7.69935 14.5255 7.41602 14.8672 7.41602C15.2089 7.41602 15.4922 7.69935 15.4922 8.04102C15.4922 8.38268 15.2089 8.66602 14.8672 8.66602Z'
        fill='currentColor'
      />
      <path
        d='M17.207 8.66602C16.857 8.66602 16.582 8.38268 16.582 8.04102C16.582 7.69935 16.8654 7.41602 17.207 7.41602C17.5487 7.41602 17.832 7.69935 17.832 8.04102C17.832 8.38268 17.5487 8.66602 17.207 8.66602Z'
        fill='currentColor'
      />
      <path
        d='M12.5176 8.66602C12.1676 8.66602 11.8926 8.38268 11.8926 8.04102C11.8926 7.69935 12.1759 7.41602 12.5176 7.41602C12.8592 7.41602 13.1426 7.69935 13.1426 8.04102C13.1426 8.38268 12.8676 8.66602 12.5176 8.66602Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ChannelsIcon;
