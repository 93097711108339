import API from "../.";

export interface AccountProps {
  id: number;
  uid: string;
  company_uid: string;
  page_name: string;
  page_id: string;
  payload: string;
  access_token: string;
  status: string;
  created_at: string;
  updated_at: string;
}

// Facebook
export const getFacebookAccounts = async () => {
  const response = await API.get(`/facebook/account/list`);

  return response.data.data as AccountProps[];
};

export const addFacebookAccount = async (data: {
  access_token: string | null;
  page_id: string | null;
  page_name: string | null;
}) => {
  const response = await API.post(`/facebook/account/connect`, data);

  return response.data.data as AccountProps[];
};

// Instagram
export const getInstagramAccounts = async () => {
  const response = await API.get(`/instagram/account/list`);

  return response.data.data as AccountProps[];
};

export const addInstagramAccount = async (data: {
  access_token: string | null;
  page_id: string | null;
  page_name: string | null;
}) => {
  const response = await API.post(`/instagram/account/connect`, data);

  return response.data.data as AccountProps[];
};

// Twitter
export const getTwitterAccounts = async () => {
  const response = await API.get(`/twitter/account/list`);

  return response.data.data as AccountProps[];
};

export const addTwitterAccount = async (data: {
  auth_token: string | null;
  auth_verifier: string | null;
}) => {
  const response = await API.post(`/twitter/account/connect`, data);

  return response.data.data as AccountProps[];
};

export const retrieveConnectionUrl = async (account: string | undefined) => {
  const response = await API.get(`/${account}/connection-url/show`);

  return response.data.data;
};

export const disconnectSocialAccount = async (data: {
  uid: string;
  social: string;
}) => {
  const response = await API.delete(
    `/${data?.social}/account/delete/${data?.uid}`
  );
  return response.data;
};

export const completeSetup = async (phoneNumber: string) => {
  const response = await API.get(
    `/whatsapp/account/connect/quick/complete?phone_number=${phoneNumber}`
  );
  return response.data;
};

export const quickWhatsappConnect = async (data: { phone_number: string }) => {
  const response = await API.post(`/whatsapp/account/connect/quick`, data);
  return response.data.data as AccountProps[];
};

export const getWhatsappConnection = async () => {
  const response = await API.get(`/whatsapp/account/connections`);
  return response.data.data;
};

export const addWhatsapp = async (data: {
  phone_number: string;
  display_name: string;
  facebook_bmid: string;
}) => {
  const response = await API.post(`whatsapp/account/connect/full`, data);

  return response.data.data;
};
