import { Box, Text } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { currentTemplateAtom } from "../../../atoms/templates";
import { createCampaignAtom } from "../../../atoms/campaigns";
import { removeHTMLTags } from "../../../functions";

export function TemplateSMSCard({
  title,
  content,
  uid,
}: {
  title: string;
  content: string;
  uid: string;
}) {
  const [currTemplate, setCurrTemplate] = useRecoilState(currentTemplateAtom);
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  if (currentStageData) {
    // console.log(currentStageData)
  }

  const setCurr = () => {
    setCurrTemplate((prev) => ({
      ...prev,
      uid: uid,
    }));
  };

  return (
    <Box
      onClick={() => {
        setCurr();
        setCurrentStageData((prevValue) => {
          return {
            ...prevValue,
            data: {
              ...prevValue?.data,
              template_uid: uid,
              sms_body: content,
            },
          };
        });
      }}
      cursor="pointer"
      display="grid"
      gridTemplateRows="67% 33%"
      background={`rgba(26, 171, 252, 0.04)`}
      // width="172px"
      width="100%"
      height="158px"
      border={`1px solid rgba(26, 171, 252, 0.16)`}
      transition="200ms ease-in"
      boxShadow={
        currTemplate.uid !== uid
          ? "none"
          : `0 0 0 .25em rgba(26, 171, 252, 0.16)`
      }
      borderRadius="4px"
    >
      <Box
        p="1em 0.8em"
        boxShadow={` 0px -4px 16px rgba(165, 193, 209, 0.24)`}
        borderRadius="8px 8px 0px 0px"
        display="grid"
        alignSelf="end"
        bg="white"
        justifySelf="center"
        width="73%"
        height="80%"
      >
        <Text
          wordBreak={"break-word"}
          fontSize={"9px"}
          color="#7489AA"
          fontWeight="500"
        >
          {title || "title"}
        </Text>
      </Box>

      <Box
        px="0.8em"
        overflowY={"hidden"}
        borderTop={`1px solid rgba(26, 171, 252, 0.16)`}
        display="grid"
        // alignSelf='end'
        bg="white"
        borderRadius="0px 0px 4px 4px"
        color="#171D26"
        fontSize="11px"
        fontWeight="700"
        py={"8px"}
      >
        <Text noOfLines={2}> {removeHTMLTags(content || "content")}</Text>
        {/* <TipTapReadOnly content={content || "content"} /> */}
      </Box>
    </Box>
  );
}
