import { useState, useMemo } from "react";
import {
  Box,
  Heading,
  Table,
  TableContainer,
  Text,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import {
  deleteContactDataEvent,
  editContactDataEvent,
  getContactDataEvents,
} from "../../../API/contacts";
import Loader from "../../../components/UI/Loader";
import NoEventsIcons from "../../../icons/NoEventsIcons";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import { FaCaretDown } from "react-icons/fa";
import CustomModal from "../../../components/UI/CustomModal";
import FormInput from "../../../components/UI/form/FormInput";
import { ButtonPrimary } from "../../../components/UI/Buttons";
import { toaster } from "../../../utils/toast";

const demoData = [
  {
    name: "sent_facebook",
    occurence_count: 1,
    uid: "f80a1956-f77c-42a6-b141-ffab8e915d2d",
  },
  {
    name: "sent_whatsapp",
    occurence_count: 4,
    uid: "cff7a428-62cf-437c-9503-cd468d3bcf66",
  },
  {
    name: "_sent_sms",
    occurence_count: 1,
    uid: "01c675d0-9dbf-4a2e-b4aa-a67ada9bf0e4",
  },
  {
    name: "sent_sms",
    occurence_count: 6,
    uid: "2090b376-7165-4043-aa53-275f370c2a34",
  },
  {
    name: "sentSms",
    occurence_count: 2,
    uid: "46ff9188-6663-4877-830d-666e0f3241bc",
  },
];
function Events() {
  const [eventsArr, setEventsArr] = useState<Array<{}> | []>([]);
  const [data] = useState(demoData);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [globalFilter] = useState("");
  const [currEvent, setCurrEvent] = useState<{
    name: string;
    uid: string;
  } | null>(null);
  const editModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  const queryClient = useQueryClient();
  const toast = useToast();

  const { isLoading } = useQuery(["contact_data_lists"], getContactDataEvents, {
    onSuccess(data) {
      // console.log("we haveeeee", data);
      setEventsArr(data.docs);
    },
  });
  const editEventMutation = useMutation(editContactDataEvent, {
    onSuccess: () => {
      queryClient.invalidateQueries(["contact_data_lists"]);
    },
    onError: (err: {}) => {
      const errors = { ...err };
      toaster(toast, errors);
    },
  });
  const deleteEventMutation = useMutation(deleteContactDataEvent, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["contact_data_lists"]);
      const success = { ...data };
      toaster(toast, success);
    },
    onError: (err: {}) => {
      const errors = { ...err };
      toaster(toast, errors);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Event name",
        accessorKey: "name",
        id: "eventname",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "No. of Occurence",
        accessorKey: "occurence_count",
        id: "occurencecount",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <Menu>
            <MenuButton
              variant={"ghost"}
              _active={{
                bg: "none",
              }}
              _hover={{
                bg: "none",
              }}
              minHeight={"0px"}
              height={"14px"}
              fontWeight={500}
              fontSize={"12px"}
              px={"0px"}
              py={"0px"}
              as={Button}
              rightIcon={<FaCaretDown />}
            >
              View actions
            </MenuButton>
            <MenuList boxShadow={"lg"}>
              <MenuItem
                py={"8px"}
                onClick={() => {
                  editModalDisclosure.onOpen();
                  // setDataToUpdate(props?.row?.original);
                  setCurrEvent({
                    name: props?.row?.original.name,
                    uid: props?.row?.original.uid,
                  });
                }}
              >
                Edit Event
              </MenuItem>
              <MenuItem
                py={"8px"}
                onClick={() => {
                  deleteModalDisclosure.onOpen();
                  setCurrEvent({
                    name: props?.row?.original.name,
                    uid: props?.row?.original.uid,
                  });
                }}
              >
                Delete Event
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ];
  }, [deleteModalDisclosure, editModalDisclosure]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box width={"100%"}>
      <Heading
        as={"h2"}
        fontSize={"20px"}
        color={"grays.9"}
        fontWeight={700}
        mb={"32px"}
      >
        Events
      </Heading>

      <Box boxShadow={"sm"} minHeight={"70vh"}>
        {isLoading ? (
          <Loader />
        ) : !isLoading && eventsArr?.length === 0 ? (
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={"76px"}
          >
            <NoEventsIcons />
            <Text
              my={"12px"}
              textAlign={"center"}
              fontWeight={500}
              fontSize={"16px"}
              color={"grays.12"}
            >
              You haven’t created an Event
            </Text>
          </Box>
        ) : (
          <Box>
            <TableContainer
              borderWidth={"1px"}
              borderColor="borders.200"
              rounded={"8px"}
            >
              <Table variant={"simple"}>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              px={"12px"}
                              textTransform="none"
                              color={"grays.12"}
                              fontSize="xs"
                              letterSpacing={"unset"}
                              fontWeight={500}
                              colSpan={header.colSpan}
                            >
                              <Box display={"flex"}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}

                                {header.column.getCanSort() && (
                                  <VStack spacing="2px" my="auto" ml="2">
                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowUp width="8px" />
                                    </chakra.button>

                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowDown width="8px" />
                                    </chakra.button>
                                  </VStack>
                                )}
                              </Box>
                            </Th>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Td
                          key={cell.id}
                          fontSize="xs"
                          color="grays.12"
                          fontWeight={"medium"}
                          px={"12px"}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      {/* edit modal */}
      <CustomModal
        titleUnderLine
        disclosure={editModalDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Edit Event
          </Text>
        }
      >
        <Box pt={"24px"} pb={"40px"}>
          <FormInput
            label="Name"
            type="text"
            for="name"
            inputProps={{
              value: currEvent?.name || "",
              onChange: (e) => {
                setCurrEvent((prev) => ({
                  ...prev!,
                  name: e.target.value,
                }));
              },
            }}
          />

          <Box pt={"36px"}>
            <ButtonPrimary
              buttonProps={{
                width: "100%",
                maxHeight: "fit-content",
                py: "14px",
                fontSize: "14px",
              }}
              onClick={() => {
                editEventMutation.mutate({
                  uid: currEvent?.uid!,
                  data: { name: currEvent?.name! },
                });
              }}
              isLoading={editEventMutation.isLoading}
            >
              Save Changes
            </ButtonPrimary>
          </Box>
        </Box>
      </CustomModal>

      {/* delete modal */}
      <CustomModal
        titleUnderLine
        disclosure={deleteModalDisclosure}
        title={<Text fontSize={"20px"} color="grays.8" mt="35px"></Text>}
      >
        <Box pt={"24px"} pb={"40px"}>
          <Text fontWeight={700} fontSize={"20px"} textAlign={"center"}>
            Are you sure you want to delete this event?
          </Text>

          <Box pt={"36px"}>
            <ButtonPrimary
              buttonProps={{
                width: "100%",
                maxHeight: "fit-content",
                py: "14px",
                fontSize: "14px",
                colorScheme: "red",
              }}
              onClick={() => {
                deleteEventMutation.mutate(currEvent?.uid!);
              }}
              isLoading={deleteEventMutation.isLoading}
            >
              Delete Event
            </ButtonPrimary>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
}

export default Events;
