import { capitalize } from "./functions";


export const toaster = (toast: any, data: any) => {
  if (data?.response?.data?.status === "error") {
    return toast({
      title:
        capitalize(data?.response?.data?.error?.name) ||
        capitalize(data?.response?.data?.message) ||
        "Error",
      description: capitalize(data?.response?.data?.error?.message) || "Something went wrong, please try again",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    })
  } else {
    return toast({
      title:
        capitalize(data?.message) ||
        "Success",
      status: "success",
      duration: 3000,
      isClosable: true,
      position: "top-right",
    })
  }
};
