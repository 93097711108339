import { Box, Center, Flex, chakra } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { FullLogo } from "../../icons/Logo";

const AuthLayout: FC<{
  children: ReactNode;
  title: string;
  description: string;
}> = ({ children, title, description }) => {
  return (
    <Center flexDir={"column"} w="full" minH="100vh" pt="24px" pb="62px" px={4}>
      <Flex flexDir={"column"} w="full" maxW="440px">
        <Box mx="auto">
          <FullLogo />
        </Box>

        <Box w="full" bg="white" rounded="4px" p="56px" mt="32px">
          <chakra.h1 fontSize={"24px"} color="grays.9" fontWeight={700}>
            {title}
          </chakra.h1>

          <chakra.p fontSize={"sm"} fontWeight={500} mt="8px" color="grays.9">
            {description}
          </chakra.p>

          {children}
        </Box>
      </Flex>
    </Center>
  );
};

export default AuthLayout;
