import React from 'react';

const HelpSupportIcon = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M19.5 17.8333V11.1667C19.5 7.48477 16.5152 4.5 12.8333 4.5H11.1667C7.48477 4.5 4.5 7.48477 4.5 11.1667V17.8333'
        stroke='currentColor'
        strokeWidth='1.25'
        strokeLinecap='round'
      />
      <path
        d='M15.334 14.5007C15.334 13.5802 16.0802 12.834 17.0007 12.834H17.834C18.7545 12.834 19.5007 13.5802 19.5007 14.5007V17.834C19.5007 18.7545 18.7545 19.5007 17.834 19.5007H17.0007C16.0802 19.5007 15.334 18.7545 15.334 17.834V14.5007Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.25'
      />
      <path
        d='M8.66602 14.5007C8.66602 13.5802 7.91982 12.834 6.99935 12.834H6.16602C5.24554 12.834 4.49935 13.5802 4.49935 14.5007V17.834C4.49935 18.7545 5.24554 19.5007 6.16602 19.5007H6.99935C7.91982 19.5007 8.66602 18.7545 8.66602 17.834V14.5007Z'
        fill='currentColor'
        stroke='currentColor'
        strokeWidth='1.25'
      />
    </svg>
  );
};

export default HelpSupportIcon;
