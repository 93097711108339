import { Box, Text, chakra, VStack, Button, useToast } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { createSupportTicket } from "../../API/account/support";
import { getData } from "../../API/data";
import FormInput from "../../components/UI/form/FormInput";
import SearchableSelect from "../../components/UI/form/SearchableSelect";
import { toaster } from "../../utils/toast";

const HelpSupport = () => {
  const [subject, setSubject] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");

  const { data } = useQuery(["bodData"], getData);

  const helpCategories = useMemo(() => {
    return data?.support_ticket_categories?.map((support) => {
      return {
        label: support,
        value: support,
      };
    });
  }, [data?.support_ticket_categories]);

  const toast = useToast();

  const { mutate, isLoading } = useMutation(createSupportTicket, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      setSubject("");
      setCategory("");
      setMessage("");
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <Box w="full" h="full">
      <Box maxW={"360px"} w="full">
        {/* Header */}
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Help & Support
        </Text>

        <Text fontSize={"xs"} mt="8px" color="grays.12" fontWeight={"500"}>
          You can get in touch with the BodSquare team if you have and questions
          or need our help
        </Text>

        {/* form */}
        <chakra.form
          mt="32px"
          onSubmit={(e) => {
            e.preventDefault();

            mutate({
              subject,
              category,
              message,
            });
          }}
        >
          <VStack align={"flex-start"} w="full" spacing={"16px"}>
            <FormInput
              label="Subject"
              type="text"
              for="subject"
              inputProps={{
                placeholder: "Integration help",
                onChange: (e) => {
                  setSubject(e.target.value);
                },
                value: subject,
              }}
              formControlProps={{
                isRequired: true,
              }}
            />

            <SearchableSelect
              label="Category"
              placeholder="Select category"
              options={helpCategories}
              onChange={(selected: { label: string; value: string }) => {
                setCategory(selected?.value);
              }}
            />

            <FormInput
              label="Message"
              type="textarea"
              for="Message"
              textareaProps={{
                placeholder: "Send us a message",
                onChange: (e) => {
                  setMessage(e.target.value);
                },
                value: message,
              }}
              formControlProps={{
                isRequired: true,
              }}
            />
          </VStack>

          <Button
            isDisabled={!subject || !category || !message}
            isLoading={isLoading}
            colorScheme={"primary"}
            w="full"
            mt="32px"
            type="submit"
          >
            Submit
          </Button>
        </chakra.form>
      </Box>
    </Box>
  );
};

export default HelpSupport;
