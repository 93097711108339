import {
  Box,
  Text,
  chakra,
  Center,
  Link as ChakraLink,
  Button,
  Flex,
} from "@chakra-ui/react";
import { useSetRecoilState } from "recoil";
import { importContactsAtom } from "../../../atoms/contacts";

const SampleStage = () => {
  const setImportContactsData = useSetRecoilState(importContactsAtom);

  return (
    <Box w="full" maxW={"380px"} mx="auto">
      <Box textAlign={"center"} mb={"40px"}>
        <Text fontSize={"20px"} fontWeight={700} color="grays.8">
          Check out sample file
        </Text>
        <Text color={"grays.12"} fontSize="xs" mt="8px" fontWeight={500}>
          Try out out sample files below to <br />
          test it out.
        </Text>
      </Box>

      {/*  */}
      <Center>
        <Flex justifyContent={"center"} gap="20px" w="full">
          {/* <ChakraLink
            width={"100%"}
            download
            href={require("./../../../assets/SampleCsv.csv")}
          >
            <Center
              borderWidth={"1px"}
              borderColor="borders.100"
              _hover={{
                borderColor: "primary.500",
              }}
              rounded="4px"
              py="20px"
              px="40px"
              flexDir={"column"}
              textAlign="center"
            >
              <chakra.svg
                width="32px"
                height="32px"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M28 9.33073V22.6641C28 26.6641 26 29.3307 21.3333 29.3307H10.6667C6 29.3307 4 26.6641 4 22.6641V9.33073C4 5.33073 6 2.66406 10.6667 2.66406H21.3333C26 2.66406 28 5.33073 28 9.33073Z"
                  fill="#96BCA1"
                />
                <path
                  d="M24.6673 12.3333H22.0007C19.974 12.3333 18.334 10.6933 18.334 8.66667V6C18.334 5.45333 18.7873 5 19.334 5C19.8807 5 20.334 5.45333 20.334 6V8.66667C20.334 9.58667 21.0807 10.3333 22.0007 10.3333H24.6673C25.214 10.3333 25.6673 10.7867 25.6673 11.3333C25.6673 11.88 25.214 12.3333 24.6673 12.3333Z"
                  fill="#96BCA1"
                />
                <path
                  d="M15.9993 18.3359H10.666C10.1193 18.3359 9.66602 17.8826 9.66602 17.3359C9.66602 16.7893 10.1193 16.3359 10.666 16.3359H15.9993C16.546 16.3359 16.9993 16.7893 16.9993 17.3359C16.9993 17.8826 16.546 18.3359 15.9993 18.3359Z"
                  fill="#96BCA1"
                />
                <path
                  d="M21.3327 23.6641H10.666C10.1193 23.6641 9.66602 23.2107 9.66602 22.6641C9.66602 22.1174 10.1193 21.6641 10.666 21.6641H21.3327C21.8793 21.6641 22.3327 22.1174 22.3327 22.6641C22.3327 23.2107 21.8793 23.6641 21.3327 23.6641Z"
                  fill="#96BCA1"
                />
              </chakra.svg>

              <Text fontSize={"sm"} mt="12px" fontWeight={700} color="grays.8">
                Excel (.xlsx file)
              </Text>
              <Text
                mt="4px"
                fontSize={"10px"}
                borderBottomWidth="1px"
                borderColor={"primary.500"}
                color={"primary.500"}
              >
                Download sample
              </Text>
            </Center>
          </ChakraLink> */}
          <ChakraLink
            download
            width={"100%"}
            href={require("./../../../assets/SampleCsv.csv")}
          >
            <Center
              borderWidth={"1px"}
              borderColor="borders.100"
              _hover={{
                borderColor: "primary.500",
              }}
              rounded="4px"
              py="20px"
              px="40px"
              flexDir={"column"}
              textAlign="center"
            >
              <chakra.svg
                width="32px"
                height="32px"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M28 9.33268V22.666C28 26.666 26 29.3327 21.3333 29.3327H10.6667C6 29.3327 4 26.666 4 22.666V9.33268C4 5.33268 6 2.66602 10.6667 2.66602H21.3333C26 2.66602 28 5.33268 28 9.33268Z"
                  fill="#96B0BC"
                />
                <path
                  d="M24.6673 12.3333H22.0007C19.974 12.3333 18.334 10.6933 18.334 8.66667V6C18.334 5.45333 18.7873 5 19.334 5C19.8807 5 20.334 5.45333 20.334 6V8.66667C20.334 9.58667 21.0807 10.3333 22.0007 10.3333H24.6673C25.214 10.3333 25.6673 10.7867 25.6673 11.3333C25.6673 11.88 25.214 12.3333 24.6673 12.3333Z"
                  fill="#96B0BC"
                />
                <path
                  d="M15.9993 18.334H10.666C10.1193 18.334 9.66602 17.8807 9.66602 17.334C9.66602 16.7873 10.1193 16.334 10.666 16.334H15.9993C16.546 16.334 16.9993 16.7873 16.9993 17.334C16.9993 17.8807 16.546 18.334 15.9993 18.334Z"
                  fill="#96B0BC"
                />
                <path
                  d="M21.3327 23.666H10.666C10.1193 23.666 9.66602 23.2127 9.66602 22.666C9.66602 22.1193 10.1193 21.666 10.666 21.666H21.3327C21.8793 21.666 22.3327 22.1193 22.3327 22.666C22.3327 23.2127 21.8793 23.666 21.3327 23.666Z"
                  fill="#96B0BC"
                />
              </chakra.svg>

              <Text fontSize={"sm"} mt="12px" fontWeight={700} color="grays.8">
                CSV (.csv file)
              </Text>

              <Text
                mt="4px"
                fontSize={"10px"}
                borderBottomWidth="1px"
                borderColor={"primary.500"}
                color={"primary.500"}
              >
                Download sample
              </Text>
            </Center>
          </ChakraLink>
        </Flex>
      </Center>

      <Button
        onClick={() => {
          setImportContactsData((prev) => {
            return {
              ...prev,
              currentStage: "upload",
            };
          });
        }}
        w="full"
        mt="40px"
        mb="10px"
        colorScheme={"primary"}
      >
        Next
      </Button>
    </Box>
  );
};

export default SampleStage;
