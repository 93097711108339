import {
  Avatar,
  Box,
  Flex,
  HStack,
  Text,
  VStack,
  Button,
  MenuButton,
  Menu,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { getListData } from "../../../API/account/list";
import { getListsContactsIsIn } from "../../../API/contacts";
import { addContactToList } from "../../../API/conversations";
import { chatAtom } from "../../../atoms/conversation";
import { SingleConversationContactProps } from "../../../utils/generalProps";
import { toaster } from "../../../utils/toast";
import EditContact from "../../contacts/EditContact";
import CustomModal from "../../UI/CustomModal";
import LabelValue from "./LabelValue";

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  color: string;
  no_of_contacts: number;
  created_at: Date;
  updated_at: Date;
}

const UserInfo: FC<{ data: SingleConversationContactProps }> = ({ data }) => {
  const {
    conversation: { channel, contact },
  } = useRecoilValue(chatAtom);

  const [searchParams] = useSearchParams();

  const [selectedList, setSelectedList] = useState<any>([]);
  const fullname = `${data?.first_name ?? ""} ${data?.last_name ?? ""}`;

  // get all lists
  const { data: listData } = useQuery(["ListData"], getListData);

  // get all list a contact is in
  const { data: contactListData } = useQuery(
    ["contactListData", contact?.uid],
    () => {
      return getListsContactsIsIn(contact?.uid);
    },
    {
      enabled: !!contact?.uid,
    }
  );

  // map lists for select dropdown
  const allLists = useMemo(() => {
    return listData?.map((list: listProps) => {
      return {
        title: {
          name: list?.name,
          color: list?.color,
        },
        value: list?.uid,
      };
    });
  }, [listData]);

  const allSelectedLists = useMemo(() => {
    return contactListData?.map((data: any) => {
      return {
        title: {
          name: data?.list?.name,
          color: data?.list?.color,
        },
        value: data?.list?.uid,
      };
    });
  }, [contactListData]);

  useEffect(() => {
    setSelectedList(allSelectedLists);
  }, [setSelectedList, allSelectedLists]);

  const toast = useToast();
  const queryClient = useQueryClient();

  // Add contact to list
  const { mutate } = useMutation(addContactToList, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["allConversations"]);
      queryClient.invalidateQueries(["listData"]);
      queryClient.invalidateQueries(["contactListData"]);
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const returnUserName = (channel: string) => {
    let username: string | undefined = "";

    switch (channel) {
      case "twitter":
        username = data?.twitter_username;
        break;
      case "instagram":
        username = data.instagram_username;
        break;
      case "facebook":
        username = data.facebook_username;
        break;

      default:
        username = `${data.first_name ?? ""} ${data.last_name ?? ""}`;
    }

    return username;
  };

  const editDisclosure = useDisclosure();

  const getDifferenceBetween = (arr1: any, arr2: any) => {
    function getDifference(a: any, b: any) {
      return a.filter((element: any) => {
        return !b.includes(element);
      });
    }

    const difference = [
      ...getDifference(arr1, arr2),
      ...getDifference(arr2, arr1),
    ];

    return difference;
  };

  return (
    <>
      <Box
        h={"calc(100vh - 184px)"}
        // overflowY={"hidden"}
        bg="white"
        rounded="12px"
        boxShadow={"md"}
      >
        <VStack w={"full"} alignItems={"flex-start"} spacing={"0px"}>
          {/* Header */}
          <Box
            width={"full"}
            pt="20px"
            pb="12px"
            borderBottomWidth="1px"
            borderColor="grays.0"
          >
            <Flex px="16px" flexDir={"column"}>
              <Avatar
                w="46px"
                h="46px"
                size="sm"
                name={returnUserName(channel?.toLowerCase()) || fullname}
                mx="auto"
                src={data?.profile_picture}
              />

              <Text
                mt="12px"
                fontSize={"xs"}
                fontWeight="bold"
                mx="auto"
                color="grays.8"
                textAlign={"center"}
              >
                {returnUserName(channel?.toLowerCase()) || fullname}
              </Text>

              <HStack spacing={"9px"} mt="20px">
                <Text
                  color="grays.8"
                  fontSize={"xs"}
                  fontWeight={600}
                  letterSpacing={"wide"}
                >
                  List:
                </Text>

                <Menu closeOnSelect={false}>
                  <MenuButton
                    as={Button}
                    aria-label="Sort"
                    variant="unstyled"
                    fontWeight={500}
                    fontSize="xs"
                    color="primary.500"
                    height="fit-content"
                    minH="unset"
                  >
                    {!(
                      selectedList &&
                      selectedList[0] &&
                      selectedList[0]?.title?.name
                    ) ? (
                      "Add to List"
                    ) : (
                      <HStack>
                        <Box
                          h="16px"
                          w="16px"
                          rounded="full"
                          bg={selectedList[0]?.title?.color}
                        ></Box>
                        <Text
                          color="grays.9"
                          fontSize={"xs"}
                          textTransform="uppercase"
                          noOfLines={1}
                          textDecor={
                            !selectedList.title?.name ? "underline" : ""
                          }
                        >
                          {selectedList[0]?.title?.name}
                        </Text>

                        {selectedList?.length > 1 && (
                          <Text fontSize={"xs"}>
                            +{selectedList?.length - 1} more
                          </Text>
                        )}
                      </HStack>
                    )}
                  </MenuButton>
                  <MenuList maxW="300px">
                    <MenuOptionGroup
                      defaultValue={selectedList}
                      type="checkbox"
                      onChange={(value: any) => {
                        const selectedArray = value?.map((list: any) => {
                          return JSON.parse(list);
                        });

                        setSelectedList(selectedArray);

                        if (selectedArray?.length > 0) {
                          let diff = getDifferenceBetween(
                            allSelectedLists,
                            value
                          );
                          let listToAddTo = diff[diff.length - 1];

                          mutate({
                            list_uid: JSON.parse(listToAddTo)?.value,
                            contact_uid: data?.uid,
                          });
                        }
                      }}
                    >
                      {allLists?.map(
                        (
                          listData: {
                            title: {
                              name: string;
                              color: string;
                            };
                            value: string;
                          },
                          index: number
                        ) => {
                          return (
                            <MenuItemOption
                              key={`${listData?.title?.color}${index}`}
                              value={JSON.stringify(listData)}
                            >
                              <HStack>
                                <Box
                                  h="16px"
                                  w="16px"
                                  rounded="full"
                                  bg={listData?.title?.color}
                                ></Box>
                                <Text
                                  color="grays.9"
                                  fontSize={"xs"}
                                  textTransform="uppercase"
                                  noOfLines={1}
                                >
                                  {listData?.title?.name}
                                </Text>
                              </HStack>
                            </MenuItemOption>
                          );
                        }
                      )}
                    </MenuOptionGroup>
                  </MenuList>
                </Menu>
              </HStack>
            </Flex>
          </Box>

          <Box
            width={"full"}
            height={"calc(100vh - 344px)"}
            overflowY={"scroll"}
            className="thinSB"
          >
            {/* Contact details */}
            <Box
              mb={"14px"}
              pb="12px"
              borderBottomWidth="1px"
              borderColor={"grays.2"}
            >
              <Text
                borderTopWidth={"1px"}
                borderBottomWidth="1px"
                borderColor={"grays.2"}
                pt="12px"
                pb="8px"
                px="16px"
                fontSize={"xs"}
                color="grays.8"
                fontWeight={600}
                letterSpacing={"wide"}
              >
                Contact Details
              </Text>

              <VStack px="16px" mt="14px" spacing="16px" align={"flex-start"}>
                <LabelValue
                  label="Email address"
                  value={data?.email || "---"}
                  showEditButton
                  editbuttonAction={editDisclosure.onOpen}
                />
                <LabelValue
                  label="Phone number"
                  value={data?.phone_number || "---"}
                  showEditButton
                  editbuttonAction={editDisclosure.onOpen}
                />
              </VStack>
            </Box>

            {/* Channels */}
            <Box pb="12px">
              <Text
                // borderTopWidth={"1px"}
                borderBottomWidth="1px"
                borderColor={"grays.2"}
                pt="12px"
                pb="8px"
                px="16px"
                fontSize={"xs"}
                color="grays.8"
                fontWeight={600}
                letterSpacing={"wide"}
              >
                Channels
              </Text>

              <VStack
                width={"full"}
                px="16px"
                mt="14px"
                spacing="16px"
                align={"flex-start"}
              >
                <LabelValue
                  label="Twitter"
                  value={data?.twitter_username || "---"}
                  showEditButton
                  editbuttonAction={editDisclosure.onOpen}
                />
                <LabelValue
                  label="Instagram"
                  value={data?.instagram_username || "---"}
                  showEditButton
                  editbuttonAction={editDisclosure.onOpen}
                />
                <LabelValue
                  label="Facebook"
                  value={data?.facebook_username || "---"}
                  showEditButton
                  editbuttonAction={editDisclosure.onOpen}
                />
              </VStack>
            </Box>
          </Box>
        </VStack>
      </Box>

      {/* Edit contact  */}
      <CustomModal
        disclosure={editDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Edit Contact
          </Text>
        }
      >
        <EditContact
          data={data}
          close={editDisclosure?.onClose}
          queriesToUpdate={[`singleChatData${searchParams?.get("uid")}`]}
        />
      </CustomModal>
    </>
  );
};

export default UserInfo;
