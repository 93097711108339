import { useState } from "react";
import { Box, Text } from "@chakra-ui/react";
import { Check } from "../../assets/svgs/svg";

type CheckboxProps = {
  isChecked?: boolean;
  text?: any;
  variant?: "circle" | "square" | "checked-circle" | "checked-square";
  props?: any;
  size?: string;
  // display?: any
  onCheck?: () => void;
};

const Checkbox = ({
  isChecked = true,
  text,
  variant,
  size,
  onCheck,
  ...props
}: CheckboxProps) => {
  const [checkAm, setCheckAm] = useState(isChecked);

  const handleClick = () => {
    // setChecked(!checked);
    onCheck && onCheck();
  };

  let borderRadius = "50%";
  let border = "2px solid white";
  let outline = "1px solid #0066FF";
  let display = "inline-block";

  if (variant === "square") {
    borderRadius = "sm";
  }

  if (variant === "checked-circle") {
    border = "none";
    display = "grid";
  }

  if (variant === "checked-square") {
    border = "none";
    borderRadius = "sm";
    display = "grid";
  }

  return (
    <Text columnGap={".5em"} display={"flex"} alignItems="center">
      <Box
        as={"span"}
        display={display}
        placeItems={"center"}
        // display=
        width={size || "18px"}
        height={size || "18px"}
        borderRadius={borderRadius}
        backgroundColor={checkAm ? "#0066FF" : "gray.200"}
        // backgroundColor={isChecked ? "#0066FF" : "gray.200"}
        transition="background-color 0.2s ease-in-out"
        cursor="pointer"
        onClick={() => {
          setCheckAm(!checkAm);
          handleClick();
        }}
        border={border}
        outline={outline}
        {...props}
        children={
          (variant === "checked-circle" || variant === "checked-square") &&
          checkAm && (
            <Check
              style={{
                placeSelf: "center",
                width: "100%",
              }}
            />
          )
        }
      />

      <Text as="span">{text}</Text>
    </Text>
  );
};

export default Checkbox;
