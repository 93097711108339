export const Badge = {
  // Styles for the base style
  baseStyle: {
    borderRadius: 'full',
    py: 1,
    px: 3,
  },
  // Styles for the size variations
  sizes: {},
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
};
