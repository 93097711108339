import { AUTH_ROUTES } from "./routes";
import { removeCookies } from "cookies-next";

const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const formatCur = (
  num: number,
  locale: string,
  currency: string,
  display = "symbol"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency,
    currencyDisplay: display,
  }).format(num);
};

const logoutHandler = () => {
  removeCookies("USER_TOKEN");
  window.location.href = AUTH_ROUTES?.LOGIN;
};

const capitalize = (str: string) => {
  const arr = str?.split(" ");
  for (var i = 0; i < arr?.length; i++) {
    arr[i] = arr[i]?.charAt(0)?.toUpperCase() + arr[i]?.slice(1);
  }
  return arr?.join(" ");
};

const removeTags = (str: string) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};
export { formatBytes, logoutHandler, capitalize, removeTags };
