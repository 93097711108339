import API from "../.";

export const createSupportTicket = async (data: {
  subject: string;
  category: string;
  message: string;
}) => {
  const response = await API.post(`/support/create`, {
    ...data,
  });

  return response.data;
};
