import axios from "axios";
import API from ".";
import { SingleConversationProps } from "../utils/generalProps";

export const getAllConversations = async () => {
  const response = await API.get(`/conversation/list`);
  return response.data.data as SingleConversationProps[];
};

export const getSingleConversation = async (uid: string | null) => {
  const response = await API.get(`/conversation/show/${uid}`);

  return response.data.data;
};

export const deleteConversation = async (uid: string) => {
  const response = await API.delete(`/conversation/delete/${uid}`);

  return response.data;
};

export const searchConversation = async (data: { search: string }) => {
  const response = await API.post(`/conversation/search`, data);
  return response.data.data;
};

export const openConversation = async (data: { conversation_uid: string }) => {
  const response = await API.post(`/conversation/open`, data);

  return response.data;
};

export const closeConversation = async (data: { conversation_uid: string }) => {
  const response = await API.post(`/conversation/close`, data);

  return response.data;
};

export const sendSocialMessage = async (data: {
  social: string;
  message_type: string;
  message: string;
  conversation_uid: string;
}) => {
  const response = await API.post(`/${data?.social}/message/send`, {
    message_type: data?.message_type,
    message: data?.message,
    conversation_uid: data?.conversation_uid,
  });

  return response.data.data;
};
export const addContactToList = async (data: {
  list_uid: string;
  contact_uid: string;
}) => {
  const response = await API.post(`/contact-list/map`, data);

  return response.data;
};

export const uploadChatImage = async (data: FormData) => {
  const response = await axios.post(
    "https://api.cloudinary.com/v1_1/bodsquare/upload",
    data
  );

  return response.data;
};
