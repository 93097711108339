import AuthLayout from "../components/general/AuthLayout";
import {
  PinInput,
  PinInputField,
  HStack,
  chakra,
  Button,
  Text,
  Flex,
  useToast,
} from "@chakra-ui/react";
import { Link, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { sendLoginOTP, verifyLoginOTP } from "../API/auth";
import { DASH_ROUTES } from "../utils/routes";
import { useState } from "react";
import { setCookie } from "cookies-next";
import { toaster } from "../utils/toast";

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams();

  // email
  // @ts-ignore
  const email: string = searchParams?.get("email");

  // code
  const [code, setCode] = useState<string | number>("");

  const toast = useToast();

  const { mutate, isLoading } = useMutation(verifyLoginOTP, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      const { token, is_new } = data?.data;
      setCookie("USER_TOKEN", token);
      window.location.href = `${DASH_ROUTES?.CONVERSATIONS}?created=${!is_new}`;
      localStorage.setItem("confirmedEmail", email);
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  // resend code
  const { mutate: resendCode, isLoading: resendLoading } = useMutation(
    sendLoginOTP,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  return (
    <AuthLayout
      title="Confirm email address"
      description="Please enter the code sent to the email"
    >
      <chakra.form
        onSubmit={(e) => {
          e.preventDefault();

          mutate({
            email,
            code,
          });
        }}
        mt="32px"
      >
        <HStack>
          <PinInput
            onChange={(value) => {
              setCode(value);
            }}
          >
            <PinInputField
              borderRadius={"8px"}
              borderColor="grays.5"
              _hover={{
                borderColor: "grays.5",
              }}
              color="grays.9"
              _placeholder={{
                color: "grays.6",
              }}
              w="56px"
              h="56px"
              py="18px"
              px="13px"
            />
            <PinInputField
              borderRadius={"8px"}
              borderColor="grays.5"
              _hover={{
                borderColor: "grays.5",
              }}
              color="grays.9"
              _placeholder={{
                color: "grays.6",
              }}
              w="56px"
              h="56px"
              py="18px"
              px="13px"
            />
            <PinInputField
              borderRadius={"8px"}
              borderColor="grays.5"
              _hover={{
                borderColor: "grays.5",
              }}
              color="grays.9"
              _placeholder={{
                color: "grays.6",
              }}
              w="56px"
              h="56px"
              py="18px"
              px="13px"
            />
            <PinInputField
              borderRadius={"8px"}
              borderColor="grays.5"
              _hover={{
                borderColor: "grays.5",
              }}
              color="grays.9"
              _placeholder={{
                color: "grays.6",
              }}
              w="56px"
              h="56px"
              py="18px"
              px="13px"
            />
            <PinInputField
              borderRadius={"8px"}
              borderColor="grays.5"
              _hover={{
                borderColor: "grays.5",
              }}
              color="grays.9"
              _placeholder={{
                color: "grays.6",
              }}
              w="56px"
              h="56px"
              py="18px"
              px="13px"
            />
          </PinInput>
        </HStack>

        {/* Resend code */}
        <Flex w="full">
          <HStack mx="auto" mt="24px" spacing={"8px"}>
            <Text as="span" color="grays.9" fontWeight={"medium"} fontSize="xs">
              Didn’t recieve a code?
            </Text>

            <Button
              type="button"
              bg="green.500"
              color="white"
              size="xs"
              colorScheme={"green"}
              rounded="2px"
              py="4px"
              px="10px"
              onClick={() => {
                resendCode(email);
              }}
              isLoading={resendLoading}
              isDisabled={isLoading}
            >
              Resend
            </Button>
          </HStack>
        </Flex>

        <Flex flexDir={"column"}>
          <Button
            isLoading={isLoading}
            isDisabled={code?.toString()?.length !== 5}
            type="submit"
            w="full"
            colorScheme={"primary"}
            mt="40px"
          >
            Continue
          </Button>

          <Link to="/login">
            <Text
              color="primary.500"
              textDecor={"underline"}
              mx="auto"
              mt={3}
              textAlign="center"
              fontSize={"xs"}
              fontWeight={500}
            >
              Back to login
            </Text>
          </Link>
        </Flex>
      </chakra.form>
    </AuthLayout>
  );
};

export default ConfirmEmail;
