import { FC } from "react";
import { Flex } from "@chakra-ui/react";
import { useRecoilState } from "recoil";
import { createCampaignAtom } from "../../../atoms/campaigns";

import SmsCapaign from "../SmsCapaign";
import EmailCampaign from "../EmailCampaign";
import WhatsappCampaign from "../WhatsappCampaign";

const CampaignBuilderStage: FC<{
  disclosure: any;
}> = ({ disclosure }) => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  return (
    <Flex flexDir={"column"} w="full" px={"72px"}>
      {currentStageData.data.channel === "SMS" ? (
        <SmsCapaign
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
        />
      ) : currentStageData.data.channel === "Email" ? (
        <EmailCampaign
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
          disclosure={disclosure}
        />
      ) : (
        <WhatsappCampaign
          currentStageData={currentStageData}
          setCurrentStageData={setCurrentStageData}
        />
      )}
    </Flex>
  );
};

export default CampaignBuilderStage;
