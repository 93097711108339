import {
  Box,
  Center,
  HStack,
  Text,
  chakra,
  VStack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { getProfileData, updateProfileData } from "../../API/account/profile";
import { getData } from "../../API/data";
import CustomPhoneInput from "../../components/UI/form/CustomPhoneInput";
import FormInput from "../../components/UI/form/FormInput";
import SearchableSelect from "../../components/UI/form/SearchableSelect";
import Loader from "../../components/UI/Loader";
import { toaster } from "../../utils/toast";

const Profile = () => {
  const toast = useToast();

  // fetch profile info
  const { isLoading } = useQuery(["profileData"], getProfileData, {
    onSuccess(data) {
      setBusinessName(data?.name);
      setSelectedIndustry(data?.industry);
      setBusinessPhoneNumber(data?.phone_number);
      setBusinessEmail(data?.email);
      setBusinessDescription(data?.description);
      setSelectedCurrency(data?.currency);
    },
  });

  const [businessName, setBusinessName] = useState<any>("");
  const [selectedindustry, setSelectedIndustry] = useState<any>("");
  const [selectedCurrency, setSelectedCurrency] = useState<any>("");

  const [businessPhoneNumber, setBusinessPhoneNumber] = useState<any>("");
  const [businessEmail, setBusinessEmail] = useState<any>("");
  const [businessDescription, setBusinessDescription] = useState<any>("");

  const { data } = useQuery(["bodData"], getData);

  const industries = useMemo(() => {
    return data?.industries?.map((industry) => {
      return {
        label: industry,
        value: industry,
      };
    });
  }, [data?.industries]);

  const currencies = useMemo(() => {
    return data?.currencies?.map((currency) => {
      return {
        label: currency?.currency,
        value: currency?.uid?.toLowerCase(),
      };
    });
  }, [data?.currencies]);

  // update profile info
  const { mutate, isLoading: updateLoading } = useMutation(updateProfileData, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
    },
    onError(data: any) {
      const error = { ...data };
      toaster(toast, error);
    },
  });
  return (
    <Box w="full" h="full">
      <Box maxW={"360px"} w="full">
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Profile Information
        </Text>

        {isLoading ? (
          <Loader />
        ) : (
          <>
            <HStack mt="24px" spacing={"16px"}>
              <Center
                rounded="full"
                bg="rgba(0, 102, 255, 0.08)"
                w="64px"
                h="64px"
              >
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M28.1194 9.30702C26.9861 8.05369 25.0927 7.42702 22.3461 7.42702H22.0261V7.37369C22.0261 5.13369 22.0261 2.36035 17.0127 2.36035H14.9861C9.97273 2.36035 9.97273 5.14702 9.97273 7.37369V7.44035H9.65273C6.89273 7.44035 5.01273 8.06702 3.8794 9.32035C2.5594 10.787 2.5994 12.7604 2.73273 14.107L2.74607 14.2004L2.8794 15.6004C2.89273 15.6137 2.9194 15.6404 2.94607 15.6537C3.38607 15.947 3.8394 16.2404 4.3194 16.507C4.50607 16.627 4.70607 16.7337 4.90607 16.8404C7.18607 18.0937 9.69273 18.9337 12.2394 19.347C12.3594 20.6004 12.9061 22.067 15.8261 22.067C18.7461 22.067 19.3194 20.6137 19.4127 19.3204C22.1327 18.8804 24.7594 17.9337 27.1327 16.547C27.2127 16.507 27.2661 16.467 27.3327 16.427C27.9461 16.0804 28.5194 15.707 29.0794 15.2937C29.1061 15.2804 29.1327 15.2537 29.1461 15.227L29.1994 14.747L29.2661 14.1204C29.2794 14.0404 29.2794 13.9737 29.2927 13.8804C29.3994 12.5337 29.3727 10.6937 28.1194 9.30702ZM17.4527 18.4404C17.4527 19.8537 17.4527 20.067 15.8127 20.067C14.1727 20.067 14.1727 19.8137 14.1727 18.4537V16.7737H17.4527V18.4404ZM11.8794 7.42702V7.37369C11.8794 5.10702 11.8794 4.26702 14.9861 4.26702H17.0127C20.1194 4.26702 20.1194 5.12035 20.1194 7.37369V7.44035H11.8794V7.42702Z"
                    fill="#0066FF"
                  />
                  <path
                    opacity="0.4"
                    d="M27.3342 16.4002C27.2675 16.4402 27.2009 16.4802 27.1342 16.5202C24.7609 17.9069 22.1342 18.8402 19.4142 19.2935C19.3075 20.5735 18.7475 22.0402 15.8275 22.0402C12.9075 22.0402 12.3475 20.5869 12.2409 19.3202C9.69419 18.9202 7.18753 18.0802 4.90753 16.8135C4.70753 16.7069 4.50753 16.6002 4.32086 16.4802C3.84086 16.2135 3.38753 15.9202 2.94753 15.6269C2.92086 15.6135 2.89419 15.5869 2.88086 15.5735L3.69419 24.2535C3.97419 26.9069 5.06753 29.6402 10.9342 29.6402H21.0942C26.9609 29.6402 28.0542 26.9069 28.3342 24.2402L29.1742 15.2002C29.1609 15.2269 29.1342 15.2535 29.1075 15.2669C28.5342 15.6802 27.9475 16.0669 27.3342 16.4002Z"
                    fill="#0066FF"
                  />
                </svg>
              </Center>

              <Box>
                <Text fontWeight={700} fontSize="20px" color="grays.9">
                  {businessName}
                </Text>
                <Text color={"rgba(23, 29, 38, 0.64)"} fontSize="sm">
                  {businessEmail}
                </Text>
              </Box>
            </HStack>

            <chakra.form
              onSubmit={(e) => {
                e.preventDefault();

                mutate({
                  industry: selectedindustry,
                  email: businessEmail,
                  name: businessName,
                  phone_number: businessPhoneNumber,
                  description: businessDescription,
                  currency: selectedCurrency,
                });
              }}
              mt="24px"
            >
              <VStack align={"flex-start"} spacing="20px">
                <FormInput
                  label="Business name"
                  type="text"
                  for="Business name"
                  inputProps={{
                    placeholder: "Enter Business name",
                    onChange: (e) => {
                      setBusinessName(e.target.value);
                    },
                    value: businessName,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />

                <SearchableSelect
                  label="What indsutry is your business in?"
                  options={industries}
                  value={industries?.filter((industry) => {
                    return industry?.value === selectedindustry;
                  })}
                  onChange={(selected: { label: string; value: string }) => {
                    setSelectedIndustry(selected?.value);
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />

                <FormInput
                  label="Business email address"
                  type="email"
                  for="Business email address"
                  inputProps={{
                    placeholder: "Enter Business email address",
                    value: businessEmail,
                    isReadOnly: true,
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />

                <CustomPhoneInput
                  label="Business phone number"
                  value={businessPhoneNumber}
                  onChange={(phone) => {
                    setBusinessPhoneNumber(phone);
                  }}
                />

                <SearchableSelect
                  label="Currency"
                  options={currencies}
                  value={currencies?.filter((currency) => {
                    return (
                      currency?.label?.toLowerCase() ===
                      selectedCurrency?.toLowerCase()
                    );
                  })}
                  onChange={(selected: { label: string; value: string }) => {
                    setSelectedCurrency(selected?.label);
                  }}
                />

                <FormInput
                  label="Business description"
                  type="textarea"
                  for="Business description"
                  textareaProps={{
                    placeholder: "Describe what your business does",
                    onChange: (e) => {
                      setBusinessDescription(e.target.value);
                    },
                    value: businessDescription,
                  }}
                />
              </VStack>

              <Button
                type="submit"
                isLoading={updateLoading || isLoading}
                isDisabled={
                  !businessName ||
                  !selectedindustry ||
                  !businessPhoneNumber ||
                  !businessEmail
                }
                w="full"
                rounded="4px"
                bg="primary.500"
                _disabled={{
                  bg: "primary.400",
                }}
                _hover={{
                  bg: "primary.400",
                }}
                _focus={{
                  bg: "primary.400",
                }}
                color={"white"}
                mt="40px"
                fontSize={"sm"}
              >
                Save changes
              </Button>
            </chakra.form>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Profile;
