import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  chakra,
  HStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getTeammatesData } from "../../../API/account/teammates";
import AddNewTeammate from "../../../components/account/teammates/AddNewTeammate";
import TeammatesTable from "../../../components/account/teammates/TeammatesTable";
import CustomModal from "../../../components/UI/CustomModal";
import ChakraSelect from "../../../components/UI/form/ChakraSelect";
import Empty from "../../../components/UI/Empty";
import Loader from "../../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import { useState } from "react";
import CustomPaginaton from "../../../components/UI/CustomPaginaton";

const Teammates = () => {
  const disclosure = useDisclosure();
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [dataToShow, setDataToShow] = useState(15);

  const { isLoading, data } = useQuery(
    ["listTeammates", myCurrentPage, dataToShow],
    () => {
      return getTeammatesData(myCurrentPage, dataToShow);
    }
  );

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <Box w="full" h="full">
      <Flex>
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Teammates
          <Text fontSize={"12px"} fontWeight={"500"} color="grays.12">
            You can invite teammates, customize permissions, and remove
            teammates from your workspace.
          </Text>
        </Text>

        <Button
          onClick={disclosure.onOpen}
          colorScheme={"primary"}
          ml="auto"
          size={"sm"}
          fontSize="10px"
          w={"128px"}
          px={"60px"}
          isDisabled={isLoading}
        >
          Add new teammate
        </Button>
      </Flex>

      <Box mt="32px">
        {/* Empty */}
        {!isLoading && data?.docs.length === 0 && (
          <Empty
            title="Invite teammates"
            description="Add your teammates to your account
            and manage their permissions."
            buttonText="Invite teammate"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M70.1201 31.08C69.8401 31.04 69.5602 31.04 69.2802 31.08C63.0802 30.88 58.1602 25.8 58.1602 19.56C58.1602 13.2 63.3202 8 69.7202 8C76.0802 8 81.2802 13.16 81.2802 19.56C81.2402 25.8 76.3201 30.88 70.1201 31.08Z"
                  fill="#7489AA"
                />
                <path
                  opacity="0.4"
                  d="M83.1604 58.8016C78.6804 61.8016 72.4004 62.9216 66.6004 62.1616C68.1204 58.8816 68.9204 55.2416 68.9604 51.4016C68.9604 47.4016 68.0804 43.6016 66.4004 40.2816C72.3204 39.4816 78.6004 40.6016 83.1204 43.6016C89.4404 47.7616 89.4404 54.6016 83.1604 58.8016Z"
                  fill="#7489AA"
                />
                <path
                  opacity="0.4"
                  d="M25.7596 31.08C26.0396 31.04 26.3196 31.04 26.5996 31.08C32.7996 30.88 37.7196 25.8 37.7196 19.56C37.7196 13.2 32.5596 8 26.1596 8C19.7996 8 14.5996 13.16 14.5996 19.56C14.6396 25.8 19.5596 30.88 25.7596 31.08Z"
                  fill="#7489AA"
                />
                <path
                  opacity="0.4"
                  d="M26.2005 51.4024C26.2005 55.2824 27.0405 58.9624 28.5605 62.2824C22.9205 62.8824 17.0405 61.6824 12.7205 58.8424C6.40047 54.6424 6.40047 47.8023 12.7205 43.6023C17.0005 40.7223 23.0405 39.5624 28.7205 40.2024C27.0805 43.5624 26.2005 47.3624 26.2005 51.4024Z"
                  fill="#7489AA"
                />
                <path
                  d="M48.4792 63.48C48.1592 63.44 47.7992 63.44 47.4392 63.48C40.0792 63.24 34.1992 57.2 34.1992 49.76C34.1992 42.16 40.3192 36 47.9592 36C55.5592 36 61.7192 42.16 61.7192 49.76C61.7192 57.2 55.8792 63.24 48.4792 63.48Z"
                  fill="#7489AA"
                />
                <path
                  d="M35.4792 71.7625C29.4392 75.8025 29.4392 82.4425 35.4792 86.4425C42.3592 91.0425 53.6392 91.0425 60.5192 86.4425C66.5592 82.4025 66.5592 75.7625 60.5192 71.7625C53.6792 67.1625 42.3992 67.1625 35.4792 71.7625Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={disclosure.onOpen}
          />
        )}

        {isLoading && <Loader />}

        {!isLoading && data?.docs.length > 0 && (
          <>
            <Box h="60vh" overflowY={"auto"}>
              <TeammatesTable
                teammatesData={data?.docs}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Box>
            <HStack
              spacing={"10px"}
              pt="30px"
              ml="auto"
              w="fit-content"
              justifyContent={"end"}
            >
              <Text color="grays.12" fontWeight={500} fontSize="xs">
                Show
              </Text>

              <ChakraSelect
                selectProps={{
                  h: "24px",
                  borderColor: "grays.12",
                  fontWeight: 500,
                  color: "grays.12",
                  value: Number(dataToShow),
                  onChange: (e) => {
                    setDataToShow(Number(e.target.value));
                  },
                }}
              >
                <chakra.option value={15}>15</chakra.option>
                <chakra.option value={20}>20</chakra.option>
                <chakra.option value={25}>25</chakra.option>
                <chakra.option value={30}>30</chakra.option>
                <chakra.option value={50}>50</chakra.option>
              </ChakraSelect>
              <CustomPaginaton
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
                numberOfdataToShow={dataToShow}
                setDataToShow={setDataToShow}
              />
            </HStack>
          </>
        )}
      </Box>

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Invite teammate
          </Text>
        }
      >
        <AddNewTeammate close={disclosure.onClose} />
      </CustomModal>
    </Box>
  );
};

export default Teammates;
