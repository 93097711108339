import { useState, useEffect } from "react";
import {
  Box,
  Button,
  // Divider,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
// import { useMemo } from "react";
import { Outlet, NavLink, useLocation } from "react-router-dom";
import ChannelsIcon from "../../icons/ChannelsIcon";
import TemplatesIcon from "../../icons/TemplatesIcon";
import HelpSupportIcon from "../../icons/HelpSupportIcon";
// import ListsIcon from "../../icons/ListsIcon";
import OptinIcon from "../../icons/Optin";
import ProfileCircle from "../../icons/ProfileCircle";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { ContactDataIcon } from "../../icons/ContactDataIcon";

const Account = () => {
  const [isDataOpen, setIsDataOpen] = useState(false);
  const [isOnContactData, setIsOnContactData] = useState(false);

  const { pathname } = useLocation();
  // console.log("loc issss", pathname.slice(21));
  useEffect(() => {
    const pathN = pathname.slice(21);
    if (
      pathN === "lists" ||
      pathN === "segments" ||
      pathN === "attributes" ||
      pathN === "events" ||
      pathN === "importhistory"
    ) {
      setIsDataOpen(true);
      setIsOnContactData(true);
    }
  }, [pathname]);

  const activeStyle = {
    color: "#0066FF",
    backgroundColor: "rgba(0, 102, 255, 0.03)",
    width: "100%",
    borderRadius: "4px",
    display: "inline-block",
  };

  const normalStyle = {
    color: "#171D26",
    width: "100%",
    borderRadius: "4px",
    display: "inline-block",
  };

  const navLinks = [
    {
      title: "Profile",
      path: "/account/profile",
      icon: <ProfileCircle />,
    },
    {
      title: "Channels",
      path: "/account/channels",
      icon: <ChannelsIcon />,
    },
    {
      title: "Contact Data",
      path: "",
      options: [
        { page: "Lists", path: "/account/contactdata/lists" },
        { page: "Segments", path: "/account/contactdata/segments" },
        { page: "Attributes", path: "/account/contactdata/attributes" },
        { page: "Events", path: "/account/contactdata/events" },
        { page: "Import History", path: "/account/contactdata/importhistory" },
      ],
      icon: <ContactDataIcon />,
      isAbstract: true,
    },
    {
      title: "Sender IDs",
      path: "/account/senderIDs",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.4993 20.9596H9.49935C4.97435 20.9596 3.04102 19.0263 3.04102 14.5013V9.5013C3.04102 4.9763 4.97435 3.04297 9.49935 3.04297H14.4993C19.0243 3.04297 20.9577 4.9763 20.9577 9.5013V14.5013C20.9577 19.0263 19.0243 20.9596 14.4993 20.9596ZM9.49935 4.29297C5.65768 4.29297 4.29102 5.65964 4.29102 9.5013V14.5013C4.29102 18.343 5.65768 19.7096 9.49935 19.7096H14.4993C18.341 19.7096 19.7077 18.343 19.7077 14.5013V9.5013C19.7077 5.65964 18.341 4.29297 14.4993 4.29297H9.49935Z"
            fill="currentColor"
          />
          <path
            d="M13.4665 16.7904H10.5249C9.6082 16.7904 8.8832 16.3404 8.47487 15.5237L7.7332 14.032C7.5582 13.6737 7.19987 13.457 6.79987 13.457H3.6582C3.31654 13.457 3.0332 13.1737 3.0332 12.832C3.0332 12.4904 3.32487 12.207 3.66654 12.207H6.79987C7.67487 12.207 8.4582 12.6904 8.84987 13.4737L9.59154 14.9654C9.7832 15.3487 10.0915 15.5404 10.5249 15.5404H13.4665C13.8665 15.5404 14.2249 15.3237 14.3999 14.9654L15.1415 13.4737C15.5332 12.6904 16.3165 12.207 17.1915 12.207H20.3082C20.6499 12.207 20.9332 12.4904 20.9332 12.832C20.9332 13.1737 20.6499 13.457 20.3082 13.457H17.1915C16.7915 13.457 16.4332 13.6737 16.2582 14.032L15.5165 15.5237C15.1249 16.307 14.3415 16.7904 13.4665 16.7904Z"
            fill="currentColor"
          />
          <path
            d="M13.3927 8.45703H10.6177C10.2677 8.45703 9.98438 8.1737 9.98438 7.83203C9.98438 7.49036 10.2677 7.20703 10.6094 7.20703H13.3844C13.726 7.20703 14.0094 7.49036 14.0094 7.83203C14.0094 8.1737 13.7344 8.45703 13.3927 8.45703Z"
            fill="currentColor"
          />
          <path
            d="M14.0827 10.957H9.91602C9.57435 10.957 9.29102 10.6737 9.29102 10.332C9.29102 9.99036 9.57435 9.70703 9.91602 9.70703H14.0827C14.4243 9.70703 14.7077 9.99036 14.7077 10.332C14.7077 10.6737 14.4243 10.957 14.0827 10.957Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      title: "Templates",
      path: "/account/templates",
      icon: <TemplatesIcon />,
    },
    // {
    //   title: "Lists",
    //   path: "/account/lists",
    //   icon: <ListsIcon />,
    // },
    {
      title: "Optin",
      path: "/account/optin",
      icon: <OptinIcon />,
    },
    {
      title: "Teammates",
      path: "/account/teammates",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.0223 8.57575C16.9972 8.57575 16.9804 8.57575 16.9553 8.57575H16.9134C15.331 8.52552 14.1504 7.30308 14.1504 5.79597C14.1504 4.25537 15.4063 3.00781 16.9385 3.00781C18.4708 3.00781 19.7267 4.26374 19.7267 5.79597C19.7183 7.31145 18.5378 8.53388 17.0306 8.58412C17.0306 8.57575 17.0306 8.57575 17.0223 8.57575ZM16.9385 4.25537C16.0929 4.25537 15.4063 4.94194 15.4063 5.7876C15.4063 6.61651 16.051 7.28634 16.8799 7.31983C16.8883 7.31146 16.9553 7.31146 17.0306 7.31983C17.8428 7.27796 18.4708 6.60814 18.4791 5.7876C18.4791 4.94194 17.7926 4.25537 16.9385 4.25537Z"
            fill="currentColor"
          />
          <path
            d="M17.0306 14.746C16.704 14.746 16.3775 14.7208 16.051 14.6622C15.7077 14.6036 15.4816 14.2771 15.5402 13.9338C15.5988 13.5905 15.9254 13.3645 16.2687 13.4231C17.2985 13.5989 18.387 13.4063 19.1154 12.9207C19.5089 12.6611 19.7183 12.3346 19.7183 12.0081C19.7183 11.6815 19.5006 11.3633 19.1154 11.1038C18.387 10.6182 17.2818 10.4256 16.2435 10.6098C15.9003 10.6768 15.5737 10.4423 15.5151 10.099C15.4565 9.75576 15.6826 9.42923 16.0258 9.37062C17.3906 9.1278 18.8056 9.38735 19.8104 10.0572C20.5472 10.5512 20.9742 11.2545 20.9742 12.0081C20.9742 12.7532 20.5555 13.4649 19.8104 13.9673C19.0484 14.4697 18.0604 14.746 17.0306 14.746Z"
            fill="currentColor"
          />
          <path
            d="M6.94913 8.57631C6.94075 8.57631 6.93238 8.57631 6.93238 8.57631C5.42527 8.52607 4.2447 7.30363 4.23633 5.79652C4.23633 4.25592 5.49225 3 7.02448 3C8.55671 3 9.81263 4.25593 9.81263 5.78815C9.81263 7.30364 8.63207 8.52607 7.12496 8.57631L6.94913 7.94835L7.00774 8.57631C6.99099 8.57631 6.96587 8.57631 6.94913 8.57631ZM7.03285 7.32038C7.08309 7.32038 7.12496 7.32038 7.17519 7.32875C7.92038 7.29526 8.57346 6.62543 8.57346 5.79652C8.57346 4.95087 7.88688 4.26429 7.04123 4.26429C6.19557 4.26429 5.509 4.95087 5.509 5.79652C5.509 6.61706 6.14533 7.27852 6.9575 7.32875C6.96587 7.32038 6.99936 7.32038 7.03285 7.32038Z"
            fill="currentColor"
          />
          <path
            d="M6.9397 14.746C5.90984 14.746 4.92184 14.4697 4.15992 13.9673C3.42311 13.4733 2.99609 12.7616 2.99609 12.0081C2.99609 11.2629 3.42311 10.5512 4.15992 10.0572C5.16466 9.38735 6.57967 9.1278 7.94444 9.37062C8.28772 9.42923 8.51379 9.75576 8.45518 10.099C8.39657 10.4423 8.07003 10.6768 7.72675 10.6098C6.68851 10.4256 5.59167 10.6182 4.85486 11.1038C4.46134 11.3633 4.25202 11.6815 4.25202 12.0081C4.25202 12.3346 4.46971 12.6611 4.85486 12.9207C5.5833 13.4063 6.67177 13.5989 7.70162 13.4231C8.04491 13.3645 8.37145 13.5989 8.43006 13.9338C8.48867 14.2771 8.26261 14.6036 7.91932 14.6622C7.59278 14.7208 7.26624 14.746 6.9397 14.746Z"
            fill="currentColor"
          />
          <path
            d="M11.9988 14.8336C11.9737 14.8336 11.957 14.8336 11.9319 14.8336H11.89C10.3075 14.7833 9.12695 13.5609 9.12695 12.0538C9.12695 10.5132 10.3829 9.26562 11.9151 9.26562C13.4473 9.26562 14.7033 10.5216 14.7033 12.0538C14.6949 13.5693 13.5143 14.7917 12.0072 14.8419C12.0072 14.8336 12.0072 14.8336 11.9988 14.8336ZM11.9151 10.5132C11.0695 10.5132 10.3829 11.1998 10.3829 12.0454C10.3829 12.8743 11.0276 13.5441 11.8565 13.5776C11.8649 13.5693 11.9319 13.5693 12.0072 13.5776C12.8194 13.5358 13.4473 12.8659 13.4557 12.0454C13.4557 11.2081 12.7691 10.5132 11.9151 10.5132Z"
            fill="currentColor"
          />
          <path
            d="M11.9969 21.0071C10.9922 21.0071 9.98742 20.7475 9.20875 20.22C8.47194 19.726 8.04492 19.0227 8.04492 18.2692C8.04492 17.524 8.46356 16.8039 9.20875 16.3099C10.7745 15.2717 13.2277 15.2717 14.7851 16.3099C15.5219 16.8039 15.9489 17.5072 15.9489 18.2608C15.9489 19.006 15.5302 19.726 14.7851 20.22C14.0064 20.7392 13.0016 21.0071 11.9969 21.0071ZM9.90369 17.3649C9.51017 17.6245 9.30085 17.951 9.30085 18.2775C9.30085 18.6041 9.51854 18.9223 9.90369 19.1818C11.034 19.9437 12.9514 19.9437 14.0817 19.1818C14.4753 18.9223 14.6846 18.5957 14.6846 18.2692C14.6846 17.9426 14.4669 17.6245 14.0817 17.3649C12.9598 16.603 11.0424 16.6114 9.90369 17.3649Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      title: "Billings",
      path: "/account/billings",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 21.2977C11.3254 21.2977 10.6681 20.9518 10.2184 20.3464L9.34487 19.1788C9.16324 18.9366 8.92108 18.7983 8.66162 18.781C8.40216 18.7723 8.1427 18.8847 7.93514 19.101L7.44216 18.6599L7.91784 19.101C6.67243 20.4329 5.71243 20.3291 5.25405 20.1474C4.78703 19.9658 4 19.3691 4 17.4491V7.71069C4 3.87069 5.10703 2.70312 8.73081 2.70312H15.2692C18.893 2.70312 20 3.87069 20 7.71069V17.4491C20 19.3604 19.213 19.9572 18.7459 20.1474C18.2876 20.3291 17.3362 20.4329 16.0822 19.101C15.8746 18.8761 15.6151 18.755 15.347 18.781C15.0876 18.7983 14.8368 18.9366 14.6551 19.1788L13.7816 20.3464C13.3319 20.9518 12.6746 21.2977 12 21.2977ZM8.60973 17.475C8.64432 17.475 8.68757 17.475 8.72216 17.475C9.36216 17.5096 9.96757 17.8469 10.3741 18.3918L11.2476 19.5593C11.6714 20.1215 12.32 20.1215 12.7438 19.5593L13.6173 18.3918C14.0324 17.8469 14.6292 17.5096 15.2778 17.475C15.9178 17.4404 16.5578 17.7085 17.0249 18.2102C17.6822 18.9107 18.1059 18.9972 18.2616 18.9366C18.4692 18.8502 18.6941 18.3485 18.6941 17.4491V7.71069C18.6941 4.58853 18.1492 4.00042 15.2605 4.00042H8.73081C5.84216 4.00042 5.2973 4.58853 5.2973 7.71069V17.4491C5.2973 18.3572 5.52216 18.8588 5.72973 18.9366C5.87676 18.9972 6.30919 18.9107 6.96649 18.2102C7.43351 17.7345 8.01297 17.475 8.60973 17.475Z"
            fill="currentColor"
          />
          <path
            d="M15.6593 11.7817H10.9026C10.548 11.7817 10.2539 11.4876 10.2539 11.133C10.2539 10.7784 10.548 10.4844 10.9026 10.4844H15.6593C16.0139 10.4844 16.308 10.7784 16.308 11.133C16.308 11.4876 16.0139 11.7817 15.6593 11.7817Z"
            fill="currentColor"
          />
          <path
            d="M15.6593 8.32073H10.9026C10.548 8.32073 10.2539 8.02668 10.2539 7.67209C10.2539 7.31749 10.548 7.02344 10.9026 7.02344H15.6593C16.0139 7.02344 16.308 7.31749 16.308 7.67209C16.308 8.02668 16.0139 8.32073 15.6593 8.32073Z"
            fill="currentColor"
          />
          <path
            d="M8.34924 8.54223C7.87356 8.54223 7.48438 8.15304 7.48438 7.67736C7.48438 7.20169 7.87356 6.8125 8.34924 6.8125C8.82492 6.8125 9.2141 7.20169 9.2141 7.67736C9.2141 8.15304 8.82492 8.54223 8.34924 8.54223Z"
            fill="currentColor"
          />
          <path
            d="M8.34924 12.0032C7.87356 12.0032 7.48438 11.614 7.48438 11.1383C7.48438 10.6626 7.87356 10.2734 8.34924 10.2734C8.82492 10.2734 9.2141 10.6626 9.2141 11.1383C9.2141 11.614 8.82492 12.0032 8.34924 12.0032Z"
            fill="currentColor"
          />
        </svg>
      ),
    },
    {
      title: "Pricing",
      path: "/account/pricing",
      icon: (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.25 6.75L6.75 17.25"
            stroke="#0066FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M16 17.25C16.6904 17.25 17.25 16.6904 17.25 16C17.25 15.3096 16.6904 14.75 16 14.75C15.3096 14.75 14.75 15.3096 14.75 16C14.75 16.6904 15.3096 17.25 16 17.25Z"
            stroke="#0066FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M8 9.25C8.69036 9.25 9.25 8.69036 9.25 8C9.25 7.30964 8.69036 6.75 8 6.75C7.30964 6.75 6.75 7.30964 6.75 8C6.75 8.69036 7.30964 9.25 8 9.25Z"
            stroke="#0066FF"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ),
    },
    {
      title: "Help & Support",
      path: "/account/helpSupport",
      icon: <HelpSupportIcon />,
    },
    {
      title: "API Key",
      path: "/account/apiKey",
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          width="24px"
          height="24px"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
          />
        </svg>
      ),
    },
  ];

  // const { pathname } = useLocation();

  // const isonAddChannelPage = useMemo(() => {
  //   return pathname.includes("/channels/addChannel");
  // }, [pathname]);

  return (
    <SimpleGrid
      minHeight={"90vh"}
      gap={"2px"}
      gridTemplateColumns={"251px 1fr"}
      spacing={"0px"}
      // maxW="1440px"
      // mx="auto"
      w="full"
      my="24px"
      // px={"40px"}
      maxHeight={"fit-content"}
      borderRadius={"8px"}
      position={"relative"}
      overflow={"hidden"}
    >
      <Box
        bg="white"
        pt={"24px"}
        pb={"32px"}
        w="full"
        borderRightWidth="1px"
        borderRightColor={"#0066FF14"}
      >
        <Heading
          as="h2"
          fontSize={"20px"}
          pl="24px"
          pr={"12px"}
          fontWeight={700}
          color={"grays.9"}
          mb={"28px"} // 36px
        >
          Account
        </Heading>

        <VStack
          // display={isonAddChannelPage ? "none" : "flex"}
          align={"flex-start"}
          px={"12px"}
          w="full"
        >
          {navLinks?.map((navData, index) => {
            if (navData.isAbstract) {
              return (
                <Box key={index} width={"100%"}>
                  <Box
                    _hover={{
                      bg: "rgba(0, 102, 255, 0.03)",
                    }}
                    width={"100%"}
                  >
                    <Button
                      variant={"ghost"}
                      _active={{
                        bg: "none",
                      }}
                      _hover={{
                        bg: "none",
                      }}
                      minHeight={"0px"}
                      px={"0px"}
                      py={"0px"}
                      iconSpacing={"12px"}
                      onClick={() => setIsDataOpen(!isDataOpen)}
                      rightIcon={
                        isDataOpen ? (
                          <FaCaretUp size={"16px"} />
                        ) : (
                          <FaCaretDown size={"16px"} />
                        )
                      }
                      color={isOnContactData ? "#0066FF" : "inherit"}
                    >
                      <HStack
                        spacing={"7px"}
                        py={"9px"}
                        pl={"16px"}
                        fontSize={"16px"}
                        w="full"
                        rounded="4px"
                      >
                        {navData?.icon}
                        <Text fontWeight={500}>{navData.title}</Text>
                      </HStack>
                    </Button>
                  </Box>
                  {isDataOpen ? (
                    <VStack>
                      {navData.options.map((op, index) => (
                        <NavLink
                          key={index}
                          to={op.path}
                          style={({ isActive }) => {
                            if (isActive) {
                              setIsOnContactData(true);
                            }
                            return isActive ? activeStyle : normalStyle;
                          }}
                        >
                          <Text
                            ml={"36px"}
                            // width={"fit-content"}
                            fontWeight={500}
                            fontSize={"14px"}
                            _hover={{
                              bg: "rgba(0, 102, 255, 0.03)",
                            }}
                          >
                            {op.page}
                          </Text>
                        </NavLink>
                      ))}
                    </VStack>
                  ) : null}
                </Box>
              );
            } else {
              return (
                <NavLink
                  key={navData?.path}
                  to={navData?.path}
                  style={({ isActive }) => {
                    if (isActive) {
                      setIsOnContactData(false);
                    }

                    return isActive ? activeStyle : normalStyle;
                  }}
                >
                  <HStack
                    spacing={"7px"}
                    py={"9px"}
                    px={"16px"}
                    fontSize={"16px"}
                    w="full"
                    _hover={{
                      bg: "rgba(0, 102, 255, 0.03)",
                    }}
                    rounded="4px"
                  >
                    {navData?.icon}
                    <Text fontWeight={500}>{navData?.title}</Text>
                  </HStack>
                </NavLink>
              );
            }
          })}

          <Link href="https://community.bodsquare.com/" isExternal>
            <HStack
              spacing={"4px"}
              py="12px"
              px="16px"
              w="full"
              _hover={{
                bg: "rgba(0, 102, 255, 0.03)",
              }}
              rounded="4px"
            >
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="1.5"
                viewBox="0 0 24 24"
                height="24px"
                width="24px"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                ></path>
              </svg>
              <Text fontWeight={500}>Join our community</Text>
            </HStack>
          </Link>
        </VStack>
      </Box>

      <Box
        w="full"
        overflow={"hidden"}
        bg="white"
        maxHeight={"100%"}
        position={"relative"}
      >
        <Box
          // bg="blue.200"
          position={"absolute"}
          top={"10px"}
          bottom={0}
          left={0}
          right={0}
        >
          <Box
            pt={"72px"}
            pb={"32px"}
            px={"64px"}
            height={"100%"}
            position={"relative"}
            // maxHeight={"100%"}
            overflowY={"scroll"}
            className="thinSB"
          >
            <Outlet />
          </Box>
        </Box>
      </Box>
    </SimpleGrid>
  );
};

export default Account;
