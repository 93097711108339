// import { Select } from "@chakra-ui/react";
import { useState } from "react";
import Select from "react-select";
import { Box } from "@chakra-ui/react";

interface SingleDropdownSelectProps {
  options: Array<{
    value: string;
    label: string;
  }>;
  setCountry?: React.Dispatch<React.SetStateAction<string | undefined>>;
  setCurrency?: React.Dispatch<React.SetStateAction<string | undefined>>;
}
export const SingleDropdownSelect = ({
  options,
  setCountry,
  setCurrency,
}: SingleDropdownSelectProps) => {
  const [selectedOption, setSelectedOption] = useState<any>(options[0]?.value);

  return (
    // <Select
    //   placeholder={placeHolder}
    //   _placeholder={{
    //     fontSize: "14px",
    //     fontWeight: 500,
    //     color: "#7489AA",
    //   }}
    //   iconSize={"14px"}
    //   icon={<FaCaretDown />}
    //   {...selectStyles}
    // >
    //   {options.map((item, index) => (
    //     <option key={index} value={item.value} style={{ ...item.styles }}>
    //       {item.title}
    //     </option>
    //   ))}
    // </Select>
    <Box>
      <Select
        options={options}
        onChange={(e) => {
          setSelectedOption(e?.value);
          if (setCountry) setCountry(e?.value);
          if (setCurrency) setCurrency(e?.value);
        }}
        value={options.filter(function (option) {
          return option.value === selectedOption;
        })}
      />
    </Box>
  );
};
