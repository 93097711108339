import React from "react";

const OptinIcon = () => {
    return (
        <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M11.9995 13.4462C11.3495 13.4462 10.7329 13.1962 10.2579 12.7296C9.64955 12.1212 9.40788 11.2462 9.60788 10.3879C9.81622 9.51289 10.5162 8.81288 11.3912 8.60454C12.2412 8.39621 13.1162 8.6379 13.7329 9.25457C14.3495 9.87123 14.5829 10.7379 14.3829 11.5962C14.1745 12.4712 13.4745 13.1712 12.5995 13.3795C12.3995 13.4212 12.1995 13.4462 11.9995 13.4462ZM11.9995 9.77957C11.8995 9.77957 11.7912 9.79623 11.6912 9.82123C11.2746 9.92123 10.9329 10.2629 10.8329 10.6795C10.7246 11.1129 10.8412 11.5379 11.1495 11.8379C11.4579 12.1462 11.8746 12.2546 12.3162 12.1546C12.7329 12.0546 13.0745 11.7129 13.1745 11.2962C13.2829 10.8629 13.1662 10.4379 12.8579 10.1379C12.6245 9.90457 12.3245 9.77957 11.9995 9.77957Z"
                fill="currentColor"
            />
            <path
                d="M7.01745 16.9724C6.86745 16.9724 6.7091 16.9141 6.59244 16.8057C4.97577 15.3057 4.05078 13.189 4.05078 10.989C4.05078 6.60571 7.61744 3.03906 12.0008 3.03906C16.3841 3.03906 19.9508 6.60571 19.9508 10.989C19.9508 13.2057 19.0591 15.2557 17.4424 16.7807C17.1924 17.0141 16.7924 17.0057 16.5591 16.7557C16.3258 16.5057 16.3341 16.1058 16.5841 15.8724C17.9508 14.5891 18.7008 12.8641 18.7008 10.9974C18.7008 7.30572 15.6924 4.2974 12.0008 4.2974C8.30911 4.2974 5.30078 7.30572 5.30078 10.9974C5.30078 12.8807 6.05911 14.6141 7.44244 15.8974C7.69244 16.1307 7.70912 16.5307 7.47578 16.7807C7.35078 16.9057 7.18411 16.9724 7.01745 16.9724Z"
                fill="currentColor"
            />
            <path
                d="M15.3336 14.7469C15.1836 14.7469 15.0253 14.6885 14.9086 14.5802C14.6586 14.3469 14.6419 13.9469 14.8836 13.6969C15.5753 12.9635 15.9586 11.9969 15.9586 10.9969C15.9586 8.81353 14.1836 7.04687 12.0086 7.04687C9.83359 7.04687 8.05859 8.82187 8.05859 10.9969C8.05859 12.0052 8.44192 12.9635 9.13359 13.6969C9.36692 13.9469 9.35859 14.3469 9.10859 14.5802C8.85859 14.8135 8.45859 14.8052 8.22526 14.5552C7.31692 13.5885 6.80859 12.3219 6.80859 10.9969C6.80859 8.1302 9.14192 5.79688 12.0086 5.79688C14.8753 5.79688 17.2086 8.1302 17.2086 10.9969C17.2086 12.3219 16.7086 13.5885 15.7919 14.5552C15.6669 14.6802 15.5003 14.7469 15.3336 14.7469Z"
                fill="currentColor"
            />
            <path
                d="M13.1991 20.961H10.8074C9.85743 20.961 9.01575 20.4276 8.60741 19.5776C8.19908 18.7276 8.30742 17.7359 8.89908 16.9943L10.0991 15.5026C10.5658 14.9193 11.2574 14.5859 12.0074 14.5859C12.7574 14.5859 13.4491 14.9193 13.9158 15.5026L15.1158 16.9943C15.7074 17.7359 15.8241 18.7276 15.4074 19.5776C14.9824 20.4276 14.1408 20.961 13.1991 20.961ZM11.0658 16.2776L9.86575 17.7693C9.57409 18.1359 9.51576 18.611 9.72409 19.0276C9.92409 19.4526 10.3324 19.7026 10.7991 19.7026H13.1907C13.6574 19.7026 14.0658 19.4526 14.2658 19.0276C14.4658 18.6026 14.4158 18.1359 14.1241 17.7693L12.9241 16.2776C12.4741 15.711 11.5241 15.711 11.0658 16.2776Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default OptinIcon;
