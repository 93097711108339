import React, { FC } from "react";

const VisualBuilderIcon: FC<any> = (props) => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M50.4167 37.8112V44.6862C50.4167 48.1237 48.125 50.4154 44.6875 50.4154H13.75C14.6896 50.4154 15.6521 50.2779 16.5458 49.9799C16.7979 49.8883 17.05 49.7966 17.3021 49.682C18.1041 49.3612 18.8834 48.9029 19.5709 48.307C19.7771 48.1466 20.0063 47.9404 20.2125 47.7341L20.3041 47.6425L35.8875 32.082H44.6875C48.125 32.082 50.4167 34.3737 50.4167 37.8112Z"
        fill="#A7A7A7"
      />
      <path
        opacity="0.6"
        d="M42.0985 25.8747L35.8881 32.0852L20.3047 47.6456C21.9089 45.9956 22.9172 43.7268 22.9172 41.2518V19.1143L29.1276 12.9039C31.5568 10.4747 34.811 10.4747 37.2401 12.9039L42.0985 17.7622C44.5276 20.1914 44.5276 23.4456 42.0985 25.8747Z"
        fill="#A7A7A7"
      />
      <path
        d="M17.1882 4.58203H10.3132C6.87565 4.58203 4.58398 6.8737 4.58398 10.3112V41.2487C4.58398 41.8675 4.6527 42.4862 4.76729 43.082C4.83604 43.38 4.9048 43.6779 4.99647 43.9758C5.11105 44.3195 5.22564 44.6633 5.36314 44.9841C5.38606 45.007 5.38608 45.03 5.38608 45.03C5.409 45.03 5.409 45.03 5.38608 45.0529C5.70692 45.6945 6.07356 46.3133 6.50898 46.8862C6.76106 47.1841 7.01318 47.4591 7.26527 47.7341C7.51735 48.0091 7.79232 48.2383 8.09023 48.4675L8.11317 48.4904C8.68609 48.9258 9.30481 49.2925 9.94648 49.6133C9.96939 49.5904 9.96942 49.5904 9.96942 49.6133C10.3132 49.7737 10.6569 49.8883 11.0236 50.0029C11.3215 50.0945 11.6194 50.1633 11.9173 50.232C12.5132 50.3466 13.1319 50.4154 13.7507 50.4154C14.6902 50.4154 15.6527 50.2779 16.5465 49.9799C16.7986 49.8883 17.0506 49.7966 17.3027 49.682C18.1048 49.3612 18.884 48.9029 19.5715 48.307C19.7778 48.1466 20.0069 47.9404 20.2132 47.7341L20.3048 47.6425C21.909 45.9925 22.9173 43.7237 22.9173 41.2487V10.3112C22.9173 6.8737 20.6257 4.58203 17.1882 4.58203ZM13.7507 44.6862C11.8486 44.6862 10.3132 43.1508 10.3132 41.2487C10.3132 39.3466 11.8486 37.8112 13.7507 37.8112C15.6527 37.8112 17.1882 39.3466 17.1882 41.2487C17.1882 43.1508 15.6527 44.6862 13.7507 44.6862Z"
        fill="#A7A7A7"
      />
    </svg>
  );
};
const TextBuilderIcon: FC<any> = (props) => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M51.3327 18.2246V37.778C51.3327 46.2713 46.2694 51.3346 37.776 51.3346H18.2227C17.756 51.3346 17.2893 51.3113 16.846 51.288C13.976 51.1013 11.5493 50.2846 9.63602 48.8846C8.65602 48.208 7.79268 47.3446 7.11602 46.3646C5.50602 44.148 4.66602 41.2546 4.66602 37.778V18.2246C4.66602 10.198 9.19268 5.22797 16.846 4.73797C17.2893 4.6913 17.756 4.66797 18.2227 4.66797H37.776C41.2527 4.66797 44.146 5.50797 46.3627 7.11797C47.3427 7.79464 48.206 8.65797 48.8827 9.63797C50.4927 11.8546 51.3327 14.748 51.3327 18.2246Z"
        fill="#7489AA"
      />
      <path
        d="M26.5767 33.5497H25.7133V18.453H31.71C31.9667 18.453 32.1767 18.663 32.1767 18.9197V20.7397C32.1767 21.6964 32.97 22.4897 33.9267 22.4897C34.8833 22.4897 35.6767 21.6964 35.6767 20.7397V18.8964C35.6767 16.703 33.9033 14.9297 31.71 14.9297H16.2167C14.0233 14.9297 12.25 16.703 12.25 18.8964V20.7164C12.25 21.6964 13.0433 22.4664 14 22.4664C14.9567 22.4664 15.75 21.6964 15.75 20.7164V18.8964C15.75 18.6397 15.96 18.4297 16.2167 18.4297H22.2133V33.5497H19.88C18.9233 33.5497 18.13 34.343 18.13 35.2997C18.13 36.2564 18.9233 37.0497 19.88 37.0497H26.5533C27.51 37.0497 28.3033 36.2564 28.3033 35.2997C28.3033 34.343 27.5333 33.5497 26.5767 33.5497Z"
        fill="#7489AA"
      />
      <path
        d="M40.4137 24.2617H32.0137C31.057 24.2617 30.2637 25.0551 30.2637 26.0117C30.2637 26.9684 31.057 27.7617 32.0137 27.7617H33.1337V37.5617H32.317C31.3603 37.5617 30.567 38.3551 30.567 39.3117C30.567 40.2684 31.3603 41.0617 32.317 41.0617H37.4503C38.407 41.0617 39.2003 40.2684 39.2003 39.3117C39.2003 38.3551 38.407 37.5617 37.4503 37.5617H36.6337V27.7617H40.2503V28.5551C40.2503 29.5117 41.0437 30.3051 42.0003 30.3051C42.957 30.3051 43.7503 29.5117 43.7503 28.5551V27.5984C43.7503 25.7551 42.257 24.2617 40.4137 24.2617Z"
        fill="#7489AA"
      />
    </svg>
  );
};

const ColorPink: FC<any> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#FF7E7D" />
    </svg>
  );
};
const ColorYellow: FC<any> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#FEE11E" />
    </svg>
  );
};
const ColorGreen: FC<any> = (props) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="6" cy="6" r="6" fill="#27D34C" />
    </svg>
  );
};

export {
  VisualBuilderIcon,
  TextBuilderIcon,
  ColorPink,
  ColorYellow,
  ColorGreen,
};
