import API from ".";

export interface WalletBalanceProps {
  exchange_rate: {
    amount: number;
    rate: number;
    base_currency: string;
    business_currency: string;
    base_amount: number;
    business_amount: number;
  };

  wallet: {
    id: number;
    uid: string;
    company_uid: string;
    available_balance: number;
    created_at: string;
    updated_at: string;
  };
}
interface initializeProps {
  code: number;
  message: string;
  status: string;
  data: {
    transaction_id: string;
    amount: number;
    provider: string;
  };
}

export const getWalletBalance = async () => {
  const response = await API.get(`/wallet/show`);

  return response.data.data as WalletBalanceProps;
};

export const getWalletTransactions = async (data: {
  page: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?page=${data?.page}&limit=${data?.limit}&type=credit`
  );

  return response.data.data;
};
export const exportWalletTransactions = async (data: {
  startDate?: string;
  endDate?: string;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?start_date=${data.startDate}&end_date=${data.endDate}&type=credit&export=true`
  );
  return response.data;
};

export const filterTransactionHistory = async (data: {
  startDate?: string;
  endDate?: string;
  page?: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?start_date=${data.startDate}&end_date=${data.endDate}&page=${data?.page}&limit=${data?.limit}`
  );

  return response.data;
};

export const getTransactionHistory = async (data: {
  page?: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?page=${data?.page}&limit=${data?.limit}&type=debit`
  );

  return response.data.data;
};

export const getLowestAmountFundableByCurrency = async (
  currency: string | undefined
) => {
  const response = await API.get(`/wallet/lowest-amount/show/${currency}`);

  return response.data.data as {
    lowest_amount: number;
  };
};
export const initializeWalletPayment = async (data: {
  amount: number;
  currency: string;
}) => {
  const response = await API.post("/wallet/payment/initiate", data);

  return response.data as initializeProps;
};

export const creditWalletPayment = async (data: {
  amount: number;
  transaction_uid: string;
  currency: string;
  provider_transaction_id: string | number;
}) => {
  const response = await API.post("/wallet/credit", data);

  return response.data;
};

export const exportWalletList = async (data: {
  exportFlag?: boolean;
  filterByDuration?: string;
  dateFilter?: [Date, Date] | any;
}) => {
  const { exportFlag, filterByDuration, dateFilter } = data;

  const dateBy = filterByDuration
    ? `${filterByDuration}h`
    : `${dateFilter[0].toISOString()},${dateFilter[1].toISOString()}`;
  const response = await API.get(
    `/wallet/transaction/list?date_by=${dateBy}&export=${exportFlag}`
  );

  return response.data;
};

export const exportTransactionHistoy = async (data: {
  startDate: string;
  endDate: string;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?start_date=${data.startDate}&end_date=${data.endDate}&export=true`
  );
  return response.data;
};
