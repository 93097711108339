/* eslint-disable react/prop-types */
import { DropDownWrapper, OptionLink } from "./dropDown.styled";
import { useState } from "react";
import "./style.css";
import CustomButton from "../Button.component";
import { Badge, Box, Flex, Text, chakra } from "@chakra-ui/react";
import Checkbox from "../CustomCheck.component";
import styled from "@emotion/styled";

export const DropDownMenu = ({
  menuListOptions,
  menuTitle,
  linkTo,
  menuBorder,
  menuBG,
  menuFontSize,
  menuColor,
  menuPad,
  menuColorOnHover,
  optionColor,
  optionPadding,
  shadow,
  rowGap,
  OptionLinkFont,
  OptionLinkColor,
  listOptionRole,
  listOptionMarkUp,
  menuOptionStyle,
  svgIcon,
  floatLeft,
  floatRight,
  checked = false,
  btnVariant = "primary",
  MenubtnStyle,
  customComponent,
  optionName,
  optionValue,
  onOptionClick,
  optionTitle,
  optionLabel,
  ...menuBtnProps
}) => {
  const [isDropDown, setIsDropDown] = useState("");
  // const [isChecked, setIsChecked] = useState(true);

  return (
    <DropDownWrapper
      optionColor={optionColor}
      optionPadding={optionPadding}
      shadow={shadow}
      rowGap={rowGap}
      OptionLinkFont={OptionLinkFont}
      OptionLinkColor={OptionLinkColor}
      menuBG={menuBG}
      menuBorder={menuBorder}
      menuFontSize={menuFontSize}
      menuColor={menuColor}
      menuPad={menuPad}
      menuColorOnHover={menuColorOnHover}
      floatLeft={floatLeft}
      floatRight={floatRight}
      // style
    >
      <CustomButton
        // color={'white'}
        size={"sm"}
        // colorScheme="primary"
        variant={btnVariant}
        id={isDropDown ? "drop-down" : ""}
        onClick={() => setIsDropDown(!isDropDown)}
        // onBlur={() => setIsDropDown("")}
        display="flex"
        alignItems="center"
        justifyContent={menuTitle ? "space-between" : ""}
        columnGap="1em"
        style={MenubtnStyle}
        {...menuBtnProps}
      >
        {menuTitle}
        {svgIcon}
      </CustomButton>
      <>
        <ScrollBox
          style={{
            overflow: "hidden",
            paddingBottom: "5px",
            ...menuOptionStyle,
          }}
          onMouseEnter={() => setIsDropDown("drop-down")}
          onMouseLeave={() => setIsDropDown("")}
          // onBlur={() => setIsDropDown("")}
          onBlur={() => setIsDropDown("")}
        >
          {customComponent && customComponent}
          {optionTitle && (
            <Box
              pl=".6em"
              mt=".4em"
              fontSize="12px"
              fontWeight="700"
              color="#7489AA"
            >
              {optionTitle}
            </Box>
          )}
          {menuListOptions?.length > 0 ? (
            menuListOptions.map((option, index) => (
              <chakra.li key={index}>
                {optionLabel && (
                  <Flex
                    justifyContent={"space-between"}
                    alignItems="center"
                    cursor={"pointer"}
                  >
                    <Text color="#7489AA" mb=".15em">
                      {option.optionLabel}
                    </Text>
                    <Badge
                      color={option?.status === "approved" ? "#159D6F" : ""}
                      background={
                        option?.status === "approved"
                          ? "rgba(21, 157, 111, 0.04)"
                          : ""
                      }
                      border={
                        option?.status === "approved"
                          ? "0.5px solid #159D6F"
                          : ""
                      }
                      size="sm"
                    >
                      {option?.status}
                    </Badge>
                  </Flex>
                )}
                {linkTo ? (
                  <OptionLink to={option.link || "#"}>
                    {option.name || option.label || option[optionName]}
                  </OptionLink>
                ) : (
                  <Flex
                    fontWeight="500"
                    fontSize="14px"
                    data-key={option.value}
                    onClick={option.onClick || onOptionClick}
                    // onChage
                    style={
                      option.style || {
                        cursor: "default",
                      }
                    }
                    role={listOptionRole}
                  >
                    {checked ? (
                      <Checkbox
                        onCheck={option.onCheck}
                        variant="checked-square"
                        size="11px"
                        text={option.name || option.label || option[optionName]}
                      />
                    ) : (
                      option.name || option.label || option[optionName]
                    )}
                  </Flex>
                )}
              </chakra.li>
            ))
          ) : listOptionMarkUp ? (
            { listOptionMarkUp }
          ) : (
            <li>No Options</li>
          )}
        </ScrollBox>
      </>
    </DropDownWrapper>
  );
};

export const ScrollBox = styled(Box)`
  overflow-x: scroll;
  #cell-2-undefined {
    /* background-color: red !important; */
    /* justify-content: end; */
    padding-right: 3.8rem;
  }
  div[data-column-id] {
    white-space: nowrap !important;
  }
  div[data-column-id="2"] {
    justify-content: end;
    /* background-color: green; */
  }
  & > div {
    margin-top: ${({ table }) => table && "1em"};
  }
  &::-webkit-scrollbar {
    width: 0.3em;
    height: 0.7em;
  }

  &::-webkit-scrollbar-track {
    border-radius: 100vw;
    margin-block: 0.5em;
  }

  &::-webkit-scrollbar-thumb {
    /* background: #8cb3edd1; */
    background: ${({ scrollHue }) => scrollHue || `#8cb3edd1`};
    /* border: 0.25em solid #8cb3edd1; */
    border: ${({ scrollHue }) => `0.25em solid ${scrollHue}` || `#8cb3edd1`};
    border-radius: 100vw;
  }

  &::-webkit-scrollbar-thumb:hover {
    /* background: #0066FF; */
    background: ${({ _bgHover }) => _bgHover || `#0066FF`};
  }
  max-height: ${({ scrollMaxH }) => scrollMaxH || `15em`};
`;
