import { chakra, VStack, Button, useToast, Text, Box } from "@chakra-ui/react";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { FC, useState, useMemo } from "react";
import FormInput from "../../UI/form/FormInput";
import SearchableSelect from "../../UI/form/SearchableSelect";
import { useRecoilValue } from "recoil";
import { createOptin, validateOptinKeyword } from "../../../API/account/optin";
import { profileAtom } from "../../../atoms/user";
import { toaster } from "../../../utils/toast";
import { getListData } from "../../../API/account/list";
import KeyphraseErrorIcon from "../../../icons/KeyphraseErrorIcon";
import KeyphraseSuccessIcon from "../../../icons/KeyphraseSuccessIcon";
import OptinLikeSuccessIcon from "../../../icons/optinLikeSuccessIcon";
import Share from "./Share";
import useDebounce from "../../../utils/useDebounce";
import { getWhatsappConnection } from "../../../API/account/channels";

const CreateOptinForm: FC<{
  close?: () => void;
  queriesToUpdate?: string[];
}> = () => {
  const [optinName, setOptinName] = useState("");
  const [optinNumber, setOptinNumber] = useState("");
  const [keyphrase, setKeyphrase] = useState("");
  const [automatedText, setAutomatedText] = useState("");
  const [stage, setStage] = useState(1);
  const [optinData, setOptinData] = useState<any>("");

  const toast = useToast();
  const queryClient = useQueryClient();
  const [listUid, setListUid] = useState("");
  const profileData = useRecoilValue(profileAtom);
  const debouncedSearch = useDebounce(keyphrase, 500);
  let regex = /^\S*$/;
  const { data } = useQuery(
    ["validateKeyphrase", debouncedSearch],
    () => {
      return validateOptinKeyword(keyphrase);
    },
    {
      enabled: !!keyphrase,
    }
  );

  const { data: listData } = useQuery(["listData"], getListData);
  const { data: whatsappNumbers } = useQuery(
    ["whatsappNumbers"],
    getWhatsappConnection
  );

  const numbers = useMemo(() => {
    return whatsappNumbers?.map((sender: any) => {
      return {
        label: sender?.phone_number,
        value: sender?.phone_number,
      };
    });
  }, [whatsappNumbers]);

  const allLists = useMemo(() => {
    return listData?.map((list: any) => {
      return {
        label: list?.name,
        value: list?.uid,
      };
    });
  }, [listData]);

  const { mutate, isLoading } = useMutation(createOptin, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      setStage(2);
      setOptinData(success);
      queryClient.invalidateQueries(["listOptin"]);
      setListUid("");
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });
  const reg = regex.test(keyphrase);
  return (
    <>
      {stage === 1 ? (
        <chakra.form
          mt="24px"
          onSubmit={(e) => {
            e.preventDefault();
            mutate({
              optin_name: optinName,
              channel_value: optinNumber,
              list_uid: listUid,
              keyword: keyphrase,
              auto_message: automatedText,
              company_uid: profileData?.uid,
              channel: "whatsapp",
            });
          }}
        >
          <VStack align={"flex-start"} spacing="20px">
            <FormInput
              label="Opt-In name"
              type="text"
              for="Opt-In name"
              inputProps={{
                placeholder: "Enter Opt-In name",
                value: optinName,
                onChange: (e) => {
                  setOptinName(e.target.value);
                },
              }}
              formControlProps={{
                isRequired: true,
              }}
            />
            <SearchableSelect
              label="What Whatsapp number do you want to use?"
              placeholder="Enter phone number"
              isRequired
              options={numbers}
              value={numbers?.filter(
                (list: { label: string; value: string }) => {
                  return list?.value === optinNumber;
                }
              )}
              onChange={(e: { label: string; value: string }) => {
                setOptinNumber(e.value);
              }}
            />

            <SearchableSelect
              label="What list would you like to assign to this Opt-In?"
              placeholder="Select list"
              isRequired
              options={allLists}
              value={allLists?.filter(
                (list: { label: string; value: string }) => {
                  return list?.value === listUid;
                }
              )}
              onChange={(e: { label: string; value: string }) => {
                setListUid(e.value);
              }}
            />
            <Box position={"relative"} w={"full"}>
              <FormInput
                label="Keyphrase"
                type="text"
                for="Keyphrase"
                inputProps={{
                  placeholder: "Enter text",
                  value: keyphrase,
                  onChange: (e) => {
                    e.target.value?.length < 12 && setKeyphrase(e.target.value);
                  },
                }}
                formControlProps={{
                  isRequired: true,
                }}
              />
              {keyphrase?.length > 0 && (!reg || data?.status === "error") && (
                <Text
                  position={"absolute"}
                  bottom="3"
                  right="2"
                  zIndex={"99999999"}
                >
                  <KeyphraseErrorIcon />
                </Text>
              )}
              {keyphrase?.length > 0 && reg && data?.status === "success" && (
                <Text
                  position={"absolute"}
                  bottom="3"
                  right="2"
                  zIndex={"99999999"}
                >
                  <KeyphraseSuccessIcon />
                </Text>
              )}
            </Box>
            {data?.status === "error" && (
              <Box fontSize={"12px"} fontWeight={700}>
                <Text mt={"-16px"} color={"danger.200"}>
                  {data?.message}
                </Text>
              </Box>
            )}
            {!reg && (
              <Box fontSize={"12px"} fontWeight={700}>
                <Text mt={"-16px"} color={"danger.200"}>
                  Spaces are not allowed
                </Text>
              </Box>
            )}
            <Box fontSize={"12px"} fontWeight={700}>
              <Text mt={"-16px"} color={"grays.12"}>
                11 CHARACTERS MAX.
              </Text>
            </Box>
            <FormInput
              label="Automated Message"
              type="textarea"
              for="Automated Message"
              textareaProps={{
                placeholder: "Enter automated message here",
                value: automatedText,

                onChange: (e) => {
                  setAutomatedText(e.target.value);
                },
              }}
              formControlProps={{
                isRequired: true,
              }}
            />
          </VStack>

          <Button
            isLoading={isLoading}
            type="submit"
            w="full"
            rounded="4px"
            bg="primary.500"
            _disabled={{
              bg: "primary.400",
            }}
            _hover={{
              bg: "primary.400",
            }}
            _focus={{
              bg: "primary.400",
            }}
            color={"white"}
            my="40px"
            fontSize={"sm"}
            isDisabled={
              !optinName ||
              !optinNumber ||
              !reg ||
              !keyphrase ||
              keyphrase?.length > 11 ||
              data?.status === "error" ||
              !automatedText ||
              !listUid
            }
          >
            Create Opt-In
          </Button>
        </chakra.form>
      ) : (
        <Box>
          <Box display={"flex"} justifyContent={"center"} mt={8}>
            <OptinLikeSuccessIcon />
          </Box>
          <Text
            textAlign={"center"}
            mx={"auto"}
            fontSize={"20px"}
            fontWeight={700}
            w={"203px"}
          >
            Your Opt-In has been successful created
          </Text>
          <Box mb={16}>
            <Share shareData={optinData?.data} fnt="bigger" />
          </Box>
        </Box>
      )}
    </>
  );
};

export default CreateOptinForm;
