import React from "react";
import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import TemplateCard from "../../../components/account/Templates/TemplateCard";
import WhatsappIcon from "../../../icons/WhatsappIcon";
import EmailIcon from "../../../icons/EmailIcon";
import SmsIcon from "../../../icons/SmsIcon";

const Templates = () => {
  return (
    <Box w="full" h="full">
      <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
        Templates
      </Text>
      <Text fontSize={"12px"} color="grays.12" fontWeight={500}>
        You can manage your templates here.
      </Text>

      <SimpleGrid mt={"5"} columns={2} spacing={5} maxWidth={"760px"}>
        <TemplateCard
          icon={<WhatsappIcon />}
          name="Whatsapp"
          description="Create or choose ready to use templates for your Whatsapp messages"
          view={true}
        />
        <TemplateCard
          icon={<SmsIcon />}
          name="SMS"
          description="Create or choose ready to use templates for your SMS messages"
          view={true}
        />
        <TemplateCard
          icon={<EmailIcon />}
          name="Email"
          description="Create or choose ready to use templates for your Email messages"
          view={false}
        />
      </SimpleGrid>
    </Box>
  );
};

export default Templates;
