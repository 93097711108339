import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
  HStack,
  useDisclosure,
  Button,
  Checkbox,
  useToast,
  IconButton,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import CustomMenu from "../UI/CustomMenu";
// import ChevronDown from "../../icons/ChevronDown";
import CustomModal from "../UI/CustomModal";
// import { GoTrashcan } from "react-icons/go";
import EditContact from "./EditContact";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { SingleContactProps } from "../../utils/generalProps";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteBulkContacts,
  deleteSingleContact,
  getContactAttributes,
} from "../../API/contacts";
import { fuzzyFilter } from "../../utils/table";
// import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
// import { Facebook, Instagram, Twitter } from "../../icons/Socials";
import { toaster } from "../../utils/toast";
import { ArrowDown } from "../../assets/svgs/svg";
import DeleteContactsModal from "./DeleteContacts";
import Loader from "../UI/Loader";
import Empty from "../UI/Empty";
import { ScaleUp, SwipeIn } from "../animations";
import ProgressLoader from "../UI/ProgressLoader";
import ExportContact from "./ExportContact";
import AddToList from "../account/lists/AddToList";
import CreateSegment from "../../pages/Contacts/Segments/CreateSegment";

const ContactsList: FC<{
  data: SingleContactProps[];
  segmentName: string;
  segSearchResults: any;
  globalFilter: any;
  setGlobalFilter: any;
  showOptions: React.Dispatch<React.SetStateAction<boolean>>;
  removedColumns?: any;
  addedColumns?: any;
  isLoading?: boolean;
  setSegSearchResults: any;
  setSegmentQ: any;
  setSegmentName: any;
  createSegmentDisclosure?: any;
  deleteAllContactsDisclosure?: any;
  exportContactDisclosure?: any;
  addToListDisclosure?: any;
  addContactDisclosure?: {
    isOpen: boolean;
    onOpen: () => void;
    onClose: () => void;
    onToggle: () => void;
    isControlled: boolean;
    getButtonProps: (props?: any) => any;
    getDisclosureProps: (props?: any) => any;
  };
}> = ({
  data,
  segSearchResults,
  segmentName,
  setSegmentName,
  setSegmentQ,
  globalFilter,
  setGlobalFilter,
  removedColumns,
  addedColumns,
  addContactDisclosure,
  deleteAllContactsDisclosure,
  exportContactDisclosure,
  addToListDisclosure,
  createSegmentDisclosure,
  showOptions,
  setSegSearchResults,
}) => {
  const navigate = useNavigate();

  const [dataToUpdate, setDataToUpdate] = useState<SingleContactProps>({
    id: "",
    uid: "",
    company_uid: "",
    profile_picture: "",
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    instagram_username: "",
    instagram_id: "",
    facebook_username: "",
    facebook_id: "",
    twitter_username: "",
    twitter_id: "",
    is_blocked: 0,
    data_source: "",
    metadata: "",
    created_at: new Date(),
    updated_at: new Date(),
    conversation: "",
  });

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [contactUids, setContactUids] = React.useState<any>([]);
  const [allChecked, setAllChecked] = React.useState<boolean>(false);
  const [attrs, setAttrs] = useState<string[]>([]);

  const editDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  // const deleteAllContactsDisclosure = useDisclosure();

  // const { mutate: deletingBulkContacts, isLoading: isDeletingBulkContacts } =
  const { isLoading: isDeletingBulkContacts, mutate: deleteBulkMutation } =
    useMutation(deleteBulkContacts, {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["allContacts"]);
        setAllChecked(false);
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    });

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<SingleContactProps>[]>(() => {
    // add contact(s) to delete
    const bulkDelete = (e: any) => {
      const { value, checked } = e.target;
      if (checked) {
        setContactUids([...contactUids, value]);
      } else {
        setContactUids(contactUids?.filter((x: any) => x !== value));
      }
    };

    // to select or unselect all contact
    const toggleSelect = () => {
      if (allChecked) {
        setAllChecked(false);
        setContactUids([]);
      } else {
        setAllChecked(true);
        setContactUids([...data?.map((x: any) => x.uid)]);
      }
    };

    return [
      {
        header: () => (
          <>
            <Box position="relative" onClick={() => toggleSelect()}>
              <Checkbox
                ml={"12px"}
                onClick={() => toggleSelect()}
                isChecked={allChecked}
                onChange={(e) => bulkDelete(e)}
              />
            </Box>
            {/* {!isDeletingBulkContacts && contactUids?.length > 0 && (
              <IconButton
                aria-label="delete selected"
                variant={"solid"}
                title="Delete all checked"
                // _hover={{ bg: "#ccc" }}
                // _focus={{ bg: "#fff" }}
                h={"12px"}
                size={"lg"}
                w={"16px"}
                ml={"4px"}
                minWidth="1px"
                position="relative"
                isLoading={isDeletingBulkContacts}
                onClick={() => {
                  deleteAllContactsDisclosure.onOpen();
                }}
                icon={<GoTrashcan />}
              />
            )} */}
          </>
        ),
        id: "checkbox",
        cell: (props: any) => (
          <Checkbox
            ml={"12px"}
            value={props?.row?.original?.uid}
            isChecked={contactUids?.includes(props?.row?.original?.uid)}
            onChange={(e) => {
              bulkDelete(e);
            }}
          />
        ),
      },
      {
        header: () => <Text>Name</Text>,
        accessorKey: "first_name",
        id: "first_name",
        accessorFn: (row) => `${row.first_name || ""} ${row.last_name || ""}`,
        cell: (props: any) => {
          return (
            <Link to={`/contacts/${props?.row?.original?.uid}`}>
              <Text
                cursor={"pointer"}
                _hover={{ textDecoration: "underline" }}
              >{`${props?.getValue()}`}</Text>
            </Link>
          );
        },
      },
      {
        header: () => <Text>Email</Text>,
        accessorKey: "email",
        id: "email",
        cell: (props: any) => {
          // console.log(dataToUpdate)
          return <Text>{props?.getValue() || "---"}</Text>;
        },
      },
      {
        header: () => <Text>Phone number</Text>,
        accessorKey: "phone_number",
        id: "phone_number",
        cell: (props: any) => {
          return <Text>{props?.getValue() || "---"}</Text>;
        },
      },

      {
        header: () => <Text>Channels</Text>,
        accessorKey: "data_source",
        id: "data_source",
        cell: (props: any) => {
          return (
            <HStack spacing={"0px"}>
              <Box>
                {/* {props?.row?.original?.phone_number && ( */}
                {props?.row?.original?.facebook_id && (
                  <IconButton
                    title="facebook"
                    aria-label="facebook"
                    minWidth={"0px"}
                    width="fit-content"
                    height="fit-content"
                    p={1}
                    bg={"white"}
                    borderRadius={"50%"}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <ellipse
                          cx="12"
                          cy="11.9556"
                          rx="12"
                          ry="11.9556"
                          fill="url(#paint0_linear_4220_138149)"
                        />
                        <path
                          d="M12.94 11.2864L12.99 14.2255L15.62 14.1856C15.81 14.1856 15.95 14.355 15.92 14.5343L15.57 16.4373C15.54 16.5767 15.42 16.6764 15.28 16.6863L13.03 16.7262L13.15 23.9493L10.15 23.9992L10.03 16.776L8.32999 16.8059C8.15999 16.8059 8.03 16.6764 8.03 16.507L8 14.6141C8 14.4447 8.12999 14.3152 8.29999 14.3152L10 14.2853L9.94998 11.0473C9.91998 9.39346 11.24 8.03849 12.9 8.0086L15.6 7.96875C15.77 7.96875 15.9 8.09827 15.9 8.26764L15.94 10.6588C15.94 10.8281 15.81 10.9576 15.64 10.9576L13.24 10.9975C13.07 10.9875 12.94 11.127 12.94 11.2864Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4220_138149"
                            x1="12"
                            y1="0"
                            x2="12"
                            y2="23.9111"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#1AABFC" />
                            <stop offset="1" stopColor="#0064D8" />
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                  />
                )}
              </Box>

              <Box transform={"translateX(-10px)"}>
                {props?.row?.original?.instagram_id && (
                  <IconButton
                    title={"instagram"}
                    aria-label="Instagram"
                    minWidth={"0px"}
                    width="fit-content"
                    height="fit-content"
                    p={1}
                    bg={"white"}
                    borderRadius={"50%"}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="12"
                          cy="12"
                          r="12"
                          fill="url(#paint0_linear_4220_138153)"
                        />
                        <path
                          d="M9.33333 6C7.49533 6 6 7.49533 6 9.33333V14.6667C6 16.5047 7.49533 18 9.33333 18H14.6667C16.5047 18 18 16.5047 18 14.6667V9.33333C18 7.49533 16.5047 6 14.6667 6H9.33333ZM9.33333 7.33333H14.6667C15.7693 7.33333 16.6667 8.23067 16.6667 9.33333V14.6667C16.6667 15.7693 15.7693 16.6667 14.6667 16.6667H9.33333C8.23067 16.6667 7.33333 15.7693 7.33333 14.6667V9.33333C7.33333 8.23067 8.23067 7.33333 9.33333 7.33333ZM15.3333 8C15.1565 8 14.987 8.07024 14.8619 8.19526C14.7369 8.32029 14.6667 8.48986 14.6667 8.66667C14.6667 8.84348 14.7369 9.01305 14.8619 9.13807C14.987 9.2631 15.1565 9.33333 15.3333 9.33333C15.5101 9.33333 15.6797 9.2631 15.8047 9.13807C15.9298 9.01305 16 8.84348 16 8.66667C16 8.48986 15.9298 8.32029 15.8047 8.19526C15.6797 8.07024 15.5101 8 15.3333 8ZM12 8.66667C10.162 8.66667 8.66667 10.162 8.66667 12C8.66667 13.838 10.162 15.3333 12 15.3333C13.838 15.3333 15.3333 13.838 15.3333 12C15.3333 10.162 13.838 8.66667 12 8.66667ZM12 10C13.1027 10 14 10.8973 14 12C14 13.1027 13.1027 14 12 14C10.8973 14 10 13.1027 10 12C10 10.8973 10.8973 10 12 10Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_4220_138153"
                            x1="12"
                            y1="0"
                            x2="12"
                            y2="24"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stopColor="#9830C8" />
                            <stop offset="0.541667" stopColor="#F84F4C" />
                            <stop offset="1" stopColor="#FDBD4F" />
                          </linearGradient>
                        </defs>
                      </svg>
                    }
                  />
                )}
              </Box>

              <Box transform={"translateX(-20px)"}>
                {props?.row?.original?.twitter_id && (
                  <IconButton
                    title={"twitter"}
                    aria-label="Twitter"
                    minWidth={"0px"}
                    width="fit-content"
                    height="fit-content"
                    p={1}
                    bg={"white"}
                    borderRadius={"50%"}
                    icon={
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle cx="12" cy="12" r="12" fill="#489BE9" />
                        <path
                          d="M16.8835 8.58414C17.4369 8.24444 17.8033 7.78077 17.9827 7.19108C17.4773 7.46443 16.935 7.66305 16.3726 7.78077C15.8911 7.263 15.2816 7 14.5508 7C13.8365 7 13.2331 7.25067 12.7406 7.73831C12.5028 7.96987 12.3148 8.24755 12.1881 8.55433C12.0614 8.86112 11.9986 9.19054 12.0037 9.52243C12.0037 9.73406 12.0304 9.92309 12.0838 10.0834C9.97712 10.0114 8.23341 9.13752 6.85953 7.45545C6.62667 7.86706 6.51024 8.28621 6.51024 8.71975C6.51024 9.61078 6.88624 10.3169 7.63687 10.8354C7.20882 10.7991 6.83145 10.6908 6.51024 10.5135C6.51024 11.1497 6.69721 11.6812 7.07184 12.149C7.43576 12.6048 7.94862 12.9178 8.52038 13.0331C8.30669 13.0879 8.08136 13.114 7.84987 13.114C7.63619 13.114 7.48483 13.0962 7.39511 13.0571C7.55263 13.5756 7.84987 13.9961 8.2793 14.318C8.70381 14.6403 9.22021 14.8186 9.75317 14.8269C8.8409 15.5426 7.79782 15.8967 6.61777 15.8967C6.32874 15.8967 6.12396 15.8925 6 15.8706C7.16225 16.6254 8.456 17 9.88741 17C11.3524 17 12.6468 16.6288 13.7734 15.8864C14.9001 15.1481 15.7315 14.2372 16.2657 13.1646C16.794 12.1268 17.0691 10.9787 17.0684 9.81419V9.49366C17.5608 9.13062 17.9871 8.68558 18.3286 8.178C17.8674 8.37789 17.3813 8.51451 16.8835 8.58414Z"
                          fill="white"
                        />
                      </svg>
                    }
                  />
                )}
              </Box>
              {/* !props?.row?.original?.email &&
                !props?.row?.original?.phone_number && */}
              {!props?.row?.original?.twitter_id &&
                !props?.row?.original?.instgram_id &&
                !props?.row?.original?.facebook_id && <Text>---</Text>}
            </HStack>
          );
        },
      },
      {
        header: "Created at",
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Last Updated",
        accessorKey: "updated_at",
        id: "updated_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <CustomMenu
            buttonTitle={
              <HStack spacing={"14px"}>
                <Text fontWeight={"medium"}>View actions</Text>
                <ArrowDown width="14px" height="14px" />
              </HStack>
            }
            itemsData={[
              {
                title: "View & edit contact",
                itemProps: {
                  fontWeight: "medium",
                  // onClick: () => {
                  //   editDisclosure.onOpen();
                  //   setDataToUpdate(props?.row?.original);
                  // },
                  onClick: () => {
                    navigate(`/contacts/${props?.row?.original?.uid}`);
                    //
                  },
                },
              },
              {
                title: "Delete contact",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    deleteDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  },
                },
              },
            ]}
          />
        ),
      },
    ];
  }, [
    allChecked,
    contactUids,
    data,
    // deleteAllContactsDisclosure,
    deleteDisclosure,
    // editDisclosure,
    // isDeletingBulkContacts,
    navigate,
  ]);

  const filteredColumns = useMemo(() => {
    let result = columns.filter(
      (column) => !removedColumns.includes(column.id)
    );
    result = [
      ...result,
      ...addedColumns
        .filter((id: any) => !columns.find((col) => col.id === id))
        .map((id: any) => columns.find((col) => col.id === id)),
    ];
    return result;
  }, [columns, removedColumns, addedColumns]);

  const table = useReactTable({
    data,
    columns: filteredColumns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
  });

  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(deleteSingleContact, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["allContacts"]);

      deleteDisclosure.onClose();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const attributesQuery = useQuery(
    ["get_attributes"],
    () => {
      return getContactAttributes();
    },
    {
      onSuccess: (data) => {
        setAttrs(data?.data);
      },
    }
  );

  React.useEffect(() => {
    if (!isDeletingBulkContacts && contactUids?.length > 0) {
      showOptions(true);
    } else {
      showOptions(false);
    }
  }, [contactUids.length, isDeletingBulkContacts, showOptions]);

  return (
    <>
      {isDeletingBulkContacts ? <ProgressLoader /> : null}
      <TableContainer
        overflowX="unset"
        overflowY="unset"
        borderWidth={"1px"}
        borderBottomWidth="0px"
        borderColor="borders.200"
      >
        <Table size="sm" variant={"simple"} position={"relative"}>
          <Thead
            position={"sticky"}
            top={0}
            bg={"white"}
            boxShadow={"sm"}
            zIndex={2}
          >
            {table.getHeaderGroups()?.map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers?.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        // colSpan={header.colSpan}
                        py={"16px"}
                      >
                        <Box display={"flex"} gap={"8px"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="4px" my="auto">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          {isLoading ? (
            <Loader />
          ) : data.length > 0 ? (
            <Tbody>
              {table.getRowModel().rows?.map((row, index) => (
                <Tr key={row.id}>
                  {row.getVisibleCells()?.map((cell, index) => (
                    <Td
                      key={cell.id}
                      // pl={"4px"}
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={"medium"}
                    >
                      <SwipeIn delay={0.2 * index}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </SwipeIn>
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          ) : null}
        </Table>

        {data.length === 0 || !data ? (
          <ScaleUp
            delay={0.5}
            style={{
              marginBlockStart: "70px",
              backgroundColor: "",
            }}
          >
            <Empty
              height={"fit-content"}
              title="You haven’t added any contact yet"
              description="Start by adding contacts or importing an existing list."
              buttonText="Add single contact"
              button2={true}
              buttonText2="Import contacts"
              icon={
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 96 96"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M88 31.24V64.76C88 76 82.84 83.7199 73.76 86.6399C71.12 87.5599 68.08 88 64.76 88H31.24C27.92 88 24.88 87.5599 22.24 86.6399C13.16 83.7199 8 76 8 64.76V31.24C8 16.68 16.68 8 31.24 8H64.76C79.32 8 88 16.68 88 31.24Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M73.7603 86.6389C71.1203 87.5589 68.0803 87.999 64.7603 87.999H31.2402C27.9202 87.999 24.8802 87.5589 22.2402 86.6389C23.6402 76.0789 34.6802 67.8789 48.0002 67.8789C61.3202 67.8789 72.3603 76.0789 73.7603 86.6389Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M62.3197 46.3201C62.3197 54.2401 55.9197 60.6799 47.9997 60.6799C40.0797 60.6799 33.6797 54.2401 33.6797 46.3201C33.6797 38.4001 40.0797 32 47.9997 32C55.9197 32 62.3197 38.4001 62.3197 46.3201Z"
                    fill="#7489AA"
                  />
                </svg>
              }
              buttonAction={addContactDisclosure?.onOpen}
              button2Action={() => navigate("/contacts/import")}
            />
          </ScaleUp>
        ) : null}
      </TableContainer>

      {/*  CREATE SEGMENT MODAL */}
      <CustomModal
        isSegment
        titleUnderLine={true}
        disclosure={createSegmentDisclosure}
        title={
          <Text fontWeight={700} fontSize="20px" color="grays.8" mt="35px">
            Create Segment
          </Text>
        }
      >
        {!attributesQuery.isLoading ? (
          <CreateSegment
            setSegmentQ={setSegmentQ}
            close={createSegmentDisclosure.onClose}
            setSegSearchResults={setSegSearchResults}
            attributesArr={attrs}
            setSegmentName={setSegmentName}
            segmentName={segmentName}
          />
        ) : null}
      </CustomModal>

      {/* Edit contact  */}
      <CustomModal
        disclosure={editDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Edit Contact
          </Text>
        }
      >
        <EditContact data={dataToUpdate} close={editDisclosure?.onClose} />
      </CustomModal>

      {/* EXPORT CONTACT MODAL */}
      <CustomModal
        disclosure={exportContactDisclosure}
        titleUnderLine={true}
        title={
          <Text
            fontWeight={700}
            fontSize="20px"
            color="grays.8"
            mt="35px"
            // px='38px'
            // borderBottom={'1px solid rgba(0, 102, 255, 0.16)'}
          >
            {`Export ${contactUids?.length > 1 ? "Contacts" : "Contact"}`}
          </Text>
        }
      >
        <ExportContact
          contactList={contactUids}
          close={exportContactDisclosure.onClose}
        />
      </CustomModal>

      {/* ADD CONTACT TO LIST MODAL */}
      <CustomModal
        disclosure={addToListDisclosure}
        titleUnderLine={true}
        title={
          <Text
            fontWeight={700}
            fontSize="20px"
            color="grays.8"
            mt="35px"
            // px='38px'
            // borderBottom={'1px solid rgba(0, 102, 255, 0.16)'}
          >
            Add to list
          </Text>
        }
      >
        <AddToList
          contactList={contactUids}
          close={addToListDisclosure.onClose}
        />
      </CustomModal>

      {/* Delete contact */}
      <CustomModal disclosure={deleteDisclosure}>
        <Box mt="38px" pb="32px" textAlign={"center"} w="full">
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Delete contact
          </Text>
          <Box
            mt="5px"
            fontSize={"sm"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
            mx="auto"
          >
            <Text>
              All conversations related to this contact will disappear from
              Bodsquare if you delete it.
            </Text>
            <Text mt="20px">Are you sure you wish to do this?.</Text>
          </Box>

          <Button
            w="full"
            colorScheme={"red"}
            mt="40px"
            isLoading={isLoading}
            onClick={() => {
              mutate(dataToUpdate?.uid);
            }}
          >
            Delete contact
          </Button>
        </Box>
      </CustomModal>

      {/* Delete all selected contact */}
      <DeleteContactsModal
        disclosure={deleteAllContactsDisclosure}
        deleteBulkMutation={deleteBulkMutation}
        isLoading={isLoading}
        contactUids={contactUids}
        setContactUids={setContactUids}
      />
    </>
  );
};

export default ContactsList;
