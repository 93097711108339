import React, { FC } from 'react';

const SortArrowDown: FC<any> = (props) => {
  return (
    <svg
      {...props}
      // width='10'
      // height='6'
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M9.14645 0.853555L5.35355 4.64645C5.15829 4.84171 4.84171 4.84171 4.64645 4.64645L0.853554 0.853553C0.538572 0.538571 0.761655 1.46033e-07 1.20711 1.84975e-07L8.79289 8.48146e-07C9.23835 8.87088e-07 9.46143 0.538573 9.14645 0.853555Z'
        fill='#7489AA'
      />
    </svg>
  );
};

export default SortArrowDown;
