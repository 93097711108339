import { Center, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

const EmptyChatList: FC<{ title?: string }> = ({
  title = 'You currently don’t have any messages yet',
}) => {
  return (
    <Center flexDir={'column'} h='60vh'>
      <svg
        width='96'
        height='96'
        viewBox='0 0 96 96'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          d='M90.12 85.88L87.16 82.92C88.68 80.6 89.6 77.8 89.6 74.8C89.6 66.64 82.9601 60 74.8001 60C66.64 60 60 66.64 60 74.8C60 82.96 66.64 89.6 74.8001 89.6C77.8001 89.6 80.6 88.68 82.92 87.16L85.88 90.12C86.48 90.72 87.24 91 88 91C88.76 91 89.52 90.72 90.12 90.12C91.28 88.96 91.28 87.04 90.12 85.88Z'
          fill='#7489AA'
        />
        <path
          opacity='0.4'
          d='M8 51.88V27.96C8 16.96 16.96 8.04004 28 8.04004H68C79.04 8.04004 88 16.96 88 28V55.92C88 66.92 79.04 75.84 68 75.84H62C60.76 75.84 59.56 76.44 58.8 77.44L52.8 85.4C50.16 88.92 45.84 88.92 43.2 85.4L37.2 77.44C36.52 76.56 35.12 75.84 34 75.84H28C16.96 75.84 8 66.92 8 55.92V51.88Z'
          fill='#7489AA'
        />
        <path
          d='M48.001 48C45.761 48 43.9609 46.2 43.9609 44C43.9609 41.8 45.7609 40 47.9609 40C50.1609 40 51.9609 41.8 51.9609 44C51.9609 46.2 50.241 48 48.001 48Z'
          fill='#7489AA'
        />
        <path
          d='M64.001 48C61.761 48 59.9609 46.2 59.9609 44C59.9609 41.8 61.7609 40 63.9609 40C66.1609 40 67.9609 41.8 67.9609 44C67.9609 46.2 66.241 48 64.001 48Z'
          fill='#7489AA'
        />
        <path
          d='M32.001 48C29.761 48 27.9609 46.2 27.9609 44C27.9609 41.8 29.7609 40 31.9609 40C34.1609 40 35.9609 41.8 35.9609 44C35.9609 46.2 34.241 48 32.001 48Z'
          fill='#7489AA'
        />
      </svg>

      <Text
        maxW='225px'
        fontWeight={'medium'}
        color='grays.12'
        mt='20px'
        textAlign={'center'}
      >
        {title}
      </Text>
    </Center>
  );
};

export default EmptyChatList;
