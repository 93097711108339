import React, { useState, useRef, FC } from "react";
import { Box, SimpleGrid, Text, Flex, Button, Center, HStack, Stack, chakra } from "@chakra-ui/react";
import CustomModal from "../UI/CustomModal";
import FormInput from "../UI/form/FormInput";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { ColorPink, ColorYellow, ColorGreen } from "../../icons/Builder";

const displayModule = {
    toolbar: false,
};
const modules = {
    toolbar: [
        [{ font: [] }],
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"],
        [{ color: [] }, { background: [] }],
        [{ script: "sub" }, { script: "super" }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
        ["link", "image", "video"],
        ["clean"],
    ],
};

const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "script",
    "blockquote",
    "background",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color",
    "code-block",
    "font-size",
];
interface EmailCampaignProps {
    currentStageData: any;
    setCurrentStageData: any;
    disclosure: any;
}
const EmailCampaign: FC<EmailCampaignProps> = ({ currentStageData, setCurrentStageData, disclosure }) => {
    const [view, setView] = useState("web");
    const quillRef = useRef<InstanceType<typeof ReactQuill> | null>(null);
    const setVariable = (e: string) => {
        const cursorPos = quillRef?.current?.getEditor()?.getSelection()?.index;
        if (cursorPos == null) return;
        quillRef?.current?.getEditor()?.insertText(cursorPos, e);
    };
    return (
        <SimpleGrid columns={{ base: 1, md: 2 }} w="full" h="full">
            {/* Message editor */}
            <Box
                maxW="559px"
                h="full"
                display={"flex"}
                borderWidth={"1px"}
                position="relative"
                borderColor={"borders.500"}
            >
                <Flex
                    fontSize={"10px"}
                    top={"20"}
                    position="absolute"
                    borderBottomWidth={"1px"}
                    borderTopWidth={"1px"}
                    w="full"
                    py={4}
                    borderColor={"borders.500"}
                    zIndex={"1"}
                >
                    <Text ml={4} fontSize={"10px"} fontWeight={500} my={"auto"}>
                        Select attribute:
                    </Text>
                    <Button
                        bg={"borders.200"}
                        borderColor={"borders.500"}
                        borderWidth={"1px"}
                        color={"grays.12"}
                        fontWeight={500}
                        size={"xs"}
                        px={3}
                        mx={2}
                        onClick={(e) => {
                            setVariable("{{Email}}");
                        }}
                    >
                        Email
                    </Button>
                    <Button
                        bg={"borders.200"}
                        borderColor={"borders.500"}
                        borderWidth={"1px"}
                        color={"grays.12"}
                        fontWeight={500}
                        size={"xs"}
                        px={3}
                        mx={2}
                        onClick={(e) => {
                            setVariable("{{Firstame}}");
                        }}
                    >
                        Firstname
                    </Button>
                    <Button
                        bg={"borders.200"}
                        borderColor={"borders.500"}
                        borderWidth={"1px"}
                        color={"grays.12"}
                        fontWeight={500}
                        size={"xs"}
                        px={3}
                        mx={2}
                        onClick={(e) => {
                            setVariable("{{Lastname}}");
                        }}
                    >
                        Lastname
                    </Button>
                    <Button
                        bg={"borders.200"}
                        borderColor={"borders.500"}
                        borderWidth={"1px"}
                        color={"grays.12"}
                        fontWeight={500}
                        size={"xs"}
                        px={3}
                        mx={2}
                        onClick={(e) => {
                            setVariable("{{Phone number}}");
                        }}
                    >
                        Phone number
                    </Button>
                </Flex>
                <ReactQuill
                    style={{ width: "559px", height: "603px", maxHeight: "603px", marginTop: "0px" }}
                    modules={modules}
                    formats={formats}
                    theme="snow"
                    ref={quillRef}
                    value={currentStageData?.data?.message_body}
                    onChange={(e) => {
                        setCurrentStageData((prevData: any) => {
                            return {
                                ...prevData,
                                data: {
                                    ...prevData?.data,
                                    message_body: e,
                                },
                            };
                        });
                    }}
                />
            </Box>
            <Box
                borderWidth={"1px"}
                borderColor={"borders.500"}
                className="previewBox"
            >
                <Flex
                    justifyContent={"space-between"}
                    borderBottomWidth={"1px"}
                    borderColor={"borders.500"}
                >
                    <HStack mx={3} p={2}>
                        <Box display={"flex"}>
                            <Box mx={1}>
                                <ColorPink />
                            </Box>
                            <Box mx={1}>
                                <ColorYellow />
                            </Box>
                            <Box mx={1}>
                                <ColorGreen />
                            </Box>
                        </Box>
                        <Box fontWeight={700}>Preview</Box>
                    </HStack>
                    <Stack mx={3} p={2}>
                        <Button
                            borderWidth={"1px"}
                            size="xs"
                            onClick={disclosure.onOpen}
                            p={4}
                            fontSize="10px"
                            color={"primary.500"}
                            bg={"backgrounds.300"}
                            borderColor={"primary.500"}
                        >
                            Send a sample mail
                        </Button>

                        <CustomModal
                            disclosure={disclosure}
                            title={
                                <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
                                    Send a sample mail
                                </Text>
                            }
                        >
                            {/* {currentStage === 'entry' && ( */}
                            <chakra.form
                            // onSubmit={(e) => {
                            //   e.preventDefault();

                            //   mutate({
                            //     amount,
                            //     currency: 'NGN',
                            //   });
                            // }}
                            >
                                <Box
                                    borderBottomWidth={"1px"}
                                    h={"0.1px"}
                                    borderColor={"borders.100"}
                                ></Box>
                                <FormInput
                                    label="Enter one or more recipients"
                                    type="email"
                                    for="amount"
                                    formControlProps={{
                                        mt: "32px",
                                    }}
                                    inputProps={{
                                        placeholder: "test@hostmail.com, test2@hostmail.com",
                                        onChange: (e) => {
                                            setCurrentStageData((prevData: any) => {
                                                return {
                                                    ...prevData,
                                                    data: {
                                                        ...prevData?.data,
                                                        email: e.target.value,
                                                    },
                                                };
                                            });
                                        },
                                        autoFocus: true,
                                    }}
                                />
                                <Text
                                    fontSize={"14px"}
                                    fontWeight={700}
                                    color="grays.8"
                                    mt="5px"
                                >
                                    Use commas to seperate multiple emails
                                </Text>
                                <Button
                                    // isLoading={isLoading || getLowestLoading}
                                    isDisabled={!currentStageData?.data?.email}
                                    w="full"
                                    colorScheme={"primary"}
                                    mt="32px"
                                    mb="40px"
                                    type="submit"
                                >
                                    Send sample
                                </Button>
                            </chakra.form>
                        </CustomModal>
                    </Stack>
                </Flex>
                <Box
                    bg={"borders.200"}
                    maxH={"603px"}
                    h={"505px"}
                    maxW={"459px"}
                    mx={"auto"}
                    mt={4}
                    p={12}
                >
                    <Box
                        bg="white"
                        maxH={"400px"}
                        m="auto"
                        maxW={view === "mobile" ? "280px" : "400px"}
                    >
                        <Text textAlign={"center"} fontWeight={700}>
                            Logo
                        </Text>
                        <Box
                            w="full"
                            p={4}
                            h="410px"
                            fontSize="10.72px"
                            position="relative"
                        >
                            <ReactQuill
                                style={{ border: "1rem solid #fff" }}
                                modules={displayModule}
                                theme="snow"
                                value={currentStageData?.data?.message_body}
                                readOnly={true}
                            />
                            <Text bottom={"0"} position="absolute" fontSize="10.72px">
                                Sent using Bodsquare by Sendchamp
                            </Text>
                        </Box>
                    </Box>
                </Box>
                <Center flexDir={"row"} textAlign="center">
                    <Flex>
                        <Box>
                            <Text
                                mt="50px"
                                mb="5px"
                                fontWeight={700}
                                mx={2}
                                fontSize={"12px"}
                                w={"72px"}
                            >
                                Preview as:
                            </Text>
                        </Box>
                        <Button
                            onClick={() => setView("web")}
                            mt="40px"
                            mb="10px"
                            mx={2}
                            w="full"
                            maxW={"96px"}
                            borderWidth={"1px"}
                            color={view === "mobile" ? "borders.400" : "primary.500"}
                            bg={"backgrounds.300"}
                            borderColor={view === "mobile" ? "borders.400" : "primary.500"}
                        >
                            Web
                        </Button>
                        <Button
                            onClick={() => setView("mobile")}
                            mt="40px"
                            mb="10px"
                            mx={2}
                            w="full"
                            maxW={"96px"}
                            borderWidth={"1px"}
                            color={view === "web" ? "borders.400" : "primary.500"}
                            bg={"backgrounds.300"}
                            borderColor={view === "web" ? "borders.400" : "primary.500"}
                        >
                            Mobile
                        </Button>
                        <Button
                            mx={2}
                            isDisabled={!currentStageData?.data?.message_body}
                            onClick={() => {
                                setCurrentStageData((prevData: any) => {
                                    return {
                                        ...prevData,
                                        currentStage: "Recipients",
                                    };
                                });
                            }}
                            mt="40px"
                            mb="10px"
                            w="full"
                            maxW={"238px"}
                            colorScheme={"primary"}
                        >
                            Continue to recipients
                        </Button>
                    </Flex>
                </Center>
            </Box>
        </SimpleGrid>
    );
};

export default EmailCampaign;
