import {
  chakra,
  Avatar,
  AvatarBadge,
  Box,
  Text,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerOverlay,
  DrawerContent,
  Badge,
  Tooltip,
  Flex,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Facebook, Instagram, Twitter } from '../../../icons/Socials';
import { SingleConversationProps } from '../../../utils/generalProps';
import ChatData from '../ChatData';
import dayjs from 'dayjs';
import { Link, useSearchParams } from 'react-router-dom';
import relativeTime from 'dayjs/plugin/relativeTime';
dayjs.extend(relativeTime);

type iconType = 'twitter' | 'facebook' | 'instagram';

const ChatListItem: FC<{ data: SingleConversationProps }> = ({ data }) => {
  const returnIcon = (type: iconType) => {
    switch (type.toLowerCase()) {
      case 'facebook':
        return <Facebook />;

      case 'instagram':
        return <Instagram />;

      case 'twitter':
        return <Twitter />;

      default:
    }
  };

  const { onOpen, isOpen, onClose } = useDisclosure();
  const fullname = `${data?.contact?.first_name ?? ''} ${
    data?.contact?.last_name ?? ''
  }`;

  const [searchParams] = useSearchParams();

  const returnUserName = (channel: string) => {
    let username: string | undefined = '';

    switch (channel) {
      case 'twitter':
        username = data?.contact?.twitter_username;
        break;
      case 'instagram':
        username = data?.contact?.instagram_username;
        break;
      case 'facebook':
        username = data?.contact?.facebook_username;
        break;

      default:
        username = `${data?.contact?.first_name ?? ''} ${
          data?.contact?.last_name ?? ''
        }`;
    }

    return username;
  };

  return (
    <>
      {/* desktop only */}
      <Link to={`/conversations?uid=${data?.uid}`}>
        <chakra.button
          mb='8px'
          display={{ base: 'none', md: 'flex' }}
          textAlign={'left'}
          w='full'
          bg={
            data?.uid === searchParams?.get('uid') ? 'backgrounds.200' : 'white'
          }
          _hover={{
            bg: 'backgrounds.200',
          }}
          rounded='4px'
          p='12px'
          onClick={() => {}}
        >
          <Avatar
            w='44px'
            h='44px'
            name={returnUserName(data?.channel?.toLowerCase()) || fullname}
            my='auto'
            src={data?.contact?.profile_picture}
          >
            <AvatarBadge boxSize='22px' bg='blue.100' left='-3'>
              {returnIcon(data?.channel)}
            </AvatarBadge>
          </Avatar>

          <Box ml='12px' mr={3} my='auto'>
            <Tooltip
              openDelay={800}
              label={returnUserName(data?.channel?.toLowerCase()) || fullname}
            >
              <Text
                fontSize={'sm'}
                color='grays.9'
                fontWeight={700}
                noOfLines={1}
                maxW='150px'
              >
                {returnUserName(data?.channel?.toLowerCase()) || fullname}
              </Text>
            </Tooltip>

            <Text
              fontSize={'xs'}
              fontWeight={500}
              noOfLines={2}
              color='rgba(23, 29, 38, 0.64)'
              maxW='190px'
            >
              {data?.message_type === 'text'
                ? data?.last_message
                : '🔗 Attachment'}
            </Text>
          </Box>

          <Flex flexDir={'column'} alignItems={'end'} ml='auto'>
            <Text color='rgba(23, 29, 38, 0.64)' fontSize={'xs'}>
              {dayjs(data?.updated_at).diff(dayjs(new Date()), 'd') < 0
                ? dayjs(data?.updated_at).fromNow()
                : dayjs(data?.updated_at).format('h:mm A')}
            </Text>

            {}
            <Badge
              ml='auto'
              variant='solid'
              colorScheme='primary'
              rounded='10px'
              mt='7px'
              py='2px'
              px='8px'
              fontSize={'10px'}
              visibility={
                data?.no_of_unseen_messages > 0 ? 'visible' : 'hidden'
              }
            >
              {data?.no_of_unseen_messages}
            </Badge>
          </Flex>
        </chakra.button>
      </Link>

      {/* mobile only */}
      <Link to={`/conversations?uid=${data?.uid}`}>
        <chakra.button
          mb='8px'
          display={{ base: 'flex', md: 'none' }}
          textAlign={'left'}
          w='full'
          bg={
            data?.uid === searchParams?.get('uid') ? 'backgrounds.200' : 'white'
          }
          _hover={{
            bg: 'backgrounds.200',
          }}
          rounded='4px'
          p='12px'
          onClick={() => {
            onOpen();
          }}
        >
          <Avatar
            w='44px'
            h='44px'
            name={returnUserName(data?.channel?.toLowerCase()) || fullname}
            my='auto'
          >
            <AvatarBadge boxSize='22px' bg='blue.100' left='-3'>
              {returnIcon(data?.channel)}
            </AvatarBadge>
          </Avatar>

          <Box ml='12px' mr={3} my='auto'>
            <Text fontSize={'sm'} color='grays.9' fontWeight={700}>
              {returnUserName(data?.channel?.toLowerCase()) || fullname}
            </Text>
            <Text
              fontSize={'xs'}
              fontWeight={500}
              noOfLines={2}
              color='rgba(23, 29, 38, 0.64)'
            >
              {data?.last_message}
            </Text>
          </Box>

          <Text ml='auto' color='rgba(23, 29, 38, 0.64)' fontSize={'xs'}>
            {dayjs(data?.updated_at).format('h:m')}
          </Text>
        </chakra.button>
      </Link>

      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='full'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody p={0}>
            <ChatData close={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default ChatListItem;
