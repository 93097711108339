import { Box, Text, Button } from "@chakra-ui/react";
import React from "react";
import CustomModal from "../UI/CustomModal";

interface DeleteContactProps {
  disclosure: any;
  isLoading?: boolean;
  deleteBulkMutation?: any;
  contactUids: any;
  setContactUids: any;
}

const DeleteContactsModal = ({
  disclosure,
  deleteBulkMutation,
  isLoading,
  contactUids,
  setContactUids,
}: DeleteContactProps) => {
  // const [contactUids, setContactUids] = React.useState<any>([]);

  return (
    <CustomModal disclosure={disclosure}>
      <Box mt="38px" pb="32px" textAlign={"center"} w="full">
        <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
          You’re about to delete{" "}
          {contactUids.length === 1 ? "this" : contactUids.length}{" "}
          {contactUids.length === 1 ? "contact" : "contacts"}
        </Text>
        <Box
          mt="5px"
          fontSize={"sm"}
          fontWeight="medisum"
          color="grays.12"
          maxW={"360px"}
          mx="auto"
        >
          <Text>
            All conversations related to{" "}
            {contactUids.length === 1 ? "this contact" : "these contacts"} will
            disappear from Bodsquare if you delete{" "}
            {contactUids.length === 1 ? "it" : "them"}.
          </Text>
          <Text mt="20px">Are you sure you wish to do this?.</Text>
        </Box>

        <Button
          w="full"
          colorScheme={"red"}
          mt="40px"
          isLoading={isLoading}
          onClick={() => {
            deleteBulkMutation({
              contacts: contactUids,
            });
            setContactUids([]);
            disclosure.onClose();
          }}
        >
          Delete {contactUids.length === 1 ? "contact" : "contacts"}
        </Button>
      </Box>
    </CustomModal>
  );
};

export default DeleteContactsModal;
