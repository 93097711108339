import { Box, SimpleGrid, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import {
  getFacebookAccounts,
  getInstagramAccounts,
  getTwitterAccounts,
  getWhatsappConnection,
} from "../../../API/account/channels";
import ConnectedSocialCard from "../../../components/account/channels/ConnectedSocialCard";
import SocialCard from "../../../components/account/channels/SocialCard";
import {
  Facebook,
  Instagram,
  SMS,
  Twitter,
  Whatsapp,
} from "../../../icons/Socials";

const Channels = () => {
  const { data: facebookData } = useQuery(
    ["facebookData"],
    getFacebookAccounts
  );
  const { data: instagramData } = useQuery(
    ["instagramData"],
    getInstagramAccounts
  );

  const { data: twitterData } = useQuery(["twitterData"], getTwitterAccounts);

  const { data: whatsappData } = useQuery(
    ["whatsappData"],
    getWhatsappConnection
  );

  const noAccountsConnected =
    facebookData?.length === 0 &&
    instagramData?.length === 0 &&
    twitterData?.length === 0 &&
    whatsappData?.length === 0;

  return (
    <Box w="full" h="full" mb={"60px"} minHeight={"100%"}>
      <Box maxW={"770px"} w="full" h={"full"}>
        {/* List connected channels */}
        {!noAccountsConnected && (
          <Box>
            <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
              Your connected channels
            </Text>

            {/* Content */}
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mt="24px">
              {instagramData?.map((instaData, index) => {
                return (
                  <ConnectedSocialCard
                    key={instaData?.page_name ?? `Instagram ${index}`}
                    title={instaData?.page_name ?? "Instagram"}
                    description={instaData?.page_name ?? "Instagram"}
                    icon={<Instagram width="26px" height="26px" />}
                    type="instagram"
                    uid={instaData?.uid}
                  />
                );
              })}

              {twitterData?.map((twiData, index) => {
                return (
                  <ConnectedSocialCard
                    key={twiData?.page_name ?? `Twitter ${index}`}
                    title={`Twitter ${twiData?.id}` ?? "Twitter"}
                    description={twiData?.page_name ?? "Twitter"}
                    icon={<Twitter width="26px" height="26px" />}
                    type="twitter"
                    uid={twiData?.uid}
                  />
                );
              })}

              {facebookData?.map((faceData, index) => {
                return (
                  <ConnectedSocialCard
                    key={faceData?.page_name ?? `Facebook ${index}`}
                    title={faceData?.page_name ?? `Facebook`}
                    description={faceData?.page_name ?? `Facebook`}
                    icon={<Facebook width="26px" height="26px" />}
                    type="facebook"
                    uid={faceData?.uid}
                  />
                );
              })}

              {whatsappData?.map((whatsData: any, index: number) => {
                return (
                  <ConnectedSocialCard
                    key={whatsData?.id ?? `Whatsapp ${index}`}
                    title={whatsData?.display_name ?? `Whatsapp`}
                    description={whatsData?.phone_number ?? `Whatsapp`}
                    icon={<Whatsapp width="26px" height="26px" />}
                    type="whatsapp"
                    uid={whatsData?.uid}
                  />
                );
              })}
            </SimpleGrid>
          </Box>
        )}

        {/* ===================================== */}
        {/*  Add channels */}
        {/* ===================================== */}

        <Box mt={!noAccountsConnected ? "64px" : "0px"}>
          {/* Header */}
          <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
            Add channels
          </Text>
          <Text mt="8px" fontSize={"xs"} color="grays.12" fontWeight={"500"}>
            Select the channels you want to connect
          </Text>

          {/* Content */}
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing="20px" mt="24px">
            <SocialCard
              title="Instagram"
              description="Send and recieve all your businesses instagram messages within your inbox."
              icon={<Instagram width="26px" height="26px" />}
            />
            <SocialCard
              title="Facebook"
              description="Send and recieve all your businesses facebook messages within your inbox."
              icon={<Facebook width="26px" height="26px" />}
            />
            <SocialCard
              title="SMS"
              description="Send and recieve all your businesses SMS within your inbox."
              icon={<SMS width="26px" height="26px" />}
            />
            <SocialCard
              title="Twitter"
              description="Send and recieve all your businesses twitter messages within your inbox."
              icon={<Twitter width="26px" height="26px" />}
              showBadge
            />
            <SocialCard
              title="Whatsapp"
              description="Send and recieve all your businesses twitter messages within your inbox."
              icon={<Whatsapp width="26px" height="26px" />}
            />
          </SimpleGrid>
        </Box>
      </Box>
    </Box>
  );
};

export default Channels;
