import { Box, CircularProgress } from "@chakra-ui/react";

export default function ProgressLoader() {
  return (
    <Box
      // boxShadow={"md"}
      p={"8px"}
      borderRadius={"6px"}
      mt={"10px"}
      ml={"4px"}
      position={"fixed"}
      zIndex={100}
      top={0}
      left={0}
      bg={"transparent"}
      // transform={"translateX(-50%)"}
    >
      <CircularProgress
        isIndeterminate
        size={"20px"}
        color="primary.500"
        thickness={"10px"}
      />
    </Box>
  );
}
