import { Box, Flex } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useSearchParams } from "react-router-dom";
import { DASH_ROUTES } from "../../utils/routes";
import Navbar from "./Navbar";
import Container from "../UI/Container";

const LoggedinLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [searchParams] = useSearchParams();

  if (searchParams?.get("created") === "false") {
    window.location.href = DASH_ROUTES.GET_STARTED;

    return <></>;
  }

  return (
    <>
      <Flex display={"flex"} flexDir="column" h="full">
        <Navbar />

        <Box pt={"24px"} flex={1} width={"100%"}>
          <Container fullHeight>{children}</Container>
        </Box>
      </Flex>
    </>
  );
};

export default LoggedinLayout;
