import {
  Box,
  Flex,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  HStack,
  useDisclosure,
  IconButton,
  chakra,
  useToast,
  Image,
  VStack,
} from "@chakra-ui/react";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { HiChevronDown, HiOutlineChevronLeft, HiX } from "react-icons/hi";
import PaperClip from "../../../icons/PaperClip";
import Send from "../../../icons/Send";
import { SingleConversationContactProps } from "../../../utils/generalProps";
import CustomModal from "../../UI/CustomModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  closeConversation,
  deleteConversation,
  openConversation,
  sendSocialMessage,
  uploadChatImage,
} from "../../../API/conversations";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { chatAtom } from "../../../atoms/conversation";
import DisplayChat from "./DisplayChat";
import TextareaAutosize from "react-textarea-autosize";
import { toaster } from "../../../utils/toast";

const ChakraTextAreaAutoResize = chakra(TextareaAutosize);

const ViewAndSendMessages: FC<{
  close?: () => void;
  contact: SingleConversationContactProps;
  conversation_uid: string;
  status?: string;
}> = ({ close, contact, conversation_uid, status }) => {
  const chatData = useRecoilValue(chatAtom);

  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();

  const [newMessages, setNewMessages] = useState<any>([]);

  useEffect(() => {
    setNewMessages(chatData?.messages);
  }, [chatData?.messages]);

  const deleteChatDisclosure = useDisclosure();
  const toast = useToast();
  const navigate = useNavigate();

  const fullname = `${contact?.first_name ?? ""} ${contact?.last_name ?? ""}`;

  const { mutate } = useMutation(sendSocialMessage, {
    onSuccess() {
      queryClient.invalidateQueries([
        "allConversations",
        `singleChatData${searchParams?.get("uid")}`,
      ]);

      setMessageType("text");
      setImageFile(undefined);
      setImagefilePreview({
        src: "",
        type: "",
      });
      textAreaRef.current.value = "";
      queryClient.invalidateQueries(["allConversations"]);
      queryClient.invalidateQueries([
        `singleChatData${searchParams?.get("uid")}`,
      ]);
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  // close chat
  const { mutate: closeChat, isLoading: closeChatLoading } = useMutation(
    closeConversation,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries([
          "allConversations",
          `singleChatData${searchParams?.get("uid")}`,
        ]);
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  // open chat
  const { mutate: openChat, isLoading: openChatLoading } = useMutation(
    openConversation,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries([
          "allConversations",
          `singleChatData${searchParams?.get("uid")}`,
        ]);
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  // delete chat
  const { mutate: deleteChat, isLoading: deleteChatLoading } = useMutation(
    deleteConversation,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries([
          "allConversations",
          `singleChatData${searchParams?.get("uid")}`,
        ]);
        navigate("/conversations");
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  const customerMessages = useMemo(() => {
    return chatData?.messages?.filter((message) => {
      return message?.message_from === "customer";
    });
  }, [chatData]);

  const bottomRef = useRef(null);

  useEffect(() => {
    // @ts-ignore
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [newMessages, chatData?.messages]);

  // File upload
  const [Imagefile, setImageFile] = useState<any>();
  const [ImagefilePreview, setImagefilePreview] = useState<any>();
  const [messageType, setMessageType] = useState("text");

  const hiddenFileInput = useRef<any>();

  const handleClick = (e: any) => {
    hiddenFileInput.current.click();
  };

  const handleChange = (event: any) => {
    const fileUploaded = event.target.files[0];

    setMessageType("file");
    setImageFile(fileUploaded);
    setImagefilePreview({
      src: URL.createObjectURL(fileUploaded),
      type: fileUploaded?.type,
    });
  };

  const { mutate: uploadImage } = useMutation(uploadChatImage, {
    onSuccess(data) {
      mutate({
        social: customerMessages[0]?.platform?.toLowerCase(),
        message_type: "image",
        message: data?.secure_url,
        conversation_uid,
      });
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const textAreaRef = useRef<any>();

  const returnUserName = (channel: string) => {
    let username: string | undefined = "";

    switch (channel) {
      case "twitter":
        username = contact?.twitter_username;
        break;
      case "instagram":
        username = contact?.instagram_username;
        break;
      case "facebook":
        username = contact?.facebook_username;
        break;

      default:
        username = `${contact?.first_name ?? ""} ${contact?.last_name ?? ""}`;
    }

    return username;
  };

  const sendMessageHandler = () => {
    if (messageType === "text") {
      if (!textAreaRef.current.value) return;

      const toUse = textAreaRef.current.value;
      textAreaRef.current.value = "";

      mutate({
        social: customerMessages[0]?.platform?.toLowerCase(),
        message_type: customerMessages[0]?.message_type,
        message: toUse,
        conversation_uid,
      });

      setNewMessages((prev: any) => {
        return [
          ...prev,
          {
            id: Math.random() * 10,
            uid: Math.random() * 10,
            company_uid: Math.random() * 10,
            contact_uid: Math.random() * 10,
            twitter_message_uid: null,
            sender_id: "",
            recipient_id: "",
            message_from: "company",
            message_type: "text",
            message: toUse,
            status: "queued",
            platform: "Bodsquare Platform",
            timestamp: "06-10-2022 17:10:39",
            created_at: new Date(),
            updated_at: new Date(),
          },
        ];
      });
    } else {
      const formData = new FormData();

      formData.append("file", Imagefile);
      formData.append("upload_preset", "ululzj4d");
      formData.append("folder", "Bod_Web");

      uploadImage(formData);

      setNewMessages((prev: any) => {
        return [
          ...prev,
          {
            id: Math.random() * 10,
            uid: Math.random() * 10,
            company_uid: Math.random() * 10,
            contact_uid: Math.random() * 10,
            twitter_message_uid: null,
            sender_id: "",
            recipient_id: "",
            message_from: "company",
            message_type: "image",
            message: ImagefilePreview?.src,
            status: "queued",
            platform: "Bodsquare Platform",
            timestamp: "06-10-2022 17:10:39",
            created_at: new Date(),
            updated_at: new Date(),
          },
        ];
      });

      setImageFile(undefined);
      setImagefilePreview({
        src: "",
        type: "",
      });
    }
  };

  const formRef = useRef<any>();

  return (
    <>
      <VStack
        pos="relative"
        height={"100%"}
        overflowY={"hidden"}
        width={"100%"}
        alignItems={"flex-start"}
        justifyContent={"space-between"}
        spacing={"0px"}
      >
        {/* header */}
        <Box width={"full"} boxShadow={"xs"}>
          <Flex
            borderBottomWidth={"1px"}
            borderBottomColor="grays.0"
            py="16px"
            px="24px"
            w="full"
          >
            {/* Mobile back button */}
            <IconButton
              aria-label="close"
              icon={<HiOutlineChevronLeft />}
              size="xs"
              rounded="full"
              mr={3}
              my="auto"
              display={{ base: "flex", md: "none" }}
              onClick={close}
            />

            <Text
              fontSize={"sm"}
              fontWeight={700}
              my="auto"
              noOfLines={1}
              maxW={{ base: "210px", md: "unset" }}
            >
              {returnUserName(chatData?.conversation?.channel.toLowerCase()) ||
                fullname}
            </Text>

            <Menu autoSelect={false}>
              <MenuButton
                ml="auto"
                my="auto"
                size="xs"
                fontWeight={500}
                color="grays.900"
                variant="ghost"
                as={Button}
                rightIcon={<HiChevronDown />}
              >
                More
              </MenuButton>
              <MenuList fontSize={"xs"} fontWeight={500} minW="150px">
                {status === "opened" && (
                  <MenuItem
                    onClick={() => {
                      closeChat({
                        conversation_uid,
                      });
                    }}
                  >
                    {closeChatLoading ? "Closing..." : "Close chat"}
                  </MenuItem>
                )}
                {status === "closed" && (
                  <MenuItem
                    onClick={() => {
                      openChat({
                        conversation_uid,
                      });
                    }}
                  >
                    {openChatLoading ? "Opening..." : "Open chat"}
                  </MenuItem>
                )}
                <MenuItem onClick={deleteChatDisclosure.onOpen}>
                  Delete chat
                </MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </Box>

        <Box width={"full"} flexGrow={1}>
          <Flex
            flexDir={"column"}
            py={"8px"}
            px="24px"
            height={"calc(100vh - 310px)"}
            overflowY={"scroll"}
            className="thinSB"
          >
            {newMessages?.length > 0 && (
              <DisplayChat
                Messages={[...newMessages]?.sort(function (a, b) {
                  // @ts-ignore
                  return new Date(a.updated_at) - new Date(b.updated_at);
                })}
                fullname={
                  returnUserName(
                    chatData?.conversation?.channel.toLowerCase()
                  ) || fullname
                }
              />
            )}

            <div ref={bottomRef} />
          </Flex>

          {/* Input */}
          <Box
            mt={"auto"}
            w="full"
            pt="16px"
            pb={"12px"}
            px="16px"
            borderTopWidth={"1px"}
            borderTopColor="grays.0"
            h="auto"
            bg="white"
          >
            <chakra.form
              ref={formRef}
              w="full"
              onSubmit={(e) => {
                e.preventDefault();

                sendMessageHandler();
              }}
            >
              <Box
                bg="grays.0"
                rounded="4px"
                pb="12px"
                _focusWithin={{
                  outline: "1.5px solid",
                  outlineColor: "#3182ce",
                }}
              >
                {messageType === "text" && (
                  <>
                    <ChakraTextAreaAutoResize
                      px={4}
                      py={3}
                      w="full"
                      fontSize={"sm"}
                      bg="grays.0"
                      color="grays.9"
                      _placeholder={{
                        color: "grays.10",
                      }}
                      _focus={{
                        outline: "none",
                      }}
                      minH="56px"
                      placeholder="Send a message"
                      ref={textAreaRef}
                      required
                      onKeyDown={(e) => {
                        if (e.keyCode === 13 && e.shiftKey === false) {
                          e.preventDefault();

                          sendMessageHandler();
                        }
                      }}
                    />
                  </>
                )}

                {messageType === "file" && (
                  <HStack
                    w="full"
                    rounded="lg"
                    bg="grays.0"
                    color="grays.9"
                    py={ImagefilePreview?.type?.includes("image") ? "15px" : ""}
                    px={ImagefilePreview?.type?.includes("image") ? "15px" : ""}
                  >
                    {ImagefilePreview?.type?.includes("image") && (
                      <Image
                        w="125px"
                        h="62px"
                        rounded="6px"
                        objectFit={"cover"}
                        src={ImagefilePreview?.src}
                      />
                    )}

                    {ImagefilePreview?.type?.includes("audio") && (
                      <chakra.audio src={Imagefile?.src} controls autoPlay>
                        <chakra.source
                          src={Imagefile?.src}
                          type={ImagefilePreview?.type}
                        ></chakra.source>
                      </chakra.audio>
                    )}

                    {ImagefilePreview?.type?.includes("video") && (
                      <chakra.video
                        src={Imagefile?.src}
                        autoPlay
                      ></chakra.video>
                    )}

                    <IconButton
                      aria-label="Cancel file upload"
                      rounded={"full"}
                      size="sm"
                      fontSize={"md"}
                      icon={<HiX />}
                      onClick={() => {
                        setImageFile(undefined);
                        setImagefilePreview({
                          src: undefined,
                          type: undefined,
                        });
                        setMessageType("text");
                      }}
                    />
                  </HStack>
                )}

                <Flex px="16px" w="full" mt={1}>
                  {/* File upload */}
                  <>
                    <chakra.button type="button" onClick={handleClick}>
                      <PaperClip />
                    </chakra.button>

                    <input
                      type="file"
                      ref={hiddenFileInput}
                      onChange={handleChange}
                      style={{ display: "none" }}
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </>

                  <Button
                    type="submit"
                    size="sm"
                    ml="auto"
                    colorScheme={"primary"}
                    rightIcon={<Send />}
                    py={"8px"}
                    px="12px"
                  >
                    Send Message
                  </Button>
                </Flex>
              </Box>
            </chakra.form>
          </Box>
        </Box>
      </VStack>

      <CustomModal disclosure={deleteChatDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"}>
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Delete Chat
          </Text>
          <Text
            mt="5px"
            fontSize={"xs"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
          >
            Deleting this message will only delete a copy of the message on the
            Bodsquare app
          </Text>

          <Button
            onClick={() => {
              deleteChat(conversation_uid);
            }}
            isLoading={deleteChatLoading}
            w="full"
            colorScheme={"red"}
            mt="40px"
          >
            Delete chat
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default ViewAndSendMessages;
