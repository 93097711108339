import API from "../.";

export const getApiKey = async () => {
  const response = await API.get(`/company/api-key/show`);

  return response.data.data as {
    api_key: string;
  };
};
export const generateApiKey = async () => {
  const response = await API.get(`/company/api-key/generate`);

  return response.data as {
    api_key: string;
  };
};
