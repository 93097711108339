import { Box, chakra, Flex, Text } from '@chakra-ui/react';
import { FC } from 'react';

interface Props {
  title: string;
  first?: {
    title: string;
    value: number | string | undefined;
  };
  second?: {
    title: string;
    value: number | string | undefined;
  };
  third?: {
    title: string;
    value: number | string | undefined;
  };
}

const SectionReport: FC<Props> = ({ title, first, second, third }) => {
  return (
    <Box bg='white' rounded='4px' p='24px' w='full'>
      <chakra.h2
        w='full'
        borderBottom='1px'
        borderBottomColor='borders.100'
        pb='12px'
        fontSize={'20px'}
        fontWeight='medium'
      >
        {title}
      </chakra.h2>

      <Flex flexDir={{ base: 'column', md: 'row' }} w='100%' mt='22px'>
        {/* first */}
        <Box w={{ base: '100%', md: '33.3%' }} mb={{ base: 2, md: 0 }}>
          <Text fontSize='sm' color={'grays.12'}>
            {first?.title}
          </Text>
          <Text
            mt='2px'
            fontSize={'32px'}
            color='grays.9'
            fontWeight={'medium'}
          >
            {first?.value ?? 0}
          </Text>
        </Box>

        {/* second */}
        {second && (
          <Box w={{ base: '100%', md: '33.3%' }} mb={{ base: 2, md: 0 }}>
            <Text fontSize='sm' color={'grays.12'}>
              {second?.title}
            </Text>
            <Text
              mt='2px'
              fontSize={'32px'}
              color='grays.9'
              fontWeight={'medium'}
            >
              {second?.value ?? 0}
            </Text>
          </Box>
        )}

        {/* third */}

        {third && (
          <Box w={{ base: '100%', md: '33.3%' }} mb={{ base: 2, md: 0 }}>
            <Text fontSize='sm' color={'grays.12'}>
              {third?.title}
            </Text>
            <Text
              mt='2px'
              fontSize={'32px'}
              color='grays.9'
              fontWeight={'medium'}
            >
              {third?.value ?? 0}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  );
};

export default SectionReport;
