import { motion } from "framer-motion";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  delay?: number;
  duration?: number;
  style?: {};
  normal?: boolean;
}
export function PageAnimate({
  children,
  style,
  delay = 0,
  duration = 0.1,
  normal,
}: Props) {
  return (
    <motion.div
      initial={{ opacity: 0.5, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: 100 }}
      transition={{
        // type: "spring",
        type: "spring",
        stiffness: 260,
        damping: 20,

        duration: duration,
        delay: delay,
      }}
      style={{
        width: "100%",
        transitionTimingFunction: "cubic-bezier(0.645,0.045,0.355,1)",
        marginInline: normal ? "0px" : "auto",
        ...style,
      }}
    >
      {children}
    </motion.div>
  );
}
