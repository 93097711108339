export const colors = {
  brand: {
    main: "#197EEF",
  },
  backgrounds: {
    100: "#F2F4F7",
    200: "#F1F4F9",
    300: "#FFFFFF",
    400: "rgba(0, 102, 255, 0.05)",
    avatar: "rgba(25, 126, 239, 0.04)",
    800: "rgba(116, 137, 170, 0.04)",
    500: "rgba(116, 137, 170, 0.08)",
    600: "#F1D9D3",
    700: "#F5F5F5",
  },

  borders: {
    50: "#197EEF1F",
    100: "rgba(0, 102, 255, 0.16)",
    200: "rgba(0, 102, 255, 0.04)",
    300: "rgba(0, 102, 255, 0.048)",
    400: "#B2B6BD",
    500: "rgba(0, 102, 255, 0.12)",
    600: "rgba(0, 102, 255, 0.08);",
    switch: "rgba(25, 126, 239, 0.12)",
  },

  grays: {
    0: "#EFF2F7",
    1: "#A2A2A2",
    2: "#F3F5F7",
    3: "#C4C6CA",
    4: "#F3F4F7",
    5: "#ACB7C3",
    6: "#666666",
    7: "#979797",
    8: "#171D26",
    9: "#1A1A1A",
    10: "#99A1AD",
    11: "#667193",
    12: "#7489AA",
    13: "#A7A7A7",
    14: "#6682A2",
  },

  primary: {
    50: "#def1ff",
    100: "#afd1ff",
    200: "#7db3ff",
    300: "#4b94ff",
    400: "#85B6FF",
    500: "#0066FF",
    600: "#0047b4",
    700: "#003382",
    800: "#001f51",
    900: "#000a21",
  },

  green: {
    100: "#159D6F",
    200: "rgba(21, 157, 111, 0.04)",
    400: "#DAFED0",
    500: "#4DAF3F",
  },

  danger: {
    100: "rgba(225, 107, 97, 0.04)",
    200: "#E16B61",
    300: "#ED6C5A",
  },

  yellow: {
    100: "#FDBD4F",
    200: " rgba(253, 189, 79, 0.04)",
  },

  text: {
    100: "#fff",
  },
};
