import { Box, Center, Flex, Grid, HStack, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import React, { useRef } from "react";
import { actualListProps } from "./billingsTable";
import { InvoiceLogo } from "../../../assets/svgs/svg";
import { getProfileData } from "../../../API/account/profile";
import dayjs from "dayjs";
import DownloadPDFButton from "../../../utils/DownloadAsPDFButton";

const BillingReceipt: React.FC<{
  data?: actualListProps[];
  onHistory?: () => void;
}> = ({ data, onHistory }) => {
  const { data: profile } = useQuery(["profileData"], getProfileData);
  const componentRef = useRef(null);

  return (
    <Box w={"100%"}>
      <Box
        // px="3em"
        mb="2em"
        cursor={"default"}
        onClick={onHistory}
        // maxW={"7rem"}
      >
        <HStack spacing={"0px"}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5L8.5 10.25L12 7"
              stroke="#7489AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text fontSize={"xs"} color="grays.12">
            Back
          </Text>
        </HStack>
      </Box>

      <Box
        width={"70%"}
        mx={"auto"}
        boxShadow={"lg"}
        borderColor={"borders.100"}
        borderWidth={"1px"}
      >
        <Box px="3em" py="4em" ref={componentRef}>
          {data?.map((d) => (
            <Grid rowGap={"1em"}>
              <InvoiceLogo />
              <Flex pt="2em" columnGap={".5em"} alignItems="center">
                <Text fontSize={"2.2em"} fontWeight="700">
                  Receipt
                </Text>
                <Text
                  bg={"green.200"}
                  borderColor={"green.100"}
                  borderWidth="0.5px"
                  color={"green.100"}
                  px="1em"
                  py=".4em"
                  w="fit-content"
                  borderRadius="50px"
                >
                  Paid
                </Text>
              </Flex>
              <Flex justifyContent="space-between">
                <Box>
                  <Text fontSize="16px" fontWeight={"700"}>
                    From
                  </Text>
                  <Text fontSize={"14px"} fontWeight="700">
                    Bodsquare by Sendchamp
                  </Text>
                  <Box fontSize="12" fontWeight="500">
                    <Text>142, Ahmadu Bello Way,</Text>
                    <Text>Victoria Island,</Text>
                    <Text>Lagos, Nigeria</Text>
                  </Box>
                </Box>
                <Box>
                  <Text fontSize="16px" fontWeight={"700"}>
                    To
                  </Text>
                  <Text fontSize={"14px"} fontWeight={"700"}>
                    Bodsquare by Sendchamp
                  </Text>
                  <Box fontSize="12" fontWeight="500">
                    <Text>142, Ahmadu Bello Way,</Text>
                    <Text>Victoria Island,</Text>
                    <Text>Lagos, Nigeria</Text>
                  </Box>
                </Box>
                <Box></Box>
              </Flex>
              <Flex justify="space-between" mt="3.8em">
                <Box color="#7489AA" fontSize="12" fontWeight="500">
                  <Flex
                    // justify={'space-between'}
                    alignItems="center"
                    columnGap=".35em"
                  >
                    <Text>Customer:</Text>
                    <Text color={"initial"} fontWeight="700">
                      {profile?.email}
                    </Text>
                  </Flex>

                  <Flex columnGap=".35em">
                    <Text>Receipt date</Text>
                    <Text color={"initial"} fontWeight="700">
                      {dayjs(d?.created_at).format("MMM DD, YYYY")}
                    </Text>
                  </Flex>
                  <Flex columnGap=".35em" alignItems={"center"}>
                    <Text>Order Number /Receipt:</Text>
                    <Text color={"initial"} fontWeight="700">
                      {d?.invoice_id}
                    </Text>
                  </Flex>
                </Box>
                <Box>
                  <Flex
                    columnGap="0.35em"
                    color="#7489AA"
                    fontSize="12"
                    fontWeight="500"
                    paddingRight="4.4em"
                  >
                    <Text>Payment Method:</Text>
                    <Text color={"initial"} fontWeight="700">
                      {"Card ending with"} {d?.card?.last_four_digits}
                    </Text>
                  </Flex>
                </Box>
              </Flex>
              <Box>
                <Flex
                  mt=".5em"
                  borderTop="1px solid rgba(0, 102, 255, 0.12)"
                  borderBottom="1px solid #7489AA"
                  py="1em"
                  fontSize="12px"
                  fontWeight={"500"}
                  justify="space-between"
                  alignItems={"center"}
                >
                  <Flex
                    // columnGap='3em'
                    justifyContent="space-between"
                    width="30%"
                  >
                    <Text>Product</Text>
                    <Text>Period</Text>
                  </Flex>
                  <Text pr="3em">Amount</Text>
                </Flex>
                <Flex
                  // mt = '.5em'
                  // borderTop = '1px solid rgba(0, 102, 255, 0.12)'
                  borderBottom="1px solid #7489AA"
                  fontWeight="500"
                  py="1em"
                  fontSize="12px"
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Flex justifyContent="space-between" width="46%">
                    <Text fontWeight="700">
                      {"Bodsquare "}
                      {d?.plan?.name}
                    </Text>
                    <Text>
                      {dayjs(d?.created_at).format("MMM DD")}
                      {" - "}
                      {dayjs(d?.updated_at).format("MMM DD, YYYY")}
                    </Text>
                  </Flex>
                  <Text pr="3em">
                    {"$ "}
                    {Number(d?.plan?.amount).toFixed(2)}
                  </Text>
                </Flex>
                <Flex
                  borderBottom="1px solid #7489AA"
                  // fontWeight='500'
                  py="1em"
                  // fontSize = '12px'
                  justifyContent="space-between"
                  alignItems={"center"}
                >
                  <Text fontSize={"16px"} fontWeight="700">
                    Total
                  </Text>
                  <Text fontSize={"20px"} fontWeight="900">
                    {"$ "}
                    {Number(d?.plan?.amount).toFixed(2)}
                  </Text>
                </Flex>
              </Box>
            </Grid>
          ))}
        </Box>
      </Box>

      {data?.map((d) => (
        <Center mt="3em">
          <DownloadPDFButton
            componentRef={componentRef}
            _filename={d?.invoice_id}
          />
        </Center>
      ))}
    </Box>
  );
};

export default BillingReceipt;
