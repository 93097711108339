import {
  Box,
  // HStack,
  Button,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getPlansData, getCards } from "../../../API/account/billings";
import { useState } from "react";
import CustomModal from "../../../components/UI/CustomModal";
import SelectUpgradePaymentMethod from "../../../components/account/billings/SelectUpgradePaymentMethod";
import Loader from "../../../components/UI/Loader";
import AddCardModal from "../../../components/account/billings/AddCardModal";
import { GoBack } from "../../../components/UI/Buttons";

const BillingPlan = () => {
  const disclosure = useDisclosure();
  const addNewCardDisclosure = useDisclosure();
  const [title, setTitle] = useState("Select a payment method");
  const [planId, setPlanId] = useState(0);
  const [amount, setAmount] = useState(0);

  const queryClient = useQueryClient();

  const { isLoading, data } = useQuery(["listPlans"], getPlansData);
  const { data: cardList } = useQuery(["getCards"], getCards);

  const upgrade = (id: number, amount: number) => {
    cardList?.length > 0 ? disclosure.onOpen() : addNewCardDisclosure.onOpen();
    setPlanId(id);
    setAmount(amount);
  };

  const openSelectCardModal = () => {
    disclosure.onOpen();
    queryClient.invalidateQueries(["getCards"]);
  };
  return (
    <Box w="full" h="full">
      <GoBack>Back to Billings</GoBack>

      {/* Table header */}
      <Box mt="20px">
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Plans
        </Text>
        <Text color={"grays.12"} fontSize={"12px"}>
          You’re currently on the{" "}
          <Text as="span" color={"grays.8"} fontWeight={700}>
            Free Plan
          </Text>
        </Text>
      </Box>

      {isLoading ? (
        <Loader />
      ) : !isLoading && data?.length > 0 ? (
        <Box mt="32px">
          <SimpleGrid columns={3}>
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px"}
              position="relative"
            >
              <Text
                position="absolute"
                bottom="0"
                fontSize={"14px"}
                fontWeight={"700"}
              >
                Core
              </Text>
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor="borders.600"
                borderWidth="1px"
                p={"20px"}
              >
                <Text
                  textTransform={"capitalize"}
                  fontSize={"12px"}
                  color="grays.9"
                  fontWeight="700"
                >
                  {x?.name}
                </Text>
                <Text fontSize={"12px"} color="grays.12" fontWeight="500">
                  {x?.details.Description}
                </Text>
                <Text fontSize={"32px"} color="grays.9" fontWeight="700">
                  ${x.amount}
                </Text>
                <Box h="60px" mt={2}>
                  {x.details.name !== "free" && (
                    <Button
                      mb={"15px"}
                      colorScheme={"primary"}
                      size="xs"
                      h="32px"
                      w="88px"
                      onClick={() => upgrade(x.id, x?.details?.amount)}
                    >
                      Upgrade
                    </Button>
                  )}
                </Box>
                <Text fontSize={"12px"} color="grays.12" fontWeight="500">
                  Up to {x.details.advanced?.["Additional Users"]} users
                </Text>
              </Box>
            ))}
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px 20px 60px 20px"}
              position="relative"
            >
              {Object.keys(data[0].details.core).map((key) => (
                <Text
                  key={key}
                  fontSize={"12px"}
                  color="grays.12"
                  fontWeight="500"
                  my={2}
                >
                  {key}
                </Text>
              ))}
              <Text
                position="absolute"
                bottom="0"
                fontSize={"14px"}
                fontWeight={"700"}
              >
                Advanced
              </Text>
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor="borders.600"
                borderWidth="1px"
                p={"20px"}
              >
                {Object.values<string | boolean>(x.details.core).map(
                  (text, i) => (
                    <Text
                      key={i}
                      my={"8px"}
                      fontSize={"12px"}
                      color="grays.8"
                      fontWeight="500"
                    >
                      {typeof text === "string"
                        ? text
                        : text === true
                        ? "✓"
                        : "-"}
                    </Text>
                  )
                )}
              </Box>
            ))}
            <Box
              bg="background.100"
              minH="200px"
              borderColor="borders.600"
              borderWidth="1px"
              p={"20px"}
            >
              {Object.keys(data[0].details.advanced).map((key, i) => (
                <Text
                  key={i}
                  fontSize={"12px"}
                  color="grays.12"
                  fontWeight="500"
                  my={2}
                >
                  {key}
                </Text>
              ))}
            </Box>
            {data?.map((x: any) => (
              <Box
                key={x.id}
                bg="background.100"
                minH="200px"
                borderColor="borders.600"
                borderWidth="1px"
                p={"20px"}
              >
                {Object.values<string | boolean>(x.details.advanced).map(
                  (text, i) => (
                    <Text
                      key={i}
                      my={"8px"}
                      fontSize={"12px"}
                      color="grays.8"
                      fontWeight="500"
                    >
                      {typeof text === "string"
                        ? text
                        : text === true
                        ? "✓"
                        : "—"}
                    </Text>
                  )
                )}
              </Box>
            ))}
          </SimpleGrid>
        </Box>
      ) : null}

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            {title}
          </Text>
        }
      >
        <SelectUpgradePaymentMethod
          close={disclosure.onClose}
          setTitle={setTitle}
          planId={planId}
          amount={amount}
          addNewCardDisclosure={addNewCardDisclosure}
        />
      </CustomModal>
      <AddCardModal
        disclosure={addNewCardDisclosure}
        onAddCardSuccess={openSelectCardModal}
      />
    </Box>
  );
};

export default BillingPlan;
