import { useState } from "react";
import {
  VStack,
  chakra,
  Button,
  useToast,
  Select,
  Box,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import { SingleAttributeProp } from "../../../types";
import FormInput from "../../UI/form/FormInput";
import { FaCaretDown } from "react-icons/fa";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { editContactAttribute } from "../../../API/contacts";
import { toaster } from "../../../utils/toast";
import { removeSpaces } from "../../../functions";

export function EditAttribute({
  close,
  data,
  queryToInvalidate,
}: {
  close: () => void;
  data: SingleAttributeProp;
  queryToInvalidate?: Array<any>;
}) {
  const [attributeName, setAttributeName] = useState(data.name);
  const [attributeDatatype, setAttributeDatatype] = useState(data.data_type);
  const [attributeDescription, setAttributeDescription] = useState(
    data.description
  );
  const toast = useToast();
  const queryClient = useQueryClient();
  const properties = [
    { name: "Text", value: "text" },
    { name: "Number", value: "number" },
    { name: "True/False", value: "boolean" },
  ];

  const editAttributeMutation = useMutation(editContactAttribute, {
    onSuccess: (data) => {
      const success = { ...data };
      queryClient.invalidateQueries(["contactAttributes"]);
      queryClient.invalidateQueries(queryToInvalidate);
      toaster(toast, success);
      close();
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <chakra.form
      mt="24px"
      onSubmit={(e) => {
        e.preventDefault();
        let formattedName = removeSpaces(attributeName);
        editAttributeMutation.mutate({
          uid: data.uid,
          name: formattedName,
          description: attributeDescription,
        });
      }}
    >
      <VStack align={"flex-start"} spacing="20px">
        <FormInput
          label="Name"
          type="text"
          for="Name"
          inputProps={{
            placeholder: "Enter attribute name",
            onChange: (e) => {
              setAttributeName(e.target.value);
            },
            value: attributeName,
          }}
        />
        <Box width={"100%"}>
          <FormControl>
            <FormLabel color={"grays.9"} fontSize={"12px"} fontWeight={700}>
              Data type
            </FormLabel>
            <Select
              isDisabled
              width={"100%"}
              fontSize={"sm"}
              color={"grays.12"}
              borderColor={"borders.100"}
              iconSize={"16px"}
              icon={<FaCaretDown />}
              aria-label="select data type"
              placeholder="Select type"
              onChange={(e) => {
                setAttributeDatatype(e.target.value);
              }}
              defaultValue={attributeDatatype}
            >
              {properties.map((option, index) => (
                <chakra.option
                  key={index}
                  value={option.value}
                  color={"grays.9"}
                >
                  {option.name}
                </chakra.option>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FormInput
          label="Description"
          type="textarea"
          for="Description"
          textareaProps={{
            placeholder: "Describe your attribute here",
            onChange: (e) => {
              setAttributeDescription(e.target.value);
            },
            value: attributeDescription,
          }}
        />
      </VStack>

      <Button
        type="submit"
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "#B2B6BD",
        }}
        _hover={{
          bg: "grey.20",
        }}
        _focus={{
          bg: "grey.20",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={editAttributeMutation.isLoading}
        isDisabled={!attributeName || !attributeDescription}
      >
        Save changes
      </Button>
    </chakra.form>
  );
}
