import React, { FC } from "react";

const KeyphraseSuccessIcon: FC<any> = (props) => {
    return (
        <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="10" cy="10" r="10" fill="#159D6F" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.66461 14C8.49211 14 8.32711 13.924 8.20899 13.79L5.16961 10.3373C4.93274 10.0693 4.94586 9.64729 5.19711 9.39528C5.44899 9.14328 5.84461 9.15661 6.08024 9.42462L8.65836 12.352L13.9134 6.21725C14.1471 5.94458 14.5421 5.92658 14.7971 6.17458C15.0515 6.42258 15.069 6.84459 14.8365 7.11592L9.12586 13.7827C9.00899 13.92 8.84274 13.9987 8.66899 14H8.66461Z"
                fill="white"
            />
        </svg>
    );
};

export default KeyphraseSuccessIcon;
