import React, { useState, FC } from "react";
import { HStack, Text, chakra, useToast } from "@chakra-ui/react";
// import FormInput from '../../UI/form/FormInput'
import SearchableSelect from "../../UI/form/SearchableSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addContactsToListFn } from "../../../API/contacts";
import { toaster } from "../../../utils/toast";
import { getListData } from "../../../API/account/list";
import { ButtonPrimary } from "../../UI/Buttons";

const AddToList: FC<{ contactList: Array<string>; close?: () => void }> = ({
  contactList,
  close,
}) => {
  const [listID, setListID] = useState("");
  const [ops, setOps] = useState([]);

  const toast = useToast();

  const addMutation = useMutation(addContactsToListFn, {
    onSuccess: (data) => {
      const success = { ...data };
      toaster(toast, success);
      close && close();
    },
  });

  const userLists = useQuery(
    ["get_user_lists"],
    () => {
      return getListData();
    },
    {
      onSuccess: (data) => {
        // console.log("we ggggg", data);
        let arr = data?.map((item: any) => {
          return { label: item.name, value: item.uid, nu: item.no_of_contacts };
        });

        setOps(arr);
      },
    }
  );

  const formatOptionLabel = ({ nu, label }: { nu: number; label: string }) => (
    <HStack justifyContent={"space-between"} width={"100%"}>
      <Text fontSize={"14px"} fontWeight={500}>
        {label}
      </Text>
      <Text color={"grays.12"} fontWeight={500} fontSize={"12px"} pr={"10px"}>
        {nu}
      </Text>
    </HStack>
  );

  return (
    <chakra.form px="0" mt="24px" pb={"44px"}>
      <SearchableSelect
        isDisabled={userLists.isLoading}
        label="List"
        placeholder="Select a list"
        options={ops}
        formatOptionLabel={formatOptionLabel}
        // value={email}
        onChange={(e: { label: string; value: string }) => {
          setListID(e.value);
          // setImportContactsData((prev) => {
          //   return {
          //     ...prev,
          //     list_uid: e.value,
          //   };
          // });
        }}
      />

      <HStack justifyContent={"center"}>
        <ButtonPrimary
          isLoading={addMutation.isLoading}
          onClick={() => {
            addMutation.mutate({
              list_uid: listID,
              contact_uids: contactList,
            });
          }}
          buttonProps={{
            my: "20px",
            py: "22px",
            width: "100%",
            fontSize: "sm",
          }}
        >
          Add to List
        </ButtonPrimary>
      </HStack>
    </chakra.form>
  );
};

export default AddToList;
