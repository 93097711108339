import {
  Box,
  chakra,
  Button,
  // HStack,
  Heading,
  Menu,
  MenuButton,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Tbody,
  Td,
  useToast,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useState, useMemo } from "react";
// import { ButtonPrimary } from "../../../components/UI/Buttons";
import { deleteSingleSegemnt, getSegments } from "../../../API/contacts";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { SingleSegmentProp } from "../../../types";
import NoEventsIcons from "../../../icons/NoEventsIcons";
import { FaCaretDown } from "react-icons/fa";
import {
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import Loader from "../../../components/UI/Loader";
import CustomModal from "../../../components/UI/CustomModal";
import { toaster } from "../../../utils/toast";
import { ButtonPrimary } from "../../../components/UI/Buttons";
function Segments() {
  const [segs, setSegs] = useState<SingleSegmentProp[]>([]);
  const [currData, setCurrData] = useState<SingleSegmentProp>();
  const editModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  // const [dataToShow] = useState(10);
  // const [myCurrentPage] = useState(1);
  const [globalFilter] = useState("");
  const [sorting, setSorting] = useState<SortingState>([]);
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery(["get_segemnts"], () => getSegments(), {
    onSuccess: (data) => {
      // console.log("we haveeeee", data);
      setSegs(data.docs);
    },
    onError: (data: any) => {
      const errors = { ...data };
      toast({
        title: errors?.message,
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const deleteSegmentMutation = useMutation(deleteSingleSegemnt, {
    onSuccess: (data) => {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["get_segemnts"]);
    },
  });

  const columns = useMemo(() => {
    return [
      {
        header: "Segment name",
        accessorKey: "name",
        id: "attributename",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "No of contacts",
        accessorKey: "no_of_contacts",
        id: "no_of_contacts",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <Menu>
            <MenuButton
              variant={"ghost"}
              _active={{
                bg: "none",
              }}
              _hover={{
                bg: "none",
              }}
              minHeight={"0px"}
              height={"14px"}
              fontWeight={500}
              fontSize={"12px"}
              px={"0px"}
              py={"0px"}
              as={Button}
              rightIcon={<FaCaretDown />}
            >
              View actions
            </MenuButton>
            <MenuList boxShadow={"lg"}>
              <MenuItem
                py={"8px"}
                onClick={() => {
                  // editModalDisclosure.onOpen();
                  // setCurrData(props?.row?.original);
                }}
              >
                Edit Segments
              </MenuItem>
              <MenuItem
                py={"8px"}
                onClick={() => {
                  deleteModalDisclosure.onOpen();
                  setCurrData(props?.row?.original);
                }}
              >
                Delete Segments
              </MenuItem>
            </MenuList>
          </Menu>
        ),
      },
    ];
  }, [deleteModalDisclosure]);
  // }, [editModalDisclosure, deleteModalDisclosure]);

  const table = useReactTable({
    data: segs!,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box width={"100%"}>
      <Heading
        as={"h2"}
        fontSize={"20px"}
        color={"grays.9"}
        fontWeight={700}
        mb={"24px"}
      >
        Segments
      </Heading>

      <Box minHeight={"70vh"}>
        {isLoading && segs?.length === 0 ? (
          <Loader />
        ) : !isLoading && segs?.length === 0 ? (
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={"76px"}
          >
            <NoEventsIcons />
            <Text
              my={"12px"}
              textAlign={"center"}
              fontWeight={500}
              fontSize={"16px"}
              color={"grays.12"}
            >
              No segments to show
            </Text>
          </Box>
        ) : (
          <Box>
            <TableContainer
              borderWidth={"1px"}
              borderColor="borders.200"
              rounded={"8px"}
            >
              <Table variant={"simple"}>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              textTransform="none"
                              color={"grays.12"}
                              fontSize="xs"
                              letterSpacing={"unset"}
                              fontWeight={500}
                              colSpan={header.colSpan}
                              px={"12px"}
                            >
                              <Box display={"flex"}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}

                                {header.column.getCanSort() && (
                                  <VStack spacing="2px" my="auto" ml="2">
                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowUp width="8px" />
                                    </chakra.button>

                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowDown width="8px" />
                                    </chakra.button>
                                  </VStack>
                                )}
                              </Box>
                            </Th>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell) => (
                        <Td
                          key={cell.id}
                          fontSize="xs"
                          color="grays.12"
                          fontWeight={"medium"}
                          px={"12px"}
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      {/* edit segments */}
      <CustomModal
        titleUnderLine
        disclosure={editModalDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Edit Segment
          </Text>
        }
      >
        <Box></Box>
      </CustomModal>

      {/* delete segments */}
      <CustomModal disclosure={deleteModalDisclosure}>
        <Box
          width={"100%"}
          maxWidth={"365px"}
          mx={"auto"}
          mb={"30px"}
          pt={"12px"}
        >
          <Text
            textAlign={"center"}
            fontWeight={700}
            fontSize={"20px"}
            maxWidth={"305px"}
            mx={"auto"}
            mb={"24px"}
          >
            Are you sure you want to delete this segment?
          </Text>

          <ButtonPrimary
            isLoading={deleteSegmentMutation.isLoading}
            buttonProps={{
              colorScheme: "red",
              width: "100%",
              py: "20px",
            }}
            onClick={() => {
              if (currData?.uid) {
                deleteSegmentMutation.mutate(currData?.uid);
                // console.log("Deleting segment", currData);
              }
              deleteModalDisclosure.onClose();
            }}
          >
            Delete Segment
          </ButtonPrimary>
        </Box>
      </CustomModal>
    </Box>
  );
}

export default Segments;
