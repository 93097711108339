import React, { FC } from 'react';

const Send: FC<any> = (props) => {
  return (
    <svg
      width='14'
      height='14'
      viewBox='0 0 14 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M11.7488 7.17898C12.3228 6.88032 12.3095 6.05447 11.7262 5.77443L2.29282 1.24595C1.68019 0.95185 1.00855 1.52199 1.19926 2.17425L2.34721 6.1004L4.61659 6.10051C4.88765 6.10052 5.10738 6.32027 5.10737 6.59133C5.10735 6.86239 4.88761 7.08211 4.61655 7.0821L2.38173 7.08199C2.37684 7.10408 2.37157 7.12611 2.36591 7.14806L1.34311 11.1159C1.17348 11.774 1.8631 12.3222 2.46596 12.0086L11.7488 7.17898Z'
        fill='white'
      />
    </svg>
  );
};

export default Send;
