import {
  chakra,
  VStack,
  Button,
  useToast,
  // Box,
  // FormLabel,
  // Select,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import { createContactForList, getListData } from "../../API/account/list";
import { createSingleContact } from "../../API/contacts";
import { toaster } from "../../utils/toast";
import CustomPhoneInput from "../UI/form/CustomPhoneInput";
import FormInput from "../UI/form/FormInput";

// import ChakraSelect from '../UI/form/ChakraSelect';
import SearchableSelect from "../UI/form/SearchableSelect";
import { SingleListProp } from "../../types";

const AddContactToList: FC<{
  isContactList?: boolean;
  close?: () => void;
  hasList?: boolean;
}> = ({ isContactList = false, close, hasList = false }) => {
  const toast = useToast();
  const queryClient = useQueryClient();

  const allListQuery = useQuery(["all_list_data"], getListData);

  const { mutate, isLoading } = useMutation(createSingleContact, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["allContacts"]);
      close && close();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const { mutate: addToContactList, isLoading: addToContactListLoading } =
    useMutation(createContactForList, {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["contactlistData"]);

        close && close();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    });

  // const { data } = useQuery(['newListData'], getListData);
  // const allLists = useMemo(() => {
  //   return data?.map((list: listProps) => {
  //     return {
  //       label: list?.name,
  //       value: list?.uid,
  //     };
  //   });
  // }, [data]);

  const { id } = useParams();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  return (
    <chakra.form
      mt="24px"
      onSubmit={(e) => {
        e.preventDefault();

        if (!isContactList) {
          mutate({
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
          });
        } else {
          addToContactList({
            first_name: firstName,
            last_name: lastName,
            email: email,
            phone_number: phoneNumber,
            list_uid: id,
          });
        }
      }}
    >
      <VStack align={"flex-start"} spacing="20px">
        <FormInput
          label="First name"
          type="text"
          for="First name"
          inputProps={{
            placeholder: "Enter First name",
            onChange: (e) => {
              setFirstName(e.target.value);
            },
            value: firstName,
          }}
          formControlProps={{
            isRequired: true,
          }}
        />
        <FormInput
          label="Last name"
          type="text"
          for="Last name"
          inputProps={{
            placeholder: "Enter Last name",
            onChange: (e) => {
              setLastName(e.target.value);
            },
            value: lastName,
          }}
          formControlProps={{
            isRequired: true,
          }}
        />

        <FormInput
          label="Email address"
          type="email"
          for="Email address"
          inputProps={{
            placeholder: "Enter email address",
            onChange: (e) => {
              setEmail(e.target.value);
            },
            value: email,
          }}
        />

        <CustomPhoneInput
          label="Phone number"
          value={phoneNumber}
          onChange={(phone) => {
            setPhoneNumber(phone);
          }}
        />
        <SearchableSelect
          isDisabled={hasList}
          label="List"
          placeholder="Search list option"
          options={
            allListQuery?.data
              ? allListQuery?.data.map(
                  (item: SingleListProp, index: number) => ({
                    option: item.uid,
                    label: item.name,
                  })
                )
              : []
          }
        />
      </VStack>

      <Button
        type="submit"
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "#B2B6BD",
        }}
        _hover={{
          bg: "grey.20",
        }}
        _focus={{
          bg: "grey.20",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={isLoading || addToContactListLoading}
        isDisabled={!firstName || !lastName || (!email && !phoneNumber)}
      >
        Add account
      </Button>
    </chakra.form>
  );
};

export default AddContactToList;
