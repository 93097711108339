import { chakra, Text, Button } from "@chakra-ui/react";
import React, { FC } from "react";
import FormInput from "../../../UI/form/FormInput";
import { useMutation } from "@tanstack/react-query";
import { shortenURL } from "../../../../API/misc";
import { useRecoilState } from "recoil";
import { tipTapAtom } from "../../../../atoms/tiptap";

const AddLink: FC<{
  channel: string;
  editor?: any;
  close?: () => void;
}> = ({ close, editor, channel }) => {
  const [tiptapValue, setTiptapValue] = useRecoilState(tipTapAtom);

  const handleAddLink = async (data: { link: string; link_uid: string }) => {
    editor
      ?.chain()
      .focus()
      .createParagraphNear()
      .insertContent(`${data.link}`)
      .run();

    setTiptapValue((prev) => ({
      ...prev,
      linkObject: {
        ...prev.linkObject,
        link: data.link,
        link_uid: data.link_uid,
      },
    }));
    close && close();
  };

  const shortenURLMutation = useMutation(shortenURL, {
    onSuccess: (data) => {
      // console.log("we haveeeeee", data);

      handleAddLink(data);
    },
  });

  return (
    <chakra.form
      px="0"
      mt="24px"
      onSubmit={(e) => {
        e.preventDefault();

        shortenURLMutation.mutate({
          url: tiptapValue.linkObject.link,
          channel: channel,
        });
      }}
    >
      <FormInput
        label={
          <>
            <Text> URL</Text>
            <Text color="#7489AA" fontSize={"12px"}>
              Links should start with http:// or https://
            </Text>
          </>
        }
        type="text"
        for="Url"
        value={tiptapValue.linkObject.link}
        inputProps={{
          placeholder: "Enter link name",
          onChange: (e) => {
            const value = e.target.value;
            // if (value.includes("http://") || value.includes("https://")) {
            // setLink(value);
            // }
            setTiptapValue((prev) => ({
              ...prev,
              linkObject: {
                ...prev.linkObject,
                link: value,
              },
            }));
          },
          value: tiptapValue.linkObject.link,
        }}
      />
      <Button
        type="submit"
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "#B2B6BD",
        }}
        _hover={{
          bg: "grey.20",
        }}
        _focus={{
          bg: "grey.20",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={shortenURLMutation.isLoading}
        isDisabled={!tiptapValue.linkObject.link}
      >
        Add
      </Button>
    </chakra.form>
  );
};

export default AddLink;
