// import { Facebook } from "../../icons/Socials"
// import React, {useState} from 'react'

export const Hamburger = (props: any) => (
  <svg
    width="59"
    height="32"
    viewBox="0 0 59 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width="58"
      height="31"
      rx="3.5"
      fill="white"
      stroke="#EBF3FF"
    />
    <path d="M39 14.3984L43 18.3984L47 14.3984L39 14.3984Z" fill="#7489AA" />
    <rect x="8" y="8" width="6" height="4" fill="#7489AA" />
    <rect x="15" y="8" width="16" height="4" fill="#7489AA" />
    <rect x="8" y="14" width="6" height="4" fill="#7489AA" />
    <rect x="15" y="14" width="16" height="4" fill="#7489AA" />
    <rect x="8" y="20" width="6" height="4" fill="#7489AA" />
    <rect x="15" y="20" width="16" height="4" fill="#7489AA" />
  </svg>
);

interface Props {
  fill?: string;
  props?: any;
  width?: string;
  height?: string;
}

export const ArrowDown = ({ fill, width, height, ...props }: Props) => (
  <svg
    width={width || "20"}
    height={height || "20"}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 8L10 13L15 8L5 8Z" fill={fill || "#7489AA"} />
  </svg>
);

export const CloseBtn = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.80532 6.19794C9.54465 5.93727 9.12332 5.93727 8.86265 6.19794L8.00065 7.05994L7.13865 6.19794C6.87798 5.93727 6.45665 5.93727 6.19598 6.19794C5.93532 6.4586 5.93532 6.87994 6.19598 7.1406L7.05798 8.0026L6.19598 8.8646C5.93532 9.12527 5.93532 9.5466 6.19598 9.80727C6.32598 9.93727 6.49665 10.0026 6.66732 10.0026C6.83798 10.0026 7.00865 9.93727 7.13865 9.80727L8.00065 8.94527L8.86265 9.80727C8.99265 9.93727 9.16332 10.0026 9.33398 10.0026C9.50465 10.0026 9.67532 9.93727 9.80532 9.80727C10.066 9.5466 10.066 9.12527 9.80532 8.8646L8.94332 8.0026L9.80532 7.1406C10.066 6.87994 10.066 6.4586 9.80532 6.19794ZM8.00065 13.3359C5.05998 13.3359 2.66732 10.9433 2.66732 8.0026C2.66732 5.06194 5.05998 2.66927 8.00065 2.66927C10.9413 2.66927 13.334 5.06194 13.334 8.0026C13.334 10.9433 10.9413 13.3359 8.00065 13.3359ZM8.00065 1.33594C4.32465 1.33594 1.33398 4.3266 1.33398 8.0026C1.33398 11.6786 4.32465 14.6693 8.00065 14.6693C11.6767 14.6693 14.6673 11.6786 14.6673 8.0026C14.6673 4.3266 11.6767 1.33594 8.00065 1.33594Z"
      fill="#7489AA"
    />
  </svg>
);

export const Check = (props: any) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2.57041 6C2.35311 6 2.13581 5.9236 1.96426 5.75989L0.248749 4.12278C-0.0829164 3.80627 -0.0829164 3.2824 0.248749 2.96589C0.580415 2.64939 1.12938 2.64939 1.46104 2.96589L2.57041 4.02456L6.53896 0.237381C6.87062 -0.0791269 7.41959 -0.0791269 7.75125 0.237381C8.08292 0.553888 8.08292 1.07776 7.75125 1.39427L3.17655 5.75989C3.01644 5.9236 2.78771 6 2.57041 6Z"
      fill="white"
    />
  </svg>
);

export const Add = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 8.5H4C3.72667 8.5 3.5 8.27333 3.5 8C3.5 7.72667 3.72667 7.5 4 7.5H12C12.2733 7.5 12.5 7.72667 12.5 8C12.5 8.27333 12.2733 8.5 12 8.5Z"
      fill="#0066FF"
    />
    <path
      d="M8 12.5C7.72667 12.5 7.5 12.2733 7.5 12V4C7.5 3.72667 7.72667 3.5 8 3.5C8.27333 3.5 8.5 3.72667 8.5 4V12C8.5 12.2733 8.27333 12.5 8 12.5Z"
      fill="#0066FF"
    />
  </svg>
);

export const AddContactIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.99935 9.3776C11.4951 9.3776 12.7077 8.16504 12.7077 6.66927C12.7077 5.1735 11.4951 3.96094 9.99935 3.96094C8.50358 3.96094 7.29102 5.1735 7.29102 6.66927C7.29102 8.16504 8.50358 9.3776 9.99935 9.3776Z"
      stroke="#7489AA"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2085 16.0391H5.79145C4.80854 16.0391 4.07508 15.1726 4.57588 14.3269C5.30223 13.1002 6.8663 11.6641 10.2085 11.6641"
      stroke="#7489AA"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.166 12.2891V16.0391"
      stroke="#7489AA"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.041 14.1641H12.291"
      stroke="#7489AA"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const EditIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.16602 12.8332L5.99935 12.1666L12.1946 5.97131C12.4549 5.71095 12.4549 5.28885 12.1946 5.02849L10.9707 3.80464C10.7104 3.54429 10.2883 3.54429 10.0279 3.80464L3.83268 9.9999L3.16602 12.8332Z"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8327 12.8359H9.16602"
      stroke="black"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const InstagramIcon = (props: any) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="13" cy="13" r="13" fill="url(#paint0_linear_4506_72009)" />
    <path
      d="M10.1111 6.5C8.11994 6.5 6.5 8.11994 6.5 10.1111V15.8889C6.5 17.8801 8.11994 19.5 10.1111 19.5H15.8889C17.8801 19.5 19.5 17.8801 19.5 15.8889V10.1111C19.5 8.11994 17.8801 6.5 15.8889 6.5H10.1111ZM10.1111 7.94444H15.8889C17.0834 7.94444 18.0556 8.91656 18.0556 10.1111V15.8889C18.0556 17.0834 17.0834 18.0556 15.8889 18.0556H10.1111C8.91656 18.0556 7.94444 17.0834 7.94444 15.8889V10.1111C7.94444 8.91656 8.91656 7.94444 10.1111 7.94444ZM16.6111 8.66667C16.4196 8.66667 16.2359 8.74276 16.1004 8.8782C15.965 9.01364 15.8889 9.19734 15.8889 9.38889C15.8889 9.58043 15.965 9.76413 16.1004 9.89958C16.2359 10.035 16.4196 10.1111 16.6111 10.1111C16.8027 10.1111 16.9864 10.035 17.1218 9.89958C17.2572 9.76413 17.3333 9.58043 17.3333 9.38889C17.3333 9.19734 17.2572 9.01364 17.1218 8.8782C16.9864 8.74276 16.8027 8.66667 16.6111 8.66667ZM13 9.38889C11.0088 9.38889 9.38889 11.0088 9.38889 13C9.38889 14.9912 11.0088 16.6111 13 16.6111C14.9912 16.6111 16.6111 14.9912 16.6111 13C16.6111 11.0088 14.9912 9.38889 13 9.38889ZM13 10.8333C14.1946 10.8333 15.1667 11.8054 15.1667 13C15.1667 14.1946 14.1946 15.1667 13 15.1667C11.8054 15.1667 10.8333 14.1946 10.8333 13C10.8333 11.8054 11.8054 10.8333 13 10.8333Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4506_72009"
        x1="13"
        y1="0"
        x2="13"
        y2="26"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#9830C8" />
        <stop offset="0.541667" stopColor="#F84F4C" />
        <stop offset="1" stopColor="#FDBD4F" />
      </linearGradient>
    </defs>
  </svg>
);

export const FacebookIcon = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="16.5"
      cy="15.5"
      r="12.5"
      fill="url(#paint0_linear_4506_72004)"
    />
    <path
      d="M17.4779 14.8047L17.5299 17.8776L20.2695 17.8359C20.4674 17.8359 20.6133 18.013 20.582 18.2005L20.2174 20.1901C20.1862 20.3359 20.0612 20.4401 19.9154 20.4505L17.5716 20.4922L17.6966 28.0443L14.5716 28.0964L14.4466 20.5443L12.6758 20.5755C12.4987 20.5755 12.3633 20.4401 12.3633 20.263L12.332 18.2839C12.332 18.1068 12.4674 17.9714 12.6445 17.9714L14.4154 17.9401L14.3633 14.5547C14.332 12.8255 15.707 11.4089 17.4362 11.3776L20.2487 11.3359C20.4258 11.3359 20.5612 11.4714 20.5612 11.6484L20.6029 14.1484C20.6029 14.3255 20.4674 14.4609 20.2903 14.4609L17.7904 14.5026C17.6133 14.4922 17.4779 14.638 17.4779 14.8047Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_4506_72004"
        x1="16.5"
        y1="3"
        x2="16.5"
        y2="28"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#1AABFC" />
        <stop offset="1" stopColor="#0064D8" />
      </linearGradient>
    </defs>
  </svg>
);

export const TwitterIcon = (props: any) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16.5" cy="16.5" r="12.5" fill="#489BE9" />
    <path
      d="M21.587 12.9392C22.1634 12.5853 22.5451 12.1024 22.732 11.4881C22.2056 11.7728 21.6406 11.9797 21.0548 12.1024C20.5532 11.563 19.9183 11.2891 19.1571 11.2891C18.413 11.2891 17.7844 11.5502 17.2715 12.0581C17.0238 12.2993 16.8279 12.5886 16.6959 12.9082C16.5639 13.2277 16.4986 13.5709 16.5039 13.9166C16.5039 14.137 16.5317 14.3339 16.5873 14.5009C14.3928 14.426 12.5765 13.5157 11.1453 11.7635C10.9028 12.1923 10.7815 12.6289 10.7815 13.0805C10.7815 14.0086 11.1732 14.7442 11.9551 15.2842C11.5092 15.2464 11.1161 15.1337 10.7815 14.9489C10.7815 15.6117 10.9763 16.1653 11.3665 16.6526C11.7456 17.1274 12.2798 17.4535 12.8754 17.5736C12.6528 17.6307 12.4181 17.6578 12.177 17.6578C11.9544 17.6578 11.7967 17.6392 11.7032 17.5986C11.8673 18.1386 12.177 18.5767 12.6243 18.912C13.0665 19.2477 13.6044 19.4335 14.1595 19.442C13.2093 20.1876 12.1227 20.5564 10.8935 20.5564C10.5924 20.5564 10.3791 20.5521 10.25 20.5293C11.4607 21.3155 12.8083 21.7057 14.2994 21.7057C15.8254 21.7057 17.1738 21.3191 18.3473 20.5457C19.5209 19.7766 20.387 18.8278 20.9435 17.7106C21.4938 16.6295 21.7803 15.4336 21.7796 14.2205V13.8866C22.2925 13.5085 22.7366 13.0449 23.0923 12.5161C22.6119 12.7244 22.1055 12.8667 21.587 12.9392Z"
      fill="white"
    />
  </svg>
);

export const ViewContactIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4506_71900)">
      <circle cx="8" cy="8" r="8" fill="#7489AA" fillOpacity="0.08" />
      <path
        d="M10.1673 7.16406L8.00065 9.4974L5.83398 7.16406"
        stroke="#7489AA"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4506_71900">
        <rect width="16" height="16" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const MsgIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.00065 15.2093C7.54065 15.2093 7.10732 14.9759 6.80065 14.5693L5.80065 13.2359C5.78065 13.2093 5.70065 13.1759 5.66732 13.1693H5.33398C2.55398 13.1693 0.833984 12.4159 0.833984 8.66927V5.33594C0.833984 2.38927 2.38732 0.835938 5.33398 0.835938H10.6673C13.614 0.835938 15.1673 2.38927 15.1673 5.33594V8.66927C15.1673 11.6159 13.614 13.1693 10.6673 13.1693H10.334C10.2807 13.1693 10.234 13.1959 10.2007 13.2359L9.20065 14.5693C8.89398 14.9759 8.46065 15.2093 8.00065 15.2093ZM5.33398 1.83594C2.94732 1.83594 1.83398 2.94927 1.83398 5.33594V8.66927C1.83398 11.6826 2.86732 12.1693 5.33398 12.1693H5.66732C6.00732 12.1693 6.39398 12.3626 6.60065 12.6359L7.60065 13.9693C7.83398 14.2759 8.16732 14.2759 8.40065 13.9693L9.40065 12.6359C9.62065 12.3426 9.96732 12.1693 10.334 12.1693H10.6673C13.054 12.1693 14.1673 11.0559 14.1673 8.66927V5.33594C14.1673 2.94927 13.054 1.83594 10.6673 1.83594H5.33398Z"
      fill="#0066FF"
    />
    <path
      d="M11.3327 5.83594H4.66602C4.39268 5.83594 4.16602 5.60927 4.16602 5.33594C4.16602 5.0626 4.39268 4.83594 4.66602 4.83594H11.3327C11.606 4.83594 11.8327 5.0626 11.8327 5.33594C11.8327 5.60927 11.606 5.83594 11.3327 5.83594Z"
      fill="#0066FF"
    />
    <path
      d="M8.66602 9.16406H4.66602C4.39268 9.16406 4.16602 8.9374 4.16602 8.66406C4.16602 8.39073 4.39268 8.16406 4.66602 8.16406H8.66602C8.93935 8.16406 9.16602 8.39073 9.16602 8.66406C9.16602 8.9374 8.93935 9.16406 8.66602 9.16406Z"
      fill="#0066FF"
    />
  </svg>
);

export const LinkIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.99378 14.3341C4.88711 14.3341 3.77378 13.9141 2.92711 13.0674C1.24044 11.3741 1.24044 8.6274 2.92711 6.94073C3.12044 6.7474 3.44044 6.7474 3.63378 6.94073C3.82711 7.13406 3.82711 7.45406 3.63378 7.6474C2.33378 8.9474 2.33378 11.0607 3.63378 12.3607C4.93378 13.6607 7.04711 13.6607 8.34711 12.3607C8.97378 11.7341 9.32044 10.8941 9.32044 10.0007C9.32044 9.11406 8.97378 8.27406 8.34711 7.64073C8.15378 7.4474 8.15378 7.1274 8.34711 6.93406C8.54044 6.74073 8.86044 6.74073 9.05378 6.93406C9.87378 7.75406 10.3204 8.84073 10.3204 10.0007C10.3204 11.1607 9.86711 12.2474 9.05378 13.0674C8.21378 13.9141 7.10711 14.3341 5.99378 14.3341Z"
      fill="#0066FF"
    />
    <path
      d="M12.7134 9.43885C12.5867 9.43885 12.4601 9.39219 12.3601 9.29219C12.1667 9.09885 12.1667 8.77885 12.3601 8.58552C13.7267 7.21885 13.7267 4.99885 12.3601 3.63885C10.9934 2.27219 8.77339 2.27219 7.41339 3.63885C6.75339 4.29885 6.38672 5.17885 6.38672 6.11219C6.38672 7.04552 6.75339 7.92552 7.41339 8.58552C7.60672 8.77885 7.60672 9.09885 7.41339 9.29219C7.22005 9.48552 6.90005 9.48552 6.70672 9.29219C5.86005 8.44552 5.38672 7.31219 5.38672 6.11219C5.38672 4.91219 5.85339 3.77885 6.70672 2.93219C8.46005 1.17885 11.3134 1.17885 13.0734 2.93219C14.8267 4.68552 14.8267 7.54552 13.0734 9.29885C12.9734 9.39219 12.8401 9.43885 12.7134 9.43885Z"
      fill="#0066FF"
    />
  </svg>
);

export const ArrowUp = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.83268 8.83594L7.99935 6.5026L10.166 8.83594"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const MsgLink = (props: any) => (
  <svg
    width="14"
    height="8"
    viewBox="0 0 14 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6.33301 7.33073H3.66634C2.78229 7.33073 1.93444 6.97954 1.30932 6.35442C0.684197 5.7293 0.333008 4.88145 0.333008 3.9974C0.333008 3.11334 0.684197 2.26549 1.30932 1.64037C1.93444 1.01525 2.78229 0.664063 3.66634 0.664062H6.33301V1.9974H3.66634C3.13591 1.9974 2.6272 2.20811 2.25213 2.58318C1.87705 2.95825 1.66634 3.46696 1.66634 3.9974C1.66634 4.52783 1.87705 5.03654 2.25213 5.41161C2.6272 5.78668 3.13591 5.9974 3.66634 5.9974H6.33301V7.33073ZM10.333 0.664062H7.66634V1.9974H10.333C10.8634 1.9974 11.3721 2.20811 11.7472 2.58318C12.1223 2.95825 12.333 3.46696 12.333 3.9974C12.333 4.52783 12.1223 5.03654 11.7472 5.41161C11.3721 5.78668 10.8634 5.9974 10.333 5.9974H7.66634V7.33073H10.333C11.2171 7.33073 12.0649 6.97954 12.69 6.35442C13.3152 5.7293 13.6663 4.88145 13.6663 3.9974C13.6663 3.11334 13.3152 2.26549 12.69 1.64037C12.0649 1.01525 11.2171 0.664062 10.333 0.664062ZM9.66634 3.33073H4.33301V4.66406H9.66634V3.33073Z"
      fill="#13161D"
    />
  </svg>
);

export const DoubleArrow = (props: any) => (
  <svg
    width="56"
    height="56"
    viewBox="0 0 56 56"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_4117_49920)">
      <rect x="12" y="12" width="32" height="32" rx="6" fill="white" />
    </g>
    <path
      d="M24 25L21 28L24 31"
      stroke="#171D26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M32 31L35 28L32 25"
      stroke="#171D26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="24" y="27" width="2" height="1.5" fill="#171D26" />
    <rect x="27" y="27" width="2" height="1.5" fill="#171D26" />
    <rect x="30" y="27" width="2" height="1.5" fill="#171D26" />
    <defs>
      <filter
        id="filter0_d_4117_49920"
        x="0"
        y="0"
        width="56"
        height="56"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="6" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.454902 0 0 0 0 0.537255 0 0 0 0 0.666667 0 0 0 0.24 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_4117_49920"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_4117_49920"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const Wallet = (props: any) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M18 8.89763V16.3459C18 17.2601 17.259 18.0011 16.3448 18.0011H7.65517C6.74105 18.0011 6 17.2601 6 16.3459V7.65625"
      stroke="#171D26"
      strokeWidth="1.24138"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.7241 12.8279C15.7241 13.0564 15.5388 13.2416 15.3103 13.2416C15.0818 13.2416 14.8965 13.0564 14.8965 12.8279C14.8965 12.5994 15.0818 12.4141 15.3103 12.4141C15.5388 12.4141 15.7241 12.5994 15.7241 12.8279Z"
      stroke="#171D26"
      strokeWidth="0.827586"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.3448 8.89655H7.44828C6.64841 8.89655 6 8.24814 6 7.44828C6 6.64841 6.64841 6 7.44828 6H14.6897C15.6038 6 16.3448 6.74105 16.3448 7.65517V8.89655ZM16.3448 8.89655H18"
      stroke="#171D26"
      strokeWidth="1.24138"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TopUpIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.3577 0H4.65032C1.73687 0 0 1.736 0 4.648V11.344C0 14.264 1.73687 16 4.65032 16H11.3497C14.2631 16 16 14.264 16 11.352V4.648C16.008 1.736 14.2711 0 11.3577 0ZM12.8064 8.6H8.60429V12.8C8.60429 13.128 8.33215 13.4 8.00399 13.4C7.67582 13.4 7.40369 13.128 7.40369 12.8V8.6H3.2016C2.87343 8.6 2.6013 8.328 2.6013 8C2.6013 7.672 2.87343 7.4 3.2016 7.4H7.40369V3.2C7.40369 2.872 7.67582 2.6 8.00399 2.6C8.33215 2.6 8.60429 2.872 8.60429 3.2V7.4H12.8064C13.1345 7.4 13.4067 7.672 13.4067 8C13.4067 8.328 13.1345 8.6 12.8064 8.6Z"
      fill="#0066FF"
    />
  </svg>
);

export const ExportIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0026 15.1654H6.0026C2.3826 15.1654 0.835938 13.6187 0.835938 9.9987V5.9987C0.835938 2.3787 2.3826 0.832031 6.0026 0.832031H10.0026C13.6226 0.832031 15.1693 2.3787 15.1693 5.9987V9.9987C15.1693 13.6187 13.6226 15.1654 10.0026 15.1654ZM6.0026 1.83203C2.92927 1.83203 1.83594 2.92536 1.83594 5.9987V9.9987C1.83594 13.072 2.92927 14.1654 6.0026 14.1654H10.0026C13.0759 14.1654 14.1693 13.072 14.1693 9.9987V5.9987C14.1693 2.92536 13.0759 1.83203 10.0026 1.83203H6.0026Z"
      fill="white"
    />
    <path
      d="M8.00224 10.1741C7.87557 10.1741 7.74891 10.1274 7.64891 10.0274L5.64891 8.02745C5.45557 7.83411 5.45557 7.51411 5.64891 7.32078C5.84224 7.12745 6.16224 7.12745 6.35557 7.32078L8.00224 8.96745L9.64891 7.32078C9.84224 7.12745 10.1622 7.12745 10.3556 7.32078C10.5489 7.51411 10.5489 7.83411 10.3556 8.02745L8.35557 10.0274C8.25557 10.1274 8.12891 10.1741 8.00224 10.1741Z"
      fill="white"
    />
    <path
      d="M8 10.1732C7.72667 10.1732 7.5 9.94651 7.5 9.67318V4.33984C7.5 4.06651 7.72667 3.83984 8 3.83984C8.27333 3.83984 8.5 4.06651 8.5 4.33984V9.67318C8.5 9.95318 8.27333 10.1732 8 10.1732Z"
      fill="white"
    />
    <path
      d="M7.99899 12.1521C6.59232 12.1521 5.17899 11.9255 3.83899 11.4788C3.57899 11.3921 3.43899 11.1055 3.52565 10.8455C3.61232 10.5855 3.89232 10.4388 4.15898 10.5321C6.63898 11.3588 9.36565 11.3588 11.8457 10.5321C12.1057 10.4455 12.3923 10.5855 12.479 10.8455C12.5657 11.1055 12.4257 11.3921 12.1657 11.4788C10.819 11.9321 9.40565 12.1521 7.99899 12.1521Z"
      fill="white"
    />
  </svg>
);

export const InvoiceLogo = (props: any) => (
  <svg
    width="169"
    height="32"
    viewBox="0 0 169 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2543_32748)">
      <path
        d="M41.8867 21.4851V5.61352H47.7735C48.4741 5.60843 49.1729 5.68444 49.855 5.83994C50.4853 5.98758 51.0887 6.22813 51.6441 6.55321C52.2075 6.8866 52.6617 7.36876 52.9536 7.9434C53.2881 8.60612 53.4538 9.33715 53.4368 10.075C53.4368 11.4757 52.7905 12.5023 51.4979 13.1545C53.3961 13.8185 54.3452 15.0386 54.3452 16.8148C54.3478 17.2171 54.3098 17.6186 54.2317 18.0137C54.1304 18.4457 53.9595 18.8594 53.7255 19.2397C53.4702 19.6819 53.1239 20.0684 52.7083 20.3753C52.2904 20.6881 51.7101 20.9501 50.9676 21.1613C50.1184 21.3885 49.2401 21.4964 48.3594 21.4815L41.8867 21.4851ZM44.8307 12.0834H47.9414C49.6399 12.0834 50.4892 11.4445 50.4892 10.1665C50.4892 8.76421 49.5678 8.06306 47.7252 8.06306H44.8259L44.8307 12.0834ZM44.8307 18.9616H48.2132C50.3345 18.9616 51.3952 18.1952 51.3952 16.6622C51.3952 15.2912 50.2983 14.6053 48.1045 14.6045H44.8307V18.9616Z"
        fill="#0066FF"
      />
      <path
        d="M57.0581 20.0932C56.0618 19.0397 55.5633 17.5291 55.5625 15.5613C55.5617 13.5936 56.0602 12.0829 57.0581 11.0295C58.0551 9.97596 59.485 9.44922 61.3478 9.44922C63.2106 9.44922 64.6401 9.97596 65.6364 11.0295C66.6334 12.0829 67.1319 13.5932 67.1319 15.5602C67.1319 17.5271 66.6334 19.0378 65.6364 20.092C64.6401 21.1455 63.2106 21.6719 61.3478 21.6711C59.485 21.6703 58.0551 21.1443 57.0581 20.0932ZM59.206 12.5757C58.7396 13.0942 58.5065 14.0914 58.5065 15.5672C58.5065 17.043 58.7396 18.0382 59.206 18.5529C59.6723 19.0683 60.3882 19.326 61.3538 19.326C62.3195 19.326 63.0334 19.0683 63.4957 18.5529C63.9572 18.0382 64.1883 17.043 64.1891 15.5672C64.1899 14.0914 63.9588 13.0942 63.4957 12.5757C63.0318 12.0571 62.3179 11.7975 61.3538 11.7967C60.3898 11.7959 59.6739 12.0556 59.206 12.5757Z"
        fill="#0066FF"
      />
      <path
        d="M68.5209 15.4066C68.5209 13.4748 69.0073 11.9947 69.9802 10.9662C70.953 9.93774 72.3528 9.42351 74.1793 9.42351C74.8618 9.42622 75.5426 9.48825 76.2137 9.60887V5.04297H79.1564V17.6672C79.1564 18.7622 78.732 19.7007 77.8832 20.4828C77.0343 21.2649 75.7361 21.6583 73.9884 21.663C73.5819 21.6632 73.1759 21.6322 72.7744 21.5703C72.2809 21.4776 71.8047 21.3126 71.3622 21.0811C70.834 20.8254 70.3576 20.4793 69.956 20.0593C69.5533 19.6409 69.2143 19.0261 68.9388 18.2151C68.6453 17.3068 68.5041 16.3585 68.5209 15.4066ZM71.4636 15.2552C71.4636 16.6536 71.6513 17.6637 72.0266 18.2855C72.4019 18.9073 73.0558 19.2201 73.9884 19.224C75.4719 19.224 76.2137 18.7966 76.2137 17.9417V11.8472C75.5781 11.7168 74.9304 11.6503 74.2808 11.649C73.3627 11.649 72.6644 11.9153 72.186 12.4479C71.7077 12.9805 71.4669 13.9163 71.4636 15.2552Z"
        fill="#0066FF"
      />
      <path
        d="M80.834 13.1406C80.834 11.9464 81.2665 11.0294 82.1314 10.3896C82.9964 9.74985 84.0752 9.42997 85.3678 9.42997C86.5383 9.4192 87.6905 9.712 88.7056 10.2782V12.4485C87.82 12.0566 86.8554 11.8618 85.8824 11.8783C84.4786 11.8783 83.7768 12.2123 83.7768 12.8802C83.7768 13.2146 83.9761 13.4762 84.3808 13.6674C84.8488 13.8813 85.3377 14.0491 85.8401 14.1683C86.4284 14.3175 87.003 14.5136 87.5579 14.7549C88.1572 15.0237 88.6638 15.4547 89.0173 15.9961C89.4199 16.578 89.6213 17.321 89.6213 18.2251C89.6213 19.2191 89.2045 20.0435 88.371 20.6981C87.5374 21.3527 86.4349 21.6796 85.0633 21.6788C83.7037 21.69 82.3648 21.3542 81.1807 20.7051V18.1723C82.1974 18.8535 83.4029 19.2192 84.6381 19.2211C85.4837 19.2211 86.0342 19.1257 86.2895 18.9348C86.5448 18.744 86.6724 18.4585 86.6724 18.0784C86.6724 17.7288 86.4711 17.4473 86.0684 17.2338C85.6043 16.9964 85.115 16.8087 84.6091 16.6742C84.0389 16.5178 83.4687 16.3293 82.8985 16.1087C82.3199 15.8853 81.8153 15.5125 81.4392 15.0306C81.0349 14.53 80.8332 13.9001 80.834 13.1406Z"
        fill="#0066FF"
      />
      <path
        d="M91.5893 15.7115C91.572 14.7576 91.7115 13.8072 92.0025 12.8959C92.2771 12.0872 92.6158 11.4737 93.0184 11.0553C93.7814 10.2884 94.7635 9.76128 95.838 9.5419C96.2375 9.48001 96.6415 9.44902 97.046 9.44922C98.7905 9.44922 100.089 9.84027 100.942 10.6224C101.795 11.4045 102.221 12.3457 102.22 13.4461V25.8886H99.2821V21.5303C98.616 21.6725 97.9358 21.7433 97.2538 21.7415C95.4249 21.7415 94.0235 21.2214 93.0498 20.1812C92.0762 19.141 91.5893 17.6511 91.5893 15.7115ZM94.5321 15.8511C94.5321 17.2143 94.781 18.1649 95.2787 18.703C95.7764 19.2411 96.4984 19.5102 97.4447 19.5102C98.0597 19.5049 98.6736 19.4587 99.2821 19.3717V13.1787C99.2821 12.327 98.55 11.9007 97.0859 11.8999H97.0509C96.1191 11.8999 95.4663 12.21 95.0926 12.8302C94.719 13.4504 94.5321 14.4574 94.5321 15.8511Z"
        fill="#0066FF"
      />
      <path
        d="M104.691 16.1051V9.60938H107.637V16.4711C107.637 17.4214 107.838 18.1053 108.241 18.523C108.643 18.9406 109.295 19.149 110.195 19.1483C111.115 19.1483 111.775 18.9398 112.174 18.523C112.573 18.1061 112.775 17.4222 112.778 16.4711V9.60938H115.722V16.1051C115.722 17.8476 115.235 19.2108 114.262 20.1947C113.288 21.1786 111.934 21.6701 110.2 21.6694C108.465 21.6694 107.116 21.1778 106.151 20.1947C105.186 19.2116 104.699 17.8484 104.691 16.1051Z"
        fill="#0066FF"
      />
      <path
        d="M117.483 17.6058C117.483 17.3582 117.508 17.1112 117.555 16.8679C117.634 16.5364 117.762 16.2176 117.934 15.9212C118.142 15.5377 118.427 15.198 118.77 14.9216C119.123 14.6354 119.647 14.3894 120.341 14.1837C121.135 13.9656 121.957 13.862 122.782 13.8764C123.501 13.8799 124.219 13.9344 124.93 14.0394C124.93 12.5284 124.067 11.7729 122.341 11.7729C120.963 11.7729 119.766 11.9743 118.749 12.3771V10.0308C119.955 9.61552 121.228 9.41097 122.508 9.42662C124.253 9.42662 125.587 9.85677 126.512 10.7171C127.436 11.5774 127.898 12.8264 127.897 14.4641V17.6715C127.902 18.2004 127.792 18.7243 127.575 19.2093C127.358 19.6944 127.039 20.1297 126.637 20.4871C125.796 21.2731 124.504 21.6661 122.759 21.6661C120.955 21.6661 119.626 21.2711 118.773 20.4812C118.361 20.1175 118.033 19.6733 117.811 19.1777C117.588 18.6821 117.477 18.1464 117.483 17.6058ZM120.427 17.2468C120.427 18.517 121.194 19.152 122.729 19.152H122.753C124.217 19.152 124.948 18.5518 124.948 17.3512V15.852C124.367 15.7673 123.782 15.7168 123.194 15.7006C121.36 15.7006 120.437 16.216 120.427 17.2468Z"
        fill="#0066FF"
      />
      <path
        d="M130.094 21.4858V10.6681C130.293 10.5766 130.567 10.4675 130.919 10.3408C131.614 10.1236 132.321 9.94302 133.037 9.80002C134.073 9.56844 135.131 9.44301 136.194 9.42578V11.8777C134.967 11.8933 133.914 12.0251 133.037 12.273V21.4858H130.094Z"
        fill="#0066FF"
      />
      <path
        d="M137.234 15.6763C137.234 13.7712 137.67 12.2492 138.543 11.1104C139.416 9.97171 140.802 9.40234 142.702 9.40234C144.497 9.40234 145.854 9.95607 146.775 11.0635C147.696 12.171 148.156 13.5342 148.157 15.1531C148.157 15.8195 148.141 16.3114 148.109 16.6289H140.177C140.257 17.659 140.575 18.3484 141.129 18.6972C141.683 19.046 142.402 19.2204 143.287 19.2204C144.729 19.2194 146.151 18.9056 147.452 18.3019V20.6024C146.095 21.3157 144.56 21.6719 142.845 21.6711C142.332 21.6738 141.819 21.631 141.313 21.5433C140.791 21.4432 140.289 21.263 139.825 21.0095C139.321 20.7466 138.87 20.3959 138.496 19.9748C138.127 19.5571 137.823 18.9784 137.585 18.2385C137.336 17.4065 137.218 16.5427 137.234 15.6763ZM140.177 14.6545H145.107C145.054 13.9137 144.796 13.2005 144.359 12.591C144.166 12.3215 143.907 12.1033 143.604 11.9563C143.302 11.8094 142.967 11.7384 142.629 11.7498C141.895 11.7498 141.323 11.9782 140.912 12.4349C140.502 12.8917 140.258 13.6315 140.18 14.6545H140.177Z"
        fill="#0066FF"
      />
      <path
        d="M15.7329 18.8154C13.7776 18.8156 11.8534 18.3401 10.1361 17.4322C9.78622 17.2466 9.5266 16.9337 9.41434 16.5622C9.30208 16.1907 9.34639 15.7911 9.5375 15.4514C9.72861 15.1116 10.0509 14.8595 10.4334 14.7505C10.8159 14.6415 11.2274 14.6845 11.5773 14.8701C12.8527 15.5438 14.2816 15.8968 15.7335 15.8968C17.1855 15.8968 18.6143 15.5438 19.8898 14.8701C20.2397 14.6845 20.6511 14.6415 21.0337 14.7505C21.4162 14.8595 21.7385 15.1116 21.9296 15.4514C22.1207 15.7911 22.165 16.1907 22.0527 16.5622C21.9405 16.9337 21.6809 17.2466 21.331 17.4322C19.6133 18.3402 17.6886 18.8157 15.7329 18.8154Z"
        fill="#0066FF"
      />
      <path
        d="M8.01264 31.9991C7.66072 31.9983 7.31304 31.9243 6.99306 31.782C6.56298 31.5968 6.19796 31.2936 5.9428 30.9097C5.68765 30.5258 5.55345 30.078 5.5567 29.6211C5.55651 29.561 5.56055 29.5011 5.56878 29.4416L6.02904 25.8342C6.08146 25.4529 6.28677 25.1071 6.60034 24.872C6.9139 24.637 7.31036 24.5316 7.70347 24.5789C8.09658 24.6263 8.45456 24.8224 8.69955 25.1247C8.94454 25.427 9.05672 25.8109 9.01169 26.1931L8.77009 28.0702L11.8578 24.9965C11.9955 24.8591 12.1598 24.7494 12.3411 24.6736C12.5225 24.5978 12.7175 24.5574 12.9149 24.5548C13.1123 24.5522 13.3084 24.5874 13.4918 24.6583C13.6752 24.7293 13.8425 24.8346 13.984 24.9684C14.1255 25.1021 14.2385 25.2616 14.3165 25.4377C14.3946 25.6138 14.4361 25.8032 14.4388 25.9949C14.4415 26.1866 14.4053 26.377 14.3322 26.5551C14.2592 26.7333 14.1507 26.8957 14.013 27.0331L9.69786 31.3304C9.66887 31.3585 9.63987 31.3855 9.60847 31.4113C9.16787 31.7892 8.6005 31.9982 8.01264 31.9991Z"
        fill="#0066FF"
      />
      <path
        d="M25.3193 27.474H12.9393C12.5408 27.474 12.1585 27.3202 11.8767 27.0465C11.5948 26.7729 11.4365 26.4016 11.4365 26.0146C11.4365 25.6275 11.5948 25.2563 11.8767 24.9826C12.1585 24.709 12.5408 24.5552 12.9393 24.5552H25.3193C26.1517 24.5533 26.9495 24.2314 27.5382 23.6598C28.1268 23.0881 28.4583 22.3134 28.4602 21.505V5.9725C28.4583 5.16412 28.1268 4.38937 27.5382 3.81775C26.9495 3.24613 26.1517 2.92417 25.3193 2.92232H6.15013C5.3177 2.92417 4.51991 3.24613 3.93129 3.81775C3.34267 4.38937 3.01114 5.16412 3.00923 5.9725V21.5015C3.01114 22.3099 3.34267 23.0846 3.93129 23.6562C4.51991 24.2279 5.3177 24.5498 6.15013 24.5517H7.52367C7.92224 24.5517 8.30449 24.7054 8.58632 24.9791C8.86815 25.2528 9.02648 25.624 9.02648 26.0111C9.02648 26.3981 8.86815 26.7693 8.58632 27.043C8.30449 27.3167 7.92224 27.4705 7.52367 27.4705H6.15013C4.52053 27.4677 2.95843 26.838 1.80578 25.7193C0.653136 24.6006 0.00383185 23.084 0 21.5015L0 5.9725C0.00287435 4.38936 0.651756 2.87185 1.80451 1.75239C2.95726 0.632932 4.5199 0.00279134 6.15013 0L25.3193 0C26.9496 0.00279134 28.5122 0.632932 29.6649 1.75239C30.8177 2.87185 31.4666 4.38936 31.4694 5.9725V21.5015C31.4666 23.0846 30.8177 24.6021 29.6649 25.7216C28.5122 26.8411 26.9496 27.4712 25.3193 27.474Z"
        fill="#0066FF"
      />
      <path
        d="M105.797 28.9462V29.4964H104.971V24.5586H105.797V26.1904C106.048 25.8279 106.565 25.5769 107.126 25.5769C108.254 25.5769 109.037 26.3582 109.037 27.5642C109.037 28.7702 108.254 29.5527 107.119 29.5527C106.558 29.5527 106.048 29.3016 105.797 28.9462ZM106.995 28.8617C107.707 28.8617 108.203 28.3385 108.203 27.5642C108.203 26.7899 107.708 26.2608 106.995 26.2608C106.283 26.2608 105.797 26.7911 105.797 27.5642C105.797 28.3373 106.292 28.8594 106.995 28.8594V28.8617Z"
        fill="#0066FF"
      />
      <path
        d="M110.472 29.4678L108.914 25.6328H109.804L110.892 28.4484L111.979 25.6328H112.847L110.778 30.7653H109.917L110.472 29.4678Z"
        fill="#0066FF"
      />
      <path
        d="M129.785 28.2423H130.589C130.625 28.6541 130.934 28.8981 131.415 28.8981C131.846 28.8981 132.112 28.6952 132.112 28.3749C132.112 27.5455 129.893 28.0746 129.893 26.6598C129.893 26.0462 130.452 25.5781 131.278 25.5781C132.19 25.5781 132.758 26.0181 132.829 26.7513H132.038C131.981 26.3993 131.716 26.2069 131.292 26.2069C130.929 26.2069 130.688 26.3677 130.688 26.6257C130.688 27.3719 132.921 26.8275 132.921 28.3479C132.921 29.0729 132.303 29.5481 131.42 29.5481C130.474 29.5539 129.835 29.0377 129.785 28.2423Z"
        fill="#0066FF"
      />
      <path
        d="M133.117 27.5693C133.117 26.4056 133.942 25.582 135.078 25.582C136.327 25.582 137.103 26.5206 136.944 27.716H133.935C133.986 28.4551 134.452 28.8809 135.098 28.8809C135.623 28.8809 135.99 28.6369 136.083 28.2463H136.929C136.764 28.9783 136.125 29.5578 135.097 29.5578C133.885 29.5531 133.117 28.7296 133.117 27.5693ZM136.097 27.1001C136.083 26.5557 135.673 26.2144 135.078 26.2144C134.482 26.2144 134.101 26.5288 133.971 27.1001H136.097Z"
        fill="#0066FF"
      />
      <path
        d="M137.391 25.6333H138.216V26.2198C138.457 25.8573 138.97 25.5781 139.545 25.5781C140.39 25.5781 141.032 26.1225 141.032 26.9448V29.4976H140.205V27.1396C140.205 26.6234 139.874 26.2891 139.36 26.2891C138.693 26.2891 138.218 26.7912 138.218 27.7602V29.4964H137.393L137.391 25.6333Z"
        fill="#0066FF"
      />
      <path
        d="M141.426 27.5689C141.426 26.3629 142.209 25.5816 143.336 25.5816C143.896 25.5816 144.413 25.8326 144.665 26.1951V24.5586H145.491V29.4964H144.665V28.9462C144.413 29.2981 143.903 29.5527 143.343 29.5527C142.209 29.5527 141.426 28.7714 141.426 27.5689ZM143.465 28.8594C144.169 28.8594 144.665 28.3361 144.665 27.5619C144.665 26.7876 144.169 26.2585 143.465 26.2585C142.761 26.2585 142.257 26.7888 142.257 27.5619C142.257 28.335 142.755 28.8594 143.465 28.8594Z"
        fill="#0066FF"
      />
      <path
        d="M145.914 27.5693C145.914 26.4056 146.76 25.582 147.904 25.582C148.902 25.582 149.613 26.1193 149.728 26.9898H148.894C148.801 26.5569 148.411 26.2648 147.904 26.2648C147.243 26.2648 146.748 26.7951 146.748 27.5682C146.748 28.3413 147.25 28.8727 147.904 28.8727C148.45 28.8727 148.859 28.5595 148.923 28.1407H149.769C149.675 28.9501 148.964 29.5567 147.909 29.5567C146.755 29.5531 145.914 28.7226 145.914 27.5693Z"
        fill="#0066FF"
      />
      <path
        d="M150.121 24.5586H150.946V26.2186C151.098 26.0159 151.299 25.8518 151.53 25.7401C151.761 25.6284 152.017 25.5724 152.275 25.5769C153.121 25.5769 153.762 26.1212 153.762 26.9436V29.4964H152.937V27.1395C152.937 26.6233 152.606 26.289 152.091 26.289C151.425 26.289 150.95 26.7911 150.95 27.7672V29.4964H150.125L150.121 24.5586Z"
        fill="#0066FF"
      />
      <path
        d="M154.193 28.4512C154.193 27.7473 154.71 27.3073 155.874 27.1818L156.829 27.0774V26.973C156.829 26.5037 156.467 26.2198 155.939 26.2198C155.411 26.2198 155.062 26.499 155.005 26.966H154.201C154.308 26.1717 154.998 25.5781 155.939 25.5781C156.937 25.5781 157.647 26.1225 157.647 27.0633V29.4976H156.829V28.9263C156.649 29.3029 156.147 29.5539 155.572 29.5539C154.725 29.5539 154.193 29.1281 154.193 28.4512ZM155.679 28.9204C156.355 28.9204 156.829 28.4805 156.829 27.7133V27.6874L155.888 27.7919C155.299 27.854 155.02 28.084 155.02 28.4265C155.02 28.7163 155.291 28.9193 155.679 28.9193V28.9204Z"
        fill="#0066FF"
      />
      <path
        d="M164.333 26.9449V29.4976H163.507V27.1337C163.507 26.6246 163.205 26.2902 162.724 26.2902C162.092 26.2902 161.697 26.8616 161.697 27.8153V29.4965H160.871V27.1337C160.871 26.6246 160.576 26.2902 160.095 26.2902C159.464 26.2902 159.068 26.8545 159.068 27.8153V29.4965H158.242V25.6344H159.068V26.2128C159.27 25.8714 159.744 25.5781 160.312 25.5781C160.9 25.5781 161.36 25.8573 161.553 26.3665C161.693 26.1276 161.896 25.9288 162.141 25.7904C162.385 25.6519 162.663 25.5787 162.946 25.5781C163.751 25.5758 164.333 26.1225 164.333 26.9449Z"
        fill="#0066FF"
      />
      <path
        d="M164.936 25.6344H165.762V26.1917C166.013 25.8292 166.53 25.5781 167.091 25.5781C168.218 25.5781 169.001 26.3594 169.001 27.5654C169.001 28.7714 168.218 29.5539 167.083 29.5539C166.523 29.5539 166.013 29.3029 165.762 28.9474V30.767H164.936V25.6344ZM166.961 28.8606C167.672 28.8606 168.169 28.3374 168.169 27.5631C168.169 26.7888 167.673 26.2597 166.961 26.2597C166.25 26.2597 165.762 26.79 165.762 27.5631C165.762 28.3362 166.257 28.8606 166.961 28.8606Z"
        fill="#0066FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.255 26.2641C120.351 26.1961 121.55 26.0295 121.68 25.999C122.026 25.9216 122.804 25.8242 123.172 25.7726C123.293 25.755 123.239 25.7503 123.335 25.7303C123.879 25.613 125.823 25.389 126.062 25.2834C126.08 24.2275 126.182 23.8158 125.274 23.9929L122.087 24.6534C121.597 24.7554 119.241 25.2071 118.892 25.3115C118.508 25.4288 118.364 25.6377 118.365 26.0588V27.0994C118.581 27.1918 118.803 27.2702 119.03 27.334L120.371 27.7857C120.605 27.8573 120.821 27.9546 121.025 28.0203C121.344 28.1236 123.58 28.8415 123.695 28.926C123.637 28.9683 123.567 28.9909 123.495 28.9905C123.415 29.0023 123.335 29.0081 123.253 29.0175L121.956 29.2029C121.045 29.3284 116.737 29.915 116.696 29.9548C116.632 30.0628 116.662 30.5121 116.658 30.6787C116.639 31.4623 117.229 31.2465 118.229 31.0717C118.66 30.9966 119.134 30.9286 119.558 30.8687C120.388 30.7514 121.353 30.5449 122.143 30.4394L124.123 30.125C124.861 29.9607 125.518 30.1144 125.519 29.2744V28.0731C125.309 27.979 125.093 27.8979 124.872 27.8303C124.657 27.754 124.42 27.6731 124.224 27.6097C123.752 27.456 123.333 27.3082 122.895 27.1651C122.683 27.0947 122.442 27.002 122.229 26.9305C122.015 26.8589 121.777 26.7932 121.571 26.7123C121.261 26.5879 120.502 26.3791 120.255 26.2606V26.2641Z"
        fill="#0066FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M127.151 27.9193C127.118 27.8153 127.07 27.7165 127.008 27.626C126.464 26.4528 126.365 26.9596 127.209 26.2182C127.374 26.0727 127.802 25.7677 127.838 25.6445L125.121 26.0422C125.001 26.0082 122.858 26.3144 122.592 26.3942L122.756 26.4751C123.609 26.7461 126.941 27.9251 127.151 27.9287V27.9193Z"
        fill="#0066FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.492 27.152C116.506 27.2775 117.076 28.25 117.071 28.3251C117.065 28.4319 115.849 29.2742 115.781 29.4584L117.179 29.266C117.404 29.232 117.649 29.1921 117.864 29.1639C118.951 29.0196 120 28.8976 121.065 28.6946C120.539 28.481 119.999 28.3012 119.449 28.1562L119.264 28.0741C118.359 27.8054 117.4 27.4441 116.486 27.1484L116.492 27.152Z"
        fill="#0066FF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2543_32748">
        <rect width="169" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const TemplateIcon = (props: any) => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.575 56.875H20.675C25.675 56.875 28.125 54.55 28.125 49.75V10.25C28.125 5.45 25.7 3.125 20.675 3.125H10.575C5.575 3.125 3.125 5.45 3.125 10.25V49.75C3.125 54.55 5.55 56.875 10.575 56.875ZM39.325 34.375H49.425C54.425 34.375 56.875 32.05 56.875 27.25V10.25C56.875 5.45 54.45 3.125 49.425 3.125H39.325C34.325 3.125 31.875 5.45 31.875 10.25V27.25C31.875 32.05 34.3 34.375 39.325 34.375ZM49.425 56.875H39.325C34.3 56.875 31.875 54.55 31.875 49.75V45.25C31.875 40.45 34.325 38.125 39.325 38.125H49.425C54.45 38.125 56.875 40.45 56.875 45.25V49.75C56.875 54.55 54.425 56.875 49.425 56.875Z"
      fill="#7489AA"
    />
  </svg>
);

export const InfoIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.6663 8.0026C14.6663 11.6853 11.6817 14.6693 7.99967 14.6693C4.31767 14.6693 1.33301 11.6853 1.33301 8.0026C1.33301 4.32127 4.31767 1.33594 7.99967 1.33594C11.6817 1.33594 14.6663 4.32127 14.6663 8.0026Z"
      fill="#7489AA"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.57975 8.42062C8.57975 8.74196 8.31775 9.00396 7.99642 9.00396C7.67509 9.00396 7.41309 8.74196 7.41309 8.42062V5.47396C7.41309 5.15262 7.67509 4.89062 7.99642 4.89062C8.31775 4.89062 8.57975 5.15262 8.57975 5.47396V8.42062ZM7.41636 10.5365C7.41636 10.2151 7.67702 9.95312 7.99636 9.95312C8.32502 9.95312 8.58636 10.2151 8.58636 10.5365C8.58636 10.8578 8.32502 11.1198 8.00302 11.1198C7.67969 11.1198 7.41636 10.8578 7.41636 10.5365Z"
      fill="white"
    />
  </svg>
);

export const AttributeIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M6 7L3 10L6 13"
      stroke="#171D26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14 13L17 10L14 7"
      stroke="#171D26"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect x="6" y="9" width="2" height="1.5" fill="#171D26" />
    <rect x="9" y="9" width="2" height="1.5" fill="#171D26" />
    <rect x="12" y="9" width="2" height="1.5" fill="#171D26" />
  </svg>
);

export const DangerIcon = (props: any) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5067 10.6159L10.24 2.93594C9.66665 1.9026 8.87332 1.33594 7.99998 1.33594C7.12665 1.33594 6.33332 1.9026 5.75998 2.93594L1.49332 10.6159C0.953318 11.5959 0.893318 12.5359 1.32665 13.2759C1.75999 14.0159 2.61332 14.4226 3.73332 14.4226H12.2667C13.3867 14.4226 14.24 14.0159 14.6733 13.2759C15.1067 12.5359 15.0467 11.5893 14.5067 10.6159Z"
      fill="#F96410"
    />
    <path
      d="M8 9.83333C7.72667 9.83333 7.5 9.60667 7.5 9.33333V6C7.5 5.72667 7.72667 5.5 8 5.5C8.27333 5.5 8.5 5.72667 8.5 6V9.33333C8.5 9.60667 8.27333 9.83333 8 9.83333Z"
      fill="#FFF9F5"
    />
    <path
      d="M7.99967 11.9991C7.95967 11.9991 7.91301 11.9924 7.86634 11.9857C7.82634 11.9791 7.78634 11.9657 7.74634 11.9457C7.70634 11.9324 7.66634 11.9124 7.62634 11.8857C7.59301 11.8591 7.55967 11.8324 7.52634 11.8057C7.40634 11.6791 7.33301 11.5057 7.33301 11.3324C7.33301 11.1591 7.40634 10.9857 7.52634 10.8591C7.55967 10.8324 7.59301 10.8057 7.62634 10.7791C7.66634 10.7524 7.70634 10.7324 7.74634 10.7191C7.78634 10.6991 7.82634 10.6857 7.86634 10.6791C7.95301 10.6591 8.04634 10.6591 8.12634 10.6791C8.17301 10.6857 8.21301 10.6991 8.25301 10.7191C8.29301 10.7324 8.33301 10.7524 8.37301 10.7791C8.40634 10.8057 8.43967 10.8324 8.47301 10.8591C8.59301 10.9857 8.66634 11.1591 8.66634 11.3324C8.66634 11.5057 8.59301 11.6791 8.47301 11.8057C8.43967 11.8324 8.40634 11.8591 8.37301 11.8857C8.33301 11.9124 8.29301 11.9324 8.25301 11.9457C8.21301 11.9657 8.17301 11.9791 8.12634 11.9857C8.08634 11.9924 8.03967 11.9991 7.99967 11.9991Z"
      fill="#FFF9F5"
    />
  </svg>
);

export const Close = (props: any) => (
  <svg
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.66636 0.242641C7.95925 -0.0502526 8.43412 -0.0502527 8.72702 0.242641C9.01991 0.535534 9.01991 1.01041 8.72702 1.3033L1.30239 8.72792C1.0095 9.02081 0.534628 9.02082 0.241734 8.72792C-0.0511589 8.43503 -0.0511586 7.96015 0.241734 7.66726L7.66636 0.242641Z"
      fill="#171D26"
    />
    <path
      d="M8.72702 7.66726C9.01991 7.96015 9.01991 8.43503 8.72702 8.72792C8.43412 9.02082 7.95925 9.02081 7.66636 8.72792L0.241735 1.3033C-0.0511582 1.01041 -0.0511586 0.535534 0.241735 0.24264C0.534628 -0.050253 1.0095 -0.0502529 1.30239 0.24264L8.72702 7.66726Z"
      fill="#171D26"
    />
  </svg>
);

export const ArrowDownGrayIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M5 8L10 13L15 8L5 8Z" fill="#6682A2" />
  </svg>
);

export const SortIcon = (props: any) => (
  <svg
    width="10"
    height="16"
    viewBox="0 0 10 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M0.853553 5.14645L4.64645 1.35355C4.84171 1.15829 5.15829 1.15829 5.35355 1.35355L9.14645 5.14645C9.46143 5.46143 9.23835 6 8.79289 6H1.20711C0.761654 6 0.53857 5.46143 0.853553 5.14645Z"
      fill="#6682A2"
    />
    <path
      d="M9.14645 10.8536L5.35355 14.6464C5.15829 14.8417 4.84171 14.8417 4.64645 14.6464L0.853554 10.8536C0.538572 10.5386 0.761655 10 1.20711 10L8.79289 10C9.23835 10 9.46143 10.5386 9.14645 10.8536Z"
      fill="#6682A2"
    />
  </svg>
);

export const CopyIcon = (props: any) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.41927 12.707C4.61385 12.707 3.96094 12.0541 3.96094 11.2487V5.6237C3.96094 4.70322 4.70713 3.95703 5.6276 3.95703H11.2526C12.058 3.95703 12.7109 4.60995 12.7109 5.41536"
      stroke="#197EEF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.3724 7.29297H8.95573C8.03525 7.29297 7.28906 8.03916 7.28906 8.95964V14.3763C7.28906 15.2968 8.03525 16.043 8.95573 16.043H14.3724C15.2929 16.043 16.0391 15.2968 16.0391 14.3763V8.95964C16.0391 8.03916 15.2929 7.29297 14.3724 7.29297Z"
      stroke="#197EEF"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
