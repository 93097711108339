import React, { FC } from 'react';

const SortArrowUp: FC<any> = (props) => {
  return (
    <svg
      // width='10'
      // height='6'
      {...props}
      viewBox='0 0 10 6'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0.853553 5.14645L4.64645 1.35355C4.84171 1.15829 5.15829 1.15829 5.35355 1.35355L9.14645 5.14645C9.46143 5.46143 9.23835 6 8.79289 6H1.20711C0.761654 6 0.53857 5.46143 0.853553 5.14645Z'
        fill='#7489AA'
      />
    </svg>
  );
};

export default SortArrowUp;
