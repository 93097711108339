import React, { FC } from "react";

const NoEventsIcons: FC<any> = (props) => {
  return (
    <svg
      width="96"
      height="96"
      viewBox="0 0 96 96"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M84 81H44C42.36 81 41 79.64 41 78C41 76.36 42.36 75 44 75H84C85.64 75 87 76.36 87 78C87 79.64 85.64 81 84 81Z"
        fill="#7489AA"
      />
      <path
        d="M84 53H44C42.36 53 41 51.64 41 50C41 48.36 42.36 47 44 47H84C85.64 47 87 48.36 87 50C87 51.64 85.64 53 84 53Z"
        fill="#7489AA"
      />
      <path
        d="M84 25H44C42.36 25 41 23.64 41 22C41 20.36 42.36 19 44 19H84C85.64 19 87 20.36 87 22C87 23.64 85.64 25 84 25Z"
        fill="#7489AA"
      />
      <path
        d="M16 28.9978C15.24 28.9978 14.48 28.7178 13.88 28.1178L9.88001 24.1178C8.72001 22.9578 8.72001 21.0378 9.88001 19.8778C11.04 18.7178 12.96 18.7178 14.12 19.8778L16 21.7578L25.88 11.8778C27.04 10.7178 28.96 10.7178 30.12 11.8778C31.28 13.0378 31.28 14.9578 30.12 16.1178L18.12 28.1178C17.52 28.7178 16.76 28.9978 16 28.9978Z"
        fill="#7489AA"
      />
      <path
        d="M16 56.9978C15.24 56.9978 14.48 56.7178 13.88 56.1178L9.88001 52.1178C8.72001 50.9578 8.72001 49.0378 9.88001 47.8778C11.04 46.7178 12.96 46.7178 14.12 47.8778L16 49.7578L25.88 39.8778C27.04 38.7178 28.96 38.7178 30.12 39.8778C31.28 41.0378 31.28 42.9578 30.12 44.1178L18.12 56.1178C17.52 56.7178 16.76 56.9978 16 56.9978Z"
        fill="#7489AA"
      />
      <path
        d="M16 84.9978C15.24 84.9978 14.48 84.7178 13.88 84.1178L9.88001 80.1178C8.72001 78.9578 8.72001 77.0378 9.88001 75.8778C11.04 74.7178 12.96 74.7178 14.12 75.8778L16 77.7578L25.88 67.8778C27.04 66.7178 28.96 66.7178 30.12 67.8778C31.28 69.0378 31.28 70.9578 30.12 72.1178L18.12 84.1178C17.52 84.7178 16.76 84.9978 16 84.9978Z"
        fill="#7489AA"
      />
    </svg>
  );
};

export default NoEventsIcons;
