import {
  chakra,
  Box,
  Text,
  HStack,
  VStack,
  Tag,
  useDisclosure,
  Button,
  Flex,
  Image,
  useToast,
} from "@chakra-ui/react";
import { FC, ReactNode, useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../UI/CustomModal";
import { useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  completeSetup,
  quickWhatsappConnect,
} from "../../../API/account/channels";
import Loader from "../../UI/Loader";
import { toaster } from "../../../utils/toast";
import CustomPhoneInput from "../../UI/form/CustomPhoneInput";

interface Props {
  title: string;
  description: string;
  icon: ReactNode;
  showBadge?: boolean;
}

const SocialCard: FC<Props> = ({ title, description, icon, showBadge }) => {
  const [stage, setStage] = useState(1);
  const [val, setVal] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [qrcode, setQrcode] = useState<any>("");
  const disclosure = useDisclosure();
  const toast = useToast();
  let navigate = useNavigate();
  const queryClient = useQueryClient();

  const nextPage = () => {
    val === "quick"
      ? phoneNumber && mutate({ phone_number: phoneNumber })
      : navigate("/account/channels/whatsapp");
  };
  const linkToUse =
    title === "SMS"
      ? "#"
      : title === "Whatsapp"
      ? ""
      : `/account/channels/addChannel?ch=${title}`;
  // : `/account/channels/addChannel?ch=${title}`;

  const { mutate: setupComplete, isLoading: completingSetup } = useMutation(
    completeSetup,
    {
      onSuccess(data) {
        const success = { ...data };
        queryClient.invalidateQueries(["whatsappData"]);
        disclosure.onClose();
        toaster(toast, success);
        setStage(1);
        setVal("");
        setPhoneNumber("");
      },
      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
        disclosure.onClose();
        setStage(1);
        setVal("");
      },
    }
  );

  const { mutate, isLoading } = useMutation(quickWhatsappConnect, {
    onSuccess(data) {
      queryClient.invalidateQueries(["whatsappData"]);
      setStage(2);
      setQrcode(data);
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <Box minHeight={"100%"}>
      {title !== "Whatsapp" && (
        <Link to={linkToUse}>
          <chakra.button
            textAlign={"left"}
            w="full"
            borderWidth={"1px"}
            borderColor={"rgba(0, 102, 255, 0.24)"}
            rounded="4px"
            pl="23px"
            pt="23px"
            pb="20px"
            pr="18px"
            display={"flex"}
            _hover={{
              borderColor: "primary.500",
            }}
            pos="relative"
          >
            <Box
              fontSize={"xs"}
              color="primary.500"
              fontWeight={500}
              bg="rgba(0, 102, 255, 0.08)"
              py="4px"
              px="8px"
              rounded="4px"
              pos={"absolute"}
              top="12px"
              right={"12px"}
            >
              ADD
            </Box>

            {icon}

            <Box ml="15px" mt={-0.4}>
              <HStack>
                <Text color="grays.8" fontWeight={700}>
                  {title}
                </Text>

                {showBadge && (
                  <Tag
                    rounded="full"
                    bg="grays.12"
                    color="white"
                    fontSize={"xs"}
                    fontWeight={700}
                    py={0}
                    px="8px"
                  >
                    PRO
                  </Tag>
                )}
              </HStack>

              <Text
                maxW={"243px"}
                fontWeight={500}
                color="grays.12"
                fontSize={"xs"}
                mt="4px"
              >
                {description}
              </Text>
            </Box>
          </chakra.button>
        </Link>
      )}
      {title === "Whatsapp" && (
        <chakra.button
          onClick={disclosure.onOpen}
          textAlign={"left"}
          w="full"
          borderWidth={"1px"}
          borderColor={"rgba(0, 102, 255, 0.24)"}
          rounded="4px"
          pl="23px"
          pt="23px"
          pb="20px"
          pr="18px"
          display={"flex"}
          _hover={{
            borderColor: "primary.500",
          }}
          pos="relative"
        >
          <Box
            fontSize={"xs"}
            color="primary.500"
            fontWeight={500}
            bg="rgba(0, 102, 255, 0.08)"
            py="4px"
            px="8px"
            rounded="4px"
            pos={"absolute"}
            top="12px"
            right={"12px"}
          >
            ADD
          </Box>

          {icon}

          <Box ml="15px" mt={-0.4}>
            <HStack>
              <Text color="grays.8" fontWeight={700}>
                {title}
              </Text>

              {showBadge && (
                <Tag
                  rounded="full"
                  bg="grays.12"
                  color="white"
                  fontSize={"xs"}
                  fontWeight={700}
                  py={0}
                  px="8px"
                >
                  PRO
                </Tag>
              )}
            </HStack>

            <Text
              maxW={"243px"}
              fontWeight={500}
              color="grays.12"
              fontSize={"xs"}
              mt="4px"
            >
              {description}
            </Text>
          </Box>
        </chakra.button>
      )}
      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px" pe={"0"}>
            {stage === 1 ? "Activate Whatsapp Number" : "Quick Setup"}
          </Text>
        }
      >
        {stage === 1 ? (
          <Box mt="42px" pb="40px" textAlign={"center"} w="full">
            <HStack justifyContent={"center"} mb={4}>
              <Flex
                onClick={() => setVal("quick")}
                flexDir={"column"}
                alignItems={"center"}
                borderRadius={"4px"}
                justifyContent={"center"}
                h={"168px"}
                w={"168px"}
                borderColor={val === "quick" ? "primary.500" : "borders.100"}
                borderWidth={"1px"}
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 27.5C23.18 27.5 22.5 26.82 22.5 26V16C22.5 15.18 23.18 14.5 24 14.5C24.82 14.5 25.5 15.18 25.5 16V26C25.5 26.82 24.82 27.5 24 27.5Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M29.7803 6.9H18.2203C17.4203 6.9 16.7803 6.26 16.7803 5.46C16.7803 4.66 17.4203 4 18.2203 4H29.7803C30.5803 4 31.2203 4.64 31.2203 5.44C31.2203 6.24 30.5803 6.9 29.7803 6.9Z"
                    fill="#7489AA"
                  />
                  <path
                    opacity="0.4"
                    d="M30.0002 39.9403V34.0603C30.0002 31.5203 31.5202 30.0003 34.0602 30.0003H39.9402C40.3202 30.0003 40.6602 30.0403 41.0002 30.1203C41.2202 29.0003 41.3402 27.8403 41.3402 26.6603C41.3402 17.1003 33.5602 9.32031 24.0002 9.32031C14.4402 9.32031 6.66016 17.1003 6.66016 26.6603C6.66016 36.2203 14.4402 44.0003 24.0002 44.0003C26.4202 44.0003 28.7202 43.5003 30.8202 42.6003C30.3002 41.9203 30.0002 41.0403 30.0002 39.9403Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M39.94 30H34.06C31.52 30 30 31.52 30 34.06V39.94C30 42.48 31.52 44 34.06 44H39.94C42.48 44 44 42.48 44 39.94V34.06C44 31.52 42.48 30 39.94 30ZM39.38 38.92L37.02 40.28C36.54 40.56 36.06 40.7 35.62 40.7C35.28 40.7 34.98 40.62 34.7 40.46C34.06 40.08 33.7 39.34 33.7 38.36V35.64C33.7 34.66 34.06 33.92 34.7 33.54C35.34 33.16 36.16 33.24 37.02 33.72L39.38 35.08C40.22 35.58 40.7 36.26 40.7 37C40.7 37.74 40.24 38.42 39.38 38.92Z"
                    fill="#7489AA"
                  />
                </svg>
                <Text fontSize={"12px"} fontWeight={500}>
                  QUICK SETUP
                </Text>
                <Text color={"grays.12"} fontWeight={500} fontSize={"10px"}>
                  Scan the QR Code and get ready immediatly
                </Text>
              </Flex>
              <Flex
                onClick={() => setVal("full")}
                flexDir={"column"}
                alignItems={"center"}
                borderRadius={"4px"}
                justifyContent={"center"}
                h={"168px"}
                w={"168px"}
                borderColor={val === "full" ? "primary.500" : "borders.100"}
                borderWidth={"1px"}
              >
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M32.38 4H15.62C8.34 4 4 8.34 4 15.62V32.36C4 39.66 8.34 44 15.62 44H32.36C39.64 44 43.98 39.66 43.98 32.38V15.62C44 8.34 39.66 4 32.38 4Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M31.1602 38.4992C30.3402 38.4992 29.6602 37.8192 29.6602 36.9992V29.1992C29.6602 28.3792 30.3402 27.6992 31.1602 27.6992C31.9802 27.6992 32.6602 28.3792 32.6602 29.1992V36.9992C32.6602 37.8192 31.9802 38.4992 31.1602 38.4992Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M31.1602 16.4C30.3402 16.4 29.6602 15.72 29.6602 14.9V11C29.6602 10.18 30.3402 9.5 31.1602 9.5C31.9802 9.5 32.6602 10.18 32.6602 11V14.9C32.6602 15.72 31.9802 16.4 31.1602 16.4Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M16.8398 38.5016C16.0198 38.5016 15.3398 37.8216 15.3398 37.0016V33.1016C15.3398 32.2816 16.0198 31.6016 16.8398 31.6016C17.6598 31.6016 18.3398 32.2816 18.3398 33.1016V37.0016C18.3398 37.8216 17.6798 38.5016 16.8398 38.5016Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M16.8398 20.3C16.0198 20.3 15.3398 19.62 15.3398 18.8V11C15.3398 10.18 16.0198 9.5 16.8398 9.5C17.6598 9.5 18.3398 10.18 18.3398 11V18.8C18.3398 19.62 17.6798 20.3 16.8398 20.3Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M31.1602 14.6602C28.1602 14.6602 25.7002 17.1002 25.7002 20.1002C25.7002 23.1002 28.1402 25.5602 31.1602 25.5602C34.1602 25.5602 36.6002 23.1202 36.6002 20.1002C36.6002 17.0802 34.1602 14.6602 31.1602 14.6602Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M16.8404 22.4609C13.8404 22.4609 11.4004 24.9009 11.4004 27.9209C11.4004 30.9409 13.8404 33.3609 16.8404 33.3609C19.8404 33.3609 22.3004 30.9209 22.3004 27.9209C22.3004 24.9209 19.8604 22.4609 16.8404 22.4609Z"
                    fill="#7489AA"
                  />
                </svg>
                <Text fontSize={"12px"} fontWeight={500}>
                  FULL BUSINESS SETUP
                </Text>
                <Text color={"grays.12"} fontWeight={500} fontSize={"10px"}>
                  Setup your business for full Whatsapp experience
                </Text>
              </Flex>
            </HStack>
            {val === "quick" && (
              <>
                <CustomPhoneInput
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(phone) => {
                    setPhoneNumber(phone);
                  }}
                />
                <Box
                  bg={"grays.0"}
                  mt={4}
                  fontWeight={500}
                  p={3}
                  display={"flex"}
                  alignItems={"center"}
                  borderRadius={4}
                  fontSize={"13px"}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14.6673 8.0026C14.6673 11.6853 11.6827 14.6693 8.00065 14.6693C4.31865 14.6693 1.33398 11.6853 1.33398 8.0026C1.33398 4.32127 4.31865 1.33594 8.00065 1.33594C11.6827 1.33594 14.6673 4.32127 14.6673 8.0026Z"
                      fill="#7489AA"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M8.58073 8.42062C8.58073 8.74196 8.31873 9.00396 7.9974 9.00396C7.67606 9.00396 7.41406 8.74196 7.41406 8.42062V5.47396C7.41406 5.15262 7.67606 4.89062 7.9974 4.89062C8.31873 4.89062 8.58073 5.15262 8.58073 5.47396V8.42062ZM7.41733 10.5365C7.41733 10.2151 7.678 9.95312 7.99733 9.95312C8.326 9.95312 8.58733 10.2151 8.58733 10.5365C8.58733 10.8578 8.326 11.1198 8.004 11.1198C7.68067 11.1198 7.41733 10.8578 7.41733 10.5365Z"
                      fill="white"
                    />
                  </svg>
                  Kindly provide your phone number to proceed
                </Box>
              </>
            )}
            <Button
              w="full"
              disabled={val.length === 0 || (val === "quick" && !phoneNumber)}
              onClick={() => {
                nextPage();
              }}
              colorScheme={"primary"}
              mt="40px"
            >
              Next
            </Button>
          </Box>
        ) : (
          <>
            {isLoading && <Loader />}
            {!isLoading && (
              <VStack h={"534px"}>
                <Text
                  mt={"5"}
                  w={"360px"}
                  textAlign={"center"}
                  px={"10"}
                  fontSize={"16px"}
                  fontWeight={700}
                >
                  Scan QR Code below to connect your Whatsapp
                </Text>
                <Box>
                  <Image src={qrcode?.qrcode} alt="qrcode_image" />
                </Box>
                <Box w={"360px"} color={"grays.14"} mb={4}>
                  <Text fontSize={"12px"} color="grays.9" fontWeight={700}>
                    Instructions
                  </Text>
                  <Text as="li" fontSize={"10px"} fontWeight={500}>
                    Open WhatsApp on your phone
                  </Text>
                  <Text as="li" fontSize={"10px"} fontWeight={500}>
                    Tap Menu or Settings and select WhatsApp Web
                  </Text>
                  <Text as="li" fontSize={"10px"} fontWeight={500}>
                    Point your phone at this screen and capture the code above
                  </Text>
                </Box>
                <Button
                  isLoading={completingSetup}
                  w="full"
                  disabled={!val && true}
                  onClick={() => setupComplete(phoneNumber)}
                  colorScheme={"primary"}
                  pt={4}
                >
                  I’ve scanned the QR Code
                </Button>
              </VStack>
            )}
          </>
        )}
      </CustomModal>
    </Box>
  );
};

export default SocialCard;
