import React, { FC, useMemo, useState } from 'react';

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
  HStack,
  useDisclosure,
  Flex,
  Input,
} from '@chakra-ui/react';

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';

import SortArrowUp from '../../icons/SortArrowUp';
import SortArrowDown from '../../icons/SortArrowDown';
import CustomModal from '../UI/CustomModal';
import ChakraSelect from '../UI/form/ChakraSelect';
import MoreDetails from './MoreDetails';
import dayjs from 'dayjs';
import { fuzzyFilter } from '../../utils/table';

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  provider_reference: string;
  sms_campaign_uid: string;
  contact_uid: string;
  message_body: string;
  message_body_count: number;
  message_from: string;
  message: string;
  status: string;
  failed_reason: string;
  created_at: Date;
  updated_at: Date;
  contact: {
    id: number;
    uid: string;
    company_uid: string;
    profile_picture: any;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    instagram_username: string;
    instagram_id: string;
    facebook_username: string;
    facebook_id: string;
    twitter_username: string;
    twitter_id: string;
    is_blocked: number;
    deleted_at: string;
    data_source: string;
    metadata: any;
    created_at: Date;
    updated_at: Date;
  };
}

interface senderProps {
  id: string | number;
  uid: string | number;
  company_uid: string | number;
  name: string;
  display_name: string;
  status: string;
  deleted_at: Date;
  created_at: Date;
  updated_at: Date;
}

const CampaignReportList: FC<{
  data: listProps[];
  globalFilter: any;
  setGlobalFilter: any;
  setListDataToShow: any;
  listDataToShow: number | string;
  sender: senderProps;
}> = ({
  data,
  globalFilter,
  setGlobalFilter,
  setListDataToShow,
  listDataToShow,
  sender,
}) => {
  const [dataToShow, setDataToShow] = useState<listProps>({
    id: 0,
    uid: '',
    company_uid: '',
    provider_reference: '',
    sms_campaign_uid: '',
    contact_uid: '',
    message_body: '',
    message: '',
    message_body_count: 0,
    message_from: '',
    failed_reason: '',
    status: '',
    created_at: new Date(),
    updated_at: new Date(),
    contact: {
      id: 0,
      uid: '',
      company_uid: '',
      profile_picture: {},
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      instagram_username: '',
      instagram_id: '',
      facebook_username: '',
      facebook_id: '',
      twitter_username: '',
      twitter_id: '',
      is_blocked: 0,
      deleted_at: '',
      data_source: '',
      metadata: {},
      created_at: new Date(),
      updated_at: new Date(),
    },
  });

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const viewDataDisclosure = useDisclosure();

  // accessor functions are for when you need to concatenate data e.g sender and phoneNumber
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    const statusColorsToUse = (status: string) => {
      switch (status) {
        case 'sent':
          return {
            color: '#159D6F',
            bg: 'rgba(21, 157, 111, 0.04)',
          };
        case 'expired':
          return {
            color: 'rgb(237, 108, 90)',
            bg: 'rgba(237, 108, 90, 0.04)',
          };
        case 'delivered':
          return {
            color: 'rgb(0, 102, 255)',
            bg: 'rgba(0, 102, 255, 0.04)',
          };
        case 'rejected':
          return {
            color: 'rgb(25, 63, 100)',
            bg: 'rgba(25, 63, 100, 0.04)',
          };
        case 'pending':
          return {
            color: 'rgb(25, 63, 100)',
            bg: 'rgba(25, 63, 100, 0.04)',
          };
        case 'failed':
          return {
            color: 'rgb(248, 169, 76)',
            bg: 'rgba(248, 169, 76, 0.04)',
          };

        default:
      }
    };

    return [
      {
        header: () => <Text>Sender</Text>,
        accessorKey: 'sender',
        id: 'sender',
        cell: (props: any) => {
          return <Text>{sender?.name || sender?.display_name ||  '---'}</Text>;
        },
      },
      {
        header: () => <Text>Customer phone number</Text>,
        accessorKey: 'contact.phone_number',
        id: 'contact.phone_number',
        cell: (props: any) => {
          return <Text>{props?.getValue() || '---'}</Text>;
        },
      },
      {
        header: () => <Text>Date processed</Text>,
        accessorKey: 'updated_at',
        id: 'updated_at',
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format('DD/MM/YYYY - hh:mmA')}
            </Text>
          );
        },
      },

      {
        header: 'Status',
        accessorKey: 'status',
        id: 'status',
        cell: (props: any) => {
          return (
            <Box
              bg={statusColorsToUse(props?.getValue())?.bg}
              w='fit-content'
              py='4px'
              px='8px'
              rounded='full'
              borderWidth={'0.5px'}
              borderColor={statusColorsToUse(props?.getValue())?.color}
              color={statusColorsToUse(props?.getValue())?.color}
              textTransform='capitalize'
              fontWeight={500}
            >
              {props?.getValue() === 'in-progress'
                ? 'In Progress'
                : props?.getValue()}
            </Box>
          );
        },
      },

      {
        header: 'Actions',
        id: 'actions',
        cell: (props: any) => (
          <chakra.button
            onClick={() => {
              setDataToShow(props?.row?.original);
              viewDataDisclosure?.onOpen();
            }}
          >
            <Text fontWeight={'medium'} textDecor='underline'>
              View more
            </Text>
          </chakra.button>
        ),
      },
    ];
  }, [sender?.name, sender?.display_name, viewDataDisclosure]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    debugTable: true,
  });
  return (
    <>
      {/* filters */}
      <Flex rounded='4px' py='12px' px='20px'>
        {/* toggle no of entries shown */}
        <HStack spacing={'10px'}>
          <Text color='grays.12' fontWeight={500} fontSize='xs'>
            Showing
          </Text>

          <ChakraSelect
            selectProps={{
              h: '24px',
              borderColor: 'grays.12',
              fontWeight: 500,
              color: 'grays.12',
              value: Number(listDataToShow),
              onChange: (e) => {
                setListDataToShow(Number(e.target.value));
              },
            }}
          >
            <chakra.option value={15}>15</chakra.option>
            <chakra.option value={20}>20</chakra.option>
            <chakra.option value={25}>25</chakra.option>
            <chakra.option value={30}>30</chakra.option>
            <chakra.option value={50}>50</chakra.option>
          </ChakraSelect>

          <Text color='grays.12' fontWeight={500} fontSize='xs'>
            entries
          </Text>
        </HStack>

        {/* search input */}
        <Input
          ml='auto'
          type='search'
          placeholder='Search by phone number..'
          borderRadius={'8px'}
          borderColor='borders.100'
          _hover={{
            borderColor: 'borders.100',
          }}
          color='grays.9'
          _placeholder={{
            color: 'grays.12',
          }}
          fontWeight={500}
          h='32px'
          maxW='285px'
          w='full'
          fontSize={'xs'}
          value={globalFilter ?? ''}
          onChange={(e) => {
            setGlobalFilter(e.target.value.toString());
          }}
        />
      </Flex>
      <TableContainer
        borderWidth={'1px'}
        borderBottomWidth='0px'
        borderLeftWidth={'0px'}
        borderRightWidth={'0px'}
        borderColor='rgba(0, 102, 255, 0.08)'
      >
        <Table variant={'simple'}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform='none'
                        color={'grays.12'}
                        fontSize='xs'
                        letterSpacing={'unset'}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={'flex'}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing='2px' my='auto' ml='2'>
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width='8px' />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width='8px' />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize='xs'
                    color='grays.12'
                    fontWeight={'medium'}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* View more  */}
      <CustomModal
        titleUnderLine = {true}
        disclosure={viewDataDisclosure}
        title={
          <Text fontSize={'20px'} color='grays.8' mt='35px'>
            More details
          </Text>
        }
      >
        <MoreDetails sender={sender?.name} data={dataToShow} />
      </CustomModal>
    </>
  );
};

export default CampaignReportList;
