import {
  Box,
  Text,
  // VStack,
  Grid,
  GridItem,
  Flex,
  // Divider,
  Button,
  Heading,
  VStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import React, { useState, FC } from "react";
// import ReactQuill from "react-quill";
import { getApprovedTemplates } from "../../API/account/templates";
import { ReadIcon } from "../../icons/ReadIcon";
import FormInput from "../UI/form/FormInput";
import Loader from "../UI/Loader";
import { OvalIcon } from "../../icons/OvalIcon";
import { SwipeIn, SwipeUp } from "../animations";
import { EmptyTemplateIcon } from "../../icons/EmptyTemplateIcon";
import { ButtonPrimary } from "../UI/Buttons";
import { useNavigate } from "react-router-dom";

// const displayModule = {
//   toolbar: false,
// };
interface WhatsappCampaignProps {
  currentStageData: any;
  setCurrentStageData: any;
}
interface TemplateDataProps {
  uid: number;
  name: string;
  template_body: string;
}

const WhatsappCampaign: FC<WhatsappCampaignProps> = ({
  currentStageData,
  setCurrentStageData,
}) => {
  const [templateBody, setTemplateBody] = useState({
    query: "",
    template_body: "",
  });
  const [filterData, setFilterData] = useState<TemplateDataProps[]>([]);
  const navigate = useNavigate();
  const { isLoading, data } = useQuery(["approvedTemplates"], () => {
    return getApprovedTemplates();
  });

  const handleSearch = (e: string) => {
    setTemplateBody({
      ...templateBody,
      query: e,
    });
    setFilterData(
      data?.docs?.filter((x: TemplateDataProps) =>
        x?.name.toLowerCase().includes(e)
      )
    );
  };
  // useEffect(() => {
  //   document.querySelectorAll(".ql-editor")[0]?.classList.add("templates");
  //   document.querySelectorAll(".ql-editor")[1]?.classList.add("templates");
  // });

  return (
    <Box mx={"auto"}>
      <Box
        borderColor={"borders.100"}
        borderWidth={"1.5px"}
        // h={"520px"}
        // w={"1149px"}
        borderRadius={"4px"}
      >
        <Grid templateColumns="repeat(4, 1fr)" gap={4}>
          <GridItem
            colSpan={1}
            // borderRightWidth={"1px"}
            // h={"520px"}
            // w={"280px"}
            // borderColor={"borders.100"}
            borderRightWidth={"1.5px"}
            borderRightColor={"borders.50"}
          >
            <Heading
              as={"h2"}
              px={"24px"}
              py={"24px"}
              fontSize="20px"
              fontWeight={700}
              borderBottomWidth={"1.5px"}
              borderBottomColor={"borders.50"}
            >
              Template
            </Heading>

            <Box
              py={"16px"}
              px={"16px"}
              // maxW={"280px"}
              // m="auto"
              overflowY={"auto"}
              // h={"400px"}
            >
              <Box w={"246px"} mb={"16px"}>
                <FormInput
                  br="4px"
                  label=""
                  type="text"
                  for="template_name"
                  inputProps={{
                    placeholder: "Search by template name",
                    value: templateBody.query,
                    onChange: (e) => {
                      handleSearch(e.target.value);
                    },
                  }}
                  formControlProps={{
                    isRequired: true,
                  }}
                />
              </Box>

              {isLoading && <Loader />}

              <Box height={"46vh"} overflowY={"auto"} className="thinSB">
                {(templateBody.query !== "" ? filterData : data?.docs)?.map(
                  (template: TemplateDataProps, index: number) => (
                    <SwipeUp key={template?.uid || index} delay={0.2 * index}>
                      <Flex
                        // bg={
                        //   template?.template_body ===
                        //   currentStageData?.data?.message_body
                        //     ? "borders.200"
                        //     : ""
                        // }
                        // pl={16}
                        cursor="pointer"
                        my={"8px"}
                        alignItems={"center"}
                        // h={"60px"}
                        minHeight={"40px"}
                        borderColor={"borders.50"}
                        // borderBottomColor={"borders.100"}
                        // borderTopWidth={0.1}
                        borderWidth={"1.5px"}
                        borderRadius={"8px"}
                        overflow={"hidden"}
                        // boxShadow={"xs"}
                        _hover={{
                          boxShadow: "sm",
                          borderColor: "primary.100",
                        }}
                        onClick={() => {
                          setTemplateBody({
                            ...templateBody,
                            template_body: template?.template_body,
                          });
                          setCurrentStageData((prevData: any) => {
                            return {
                              ...prevData,
                              data: {
                                ...prevData?.data,
                                message_body: template?.template_body,
                                template_uid: template?.uid,
                              },
                            };
                          });
                        }}
                      >
                        <Box
                          px={"7px"}
                          py={"10px"}
                          bg={"#D6E7FF60"}
                          borderRightColor={"borders.50"}
                          borderRightWidth={"1.5px"}
                        >
                          <OvalIcon />
                        </Box>
                        <Text
                          fontWeight={700}
                          fontSize={"xs"}
                          color={"grays.12"}
                          width={"100%"}
                          pl={"12px"}
                        >
                          {template?.name}
                        </Text>
                      </Flex>
                    </SwipeUp>
                  )
                )}

                {data?.docs.length === 0 ? (
                  <SwipeIn>
                    <Box
                      py={"40px"}
                      borderColor={"borders.100"}
                      borderWidth={"1.5px"}
                      borderRadius={"4px"}
                    >
                      <VStack
                        alignItems={"center"}
                        maxWidth={"134px"}
                        mx={"auto"}
                      >
                        <EmptyTemplateIcon />

                        <Text
                          mt={"20px"}
                          mb={"24px"}
                          fontWeight={700}
                          fontSize={"13px"}
                          textAlign={"center"}
                        >
                          You haven’t created a template yet
                        </Text>

                        <ButtonPrimary
                          onClick={() => {
                            navigate("/account/templates/Whatsapp/create");
                          }}
                        >
                          Create Template
                        </ButtonPrimary>
                      </VStack>
                    </Box>
                  </SwipeIn>
                ) : null}
              </Box>
            </Box>
          </GridItem>

          <GridItem colStart={2} colSpan={3}>
            <Heading
              as="h3"
              fontSize="20px"
              fontWeight={700}
              textAlign={"center"}
              pt={"32px"}
              mb={"54px"}
            >
              Preview
            </Heading>

            <Box width={"100%"}>
              <Box
                width={"388px"}
                mx="auto"
                bg={"backgrounds.600"}
                borderRadius={"8px"}
                p={"40px"}
                mb={"64px"}
              >
                <Text
                  mx={"auto"}
                  mb={"16px"}
                  borderRadius={"8px"}
                  fontSize={"12px"}
                  bg={"backgrounds.700"}
                  w={"100px"}
                  display={"flex"}
                  justifyContent={"center"}
                  px={"auto"}
                  h={"21px"}
                  overflow={"hidden"}
                  fontWeight={600}
                >
                  {dayjs(new Date()).format("ddd, MMM D")}
                </Text>

                <Box position={"relative"}>
                  <Box
                    maxHeight={"228px"}
                    width={"100%"}
                    bg={"green.400"}
                    borderRadius={"8px"}
                    overflowY="auto"
                    className="thinSB"
                    position={"relative"}
                  >
                    <Box
                      h={"100%"}
                      width={"100%"}
                      pb={"8px"}
                      pt={"8px"}
                      px={"12px"}
                      position={"relative"}
                    >
                      {/* <ReactQuill
                        modules={displayModule}
                        theme="snow"
                        value={currentStageData?.data?.message_body}
                        readOnly={true}
                      /> */}

                      <Text
                        fontWeight={400}
                        fontSize={"14px"}
                        pb={
                          !currentStageData?.data?.message_body
                            ? "100px"
                            : "0px"
                        }
                      >
                        {currentStageData?.data?.message_body || `---`}
                      </Text>
                      <Flex
                        bg={"green.400"}
                        pl={"12px"}
                        // position="absolute"
                        bottom="8px"
                        right="11px"
                        justifyContent={"right"}
                        alignItems={"center"}
                      >
                        <Text
                          color={"grays.12"}
                          fontSize={"11px"}
                          fontWeight={"400"}
                        >
                          {dayjs(new Date()).format("hh:mm : A")}
                        </Text>
                        <ReadIcon />
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </GridItem>
        </Grid>

        <Button
          float="right"
          isDisabled={!currentStageData?.data?.message_body}
          onClick={() => {
            setCurrentStageData((prevData: any) => {
              return {
                ...prevData,
                currentStage: "Recipients",
              };
            });
          }}
          mt="5px"
          w="full"
          maxW={"360px"}
          colorScheme={"primary"}
        >
          Continue to recipients
        </Button>
      </Box>
    </Box>
  );
};

export default WhatsappCampaign;
