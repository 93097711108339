import {
    Box,
    Radio,
    Text,
    HStack
} from "@chakra-ui/react";
import { FC } from "react";
import CardImage from "../../../icons/CardImage";

interface cardObjectType {
    id: number;
    last_four_digits: string;
    expiry_date: string;
    card_type: string;
}
const SingleCard: FC<{ click?: () => void, data: cardObjectType }> = ({ data, click }) => {
    return (
        <HStack my="2">
            <Box
                onClick={click}
                borderColor="borders.500"
                borderWidth="1px"
                p="10px"
                borderRadius="4px"
                w={"360px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Box>
                    <Text fontSize="20px" fontWeight={"700"}>
                        **** {data?.last_four_digits}
                    </Text>
                    <Text fontSize="12px" fontWeight={"500"} color="grays.12">
                        Expires {data?.expiry_date}
                    </Text>
                </Box>
                <HStack>
                    <CardImage type={data?.card_type} />
                    <Radio value={data?.id.toString()} />
                </HStack>
            </Box>
        </HStack>
    )
}

export default SingleCard;