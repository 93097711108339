import {
  useState,
  //  useEffect
} from "react";
import {
  Badge,
  Box,
  Checkbox,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Skeleton,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  ArrowUp,
  LinkIcon,
  MsgIcon,
  ViewContactIcon,
} from "../../../assets/svgs/svg";
import { DropIn } from "../../animations";
import dayjs from "dayjs";
// import { ContactEventDataProp } from "../../../types";
import { PageAnimate } from "../../animations/pageAnimate";
import { AnimatePresence } from "framer-motion";
// import EmptyConversationHistory from './EmptyConversationHistory';

interface ContactListProp {
  color: string;
  company_uid: string;
  created_at: string;
  deleted_at: string | null;
  id: number;
  name: string;
  no_of_contacts: number;
  uid: string;
  updated_at: string;
}
interface ContactSegmentProp {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  no_of_contacts: number;
  condition: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

interface FilterOptionsProp {
  label: string;
  checker: string;
  action: string;
}

// const demoData: ContactEventDataProp[] = [
//   {
//     id: 6,
//     uid: "c6acdf9e-7788-4200-842f-f2823ce02bad",
//     contact_uid: "48b45f8c-0a4a-480a-935c-6cfb0f9c1115",
//     company_uid: "28addf1b-9dd9-4cc8-9dfd-419af49d2340",
//     name: "started _new _conversation",
//     occurence_count: 1,
//     created_at: "2023-04-20T09:37:42.000Z",
//     updated_at: "2023-04-20T09:37:42.000Z",
//     contact: {
//       id: 85,
//       uid: "48b45f8c-0a4a-480a-935c-6cfb0f9c1115",
//       company_uid: "28addf1b-9dd9-4cc8-9dfd-419af49d2340",
//       profile_picture: null,
//       first_name: "Uko",
//       last_name: "Inimfon",
//       email: "johnclefofficial@gmail.com",
//       phone_number: "+2348118875024",
//       instagram_username: null,
//       instagram_id: null,
//       facebook_username: null,
//       facebook_id: null,
//       twitter_username: null,
//       twitter_id: null,
//       is_blocked: "0",
//       deleted_at: null,
//       data_source: "import",
//       metadata: null,
//       external_user_id: "1426046848209",
//       created_at: "2023-02-07T08:55:27.000Z",
//       updated_at: "2023-02-07T08:55:27.000Z",
//     },
//   },

//   {
//     id: 2,
//     uid: "2090b376-7165-4043-aa53-275f370c2a34",
//     contact_uid: "48b45f8c-0a4a-480a-935c-6cfb0f9c1115",
//     company_uid: "28addf1b-9dd9-4cc8-9dfd-419af49d2340",
//     name: "sent_sms",
//     occurence_count: 6,
//     created_at: "2023-02-13T10:01:05.000Z",
//     updated_at: "2023-02-13T10:35:39.000Z",
//     contact: {
//       id: 85,
//       uid: "48b45f8c-0a4a-480a-935c-6cfb0f9c1115",
//       company_uid: "28addf1b-9dd9-4cc8-9dfd-419af49d2340",
//       profile_picture: null,
//       first_name: "Uko",
//       last_name: "Inimfon",
//       email: "johnclefofficial@gmail.com",
//       phone_number: "+2348118875024",
//       instagram_username: null,
//       instagram_id: null,
//       facebook_username: null,
//       facebook_id: null,
//       twitter_username: null,
//       twitter_id: null,
//       is_blocked: "0",
//       deleted_at: null,
//       data_source: "import",
//       metadata: null,
//       external_user_id: "1426046848209",
//       created_at: "2023-02-07T08:55:27.000Z",
//       updated_at: "2023-02-07T08:55:27.000Z",
//     },
//   },
// ];

const filterOptions: FilterOptionsProp[] = [
  {
    label: "Conversations",
    checker: "conversation",
    action: "",
  },
  { label: "Events", checker: "event", action: "" },
  {
    label: "Contact Attributes",
    checker: "attribute",
    action: "",
  },
];

const ConversationHistory = ({
  contactLists,
  contactSegments,
  contactEvents,
  isLoading,
}: {
  contactSegments: ContactSegmentProp[];
  contactLists: ContactListProp[];
  contactEvents: any[];
  isLoading: boolean;
}) => {
  // const [dataToShow, setDataToShow] = useState<ContactEventDataProp[] | []>(
  //   contactEvents
  // );
  const [
    filterValue,
    // setFilterValue
  ] = useState({
    all: true,
    conversation: true,
    event: true,
    attribute: true,
  });

  const handleFilter = (str: string, bool: boolean) => {
    // if (str === "all" && bool) {
    //   setFilterValue((prev) => ({
    //     ...prev,
    //     all: true,
    //     conversation: true,
    //     event: true,
    //     attribute: true,
    //   }));
    //   setDataToShow(contactEvents);
    //   return;
    // } else if (str === "all" && !bool) {
    //   setFilterValue((prev) => ({
    //     ...prev,
    //     all: false,
    //     conversation: false,
    //     event: false,
    //     attribute: false,
    //   }));
    //   setDataToShow([]);
    //   return;
    // }
    // if (str !== "all" && bool) {
    //   setFilterValue((prev) => ({
    //     ...prev,
    //     all: false,
    //     [str]: true,
    //   }));
    // } else if (str !== "all" && !bool) {
    //   setFilterValue((prev) => ({
    //     ...prev,
    //     all: false,
    //     [str]: false,
    //   }));
    // }
  };

  // filter action HIDDEN
  // useEffect(() => {
  //   const verifyDataToShow = () => {
  //     let data = [...contactEvents];

  //     let arr: string[] = [];

  //     if (filterValue.conversation) {
  //       arr = [...arr, "conversation"];
  //     }
  //     if (filterValue.event) {
  //       arr = [...arr, "sent"];
  //     }
  //     if (filterValue.attribute) {
  //       arr = [...arr, "attribute"];
  //     }

  //     let newData = arr.reduce((acc, curr) => {
  //       let newArray = data.filter((val) => val.name.includes(curr));
  //       acc = [...acc, ...newArray];

  //       return acc;
  //     }, [] as ContactEventDataProp[]);

  //     setDataToShow(newData);
  //   };

  //   verifyDataToShow();
  // }, [filterValue, contactEvents]);

  // console.log("contact evntssssss", contactEvents);

  return (
    <Box
      display="grid"
      gridTemplateColumns={{ base: "1fr", lg: "60% 37%" }}
      gap="1.4em"
      rounded="4px"
      w="full"
      minH="95vh"
      ml="20px"
    >
      <Box px="6" py="6" bg="white" borderRadius={"4px"}>
        <DropIn fullWidth>
          <Flex justifyContent="space-between">
            <Text fontSize="16px" fontWeight="700">
              Customers Activity
            </Text>

            {/* FILTER */}
            <HStack>
              <Text color={"grays.12"} fontWeight={500} fontSize={"xs"}>
                Filter
              </Text>

              <Box>
                <Menu placement={"bottom-end"} closeOnSelect={false}>
                  <MenuButton as={Box} py={"0px"} px={"0px"} cursor={"pointer"}>
                    <ViewContactIcon />
                  </MenuButton>
                  <MenuList boxShadow={"lg"} width={"100%"} maxWidth="240px">
                    <MenuItem>
                      <HStack width={"100%"}>
                        <Checkbox
                          spacing="14px"
                          isChecked={filterValue.all}
                          onChange={(e) => {
                            handleFilter("all", e.target.checked);
                          }}
                        >
                          <Text
                            fontSize={"xs"}
                            fontWeight={400}
                            color={"grays.12"}
                          >
                            Select All
                          </Text>
                        </Checkbox>
                      </HStack>
                    </MenuItem>
                    <MenuDivider />
                    {filterOptions.map((item: FilterOptionsProp, index) => {
                      let vsl: "all" | "conversation" | "event" | "attribute" =
                        item.checker as
                          | "all"
                          | "conversation"
                          | "event"
                          | "attribute";
                      return (
                        <MenuItem key={index}>
                          <HStack width={"100%"}>
                            <Checkbox
                              spacing="14px"
                              isChecked={filterValue[vsl]}
                              onChange={(e) => {
                                handleFilter(item.checker, e.target.checked);
                              }}
                            >
                              <Text
                                fontSize={"xs"}
                                fontWeight={400}
                                color={"grays.12"}
                              >
                                {item.label}
                              </Text>
                            </Checkbox>
                          </HStack>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Menu>
              </Box>
            </HStack>
          </Flex>

          <Box
            background={`rgba(0, 102, 255, 0.04)`}
            width={"100%"}
            height={"1px"}
            mt={"12px"}
            mb={"20px"}
          />

          {/* conversations */}
          <VStack
            spacing={"16px"}
            width={"100%"}
            maxH={"90vh"}
            overflowY={"auto"}
            className="thinSB"
            pb={"10px"}
          >
            {isLoading ? (
              <>
                <VStack width={"100%"} spacing={"10px"}>
                  <Skeleton
                    startColor="gray.100"
                    endColor="gray.300"
                    height="48px"
                    width={"100%"}
                    borderRadius={"4px"}
                  />
                  <Skeleton
                    startColor="gray.100"
                    endColor="gray.300"
                    height="48px"
                    width={"100%"}
                    borderRadius={"4px"}
                  />
                </VStack>
              </>
            ) : contactEvents.length > 0 ? (
              <AnimatePresence>
                {contactEvents?.map((item, index) => (
                  <PageAnimate key={index}>
                    <HStack
                      cursor={"default"}
                      _hover={{ boxShadow: "sm" }}
                      spacing={"8px"}
                      width={"100%"}
                      py={"12px"}
                      px={"16px"}
                      border={`1px solid #0066FF24`}
                      borderRadius={"4px"}
                    >
                      <Box>
                        {item.name.includes("Profile") ? (
                          <MsgIcon />
                        ) : (
                          <LinkIcon />
                        )}
                      </Box>

                      <Box
                        color={"grays.12"}
                        flexGrow={1}
                        fontSize={"xs"}
                        fontWeight={500}
                      >
                        <VStack alignItems={"flex-start"} spacing={"0px"}>
                          <Text>{item.name}</Text>
                          <Text color={"grays.9"}>{item.description}</Text>
                        </VStack>
                      </Box>

                      <Text color="grays.12" fontSize="xs" fontWeight={500}>
                        {dayjs(item.created_at).format("MMMM D, h:mm A")}
                      </Text>
                    </HStack>
                  </PageAnimate>
                ))}
              </AnimatePresence>
            ) : (
              <Text
                width={"100%"}
                py={"12px"}
                px={"16px"}
                color={"grays.12"}
                fontSize={"xs"}
                textAlign={"center"}
              >
                This contact has no events
              </Text>
            )}
          </VStack>
        </DropIn>
      </Box>

      <DropIn fullWidth delay={0.5}>
        <Box>
          <Box
            height="fit-content"
            px="6"
            py="6"
            bg="white"
            borderRadius={"4px"}
          >
            <Text fontSize="14px" fontWeight="700">
              Lists
            </Text>
            <Flex gap="0.8em" mt="1em" flexWrap={"wrap"}>
              {contactSegments ? (
                contactLists?.length === 0 ? (
                  <Text fontSize={"sm"} color={"grays.12"}>
                    No lists to show
                  </Text>
                ) : (
                  contactLists?.map((item, index) => (
                    <Badge
                      key={index}
                      variant={"outline"}
                      textTransform={"none"}
                      size="sm"
                      color="#7489AA"
                    >
                      {item.name}
                    </Badge>
                  ))
                )
              ) : null}
            </Flex>
          </Box>

          <Box bg="white" py="4" px="4" mt="1.4em" borderRadius={"4px"}>
            <HStack justifyContent="space-between">
              <Text fontSize="14px" fontWeight="700">
                Segments
              </Text>
              <ArrowUp />
            </HStack>

            <Flex gap="0.8em" mt="1em" flexWrap={"wrap"}>
              {contactSegments ? (
                contactSegments?.length === 0 ? (
                  <Text fontSize={"sm"} color={"grays.12"}>
                    No segments to show
                  </Text>
                ) : (
                  contactSegments?.map((item, index) => (
                    <Badge
                      key={index}
                      variant={"outline"}
                      textTransform={"none"}
                      size="sm"
                      color="#7489AA"
                    >
                      {item.name}
                    </Badge>
                  ))
                )
              ) : null}
            </Flex>
          </Box>
        </Box>
      </DropIn>
    </Box>
  );
};

export default ConversationHistory;
