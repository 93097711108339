// import {useState} from 'react'
import Select from "react-select";
// import OptionTypeBase  from 'react-select';
import {
  FormControl,
  FormControlProps,
  FormLabel,
  VStack,
} from "@chakra-ui/react";
import { useRef } from "react";
import { ArrowDown } from "../../../assets/svgs/svg";

interface selectProps {
  defaultValue?: string;
  value?: any;
  isMulti?: boolean;
  options?: any;
  // list?: boolean
  placeholder?: string;
  onChange?: any;
  label?: string;
  isRequired?: boolean;
  onBlur?: () => void;
  handleCreateNewList?: any;
  isFilter?: boolean;
  height?: string | number;
  formControlProps?: FormControlProps;
  fontSize?: string;
  isDisabled?: boolean;
  formatOptionLabel?: any;
}

const SearchableSelect = ({
  isDisabled = false,
  formatOptionLabel,
  onChange,
  options,
  isMulti,
  value,
  defaultValue,
  placeholder,
  label,
  isRequired = false,
  onBlur = () => {},
  isFilter = false,
  formControlProps,
  height,
  // list = false,
  // handleCreateNewList,
  fontSize = "14px",
}: selectProps) => {
  const customStyles = {
    input: (provided: any) => ({
      ...provided,
      height: height ?? "38px",
      fontWeight: "semibold",
      width: "100%",
    }),

    control: (provided: any) => ({
      ...provided,
      borderColor: "rgba(0, 102, 255, 0.16)",
      borderRadius: "4px",
      borderWidth: !isFilter ? "1px" : "0px",
      fontSize,
      outline: 0,
      paddingLeft: "8px",
      width: "100%",
    }),

    container: (provided: any) => ({
      ...provided,
      height: "100%",
      outline: 0,
      paddingLeft: 0,
      width: "100%",
      fontSize,
    }),

    indicatorSeparator: (provided: any) => ({
      ...provided,
      display: "none",
      outline: 0,
    }),

    ValueContainer: (provided: any) => ({
      ...provided,
      paddingLeft: 0,
      outline: 0,
      fontSize,
    }),

    placeholder: (provided: any) => ({
      ...provided,
      fontWeight: "semibold",
      color: "#9CA3AF",
      fontSize,
    }),

    // added new style for option button
    option: (provided: any, { isFocused, data }: any) => ({
      ...provided,
      backgroundColor: data.isGray
        ? "#F4F4F4"
        : isFocused
        ? "rgba(0, 102, 255, 0.16)"
        : "transparent",
      color: data.isGray ? "#0066FF" : isFocused ? "#0066FF" : "inherit",
      cursor: "pointer",
      display: "flex",
      justifyContent: data.isGray ? "center" : "left",
      alignItems: "center",
      height: "38px",
      fontWeight: data.isGray ? "600" : "",
      textDecoration: data.isGray ? "underline" : "",
    }),
  };

  const optionButtonRef = useRef<HTMLDivElement>(null);

  // define function to scroll to the bottom of the options list when option button is clicked
  const handleOptionButtonClick = () => {
    if (optionButtonRef.current) {
      optionButtonRef.current.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };

  return (
    <VStack align={"flex-start"} w="100%" spacing={-1}>
      <FormControl {...formControlProps} isRequired={isRequired}>
        <FormLabel
          fontWeight={700}
          fontSize="xs"
          color="grays.9"
          mb="4px"
          display="flex"
        >
          {label}
        </FormLabel>
        <Select
          formatOptionLabel={formatOptionLabel}
          isDisabled={isDisabled}
          placeholder={placeholder}
          isMulti={isMulti}
          options={
            // list ?
            //  ( [
            //     ...options,
            //     { value: 'new', label: 'Create New List', isGray: true },
            //   ])
            //   :
            options
          }
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          defaultValue={defaultValue}
          styles={customStyles}
          components={{
            IndicatorSeparator: null, // remove default separator
            DropdownIndicator: () => (
              // add custom dropdown indicator button
              <div
                ref={optionButtonRef}
                onClick={handleOptionButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: '#F4F4F4',
                  borderRadius: "0px 0px 4px 4px",
                  cursor: "pointer",
                  height: "38px",
                  width: "100%",
                  marginRight: ".7em",
                }}
              >
                <ArrowDown fill="#7489AA" />
              </div>
            ),
          }}
        />
      </FormControl>
    </VStack>
  );
};

export default SearchableSelect;
