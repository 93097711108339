import { useQuery } from "@tanstack/react-query";
import { getTemplates } from "../API/account/templates";

export const useTemplateData=(myCurrentPage?: any, dataToShow?: any)=>{
    return useQuery(
        ["templateData", myCurrentPage, dataToShow],
        () => {
            return getTemplates(myCurrentPage, dataToShow);
        }
    );

}