import { atom } from "recoil";
import { LinkObjectProps } from "../types";

export interface TiptapAtomProps {
  linkObject: LinkObjectProps;
  content: string;
  attributes: {
    front: string;
    back: string;
  }[];
  rawAttributes: string[];
  fallbacks:
    | {
        key: string;
      }
    | {};
}
export const tipTapAtom = atom<TiptapAtomProps>({
  key: "tiptap_atom",
  default: {
    linkObject: {
      link: "",
      link_uid: "",
    },
    content: "",
    attributes: [
      { front: "First name", back: "first_name" },
      { front: "Last name", back: "last_name" },
      { front: "Name", back: "name" },
    ],
    rawAttributes: [],
    fallbacks: {},
  },
});
