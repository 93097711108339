import {
  Flex,
  Text,
  Box,
  chakra,
  HStack,
  VStack,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import CustomMenu from "../components/UI/CustomMenu";
import ChevronDown from "../icons/ChevronDown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SectionReport from "../components/reports/SectionReport";
import { useQuery } from "@tanstack/react-query";
import {
  getContactsReports,
  getConversationReports,
  getMessagesReports,
} from "../API/reports";
import Loader from "../components/UI/Loader";
import "react-datepicker/dist/react-datepicker.css";
import CustomButton from "../components/Ziz_components/Button.component";
import { ExportIcon } from "../assets/svgs/svg";
import CustomModal from "../components/UI/CustomModal";
import ExportReport from "./ExportReport";
// import { getData } from '../API/data';
import { PageAnimate } from "../components/animations/pageAnimate";

const Reports = () => {
  const [filterBy, setFilterBy] = useState<any>("All");
  // const { data } = useQuery(['bodData'], getData);

  const [filterByDuration, setFilterByDuration] = useState<any>("Today");
  const [dateRange, setDateRange] = useState<any>([new Date(), new Date()]);
  const [startDate, endDate] = dateRange;

  const [conversationsEnabled, setConversationsEnabled] = useState(true);
  const [messagesEnabled, setMessagesEnabled] = useState(true);
  const [contactsEnabled, setContactsEnabled] = useState(true);

  const returnfilterBy = (value: string) => {
    if (value === "All") {
      return "";
    } else {
      return value;
    }
  };

  const exportReportDisclosure = useDisclosure();

  useEffect(() => {
    setConversationsEnabled(true);
    setMessagesEnabled(true);
    setContactsEnabled(true);
  }, [startDate, endDate, filterBy, filterByDuration]);

  const { data: conversationsData, isLoading: conversationLoading } = useQuery(
    [
      "conversationReports",
      startDate,
      endDate,
      returnfilterBy(filterBy),
      filterByDuration,
    ],
    () => {
      return getConversationReports({
        startDate,
        endDate,
        filterBy: returnfilterBy(filterBy),
        filterByDuration,
      });
    },
    {
      enabled: conversationsEnabled,
      onSuccess() {
        // setEnabled(false);
      },
    }
  );

  const { data: messagesData, isLoading: messagesloaing } = useQuery(
    [
      "messageReports",
      startDate,
      endDate,
      returnfilterBy(filterBy),
      filterByDuration,
    ],
    () => {
      return getMessagesReports({
        startDate,
        endDate,
        filterBy: returnfilterBy(filterBy),

        filterByDuration,
      });
    },
    {
      enabled: messagesEnabled,
      onSuccess() {
        // setEnabled(false);
      },
    }
  );
  const { data: contactsData, isLoading: contactsLoading } = useQuery(
    [
      "contactReports",
      startDate,
      endDate,
      returnfilterBy(filterBy),
      filterByDuration,
    ],
    () => {
      return getContactsReports({
        startDate,
        endDate,
        filterBy: returnfilterBy(filterBy),

        filterByDuration,
      });
    },
    {
      enabled: contactsEnabled,
      onSuccess() {
        // setEnabled(false);
      },
    }
  );

  return (
    <Flex flexDir={"column"} w="full" pt={"20px"} pb="31px">
      <PageAnimate>
        <chakra.h1 fontSize={"20px"} color="grays.9" fontWeight={500}>
          Reports
        </chakra.h1>

        <Flex flexDir={{ base: "column", md: "row" }} mt={{ md: "-8px" }}>
          <Text
            fontSize={"xs"}
            color="rgba(23, 29, 38, 0.56)"
            mr={{ base: 0, md: 5 }}
            my="auto"
          >
            Get an overview of how your business have been doing.
          </Text>

          {/* filters */}
          <Flex
            flexDir={{ base: "column", md: "row" }}
            ml={{ base: 0, md: "auto" }}
            my={{ base: 5, md: "auto" }}
          >
            {/* Filter by channel */}
            <Stack
              direction={{ base: "column", md: "row" }}
              spacing={"12px"}
              align="center"
            >
              <Text fontSize={"xs"} color="rgba(23, 29, 38, 0.56)">
                Filter by:{" "}
              </Text>

              <CustomMenu
                buttonTitle={
                  <HStack spacing={"14px"}>
                    <Text>
                      {filterBy.charAt(0).toUpperCase() + filterBy?.slice(1)}
                    </Text>
                    <ChevronDown />
                  </HStack>
                }
                buttonProps={{
                  fontSize: "xs",
                  color: "grays.9",
                  type: "button",
                  bg: "white",
                  borderWidth: "1px",
                  borderColor: "#D2D2D4",
                  borderRadius: "4px",
                  py: "10px",
                  px: "12px",
                  minW: "112px",
                  maxW: "112px",
                  noOfLines: 1,
                }}
                isSelect
                itemsSelectData={[
                  {
                    title: "All",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "",
                    },
                  },
                  {
                    title: "Facebook Messenger",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "messenger",
                    },
                  },

                  {
                    title: "Instagram",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "instagram",
                    },
                  },
                  {
                    title: "Twitter",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "twitter",
                    },
                  },
                ]}
                menuSelectProps={{
                  defaultValue: "Messenger",
                  onChange: (value) => {
                    setFilterBy(value);
                  },
                }}
              />
            </Stack>

            {/* Filter by duration and date */}
            <Box mt={{ base: 5, md: 0 }} ml={{ base: 0, md: "64px" }}>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={{ base: "16px", md: "12px" }}
                align="center"
              >
                <Text fontSize={"xs"} w="full" color="rgba(23, 29, 38, 0.56)">
                  Date by:{" "}
                </Text>

                <CustomMenu
                  buttonTitle={
                    <HStack textAlign={"left"} spacing={"14px"}>
                      <Text flex={1}>
                        {filterByDuration.charAt(0).toUpperCase() +
                          filterByDuration?.slice(1).replace("_", " ")}
                      </Text>
                      <ChevronDown />
                    </HStack>
                  }
                  buttonProps={{
                    fontSize: "xs",
                    color: "grays.9",
                    type: "button",
                    bg: "white",
                    borderWidth: "1px",
                    borderColor: "#D2D2D4",
                    borderRadius: "4px",
                    py: "10px",
                    px: "12px",
                    minW: "130px",
                    maxW: "130px",
                  }}
                  isSelect
                  itemsSelectData={[
                    {
                      title: "Today",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "today",
                      },
                    },
                    {
                      title: "Last week",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_week",
                      },
                    },
                    {
                      title: "Last month",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_month",
                      },
                    },
                    {
                      title: "Last Quarter",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_quarter",
                      },
                    },
                    {
                      title: "Last Year",
                      itemSelectProps: {
                        fontSize: "xs",
                        value: "last_year",
                      },
                    },
                  ]}
                  menuSelectProps={{
                    defaultValue: "today",
                    onChange: (value) => {
                      setFilterByDuration(value);
                    },
                  }}
                />

                <Text fontSize={"xs"} color="grays.8">
                  OR
                </Text>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate ?? new Date()}
                  endDate={endDate ?? new Date()}
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
                <CustomButton
                  children={
                    <Flex
                      justifyContent={"space-between"}
                      alignItems="center"
                      columnGap=".5em"
                      onClick={exportReportDisclosure.onOpen}
                    >
                      <ExportIcon />
                      <Text>Export</Text>
                    </Flex>
                  }
                  padding={"1.5em 3em"}
                  border="none"
                  size="sm"
                />
              </Stack>
            </Box>
          </Flex>
        </Flex>

        <VStack align={"flex-start"} w="full" mt="24px" spacing={"20px"}>
          {conversationLoading || messagesloaing || contactsLoading ? (
            <Box
              mt="24px"
              bg="white"
              rounded="4px"
              w="full"
              maxW="1360px"
              mx="auto"
              py="20px"
            >
              <Loader />
            </Box>
          ) : (
            <>
              <SectionReport
                title="Conversations"
                first={{
                  title: "Total Conversations",
                  value: conversationsData?.total_conversations,
                }}
                second={{
                  title: "Total Open Conversations",
                  value: conversationsData?.open_conversations,
                }}
                third={{
                  title: "Total Closed Conversations",
                  value: conversationsData?.closed_conversations,
                }}
              />

              <SectionReport
                title="Messages"
                first={{
                  title: "Total Messages",
                  value: messagesData?.total_messages,
                }}
                second={{
                  title: "Total Sent Messages",
                  value: messagesData?.sent_messages,
                }}
                third={{
                  title: "Total Received Messages",
                  value: messagesData?.received_messages,
                }}
              />

              <SectionReport
                title="Contacts"
                first={{
                  title: "Total Contacts",
                  value: contactsData?.total_contacts,
                }}
              />
            </>
          )}
        </VStack>
        <CustomModal
          disclosure={exportReportDisclosure}
          titleUnderLine={true}
          title={
            <Text
              fontWeight={700}
              fontSize="20px"
              color="grays.8"
              mt="35px"
              // px='38px'
              // borderBottom={'1px solid rgba(0, 102, 255, 0.16)'}
            >
              Export report
            </Text>
          }
        >
          <ExportReport close={exportReportDisclosure.onClose} />
        </CustomModal>
      </PageAnimate>
    </Flex>
  );
};

export default Reports;
