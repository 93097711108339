import React from "react";

const OptinLikeSuccessIcon = () => {
    return (
        <svg
            width="56"
            height="56"
            viewBox="0 0 56 56"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                opacity="0.24"
                d="M25.0837 5.72C26.6937 4.34333 29.3303 4.34333 30.9637 5.72L34.6504 8.8933C35.3504 9.49997 36.657 9.99004 37.5903 9.99004H41.557C44.0304 9.99004 46.0604 12.02 46.0604 14.4934V18.4599C46.0604 19.3699 46.5504 20.6999 47.157 21.3999L50.3304 25.0866C51.7071 26.6966 51.7071 29.3333 50.3304 30.9667L47.157 34.6532C46.5504 35.3532 46.0604 36.6599 46.0604 37.5933V41.56C46.0604 44.0333 44.0304 46.0633 41.557 46.0633H37.5903C36.6803 46.0633 35.3504 46.5534 34.6504 47.16L30.9637 50.3333C29.3537 51.71 26.717 51.71 25.0837 50.3333L21.3971 47.16C20.6971 46.5534 19.3904 46.0633 18.457 46.0633H14.4204C11.947 46.0633 9.91704 44.0333 9.91704 41.56V37.5699C9.91704 36.6599 9.42706 35.3532 8.84373 34.6532L5.69371 30.9433C4.34038 29.3333 4.34038 26.72 5.69371 25.11L8.84373 21.3999C9.42706 20.6999 9.91704 19.3933 9.91704 18.4833V14.4934C9.91704 12.02 11.947 9.99004 14.4204 9.99004H18.457C19.367 9.99004 20.6971 9.49997 21.3971 8.8933L25.0837 5.72Z"
                fill="#159D6F"
            />
            <path
                d="M38.6867 25.8297C38.2434 25.1997 37.4968 24.8498 36.6101 24.8498H32.0601C31.7567 24.8498 31.4768 24.733 31.2901 24.4997C31.1034 24.2664 31.0101 23.963 31.0568 23.6364L31.6167 19.9964C31.8501 18.9231 31.1268 17.6864 30.0534 17.3364C29.0501 16.963 27.8601 17.4763 27.3934 18.1763L22.8667 24.8963V24.0564C22.8667 22.423 22.1668 21.7464 20.4401 21.7464H19.2967C17.5701 21.7464 16.8701 22.423 16.8701 24.0564V35.2098C16.8701 36.8431 17.5701 37.5197 19.2967 37.5197H20.4401C22.0734 37.5197 22.7734 36.8897 22.8434 35.3963L26.2734 38.033C26.7401 38.4997 27.7901 38.7563 28.5368 38.7563H32.8534C34.3467 38.7563 35.8401 37.6363 36.1668 36.2597L38.8968 27.953C39.2001 27.2063 39.1301 26.4364 38.6867 25.8297Z"
                fill="#159D6F"
            />
        </svg>
    );
};

export default OptinLikeSuccessIcon;
