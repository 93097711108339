import { Center, Spinner, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

const Loader: FC<{ text?: string; height?: string }> = ({
  text = 'Fetching data...',
  height = '50vh',
}) => {
  return (
    <Center flexDir={'column'} h={height}>
      <Spinner color={'primary.500'} boxSize='24px' />
      <Text mt='28px' fontSize={'sm'} fontWeight={500} color='grays.12'>
        {text}
      </Text>
    </Center>
  );
};

export default Loader;
