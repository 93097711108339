export const Button = {
  baseStyle: {},
  sizes: {
    sm: {
      borderRadius: '4px',
      fontSize: 'xs',
      py: 5,
      px: '24px',
      height: '32px',
    },
    md: {
      borderRadius: '4px',
      fontSize: 'sm',
      py: '14px',
      px: '24px',
      height: '48px',
    },
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {},
};
