import React, { FC, useMemo, useState } from "react";

import {
    Table,
    Thead,
    Tbody,
    chakra,
    Tr,
    Th,
    Td,
    TableContainer,
    Text,
    VStack,
    Box,
    useDisclosure,
} from "@chakra-ui/react";

import {
    ColumnDef,
    flexRender,
    getCoreRowModel,
    getFilteredRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import CustomModal from "../../UI/CustomModal";
import dayjs from "dayjs";
import { fuzzyFilter } from "../../../utils/table";
import MoreOptinDetails from "./MoreOptinDetails";

export interface optinListProps {
    id: number;
    uid: string;
    optin_name: string;
    list: { name: string };
    created_at: Date;
    updated_at: Date;
    channel: string;
    keyphrase: string;
    auto_message: string;
}

const ListOptinTable: FC<{
    optinData: optinListProps[];
    globalFilter: any;
    setGlobalFilter: any;
}> = ({ optinData, globalFilter, setGlobalFilter }) => {
    const [dataToUpdate, setDataToUpdate] = useState<optinListProps>({
        id: 0,
        uid: "",
        optin_name: "",
        list: { name: "" },
        created_at: new Date(),
        updated_at: new Date(),
        channel: "",
        keyphrase: "",
        auto_message: "",
    });

    const [sorting, setSorting] = React.useState<SortingState>([]);

    const disclosure = useDisclosure();

    const columns = useMemo<ColumnDef<optinListProps>[]>(() => {
        return [
            {
                header: () => <Text>Opt-in Name</Text>,
                accessorKey: "name",
                id: "name",
                cell: (props: any) => {
                    return <Text>{props?.getValue()}</Text>;
                },
            },
            {
                header: () => <Text>Channel</Text>,
                accessorKey: "channel",
                id: "channel",
                cell: (props: any) => {
                    return (
                        <Text
                            textTransform={"capitalize"}
                            borderColor={"grays.12"}
                            borderWidth="0.5px"
                            color={"grays.12"}
                            px="8px"
                            w="fit-content"
                            py="4px"
                            borderRadius="50px"
                        >
                            {props?.getValue()}
                        </Text>
                    );
                },
            },
            {
                header: () => <Text>Keyphrase</Text>,
                accessorKey: "keyword",
                id: "keyword",
                cell: (props: any) => {
                    return <Text>{props?.getValue()}</Text>;
                },
            },
            {
                header: () => <Text>Created on</Text>,
                accessorKey: "created_at",
                id: "created_at",
                cell: (props: any) => {
                    return (
                        <Text>
                            {dayjs(props?.getValue()).format("DD/MM/YYYY - hh:mmA")}
                        </Text>
                    );
                },
            },
            {
                header: () => <Text>List</Text>,
                accessorFn: (row) => row?.list?.name,
                id: "list",
                cell: (props: any) => {
                    return (
                        <Text
                            borderColor={"grays.12"}
                            borderWidth="0.5px"
                            color={"grays.12"}
                            px="8px"
                            w="fit-content"
                            py="4px"
                            borderRadius="50px"
                        >
                            {props?.getValue()}
                        </Text>
                    );
                },
            },
            {
                header: "",
                id: "view_more",
                cell: (props: any) => (
                    <Text
                        textDecor={"underline"}
                        cursor={"pointer"}
                        onClick={() => {
                            disclosure.onOpen();
                            setDataToUpdate(props?.row?.original);
                        }}
                        fontWeight={"medium"}
                    >
                        View more details
                    </Text>
                ),
            },
        ];
    }, [disclosure]);

    const data = optinData;

    const table = useReactTable({
        data,
        columns,
        state: {
            sorting,
            globalFilter,
        },
        globalFilterFn: fuzzyFilter,
        onSortingChange: setSorting,
        getFilteredRowModel: getFilteredRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onGlobalFilterChange: setGlobalFilter,

        debugTable: true,
    });

    return (
        <>
            <TableContainer
                borderWidth={"1px"}
                borderColor="borders.200"
                rounded={"8px"}
            >
                <Table variant={"simple"}>
                    <Thead>
                        {table.getHeaderGroups().map((headerGroup) => {
                            return (
                                <Tr key={headerGroup.id}>
                                    {headerGroup.headers.map((header) => {
                                        return (
                                            <Th
                                                key={header.id}
                                                textTransform="none"
                                                color={"grays.12"}
                                                fontSize="xs"
                                                letterSpacing={"unset"}
                                                fontWeight={500}
                                                colSpan={header.colSpan}
                                            >
                                                <Box display={"flex"}>
                                                    {header.isPlaceholder
                                                        ? null
                                                        : flexRender(
                                                            header.column.columnDef.header,
                                                            header.getContext()
                                                        )}

                                                    {header.column.getCanSort() && (
                                                        <VStack spacing="2px" my="auto" ml="2">
                                                            <chakra.button
                                                                onClick={() => {
                                                                    header.column.toggleSorting();
                                                                }}
                                                            >
                                                                <SortArrowUp width="8px" />
                                                            </chakra.button>

                                                            <chakra.button
                                                                onClick={() => {
                                                                    header.column.toggleSorting();
                                                                }}
                                                            >
                                                                <SortArrowDown width="8px" />
                                                            </chakra.button>
                                                        </VStack>
                                                    )}
                                                </Box>
                                            </Th>
                                        );
                                    })}
                                </Tr>
                            );
                        })}
                    </Thead>
                    <Tbody>
                        {table.getRowModel().rows.map((row) => (
                            <Tr key={row.id}>
                                {row.getVisibleCells().map((cell) => (
                                    <Td
                                        key={cell.id}
                                        fontSize="xs"
                                        color="grays.12"
                                        fontWeight={"medium"}
                                    >
                                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>

            <CustomModal
                disclosure={disclosure}
                titleUnderLine = {true}
                title={
                    <Text fontSize={"20px"} color="grays.8" mt="35px" >
                        More details
                    </Text>
                }
            >
                <MoreOptinDetails data={dataToUpdate} />
            </CustomModal>
        </>
    );
};

export default ListOptinTable;
