// import axios from "axios";
import API from ".";

interface SMSCampaignExportProps {
  status: string;
  message: string;
  code: 404;
}

interface CampaignReportProps {
  pending: number;
  sent: number;
  delivered: number;
  failed: number;
  rejected: number;
  expired: number;
  success: number;
}

export const getAllCampaigns = async (data: {
  page: string | number;
  limit?: string | number;
  type: string;
}) => {
  const response = await API.get(
    `/campaign/${data?.type}/list?page=${data?.page}&limit=${data?.limit}`
  );
  return response.data.data;
};

export const createCampaign = async (data: {
  campaign_name: string;
  list_uid: string;
  sms_sender_uid: string;
  email_sender_uid: string;
  whatsapp_sender_uid: string;
  email: string;
  sender_number: string;
  message_body: string;
  subject: string;
  timezone: any;
  send_date: any;
  template_uid: string;
  channel: string;
  link_uid: string;
  fallbacks: { [key: string]: string };
}) => {
  const smsData = {
    campaign_name: data.campaign_name,
    list_uid: data.list_uid,
    sms_sender_uid: data.sms_sender_uid,
    message_body: data.message_body,
    timezone: data.timezone,
    send_date: data.send_date,
    channel: data.channel,
    link_uid: data.link_uid,
    fallbacks: data.fallbacks,
  };
  const emailData = {
    campaign_name: data.campaign_name,
    list_uid: data.list_uid,
    email_sender_uid: data.email_sender_uid,
    message_body: data.message_body,
    email: data.email,
    subject: data.subject,
    timezone: data.timezone,
    send_date: data.send_date,
    channel: data.channel,
  };
  const whatsappData = {
    name: data.campaign_name,
    list_uid: data.list_uid,
    template_uid: data.template_uid,
    timezone: data.timezone,
    sender: data.sender_number,
    send_date: data.send_date,
    channel: data.channel,
    link_uid: data.link_uid,
    fallbacks: data.fallbacks,
  };
  const response = await API.post(
    `/campaign/${data.channel.toLowerCase()}/create`,
    data.channel === "SMS"
      ? smsData
      : data.channel === "email"
      ? emailData
      : whatsappData
  );
  return response.data;
};

export const updateCampaign = async (data: {
  campaign_uid: string | null;
  campaign_name: string;
  list_uid: string;
  sms_sender_uid: string;
  message_body: string;
  timezone: any;
  send_date: any;
  channel: string;
  link_uid: string;
}) => {
  const response = await API.put(`/campaign/sms/edit`, data);
  return response.data;
};

export const deleteCampaign = async (uid: string, channel: string) => {
  const response = await API.delete(`/campaign/${channel}/delete/${uid}`);
  return response.data;
};
// ''campaign/sms/delete/efe15690-02aa-4e4a-999c-c04a4d8267ee''

export const getCampaignReportAnalytics = async (
  uid: string | undefined,
  channel: string | undefined
) => {
  const response = await API.get(
    `/campaign/${channel?.toLowerCase()}/statistic/show/${uid}`
  );
  return response.data.data as CampaignReportProps;
};

export const getCampaignReportContactsData = async (data: {
  uid: string | undefined;
  channel: string | undefined;
  page?: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/campaign/${data?.channel?.toLowerCase()}/list-${data?.channel?.toLowerCase()}/${
      data?.uid
    }?page=${data?.page}&limit=${data?.limit}`
  );
  return response.data.data;
};

// `campaign/sms/list-sms/23cab2d5-b025-4d01-854f-537b2d439e60?export=true&export_email=uko%40bodsquare.com'`

// export const getSMSCampaignExport = async (data: {
//   uid: string | undefined;
//   channel: string;
//   email: string | undefined;
//   exportFlag: boolean;
// }): Promise<SMSCampaignExportProps> => {
//   const { uid, channel, email, exportFlag } = data;
//   const response = await API.get(`/campaign/${channel}/list-${channel}/${uid}?export=${exportFlag}&export_email=${email}`);
//   return response.data.data as SMSCampaignExportProps;
// };

export const getSMSCampaignExport = async (data: {
  uid: string | undefined;
  channel: string;
  exportFlag: boolean;
  email: string | undefined;
  phone_number?: string | undefined;
}): Promise<SMSCampaignExportProps> => {
  const { uid, channel, email, exportFlag, phone_number } = data;
  const response = await API.get(
    `/campaign/${channel}/list-${channel}/${uid}`,
    {
      params: {
        search: phone_number,
        export: exportFlag,
        export_email: email,
      },
    }
  );
  return response.data.data as SMSCampaignExportProps;
};

// get campign and continue create campaign
export const getSingleCampaign = async (data: {
  uid: string;
  channel: string;
}) => {
  const res = await API.get(
    `/campaign/${data.channel.toLowerCase()}/show/${data.uid}`
  );

  return res.data.data;
};
