import React, { FC, useMemo } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
  Checkbox,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import dayjs from "dayjs";
import { SwipeIn } from "../animations";

interface listProps {
  id: string | number;
  uid: string;
  company_uid: string;
  wallet_uid: string;
  reference: string;
  provider_reference: string;
  base_currency_amount: number;
  base_currency_amount_before: number;
  base_currency_amount_after: number;
  transaction_business_currency_amount: number;
  transaction_business_currency: string;
  transaction_service_code: string;
  type: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  exchange_rate: {
    current_rate: number;
    current_business_currency: string;
    current_business_amount: number;
    current_business_balance_before: number;
    current_business_balance_after: number;
  };
}

const PaymentHistoryList: FC<any> = ({ data }) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g campaignName and Contacts
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    const statusColorsToUse = (status: string) => {
      switch (status) {
        case "success":
          return {
            color: "#159D6F",
            bg: "rgba(21, 157, 111, 0.04)",
          };
        case "pending":
          return {
            color: "rgb(220, 168, 10)",
            bg: "rgba(220, 168, 10, 0.04)",
          };
        case "scheduled":
          return {
            color: "rgb(0, 102, 255)",
            bg: "rgba(0, 102, 255, 0.04)",
          };
        case "draft":
          return {
            color: "rgb(25, 63, 100)",
            bg: "rgba(25, 63, 100, 0.04)",
          };

        default:
      }
    };

    return [
      {
        header: "",
        id: "checkbox",
        cell: (props: any) => <Checkbox />,
      },
      {
        header: () => <Text>Amount</Text>,
        accessorKey: "amount",
        id: "amount",
        accessorFn: (row) =>
          `${row?.transaction_business_currency} ${row?.transaction_business_currency_amount}`,
      },
      {
        header: "Payment method",
        accessorKey: "payment__method",
        id: "paymentm",
        cell: (props: any) => {
          return <Text>{props?.getValue() || "---"}</Text>;
        },
      },
      {
        header: () => <Text>Date & time</Text>,
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        id: "status",
        cell: (props: any) => {
          return (
            <Box
              bg={statusColorsToUse(props?.getValue())?.bg}
              w="fit-content"
              py="4px"
              px="8px"
              rounded="full"
              borderWidth={"0.5px"}
              borderColor={statusColorsToUse(props?.getValue())?.color}
              color={statusColorsToUse(props?.getValue())?.color}
              textTransform="capitalize"
              fontWeight={500}
            >
              {props?.getValue() === "in-progress"
                ? "In Progress"
                : props?.getValue()}
            </Box>
          );
        },
      },
      {
        header: "",
        id: "view_detail",
        cell: (props: any) => {
          return (
            <Text
              cursor={"pointer"}
              textDecoration={"underline"}
              fontWeight={500}
              fontSize={"xs"}
              color={"#0066FF"}
              _hover={{ color: "#0066FF90" }}
              textUnderlineOffset={"3px"}
            >
              View
            </Text>
          );
        },
      },
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <>
      <TableContainer overflowX="unset" overflowY="unset">
        <Table variant={"simple"} position={"relative"}>
          <Thead
            position={"sticky"}
            top={0}
            bg={"white"}
            boxShadow={"sm"}
            zIndex={1}
          >
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>

          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    <SwipeIn delay={0.2 * index}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </SwipeIn>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default PaymentHistoryList;
