import API from "../.";
import { ProfileDataProps } from "../../utils/generalProps";

export const getProfileData = async () => {
  const response = await API.get(`/company/show`);

  return response.data.data as ProfileDataProps;
};

export const updateProfileData = async (data: {
  industry: string;
  email: string;
  name: string;
  phone_number: string;
  description: string;
  currency?: string;
}) => {
  const response = await API.post(`/company/edit`, {
    ...data,
  });

  return response.data;
};
