import React from 'react'

function WhatsappIcon() {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_2851_59990)" />
            <path d="M16.0085 9.33594C12.3378 9.33594 9.34964 12.3215 9.34831 15.9922C9.34764 17.1655 9.65496 18.311 10.2376 19.3203L9.33398 22.6693L12.8223 21.8451C13.7949 22.3757 14.8899 22.6543 16.0046 22.6549H16.0072C19.6772 22.6549 22.664 19.6687 22.666 15.9987C22.6673 14.2194 21.9754 12.5464 20.7181 11.2878C19.4608 10.0298 17.7898 9.3366 16.0085 9.33594ZM16.0072 10.6693C17.4312 10.6699 18.7694 11.2251 19.7754 12.2305C20.7814 13.2371 21.334 14.5747 21.3327 15.9974C21.3313 18.9334 18.9432 21.3216 16.0059 21.3216C15.1172 21.3209 14.2369 21.0978 13.4616 20.6745L13.0124 20.4297L12.5163 20.5469L11.2038 20.8568L11.5241 19.6667L11.6686 19.1328L11.3926 18.6536C10.9272 17.8483 10.681 16.9275 10.6816 15.9922C10.683 13.0575 13.0718 10.6693 16.0072 10.6693ZM13.6517 12.9193C13.5404 12.9193 13.3603 12.9609 13.2077 13.1276C13.055 13.2936 12.6243 13.6957 12.6243 14.5143C12.6243 15.333 13.2207 16.1243 13.304 16.2357C13.3867 16.3463 14.4552 18.0794 16.1465 18.7461C17.5518 19.3001 17.8372 19.1908 18.1426 19.1628C18.4479 19.1354 19.1276 18.7611 19.2663 18.3724C19.4049 17.9837 19.4053 17.6494 19.3639 17.5807C19.3226 17.5114 19.2116 17.4701 19.0449 17.3867C18.8789 17.3034 18.0609 16.9017 17.9082 16.8464C17.7555 16.791 17.6439 16.763 17.5332 16.9297C17.4225 17.0964 17.1045 17.4701 17.0072 17.5807C16.9098 17.6921 16.8132 17.707 16.6465 17.6237C16.4798 17.5397 15.9439 17.3635 15.3079 16.7969C14.8133 16.3562 14.4795 15.8125 14.3822 15.6458C14.2855 15.4798 14.373 15.3887 14.4564 15.306C14.531 15.2313 14.6217 15.1117 14.7051 15.0143C14.7877 14.917 14.8164 14.8476 14.8717 14.737C14.9271 14.6263 14.8988 14.5286 14.8574 14.4453C14.8161 14.362 14.4924 13.5403 14.3444 13.2109C14.2197 12.9343 14.0881 12.9278 13.9694 12.9232C13.8727 12.9192 13.7624 12.9193 13.6517 12.9193Z" fill="white" />
            <defs>
                <linearGradient id="paint0_linear_2851_59990" x1="16" y1="3" x2="16" y2="29" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#5FFC7B" />
                    <stop offset="0.541667" stopColor="#42E55F" />
                    <stop offset="1" stopColor="#27D145" />
                </linearGradient>
            </defs>
        </svg>
    )
}

export default WhatsappIcon;