import API from ".";

export const shortenURL = async (data: { url: string; channel: string }) => {
  const res = await API.post(`/misc/shorten-url`, data);

  return res.data.data;
};

export const getCountries = async () => {
  try {
    const res = await API.get("/country");
    return res?.data?.data;
  } catch (error) {
    // @ts-ignore
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

export const getCurrencies = async () => {
  try {
    const res = await API.get("/currencies");
    return res?.data?.data;
  } catch (error) {
    // @ts-ignore
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};

interface PricingProps {
  // country_code: string
  [x: string]: any;
}

export const getPricing = async ({
  country_code,
  currency,
  country_alpha,
}: PricingProps) => {
  try {
    const url = `/pricing/${country_code}/${currency}/${country_alpha}`;
    const res = await API.get(url);
    console.log(res);
    return res?.data?.data;
  } catch (error) {
    // @ts-ignore
    const err = error?.response?.data?.message || error?.message;
    throw new Error(err);
  }
};
