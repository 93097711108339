import {
  Box,
  Button,
  Flex,
  HStack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { getListData } from "../../../../API/account/list";
import AddNewList from "../../../../components/account/lists/AddNewList";
import ListsTable from "../../../../components/account/lists/ListsTable";
import CustomModal from "../../../../components/UI/CustomModal";
import Empty from "../../../../components/UI/Empty";
import Loader from "../../../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import { useState } from "react";
import CustomPaginaton from "../../../../components/UI/CustomPaginaton";

const Lists = () => {
  const disclosure = useDisclosure();

  // const [myCurrentPage, setMyCurrentPage] = useState(1);

  const { isLoading, data } = useQuery(["listData"], getListData);

  const [globalFilter, setGlobalFilter] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    // window.scrollTo({
    //   top: 0,
    // });
    setCurrentPage(data.nextPage);
    // setMyCurrentPage(currentPage);
  };

  return (
    <Box bg={"white"} borderRadius={"6px"}>
      <Flex>
        <Text
          // onClick={() => console.log(data)}
          fontSize={"20px"}
          color="grays.9"
          fontWeight={"700"}
        >
          Current lists
        </Text>

        <Button
          onClick={disclosure.onOpen}
          colorScheme={"primary"}
          ml="auto"
          size={"sm"}
          isDisabled={isLoading}
        >
          Create new list
        </Button>
      </Flex>

      <Box mt="32px">
        {/* Empty */}
        {!isLoading && data?.length === 0 && (
          <Empty
            title="You haven’t made any list yet"
            description="Create a list to help categorize your contacts."
            buttonText="Create new list"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M82 40.76H70.44C60.96 40.76 53.24 33.04 53.24 23.56V12C53.24 9.8 51.44 8 49.24 8H32.28C19.96 8 10 16 10 30.28V65.72C10 80 19.96 88 32.28 88H63.72C76.04 88 86 80 86 65.72V44.76C86 42.56 84.2 40.76 82 40.76Z"
                  fill="#7489AA"
                />
                <path
                  d="M63.1987 8.84193C61.5587 7.20193 58.7188 8.32193 58.7188 10.6019V24.5619C58.7188 30.4019 63.6787 35.2419 69.7187 35.2419C73.5187 35.2819 78.7987 35.2819 83.3187 35.2819C85.5987 35.2819 86.7987 32.6019 85.1987 31.0019C79.4387 25.2019 69.1187 14.7619 63.1987 8.84193Z"
                  fill="#7489AA"
                />
                <path
                  d="M54 55H30C28.36 55 27 53.64 27 52C27 50.36 28.36 49 30 49H54C55.64 49 57 50.36 57 52C57 53.64 55.64 55 54 55Z"
                  fill="#7489AA"
                />
                <path
                  d="M46 71H30C28.36 71 27 69.64 27 68C27 66.36 28.36 65 30 65H46C47.64 65 49 66.36 49 68C49 69.64 47.64 71 46 71Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={disclosure.onOpen}
          />
        )}

        {isLoading && <Loader />}

        {!isLoading && data?.length > 0 && (
          <>
            <ListsTable
              listsData={data}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              isLoading={isLoading}
            />

            <HStack pt={"10px"} justifyContent={"flex-end"}>
              <CustomPaginaton
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
              />
            </HStack>
          </>
        )}
      </Box>

      <CustomModal
        titleUnderLine={true}
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Create new list
          </Text>
        }
      >
        <AddNewList close={disclosure.onClose} />
      </CustomModal>
    </Box>
  );
};

export default Lists;
