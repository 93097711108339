import Teammates from "./pages/Account/Teammates";
import Billings from "./pages/Account/Billings";
import BillingPlan from "./pages/Account/Billings/plans";
import BillingCard from "./pages/Account/Billings/cards";
import SwitchBusiness from "./pages/SwitchBusiness";
import { Box } from "@chakra-ui/react";
import { App } from "./App";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./pages/Login";
import ConfirmEmail from "./pages/ConfirmEmail";
import GetStarted from "./pages/GetStarted";
import LoggedinLayout from "./components/general/LoggedinLayout";
import Reports from "./pages/Reports";
import Account from "./pages/Account/Account";
import Profile from "./pages/Account/Profile";
import Lists from "./pages/Account/ContactData/Lists/index";
import Channels from "./pages/Account/Channels";
import Conversations from "./pages/Conversations";
import HelpSupport from "./pages/Account/HelpSupport";
import AddChannel from "./pages/Account/Channels/AddChannel";
import SingleListContacts from "./pages/Account/ContactData/Lists/SingleListContacts";
import Contacts from "./pages/Contacts";
import SingleContactPage from "./pages/Contacts/SingleContactPage";
import ImportContacts from "./pages/Contacts/ImportContacts";
import Campaigns from "./pages/Campaigns";
import NewCampaign from "./pages/Campaigns/NewCampaign";
import CampaignReport from "./pages/Campaigns/CampaignReport";
import Wallet from "./pages/Account/Wallet";
import { getCookie } from "cookies-next";
import NotFound from "./pages/NotFound";
import SenderIDs from "./pages/Account/SenderIDs";
import ImportHistory from "./pages/Account/ContactData/ImportHistory";
import TransactionHistory from "./pages/TransactionHistory";
import Apikey from "./pages/Apikey";
import Optin from "./pages/Account/Optin";
import CreateOptin from "./pages/Account/Optin/createOpt-in";
import WhatsappConnect from "./pages/Account/Channels/WhatsAppConnect";
import Templates from "./pages/Account/Templates";
import AllTemplates from "./pages/Account/Templates/AllTemplates";
import CreateTemplates from "./pages/Account/Templates/CreateTemplates";
// import CreateSegment from "./pages/Contacts/Segments/CreateSegment";
import BillingReceipt from "./components/account/billings/billingInvoice";
import Pricing from "./pages/Account/Pricing";
import { EditTemplate } from "./pages/Account/Templates/EditTemplate";
import Segments from "./pages/Account/ContactData/Segments";
import Attributes from "./pages/Account/ContactData/Attributes";
import Events from "./pages/Account/ContactData/Events";
import { AnimatePresence } from "framer-motion";

export default function MainApp() {
  const loggedIn = !!getCookie("USER_TOKEN");
  const location = useLocation();

  return (
    <Box minH="100vh" w="full">
      {loggedIn ? (
        <LoggedinLayout>
          <AnimatePresence
            exitBeforeEnter
            onExitComplete={() => window.scrollTo(0, 0)}
          >
            <Routes location={location} key={location.pathname}>
              <Route path="/" element={<App />} />
              <Route path="/getStarted" element={<GetStarted />} />
              <Route path="/conversations" element={<Conversations />} />
              <Route path="/reports" element={<Reports />} />

              {/* =================== contacts =================== */}
              <Route path="/contacts" element={<Contacts />} />
              <Route path="/contacts/:id" element={<SingleContactPage />} />
              <Route path="/contacts/import" element={<ImportContacts />} />
              {/* <Route
                path="/contacts/segment_create"
                element={<CreateSegment />}
              /> */}
              {/* <Route path="/contacts/segment_edit" element={<E />} /> */}

              {/* ================================================ */}

              {/* =================== Campigns =================== */}
              <Route path="/campaigns" element={<Campaigns />} />
              <Route
                path="/campaigns/:channel/:id/reports"
                element={<CampaignReport />}
              />

              <Route path="/campaigns/new" element={<NewCampaign />} />
              <Route path="/wallet" element={<Wallet />} />

              {/* ================================================ */}

              <Route path="/wallet" element={<Wallet />} />
              <Route
                path="/transactionHistory"
                element={<TransactionHistory />}
              />

              {/* =================== Account =================== */}
              <Route path="/account/lists" element={<Lists />} />
              <Route path="/account" element={<Account />}>
                <Route path="profile" element={<Profile />} />
                <Route path="teammates" element={<Teammates />} />
                {/* contact data links */}
                <Route path="contactdata/lists" element={<Lists />} />
                <Route
                  path="contactdata/lists/:id/contacts"
                  element={<SingleListContacts />}
                />
                <Route path="contactdata/segments" element={<Segments />} />
                <Route path="contactdata/attributes" element={<Attributes />} />
                <Route path="contactdata/events" element={<Events />} />
                <Route
                  path="contactdata/importhistory"
                  element={<ImportHistory />}
                />

                {/* billings links */}
                <Route path="billings" element={<Billings />} />
                <Route path="billings/plans" element={<BillingPlan />} />
                <Route path="billings/cards" element={<BillingCard />} />
                <Route path="billings/invoice" element={<BillingReceipt />} />
                <Route path="pricing" element={<Pricing />} />

                {/* <Route
                  path="lists/:id/contacts"
                  element={<SingleListContacts />}
                /> */}

                <Route path="senderIDs" element={<SenderIDs />} />

                <Route path="channels" element={<Channels />} />
                <Route path="senderIDs" element={<SenderIDs />} />
                <Route path="optin" element={<Optin />} />
                <Route path="optin/create" element={<CreateOptin />} />
                <Route path="channels" element={<Channels />} />
                {/* =================== Templates =================== */}
                <Route path="templates" element={<Templates />} />
                <Route path="templates/:all" element={<AllTemplates />} />
                <Route
                  path="templates/:all/create"
                  element={<CreateTemplates />}
                />
                <Route
                  path="templates/:all/edit/:id"
                  element={<EditTemplate />}
                />
                {/* ================================================ */}

                <Route path="channels" element={<Channels />} />
                <Route path="channels/whatsapp" element={<WhatsappConnect />} />
                <Route path="channels/addChannel" element={<AddChannel />} />
                <Route path="helpSupport" element={<HelpSupport />} />
                <Route path="apiKey" element={<Apikey />} />

                {/* ================================================ */}
              </Route>

              <Route path="/switch-business" element={<SwitchBusiness />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </AnimatePresence>
        </LoggedinLayout>
      ) : (
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="/login" element={<Login />} />
          <Route path="/confirmEmail" element={<ConfirmEmail />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </Box>
  );
}
