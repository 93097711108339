import {
  Box,
  chakra,
  Button,
  HStack,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Table,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Tbody,
  Td,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { ButtonPrimary } from "../../../components/UI/Buttons";
import CustomModal from "../../../components/UI/CustomModal";
import CreateAttribute from "../../../components/account/attributes/CreateAttribute";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  deleteContactAttribute,
  getContactAttributesFull,
} from "../../../API/contacts";
import NoEventsIcons from "../../../icons/NoEventsIcons";
import { FaCaretDown } from "react-icons/fa";
import {
  useReactTable,
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import Loader from "../../../components/UI/Loader";
import { removeUnderscores } from "../../../functions";
import dayjs from "dayjs";
import { EditAttribute } from "../../../components/account/attributes/EditAttribute";
import { SingleAttributeProp } from "../../../types";
import { toaster } from "../../../utils/toast";
import { SwipeIn } from "../../../components/animations";

function Attributes() {
  const [attributes, setAttributes] = React.useState<
    SingleAttributeProp[] | []
  >([]);
  const [dataToShow] = React.useState(10);
  const [myCurrentPage] = React.useState(1);
  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [currData, setCurrData] = React.useState<SingleAttributeProp>();
  const [globalFilter] = React.useState("");
  const createAttributeDisclosure = useDisclosure();
  const editModalDisclosure = useDisclosure();
  const deleteModalDisclosure = useDisclosure();
  const toast = useToast();
  const queryClient = useQueryClient();

  const { isLoading } = useQuery(
    ["contact_data_attributes", myCurrentPage, dataToShow],
    () => {
      return getContactAttributesFull({
        page: myCurrentPage,
        limit: dataToShow,
      });
    },
    {
      onSuccess: (data: any) => {
        // console.log("we haveeeee", data);
        setAttributes(data.docs);
      },
    }
  );
  const deleteAttributeMutation = useMutation(deleteContactAttribute, {
    onSuccess: (data) => {
      const success = { ...data };
      queryClient.invalidateQueries([
        "contact_data_attributes",
        myCurrentPage,
        dataToShow,
      ]);
      toaster(toast, success);
    },
    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  const columns = React.useMemo(() => {
    return [
      {
        header: "Attribute name",
        accessorKey: "name",
        id: "attributename",
        cell: (props: any) => {
          return <Text>{removeUnderscores(props?.getValue())}</Text>;
        },
      },
      {
        header: "Data Type",
        accessorKey: "data_type",
        id: "data_type",
        cell: (props: any) => {
          return (
            <Text>
              {props?.getValue() === "boolean"
                ? "True/False"
                : props?.getValue() === "string"
                ? "text"
                : props?.getValue()}
            </Text>
          );
        },
      },
      {
        header: "Date created",
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <>
            {!props?.row?.original.isDefault ? (
              <Menu>
                <MenuButton
                  variant={"ghost"}
                  _active={{
                    bg: "none",
                  }}
                  _hover={{
                    bg: "none",
                  }}
                  minHeight={"0px"}
                  height={"14px"}
                  fontWeight={500}
                  fontSize={"12px"}
                  px={"0px"}
                  py={"0px"}
                  as={Button}
                  rightIcon={<FaCaretDown />}
                >
                  View actions
                </MenuButton>
                <MenuList boxShadow={"lg"}>
                  <MenuItem
                    py={"8px"}
                    onClick={() => {
                      editModalDisclosure.onOpen();
                      setCurrData(props?.row?.original);
                    }}
                  >
                    Edit Attribute
                  </MenuItem>
                  <MenuItem
                    py={"8px"}
                    onClick={() => {
                      deleteModalDisclosure.onOpen();
                      setCurrData(props?.row?.original);
                    }}
                  >
                    Delete Attribute
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : null}
          </>
        ),
      },
    ];
  }, [editModalDisclosure, deleteModalDisclosure]);

  const table = useReactTable({
    data: attributes,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box width={"100%"}>
      <HStack
        justifyContent={"space-between"}
        alignItems={"center"}
        mb={"24px"}
      >
        <Heading as={"h2"} fontSize={"20px"} color={"grays.9"} fontWeight={700}>
          Attributes
        </Heading>

        <ButtonPrimary
          onClick={() => {
            createAttributeDisclosure.onOpen();
          }}
        >
          Create attribute
        </ButtonPrimary>
      </HStack>

      <Box minHeight={"70vh"} className="thinSB">
        {isLoading ? (
          <Loader />
        ) : !isLoading && attributes?.length === 0 ? (
          <Box
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            pt={"76px"}
          >
            <NoEventsIcons />
            <Text
              my={"12px"}
              textAlign={"center"}
              fontWeight={500}
              fontSize={"16px"}
              color={"grays.12"}
            >
              No attributes to show
            </Text>
          </Box>
        ) : (
          <Box height={"70vh"} className="thinSB">
            <TableContainer
              borderWidth={"1px"}
              borderColor="borders.200"
              rounded={"8px"}
            >
              <Table variant={"simple"}>
                <Thead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <Tr key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <Th
                              key={header.id}
                              textTransform="none"
                              color={"grays.12"}
                              fontSize="xs"
                              letterSpacing={"unset"}
                              fontWeight={500}
                              colSpan={header.colSpan}
                              px={"12px"}
                            >
                              <Box display={"flex"}>
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(
                                      header.column.columnDef.header,
                                      header.getContext()
                                    )}

                                {header.column.getCanSort() && (
                                  <VStack spacing="2px" my="auto" ml="2">
                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowUp width="8px" />
                                    </chakra.button>

                                    <chakra.button
                                      onClick={() => {
                                        header.column.toggleSorting();
                                      }}
                                    >
                                      <SortArrowDown width="8px" />
                                    </chakra.button>
                                  </VStack>
                                )}
                              </Box>
                            </Th>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Thead>
                <Tbody>
                  {table.getRowModel().rows.map((row) => (
                    <Tr key={row.id}>
                      {row.getVisibleCells().map((cell, index) => (
                        <Td
                          key={cell.id}
                          fontSize="xs"
                          color="grays.12"
                          fontWeight={"medium"}
                          px={"12px"}
                        >
                          <SwipeIn delay={0.2 * index}>
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </SwipeIn>
                        </Td>
                      ))}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </Box>

      {/* create attribute modal */}
      <CustomModal
        titleUnderLine
        disclosure={createAttributeDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Create new attribute
          </Text>
        }
      >
        <CreateAttribute
          close={createAttributeDisclosure.onClose}
          queryToInvalidate={[
            "contact_data_attributes",
            myCurrentPage,
            dataToShow,
          ]}
        />
      </CustomModal>

      {/* edit atrribute */}
      <CustomModal
        titleUnderLine
        disclosure={editModalDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Edit attribute
          </Text>
        }
      >
        <EditAttribute
          close={editModalDisclosure.onClose}
          data={currData as SingleAttributeProp}
          queryToInvalidate={[
            "contact_data_attributes",
            myCurrentPage,
            dataToShow,
          ]}
        />
      </CustomModal>

      {/* delete atrribute */}
      <CustomModal
        titleUnderLine
        disclosure={deleteModalDisclosure}
        title={<Text fontSize={"20px"} color="grays.8" mt="35px"></Text>}
      >
        <Box pt={"24px"} pb={"40px"}>
          <Text fontWeight={700} fontSize={"20px"} textAlign={"center"}>
            Are you sure you want to delete this attribute?
          </Text>

          <Box pt={"36px"}>
            <ButtonPrimary
              buttonProps={{
                width: "100%",
                maxHeight: "fit-content",
                py: "14px",
                fontSize: "14px",
                colorScheme: "red",
              }}
              onClick={() => {
                deleteAttributeMutation.mutate(currData?.uid);
                deleteModalDisclosure.onClose();
              }}
              isLoading={deleteAttributeMutation.isLoading}
            >
              Delete Attribute
            </ButtonPrimary>
          </Box>
        </Box>
      </CustomModal>
    </Box>
  );
}

export default Attributes;
