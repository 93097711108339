import { useState, FC } from "react";
// import CustomModal from '../UI/CustomModal'
import { Box, FormLabel, HStack, Text, useToast } from "@chakra-ui/react";
// import FormInput from '../UI/form/FormInput'
// import SearchableSelect from "../UI/form/SearchableSelect";
import { useMutation, useQuery } from "@tanstack/react-query";
import { exportContacts } from "../../API/contacts";
import { toaster } from "../../utils/toast";
import { ButtonPrimary } from "../UI/Buttons";
import { getTeammatesData } from "../../API/account/teammates";
import Select from "react-select";
import { ArrowDown } from "../../assets/svgs/svg";

const ExportContact: FC<{
  contactList: Array<string>;
  close?: () => void;
}> = ({ close, contactList }) => {
  // const [nuStr] = useState(contactList.length > 1 ? "contacts" : "contact");
  const [teammates, setTeammates] = useState([]);
  const [email, setEmail] = useState("");
  const toast = useToast();

  const getTeammatesQuery = useQuery(
    ["get_teammates"],
    () => {
      return getTeammatesData();
    },
    {
      onSuccess: (data) => {
        // console.log("we haveeeeee", data);
        let arr = data?.docs.map((item: any) => ({
          value: item.email,
          label: item.first_name,
        }));
        setTeammates(arr);
      },
    }
  );

  const exportMutation = useMutation(exportContacts, {
    onSuccess: (data) => {
      // console.log("we haveeeee", data);

      // const csvString = data;

      // // Parse the CSV string into an array of objects
      // const rows = csvString.split("\n").map((row: string) => {
      //   const [name, email, phoneNumber, dateCreated, lastUpdated] =
      //     row.split(",");
      //   return { name, email, phoneNumber, dateCreated, lastUpdated };
      // });

      // // Convert the array of objects to a CSV string
      // const header = Object.keys(rows[0]).join(",");
      // const csvData = `${header}\n${rows
      //   .map((row: any) => Object.values(row).join(","))
      //   .join("\n")}`;

      // // Create a data URI for the CSV file
      // const csvDataUri = `data:text/csv;charset=utf-8,${encodeURIComponent(
      //   csvData
      // )}`;

      // // Create a link element with the data URI as the href and the download attribute set to the desired filename
      // const downloadLink = document.createElement("a");
      // downloadLink.setAttribute("href", csvDataUri);
      // downloadLink.setAttribute("download", "bodsquare.csv");

      // // Programmatically click the link to trigger the download
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);

      const success = { ...data };
      toaster(toast, success);
      close && close();
    },
  });

  const formatOptionLabel = ({
    value,
    label,
  }: {
    value: string;
    label: string;
  }) => (
    <HStack spacing={"4px"}>
      <Text fontSize={"14px"} fontWeight={500}>
        {label}
      </Text>
      <Box
        border={"1px solid #7489AA1F"}
        bg={"#7489AA1F"}
        px={"10px"}
        py={"2px"}
        borderRadius={"10px"}
      >
        <Text color={"grays.12"} fontWeight={500} fontSize={"10px"}>
          {value}
        </Text>
      </Box>
    </HStack>
  );

  return (
    <Box px="0" mt="24px">
      <Box>
        <FormLabel
          fontWeight={700}
          fontSize="xs"
          color="grays.9"
          mb="8px"
          display="flex"
        >
          Teammate mail
        </FormLabel>

        <Select
          // placeholder={placeholder}
          isDisabled={getTeammatesQuery.isLoading}
          formatOptionLabel={formatOptionLabel}
          options={teammates}
          onChange={(newValue) => {
            setEmail(newValue?.value || "");
          }}
          // onBlur={onBlur}
          // value={value}
          defaultValue={teammates.length > 0 ? teammates[0] : undefined}
          components={{
            IndicatorSeparator: null, // remove default separator
            DropdownIndicator: () => (
              // add custom dropdown indicator button
              <div
                // ref={optionButtonRef}
                // onClick={handleOptionButtonClick}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundColor: '#F4F4F4',
                  borderRadius: "0px 0px 4px 4px",
                  cursor: "pointer",
                  height: "38px",
                  width: "100%",
                  marginRight: ".7em",
                }}
              >
                <ArrowDown fill="#7489AA" />
              </div>
            ),
          }}
        />
      </Box>

      <Box mt="38px" pb="32px" textAlign={"center"} w="full">
        {/* <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
          Export Contacts
        </Text> */}
        <Box maxW={"360px"} mx="auto">
          {/* <Text mb="25px" fontSize={"md"} fontWeight="medium" color="grays.12">
            {`You are about to export ${contactList?.length} ${nuStr}`}
          </Text> */}

          <ButtonPrimary
            buttonProps={{
              width: "50%",
              py: "20px",
              fontSize: "14px",
            }}
            isLoading={exportMutation.isLoading}
            onClick={() => {
              exportMutation.mutate({
                contact_uids: contactList,
                email: email,
              });
            }}
          >
            Confirm
          </ButtonPrimary>
        </Box>
      </Box>
    </Box>
  );
};

export default ExportContact;
