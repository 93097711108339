import { Box, Button, FormLabel, HStack, Text, VStack } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";

import { getWhatsappConnection } from "../../../API/account/channels";
import { getSenderIDLists } from "../../../API/account/senderId";
import { ArrowDown } from "../../../assets/svgs/svg";
import { createCampaignAtom } from "../../../atoms/campaigns";
import FormInput from "../../UI/form/FormInput";
// import SearchableSelect from "../../UI/form/SearchableSelect";
import { DropDownMenu } from "../../Ziz_components/drop-down/dropDown";

interface listProps {
  phone_number: string;
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  display_name: string;
  status: string;
  created_at: Date;
  sender_number: string;
  updated_at: Date;
}

const SettingssStage = () => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);

  const [MenuLabel, setMenuLabel] = useState({});
  const [onselectLabel, setOnSelectLabel] = useState(false);

  const { data } = useQuery(
    ["senderIDData"],
    currentStageData?.data?.channel === "Whatsapp"
      ? getWhatsappConnection
      : getSenderIDLists
  );
  const senderIDs = useMemo(() => {
    return (data?.docs || data)?.map((sender: listProps) => {
      if (currentStageData?.data.channel === "Whatsapp") {
        // console.log(sender);
        return {
          label: sender?.phone_number,
          value: sender?.phone_number,
          optionLabel: sender?.display_name,
          status: sender?.status,
        };
      }
      return {
        label: sender?.name,
        value: sender?.uid,
      };
    });
  }, [currentStageData?.data.channel, data]);

  return (
    <Box w="full" maxW={"480px"} mx="auto">
      <VStack align={"flex-start"} spacing="16px">
        <FormInput
          type="text"
          label="Campaign name"
          for="Campaign name"
          inputProps={{
            placeholder: "Enter campaign name",
            value: currentStageData?.data?.campaign_name,
            onChange: (e) => {
              setCurrentStageData((prevValue) => {
                return {
                  ...prevValue,
                  data: {
                    ...prevValue.data,
                    campaign_name: e.target.value,
                  },
                };
              });
            },
          }}
        />
        {currentStageData?.data?.channel === "Email" && (
          <FormInput
            type="text"
            label="Subject"
            for="Subject"
            inputProps={{
              placeholder: "Enter campaign subject",
              value: currentStageData?.data?.subject,
              onChange: (e) => {
                setCurrentStageData((prevValue) => {
                  return {
                    ...prevValue,
                    data: {
                      ...prevValue.data,
                      subject: e.target.value,
                    },
                  };
                });
              },
            }}
          />
        )}

        <Box w="full">
          {/* form */}
          <FormLabel fontWeight="700" fontSize="14px">
            Sender
          </FormLabel>
          {/* @ts-ignore */}
          <DropDownMenu
            menuOptionStyle={{
              paddingTop: ".4em",
              zIndex: "1",
              border: "1px solid rgba(0, 102, 255, 0.16)",
            }}
            MenubtnStyle={{
              width: "100%",
              border: "1px solid rgba(0, 102, 255, 0.16)",
              color: "#171D26",
            }}
            btnVariant="primary2"
            floatLeft
            svgIcon={
              <Box>
                <ArrowDown fill="#171D26" />
              </Box>
            }
            px="4"
            listOptionRole={"button"}
            menuTitle={
              onselectLabel
                ? MenuLabel
                : currentStageData?.data.channel === "Whatsapp"
                ? "Select number"
                : "Select name"
            }
            optionLabel={true}
            menuListOptions={senderIDs}
            onOptionClick={(e: any) => {
              // console.log(e.target);
              setOnSelectLabel(true);
              // @ts-ignore
              setMenuLabel(e.target.innerText);
              setCurrentStageData((prevValue) => {
                if (currentStageData.data.channel === "SMS") {
                  return {
                    ...prevValue,
                    data: {
                      ...prevValue.data,
                      sms_sender_uid: e.target.dataset.key,
                    },
                  };
                }
                if (currentStageData.data.channel === "Whatsapp") {
                  return {
                    ...prevValue,
                    data: {
                      ...prevValue.data,
                      sender_number: e.target.dataset.key,
                    },
                  };
                }
                return {
                  ...prevValue,
                  data: {
                    ...prevValue.data,
                    email_sender_uid: e.target.dataset.key,
                  },
                };
              });
            }}
          />

          {currentStageData?.data?.channel === "Email" && (
            <FormInput
              type="email"
              label="Sender email"
              for="Sender email"
              inputProps={{
                placeholder: "user@hostmail.com",
                value: currentStageData?.data?.email,
                onChange: (e) => {
                  setCurrentStageData((prevValue) => {
                    return {
                      ...prevValue,
                      data: {
                        ...prevValue.data,
                        email: e.target.value,
                      },
                    };
                  });
                },
              }}
            />
          )}

          {currentStageData?.data?.channel !== "Whatsapp" &&
            senderIDs?.length === 0 && (
              <HStack
                mt="12px"
                py="8px"
                px={"16px"}
                bg="rgba(251, 167, 5, 0.08)"
                rounded={"2px"}
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M14.6663 8.0026C14.6663 11.6853 11.6817 14.6693 7.99967 14.6693C4.31767 14.6693 1.33301 11.6853 1.33301 8.0026C1.33301 4.32127 4.31767 1.33594 7.99967 1.33594C11.6817 1.33594 14.6663 4.32127 14.6663 8.0026Z"
                    fill="#FBA705"
                  />
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.57975 8.42062C8.57975 8.74196 8.31775 9.00396 7.99642 9.00396C7.67509 9.00396 7.41309 8.74196 7.41309 8.42062V5.47396C7.41309 5.15262 7.67509 4.89062 7.99642 4.89062C8.31775 4.89062 8.57975 5.15262 8.57975 5.47396V8.42062ZM7.41636 10.5365C7.41636 10.2151 7.67702 9.95312 7.99636 9.95312C8.32502 9.95312 8.58636 10.2151 8.58636 10.5365C8.58636 10.8578 8.32502 11.1198 8.00302 11.1198C7.67969 11.1198 7.41636 10.8578 7.41636 10.5365Z"
                    fill="white"
                  />
                </svg>

                <Text fontSize={"10px"} color="grays.8" fontWeight={500}>
                  You currently don’t have any SENDER ID created,{" "}
                  <Link to="/account/senderIDs?new=true">
                    <Text as="span" color="primary.500" textDecor={"underline"}>
                      click here
                    </Text>
                  </Link>{" "}
                  to create one. Your campaign will be saved as a draft to
                  continue.
                </Text>
              </HStack>
            )}
        </Box>
      </VStack>

      <Button
        isDisabled={
          !(
            currentStageData?.data?.sms_sender_uid ||
            currentStageData?.data?.email_sender_uid ||
            currentStageData?.data?.sender_number
          ) ||
          senderIDs?.length === 0 ||
          (currentStageData?.data?.channel === "Email" &&
            (!currentStageData?.data?.email ||
              !currentStageData?.data?.subject))
        }
        onClick={() => {
          setCurrentStageData((prevData) => {
            return {
              ...prevData,
              currentStage: "Send or schedule",
            };
          });
        }}
        w="full"
        mt={"40px"}
        mb="10px"
        colorScheme={"primary"}
      >
        Continue to send or schedule
      </Button>
    </Box>
  );
};

export default SettingssStage;
