import { useState, useEffect } from "react";
import {
  Center,
  Flex,
  HStack,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
  MenuDivider,
  Icon,
  IconButton,
  Link as ChakraLink,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import { IconLogo } from "../../icons/Logo";
import { Link, NavLink, useLocation } from "react-router-dom";
import ProfileCircle from "../../icons/ProfileCircle";
import ChannelsIcon from "../../icons/ChannelsIcon";
import CustomMenu from "../UI/CustomMenu";
import { HiChevronDown } from "react-icons/hi";
import { logoutHandler } from "../../utils/functions";
import { getProfileData } from "../../API/account/profile";
import { useQuery } from "@tanstack/react-query";
import { useSetRecoilState } from "recoil";
import { profileAtom } from "../../atoms/user";
import { getWalletBalance } from "../../API/wallet";
import { TopUpIcon, Wallet } from "../../assets/svgs/svg";
import TopUp from "../Wallet/TopUpModal";
import CustomButton from "../Ziz_components/Button.component";
import { useNavigate } from "react-router-dom";
import { getCurrentPlan } from "../../API/account/billings";

const Navbar = () => {
  const [bal, setBal] = useState<number>(0);
  let activeStyle = {
    backgroundColor: "rgba(0, 102, 255, 0.04)",
    borderRadius: "4px",
  };
  const { pathname } = useLocation();

  const setProfileData = useSetRecoilState(profileAtom);

  // fetch profile info
  const { data } = useQuery(["profileData"], getProfileData, {
    onSuccess(data) {
      setProfileData(data);
    },
  });
  const { isLoading, data: walletBal } = useQuery(
    ["walletBal"],
    getWalletBalance
  );
  const { data: currentPlan } = useQuery(["currentPlan"], getCurrentPlan);

  const TopUpDisclusure = useDisclosure();
  const navigate = useNavigate();

  const navLinks = [
    {
      title: "Switch Business",
      path: "/switch-business",
      icon: <ProfileCircle />,
    },
    {
      title: "Account",
      path: "/account/profile",
      icon: <ProfileCircle />,
    },
    {
      title: "Wallet",
      path: "/wallet",
      icon: <ChannelsIcon />,
    },

    {
      title: "Transaction History",
      path: "/transactionHistory",
      icon: <ProfileCircle />,
    },
  ];

  useEffect(() => {
    // @ts-ignore
    setBal(walletBal?.exchange_rate?.business_amount?.toFixed(2));
  }, [walletBal]);

  return (
    <Flex
      display={!pathname.includes("/getStarted") ? "flex" : "none"}
      bg="white"
      w="full"
      position={"relative"}
      zIndex={5}
      boxShadow={"sm"}
    >
      <Flex
        maxW="1440px"
        mx="auto"
        w="full"
        px={{ base: "10px", md: "40px" }}
        py="12px"
      >
        {/* logo */}
        <Center bg="primary.500" borderRadius={"4.5px"} w="32px" h="32px">
          <IconLogo />
        </Center>

        {/* urls */}
        <HStack
          display={{ base: "none", md: "flex" }}
          ml="40px"
          spacing={"8px"}
        >
          {/* conversations */}
          <NavLink
            to="/conversations"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <Text
              color="grays.9"
              fontSize={"xs"}
              // bg='white'
              py="8px"
              px="12px"
              borderRadius={"4px"}
              fontWeight="500"
              _hover={{
                backgroundColor: "rgba(0, 102, 255, 0.04)",
              }}
            >
              Conversations
            </Text>
          </NavLink>

          {/* campaign */}
          <CustomMenu
            buttonTitle={
              <HStack spacing={2} px="12px">
                <Text
                  color="grays.9"
                  fontSize={"xs"}
                  py="8px"
                  borderRadius={"4px"}
                  fontWeight="500"
                >
                  Campaign
                </Text>

                <Icon as={HiChevronDown} />
              </HStack>
            }
            buttonProps={{
              _hover: {
                backgroundColor: "rgba(0, 102, 255, 0.04)",
                borderRadius: "4px",
              },
            }}
            itemsData={[
              {
                title: "New campaign",
                itemProps: {
                  fontSize: "xs",
                },
                isLink: true,
                href: "/campaigns/new",
              },
              {
                title: "Manage campaigns",
                itemProps: {
                  fontSize: "xs",
                },
                isLink: true,
                href: "/campaigns",
              },
            ]}
          />

          {/* contacts */}
          <CustomMenu
            buttonTitle={
              <HStack spacing={2} px="12px">
                <Text
                  color="grays.9"
                  fontSize={"xs"}
                  py="8px"
                  borderRadius={"4px"}
                  fontWeight="500"
                >
                  Contacts
                </Text>

                <Icon as={HiChevronDown} />
              </HStack>
            }
            buttonProps={{
              _hover: {
                backgroundColor: "rgba(0, 102, 255, 0.04)",
                borderRadius: "4px",
              },
            }}
            itemsData={[
              {
                title: "Manage contacts",
                itemProps: {
                  fontSize: "xs",
                },
                isLink: true,
                href: "/contacts",
              },
              {
                title: "Manage lists",
                itemProps: {
                  fontSize: "xs",
                },
                isLink: true,
                href: "/account/contactdata/lists",
              },
              {
                title: "Import History",
                itemProps: {
                  fontSize: "xs",
                },
                isLink: true,
                href: "account/contactdata/importhistory",
              },
            ]}
          />

          {/* reports */}
          <NavLink
            to="/reports"
            style={({ isActive }) => (isActive ? activeStyle : undefined)}
          >
            <Text
              color="grays.9"
              fontSize={"xs"}
              // bg='white'
              py="8px"
              px="12px"
              borderRadius={"4px"}
              fontWeight="500"
              _hover={{
                backgroundColor: "rgba(0, 102, 255, 0.04)",
              }}
            >
              Reports
            </Text>
          </NavLink>
        </HStack>

        {/* Profile */}
        <HStack ml="auto" columnGap={"1em"}>
          <Box>
            {isLoading && <Text fontSize={"10px"}>Loading bal...</Text>}
            {!isLoading && (
              <Box
                whiteSpace="nowrap"
                bg="rgba(0, 102, 255, 0.04)"
                padding={"4px 10px"}
                borderRadius="4px"
                display={"flex"}
                alignItems="center"
                columnGap={".5em"}
              >
                <TopUpIcon onClick={TopUpDisclusure.onOpen} />
                <Box alignItems="center" display="flex" columnGap={".14em"}>
                  <Wallet />
                  <Link to="/wallet">
                    <Text
                      // _hover={{ bg: "#f1f1f1", color: "#000" }}
                      fontSize={"12px"}
                      // boxShadow="2xl"
                      px="2"
                      fontWeight={500}
                      cursor="pointer"
                    >
                      {"N "}
                      {bal}
                    </Text>
                  </Link>
                </Box>
              </Box>
            )}
          </Box>
          <Flex columnGap={".3em"}>
            <Text fontSize={"12px"} fontWeight="500" color="#7489AA">
              {"Subscription:"}
            </Text>
            <Text
              fontSize={"12px"}
              fontWeight={500}
              as="span"
              color="#171D26"
              cursor={"pointer"}
              onClick={() => navigate(`/account/pricing`)}
            >
              {currentPlan?.plan?.name[0] + currentPlan?.plan?.name?.slice(1) ||
                "Free plan"}
            </Text>
          </Flex>
          <CustomButton
            onClick={() => navigate("/account/billings/plans")}
            size="xs"
            p="1.4em 1.5em"
            border="none"
            children="Upgrade plan"
          />

          <Flex columnGap=".3em">
            <ChakraLink href="https://guides.bodsquare.com" isExternal>
              <IconButton
                aria-label="Guides"
                rounded="full"
                fontSize={"sm"}
                size="sm"
                variant={"ghost"}
                colorScheme="primary"
                icon={
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M18 12C18 15.3137 15.3137 18 12 18C8.6863 18 6 15.3137 6 12C6 8.6863 8.6863 6 12 6C15.3137 6 18 8.6863 18 12Z"
                      stroke="#7489AA"
                      strokeWidth="1.24138"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.1387 10.3464C10.1387 10.3464 10.3456 8.48438 12.0007 8.48438C13.6559 8.48438 13.8628 9.51886 13.8628 10.3464C13.8628 10.9682 13.5124 11.5901 12.8116 11.8608C12.3853 12.0255 12.0007 12.3721 12.0007 12.8292V13.0361"
                      stroke="#7489AA"
                      strokeWidth="1.24138"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4135 15.3122C12.4135 15.5407 12.2282 15.726 11.9997 15.726C11.7712 15.726 11.5859 15.5407 11.5859 15.3122C11.5859 15.0837 11.7712 14.8984 11.9997 14.8984C12.2282 14.8984 12.4135 15.0837 12.4135 15.3122Z"
                      stroke="#7489AA"
                      strokeWidth="0.827586"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                }
              />
            </ChakraLink>
            <Menu autoSelect={false}>
              <MenuButton>
                <HStack spacing={"8px"}>
                  <Avatar
                    w="24px"
                    h="24px"
                    name={data?.name ?? ""}
                    bg="rgba(0, 102, 255, 0.12)"
                    color="primary.500"
                    size="xs"
                    fontWeight={500}
                  />

                  <Text fontSize={"xs"} color="grays.9">
                    {data?.name}
                  </Text>
                </HStack>
              </MenuButton>
              <MenuList minW="150px" fontSize={"xs"}>
                {navLinks?.map((data) => {
                  return (
                    <Link key={data.path} to={data.path}>
                      <MenuItem>{data.title}</MenuItem>
                    </Link>
                  );
                })}

                <MenuDivider />

                <MenuItem onClick={logoutHandler}>Sign out</MenuItem>
              </MenuList>
            </Menu>
          </Flex>
        </HStack>
      </Flex>
      <TopUp disclosure={TopUpDisclusure} data={walletBal} />
    </Flex>
  );
};

export default Navbar;
