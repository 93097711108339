import React from 'react'

const EmailIcon = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="16" cy="16" r="13" fill="url(#paint0_linear_2851_60756)" />
            <path d="M19.334 21.6693H12.6673C10.6673 21.6693 9.33398 20.6693 9.33398 18.3359V13.6693C9.33398 11.3359 10.6673 10.3359 12.6673 10.3359H19.334C21.334 10.3359 22.6673 11.3359 22.6673 13.6693V18.3359C22.6673 20.6693 21.334 21.6693 19.334 21.6693Z" stroke="white" strokeWidth="1.33333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M19.3327 14L17.246 15.6667C16.5593 16.2133 15.4327 16.2133 14.746 15.6667L12.666 14" stroke="white" strokeWidth="1.33333" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
            <defs>
                <linearGradient id="paint0_linear_2851_60756" x1="16" y1="3" x2="16" y2="29" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7BAFFD" />
                    <stop offset="0.541667" stopColor="#5297FE" />
                    <stop offset="1" stopColor="#0066FF" />
                </linearGradient>
            </defs>
        </svg>

    )
}

export default EmailIcon