import API from ".";
import { SingleContactProps } from "../utils/generalProps";

export const getAllContacts = async (data: {
  page: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/contact/list?page=${data?.page}&limit=${data?.limit}`
  );

  return response.data.data;
};

export const createSingleContact = async (data: {
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}) => {
  const response = await API.post(`/contact/create`, data);

  return response.data;
};

export const updateSingleContact = async (data: {
  uid: string;
  attr: string;
  newValue: any;
  attr2?: string;
  newValue2?: any;
}) => {
  if (data.attr2 && data.newValue2) {
    const response = await API.post(`/contact/edit`, {
      contact_uid: data.uid,
      [data.attr]: data.newValue,
      [data.attr2]: data.newValue2,
    });

    return response.data;
  } else {
    const response = await API.post(`/contact/edit`, {
      contact_uid: data.uid,
      [data.attr]: data.newValue,
    });

    return response.data;
  }
};

export const getSingleContact = async (uid: string | undefined) => {
  const response = await API.get(`/contact/show/${uid}`);

  return response.data.data as SingleContactProps;
};

export const deleteSingleContact = async (uid: string | undefined) => {
  const response = await API.delete(`/contact/delete/${uid}`);

  return response.data as SingleContactProps;
};

export const deleteBulkContacts = async (data: any) => {
  const response = await API.delete(`/contact/delete/bulk`, {
    data,
  });

  return response.data;
};

export const importContacts = async (data: any) => {
  const response = await API.post(`/contact-list/import`, data);

  return response.data;
};

export const getListsContactsIsIn = async (uid: string) => {
  const response = await API.get(`/contact-list/contact/list/${uid}`);

  return response.data.data;
};
export const getImportHistory = async (data: {
  page: string | number;
  limit?: string | number;
}) => {
  const response = await API.get(
    `/contact-list/import-history/list?page=${data?.page}&limit=${data?.limit}`
  );

  return response.data.data;
};

export const exportContacts = async (data: {
  contact_uids: Array<string>;
  email: string;
}) => {
  const res = await API.post(`/contact/export`, data);

  return res.data;
};
export const addContactsToListFn = async (data: {
  list_uid: string;
  contact_uids: Array<string>;
}) => {
  const res = await API.post(`/contact-list/map/bulk`, data);

  return res.data;
};

// attributes
export const createContactAttributes = async (data: {
  name: string;
  data_type: string;
  description: string;
}) => {
  const res = await API.post(`/contact/attributes/create`, data);
  return res.data;
};
export const getContactAttributes = async () => {
  const res = await API.get(`/contact/attributes/list?default=false`);
  return res.data;
};
export const getContactAttributesFull = async (data: {
  page?: string | number;
  limit?: string | number;
}) => {
  const res = await API.get(
    `/contact/attributes/list?limit=${data.limit}&page=${data.page}`
  );
  return res.data.data;
};
export const editContactAttribute = async (data: {
  uid: string;
  name: string;
  description: string;
}) => {
  const res = await API.patch(`/contact/attributes/${data.uid}/edit`, {
    name: data.name,
    description: data.description,
  });

  return res.data;
};
export const deleteContactAttribute = async (uid?: string) => {
  const res = await API.delete(`/contact/attributes/${uid}/delete`);
  return res.data;
};

// events
export const getContactDataEvents = async () => {
  const res = await API.get(`/contact/events/list`);
  return res.data.data;
};
export const editContactDataEvent = async (obj: {
  uid: string;
  data: { name: string };
}) => {
  const res = await API.patch(`/contact/events/edit/${obj.uid}`, obj.data);

  return res.data;
};
export const deleteContactDataEvent = async (uid: string) => {
  const res = await API.delete(`/contact/events/delete/${uid}`);

  return res.data;
};

// segments
export const createSegments = async (data: {
  name: string;
  contact_uids: Array<string>;
  condition?: {
    query: {
      operator: "AND";
      value: {
        field: "first_name";
        operator: "^";
        value: "u";
      }[];
    };
  };
}) => {
  const res = await API.post(`/segments/create`, data);

  return res.data;
};
export const getSegments = async () => {
  const res = await API.get(`/segments/list`);

  return res.data.data;
};
export const getSingleContactSegments = async (uid: string) => {
  const res = await API.get(`/contact/${uid}/segments`);

  return res.data.data.docs;
};

// export const editSingleSegment = async (data) => {
//   const res = await API.put(`/segments/edit/${data.uid}`, {

//   })
// }

export const deleteSingleSegemnt = async (uid: string) => {
  const res = await API.delete(`/segments/delete/${uid}`);

  return res.data;
};

// search contact
export const searchContactsFn = async (
  data:
    | {
        query: {
          operator: string;
          value: string;
          field?: string;
        };
      }
    | {
        query: {
          operator: string;
          value: [
            {
              field: string;
              operator: string;
              value: string;
            }
          ];
        };
      }
) => {
  const res = await API.post(`/contact/search`, data);

  return res.data.data;
};

// OPEN API
export const getContactOpenAPIEvents = async (uid: string) => {
  const res = await API.get(`/contact/${uid}/events`);

  return res.data.data;
};
