import { Box, Flex, HStack } from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FC, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { getSingleConversation } from "../../../API/conversations";
import { chatAtom } from "../../../atoms/conversation";
import Loader from "../../UI/Loader";
import EmptyChatData from "./EmptyChatData";
import UserInfo from "./UserInfo";
import ViewAndSendMessages from "./ViewAndSendMessages";

const ChatData: FC<{ close?: () => void }> = ({ close }) => {
  const [searchParams] = useSearchParams();
  const uid = searchParams?.get("uid");

  const setChatData = useSetRecoilState(chatAtom);

  const { isLoading, data } = useQuery(
    [`singleChatData${uid}`, uid],
    () => {
      return getSingleConversation(uid);
    },
    {
      enabled: !!uid,
      onSuccess(data: any) {
        setChatData(data);
      },
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    const client = new WebSocket(
      `wss://staging-api.bodsquare.com/${data?.conversation?.company_uid}`
    );

    client.onmessage = function (e) {
      if (typeof e.data === "string") {
        queryClient.invalidateQueries(["allConversations"]);
        queryClient.invalidateQueries([
          `singleChatData${searchParams?.get("uid")}`,
        ]);
      }
    };

    return () => {
      client.close();
    };
  }, [data?.conversation?.company_uid, queryClient, searchParams]);

  return (
    <HStack
      w="full"
      h={"full"}
      maxHeight={"100%"}
      overflow={"hidden"}
      alignItems={"flex-start"}
    >
      {/* 1 */}
      <Flex
        flexDir={"column"}
        w="full"
        bg="white"
        rounded="4px"
        flexBasis={"63%"}
        h={"calc(100vh - 104px)"}
        borderRightWidth={"1px"}
        borderRightColor="grays.0"
      >
        {searchParams?.get("uid") && (
          <>
            {isLoading && <Loader height="80vh" />}

            {!isLoading && data && (
              <ViewAndSendMessages
                contact={data?.conversation?.contact}
                close={close}
                conversation_uid={data?.conversation?.uid}
                status={data?.conversation?.status}
              />
            )}
          </>
        )}

        {!searchParams?.get("uid") && <EmptyChatData />}
      </Flex>

      {/* 2 */}
      <Box
        display={{ base: "none", md: "block" }}
        w="full"
        h="full"
        flexBasis={"37%"}
        pl="12px"
        pr={"20px"}
      >
        {!isLoading && data && <UserInfo data={data?.conversation?.contact} />}
      </Box>
    </HStack>
  );
};

export default ChatData;
