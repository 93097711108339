import axios from "axios";
import { getCookie } from "cookies-next";
import { ENV } from "../helpers";

// import

const tokenAuth = { Authorization: `Bearer ${getCookie("USER_TOKEN")}` };
const emptyHeader = {};

const headerData = getCookie("USER_TOKEN") ? tokenAuth : emptyHeader;

export default axios.create({
  baseURL:
    process.env.NODE_ENV === "development" ? ENV.API_STAGING_URL : ENV.API_URL,
  headers: headerData,
});
