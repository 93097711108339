import styled from "styled-components";
import { Link } from "react-router-dom";

export const DropDownListWrapper = styled.div`
  background-color: ${({ BGColor }) => BGColor || `#f3f3f3`};
  display: flex;
  padding: ${({ padding }) => padding || `.5em`};
  gap: 1em;
`;

export const DropDownWrapper = styled.div`
  position: relative;
  button {
    /* background: ${({ menuBG }) => menuBG || `#0066FF`};
    border: ${({ menuBorder }) => menuBorder || `none`};
    text-decoration: none;
    font-size: ${({ menuFontSize }) => menuFontSize || `14px`};
    color: ${({ menuColor }) => menuColor || `#777`}; */
    /* padding: ${({ menuPad }) => menuPad || `0em`}; */
    cursor: pointer;
    &:hover {
      color: ${({ menuColorOnHover }) => menuColorOnHover || ``};
    }
  }

  & > div {
    position: absolute;
    min-width: max-content;
    left: ${({ floatLeft }) => floatLeft || `0em`};
    right: ${({ floatRight }) => floatRight};
    top: 120%;
    width: 100%;
    background-color: ${({ optionColor }) => optionColor || `white`};
    /* padding: ${({ optionPadding }) => optionPadding || `0.75em`}; */
    border-radius: 0.3em;
    box-shadow: ${({ shadow }) => shadow || `0 2px 5px 0 rgba(0, 0, 0, .1)`};
    opacity: 0;
    transform: translateY(6px);
    pointer-events: none;
    transition: opacity 150ms ease-in, transform 150ms ease-in;
    display: grid;
    row-gap: ${({ rowGap }) => rowGap || `.2em`};
    button>div>svg {
      padding-left: 0.3em !important;
      color: black;
      height: 16.25px;
      width: 8.75px;
    }
    li {
      list-style-type: none;
      font-size: 12px;
      display: grid;
      padding: 0.3em 0.9em;

      &:hover{
        background: rgba(116, 137, 170, 0.08);
      }

      & > a {
        text-decoration: none;
        font-size: ${({ OptionLinkFont }) => OptionLinkFont || `14px`};
        color: ${({ OptionLinkColor }) => OptionLinkColor || `black`};
      }
    }
  }

  /* & > button:focus + div{
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
    } */
`;

export const OptionLink = styled(Link)`
  text-decoration: none;
  cursor: pointer;
`;
