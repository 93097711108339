import { Box, Button, HStack, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'

const handleCopy = (e: any, i: string) => {
    e.target.textContent = 'Copied!';
    e.target.style.background = '#0066ff';
    e.target.style.color = '#fff';
    navigator.clipboard.writeText(i);
    setTimeout(function Copy() {
        e.target.textContent = 'Copy';
        e.target.style.background = '#fff';
        e.target.style.color = '#0066ff';

    }, 2000);
};

const Share: FC<{ shareData: any, fnt?: string }> = ({ shareData, fnt }) => {

    return (
        <>
            <Text my={2} fontWeight={fnt === "bigger" ? 700 : 500} fontSize={fnt === "bigger" ? "14px" : "10px"} color={"grays.12"}>Share via QR Code:</Text>
            <Box borderWidth={'1px'} borderColor='borders.100' rounded='4px' w='full'>
                <HStack justifyContent={"space-around"}>
                    <Box id="img-copy"><Image h={"98px"} w={"98px"} src={shareData?.share_details?.qrcode} alt="barcode" /></Box>
                    <Button size="sm" colorScheme={"primary"} variant={"outline"}>Share QR code</Button>
                </HStack>
            </Box>
            <Text my={2} fontWeight={fnt === "bigger" ? 700 : 500} fontSize={fnt === "bigger" ? "14px" : "10px"} color={"grays.12"}>Share via Link:</Text>
            <Box borderWidth={'1px'} borderColor='borders.100' rounded='4px' w='full' mb={4} px={2}>
                <HStack justifyContent={"space-around"} py={2}>
                    <Text fontSize={"12px"} color={"grays.12"}>{shareData?.short_link}</Text>
                    <Button w={"70px"} size="xs" px={4} colorScheme={"primary"} variant={"outline"} onClick={(e) => handleCopy(e, shareData?.short_link)}>Copy</Button>
                </HStack>
            </Box>
            <Text my={2} fontWeight={fnt === "bigger" ? 700 : 500} fontSize={fnt === "bigger" ? "14px" : "10px"} color={"grays.12"}>Share via Text:</Text>
            <Box borderWidth={'1px'} borderColor='borders.100' rounded='4px' w='full' mb={8} px={2}>
                <Text fontSize={"12px"} color={"grays.12"} p={2}>Text #{shareData?.keyword} to {shareData?.channel_value} to join {shareData?.name} whatsapp list</Text>
            </Box>
        </>
    )
}

export default Share