import React, { useState } from "react";
import {
  Box,
  Text,
  HStack,
  chakra,
  VStack,
  Button,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";
import CustomPhoneInput from "../../../components/UI/form/CustomPhoneInput";
import FormInput from "../../../components/UI/form/FormInput";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { addWhatsapp } from "../../../API/account/channels";
import { toaster } from "../../../utils/toast";
const WhatsAppConnect = () => {
  const [businessPhoneNumber, setBusinessPhoneNumber] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [businessId, setBusinessId] = useState("");
  const queryClient = useQueryClient();
  const toast = useToast();
  const navigate = useNavigate();

  const { isLoading, mutate } = useMutation(addWhatsapp, {
    onSuccess(data) {
      const success = { ...data };
      queryClient.invalidateQueries(["listCards"]);
      toaster(toast, success);
      navigate("/account/channels");
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
    retry: false,
  });
  return (
    <Box w="full" h="full">
      <Link to="/account/channels">
        {" "}
        <HStack spacing={"4px"}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5L8.5 10.25L12 7"
              stroke="#7489AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text fontSize={"xs"} color="grays.12">
            Back to Channels
          </Text>
        </HStack>
      </Link>
      <Text mt={"20px"} fontSize={"20px"} color="grays.9" fontWeight={"700"}>
        Whatsapp Business API
      </Text>
      <Stack justifyContent="center" alignItems={"center"}>
        <Text my={"8"} fontSize={"16px"} color="grays.9" fontWeight={"700"}>
          Activate Whatsapp Business API
        </Text>
        <chakra.form
          w={"360px"}
          onSubmit={(e) => {
            e.preventDefault();
            mutate({
              phone_number: businessPhoneNumber,
              display_name: displayName,
              facebook_bmid: displayName,
            });
          }}
          mt="24px"
        >
          <VStack align={"flex-start"} spacing="20px">
            <CustomPhoneInput
              label="Whatsapp mobile number"
              value={businessPhoneNumber}
              onChange={(phone) => {
                setBusinessPhoneNumber(phone);
              }}
            />
            <FormInput
              label="Whatsapp display name"
              type="text"
              for="Whatsapp display name"
              inputProps={{
                placeholder: "Enter Whatsapp display name",
                onChange: (e) => {
                  setDisplayName(e.target.value);
                },
                value: displayName,
              }}
              formControlProps={{
                isRequired: true,
              }}
            />
            <FormInput
              label="Whatsapp business ID"
              type="text"
              for="Whatsapp business ID"
              inputProps={{
                placeholder: "Enter Whatsapp business ID",
                onChange: (e) => {
                  setBusinessId(e.target.value);
                },
                value: businessId,
              }}
              formControlProps={{
                isRequired: true,
              }}
            />
          </VStack>

          <Button
            isLoading={isLoading}
            type="submit"
            w="full"
            rounded="4px"
            bg="primary.500"
            _disabled={{
              bg: "primary.400",
            }}
            _hover={{
              bg: "primary.400",
            }}
            _focus={{
              bg: "primary.400",
            }}
            color={"white"}
            mt="40px"
            fontSize={"sm"}
          >
            Save
          </Button>
        </chakra.form>
      </Stack>
    </Box>
  );
};

export default WhatsAppConnect;
