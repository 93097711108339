import React, { FC } from "react";

export const ReadIcon: FC<any> = (props) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4995 0.109375L13.9985 0.59615L8.59892 8.00042L6.41016 5.83877L7.33707 4.56687L8.59892 5.30281L13.4995 0.109375ZM9.11925 0L9.61822 0.486775L4.21865 7.89105L0.5 4.23358L1.33033 3.42354L4.21865 5.19343L9.11925 0Z"
        fill="black"
        fillOpacity="0.24"
      />
    </svg>
  );
};
