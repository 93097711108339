import React from "react";
import {
  Box,
  Button,
  Flex,
  Text,
  useDisclosure,
  HStack,
  // chakra,
  Stack,
} from "@chakra-ui/react";
import AddNewList from "../../../components/account/lists/AddNewList";
import TemplatesTable from "../../../components/account/Templates/TemplatesTable";
import CustomModal from "../../../components/UI/CustomModal";
import Loader from "../../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import { Link, useParams } from "react-router-dom";
import { useState } from "react";
import CustomPaginaton from "../../../components/UI/CustomPaginaton";
// import ChakraSelect from "../../../components/UI/form/ChakraSelect";
import { useTemplateData } from "../../../hooks/useTemplateData";

const AllTemplates = () => {
  const disclosure = useDisclosure();
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [globalFilter, setGlobalFilter] = useState("");
  const [dataToShow, setDataToShow] = useState(10);
  const params = useParams();

  // console.log("params is", params.all);

  const { isLoading, data } = useTemplateData(myCurrentPage, dataToShow);

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.whatsappTemplates?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <Box w="full" h="full">
      <Link to="/account/templates">
        <HStack spacing={"4px"} ml={"-8px"}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5L8.5 10.25L12 7"
              stroke="#7489AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text fontSize={"xs"} color="grays.12">
            Back to Templates
          </Text>
        </HStack>
      </Link>
      <Flex mt={"20px"}>
        <Box>
          <Text fontSize={"20px"} color="grays.9" fontWeight={700} mb={"6px"}>
            {params.all === "Whatsapp" ? `WhatsApp Templates` : "SMS Templates"}
          </Text>
          <Text fontSize={"12px"} color="grays.12" fontWeight={500}>
            You can manage your {params.all === "Whatsapp" ? "whatsapp" : "sms"}{" "}
            templates here.
          </Text>
        </Box>
        <Button
          colorScheme={"primary"}
          ml="auto"
          size={"sm"}
          isDisabled={isLoading}
        >
          <Link to="create"> Create Template</Link>
        </Button>
      </Flex>

      <Box mt="32px">
        {/* Empty */}

        {params.all === "Whatsapp"
          ? !isLoading &&
            data?.whatsappTemplates?.docs?.length === 0 && (
              <Stack mt="20" justifyContent={"center"} alignItems={"center"}>
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 96 96"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M86 33.48V63.28C86 63.88 85.8 64.48 85.28 65C79.48 70.84 69.16 81.24 63.24 87.2C62.72 87.76 62.04 88 61.36 88H29.48C18.72 88 10 79.28 10 68.52V33.48C10 22.72 18.72 14 29.48 14H66.52C77.28 14 86 22.72 86 33.48Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M33.1602 25.16C31.4802 25.16 30.1602 23.8 30.1602 22.16V11C30.1602 9.36 31.4802 8 33.1602 8C34.8402 8 36.1602 9.36 36.1602 11V22.12C36.1602 23.8 34.8402 25.16 33.1602 25.16Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M62.8398 25.16C61.1598 25.16 59.8398 23.8 59.8398 22.16V11C59.8398 9.32 61.1998 8 62.8398 8C64.5198 8 65.8398 9.36 65.8398 11V22.12C65.8398 23.8 64.5198 25.16 62.8398 25.16Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M59.1195 50.8438H29.4395C27.7595 50.8438 26.4395 49.4837 26.4395 47.8438C26.4395 46.2038 27.7995 44.8438 29.4395 44.8438H59.1195C60.7995 44.8438 62.1195 46.2038 62.1195 47.8438C62.1195 49.4837 60.7995 50.8438 59.1195 50.8438Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M47.9995 65.6797H29.4395C27.7595 65.6797 26.4395 64.3197 26.4395 62.6797C26.4395 60.9997 27.7995 59.6797 29.4395 59.6797H47.9995C49.6795 59.6797 50.9995 61.0397 50.9995 62.6797C50.9995 64.3197 49.6795 65.6797 47.9995 65.6797Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M85.9998 63.2787C85.9998 63.8787 85.7998 64.4787 85.2798 64.9987C79.4798 70.8387 69.1598 81.2387 63.2398 87.1987C62.7198 87.7587 62.0398 87.9987 61.3598 87.9987C60.0398 87.9987 58.7598 86.9588 58.7598 85.4388V71.4388C58.7598 65.5988 63.7198 60.7587 69.7998 60.7587C73.5998 60.7187 78.8798 60.7188 83.3998 60.7188C84.9598 60.7188 85.9998 61.9587 85.9998 63.2787Z"
                    fill="#7489AA"
                  />
                </svg>
                <Text
                  textAlign={"center"}
                  w={"205px"}
                  fontSize={"20px"}
                  fontWeight={700}
                  color="grays.8"
                >
                  You haven’t created a template yet
                </Text>
                <Button
                  colorScheme={"primary"}
                  ml="auto"
                  size={"sm"}
                  isDisabled={isLoading}
                >
                  <Link to="create">Create Template</Link>
                </Button>
              </Stack>
            )
          : !isLoading &&
            data?.smsTemplates?.docs?.length === 0 && (
              <Stack mt="20" justifyContent={"center"} alignItems={"center"}>
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 96 96"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M86 33.48V63.28C86 63.88 85.8 64.48 85.28 65C79.48 70.84 69.16 81.24 63.24 87.2C62.72 87.76 62.04 88 61.36 88H29.48C18.72 88 10 79.28 10 68.52V33.48C10 22.72 18.72 14 29.48 14H66.52C77.28 14 86 22.72 86 33.48Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M33.1602 25.16C31.4802 25.16 30.1602 23.8 30.1602 22.16V11C30.1602 9.36 31.4802 8 33.1602 8C34.8402 8 36.1602 9.36 36.1602 11V22.12C36.1602 23.8 34.8402 25.16 33.1602 25.16Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M62.8398 25.16C61.1598 25.16 59.8398 23.8 59.8398 22.16V11C59.8398 9.32 61.1998 8 62.8398 8C64.5198 8 65.8398 9.36 65.8398 11V22.12C65.8398 23.8 64.5198 25.16 62.8398 25.16Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M59.1195 50.8438H29.4395C27.7595 50.8438 26.4395 49.4837 26.4395 47.8438C26.4395 46.2038 27.7995 44.8438 29.4395 44.8438H59.1195C60.7995 44.8438 62.1195 46.2038 62.1195 47.8438C62.1195 49.4837 60.7995 50.8438 59.1195 50.8438Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M47.9995 65.6797H29.4395C27.7595 65.6797 26.4395 64.3197 26.4395 62.6797C26.4395 60.9997 27.7995 59.6797 29.4395 59.6797H47.9995C49.6795 59.6797 50.9995 61.0397 50.9995 62.6797C50.9995 64.3197 49.6795 65.6797 47.9995 65.6797Z"
                    fill="#7489AA"
                  />
                  <path
                    d="M85.9998 63.2787C85.9998 63.8787 85.7998 64.4787 85.2798 64.9987C79.4798 70.8387 69.1598 81.2387 63.2398 87.1987C62.7198 87.7587 62.0398 87.9987 61.3598 87.9987C60.0398 87.9987 58.7598 86.9588 58.7598 85.4388V71.4388C58.7598 65.5988 63.7198 60.7587 69.7998 60.7587C73.5998 60.7187 78.8798 60.7188 83.3998 60.7188C84.9598 60.7188 85.9998 61.9587 85.9998 63.2787Z"
                    fill="#7489AA"
                  />
                </svg>
                <Text
                  textAlign={"center"}
                  w={"205px"}
                  fontSize={"20px"}
                  fontWeight={700}
                  color="grays.8"
                >
                  You haven’t created a template yet
                </Text>
                <Button
                  colorScheme={"primary"}
                  ml="auto"
                  size={"sm"}
                  isDisabled={isLoading}
                >
                  <Link to="create">Create Template</Link>
                </Button>
              </Stack>
            )}

        {isLoading && <Loader />}

        {params.all === "Whatsapp"
          ? !isLoading &&
            data?.whatsappTemplates?.docs?.length > 0 && (
              <>
                <TemplatesTable
                  channel={params.all}
                  listsData={data?.whatsappTemplates?.docs}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                <HStack
                  spacing={"10px"}
                  pt="14px"
                  pb={"48px"}
                  ml="2px"
                  w="100%"
                  justifyContent={"end"}
                >
                  {/* <HStack spacing={"13px"}>
                    <Text color="grays.12" fontWeight={500} fontSize="xs">
                      Show
                    </Text>
                    <ChakraSelect
                      selectProps={{
                        h: "24px",
                        borderColor: "grays.12",
                        fontWeight: 500,
                        color: "grays.12",
                        value: Number(dataToShow),
                        onChange: (e) => {
                          setDataToShow(Number(e.target.value));
                        },
                      }}
                    >
                      <chakra.option value={10}>10</chakra.option>
                      <chakra.option value={20}>20</chakra.option>
                      <chakra.option value={25}>25</chakra.option>
                      <chakra.option value={30}>30</chakra.option>
                      <chakra.option value={50}>50</chakra.option>
                    </ChakraSelect>
                  </HStack> */}
                  <CustomPaginaton
                    pagesCount={pagesCount}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    pages={pages}
                    numberOfdataToShow={dataToShow}
                    setDataToShow={setDataToShow}
                  />
                </HStack>
              </>
            )
          : !isLoading &&
            data?.smsTemplates?.docs?.length > 0 && (
              <>
                <TemplatesTable
                  channel={params.all!}
                  listsData={data?.smsTemplates?.docs}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                />
                {/* <HStack
                spacing={"10px"}
                pt="30px"
                ml="auto"
                w="fit-content"
                justifyContent={"end"}
              >
                <Text color="grays.12" fontWeight={500} fontSize="xs">
                  Show
                </Text>
                <ChakraSelect
                  selectProps={{
                    h: "24px",
                    borderColor: "grays.12",
                    fontWeight: 500,
                    color: "grays.12",
                    value: Number(dataToShow),
                    onChange: (e) => {
                      setDataToShow(Number(e.target.value));
                    },
                  }}
                >
                  <chakra.option value={10}>10</chakra.option>
                  <chakra.option value={20}>20</chakra.option>
                  <chakra.option value={25}>25</chakra.option>
                  <chakra.option value={30}>30</chakra.option>
                  <chakra.option value={50}>50</chakra.option>
                </ChakraSelect>
                <CustomPaginaton
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  pages={pages}
                  numberOfdataToShow={dataToShow}
                  setDataToShow={setDataToShow}
                />
              </HStack> */}
              </>
            )}
      </Box>

      <CustomModal
        disclosure={disclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Create Template
          </Text>
        }
      >
        <AddNewList close={disclosure.onClose} />
      </CustomModal>
    </Box>
  );
};

export default AllTemplates;
