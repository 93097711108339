import {
  Flex,
  Text,
  chakra,
  Box,
  useDisclosure,
  Stack,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  exportWalletTransactions,
  getWalletBalance,
  getWalletTransactions,
} from "../../API/wallet";
import WalletBalance from "./../../components/Wallet/WalletBalance";
import Empty from "../../components/UI/Empty";
import Loader from "../../components/UI/Loader";
import PaymentHistoryList from "../../components/Wallet/PaymentHistoryList";
import CustomPaginaton from "../../components/UI/CustomPaginaton";
import { useState } from "react";
import { usePagination } from "@ajna/pagination";
import CustomMenu from "../../components/UI/CustomMenu";
import ChevronDown from "../../icons/ChevronDown";
import DatePicker from "react-datepicker";
import { ExportIcon } from "../../assets/svgs/svg";
import { ButtonPrimary } from "../../components/UI/Buttons";
import dayjs from "dayjs";
import { PageAnimate } from "../../components/animations/pageAnimate";
import { ScaleUp } from "../../components/animations";

const Wallet = () => {
  const [dataToShow, setDataToShow] = useState(15);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const toast = useToast();
  const { data: walletBalance } = useQuery(
    ["walletBalanceData"],
    getWalletBalance
  );

  const { data: walletTransactions, isLoading } = useQuery(
    ["walletTransactionsData", dataToShow, myCurrentPage],
    () => {
      return getWalletTransactions({
        page: myCurrentPage,
        limit: dataToShow,
      });
    }
  );

  // console.log("we haveeeee", walletTransactions.docs);

  const exportWalletMutations = useMutation({
    mutationFn: exportWalletTransactions,
    onSuccess: () => {
      toast({
        title: "Wallet transactions exported successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: walletTransactions?.totalPages,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  const topupDisclosure = useDisclosure();

  // const [exportFlag, setExportFlag] = useState(true);
  const [filterByDuration, setFilterByDuration] = useState("Today");
  const [dateFilter, setDateFilter] = useState<[Date, Date]>([
    new Date(),
    new Date(),
  ]);

  const handleExport = () => {
    exportWalletMutations.mutate({
      startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
    });
  };

  //   const { data: exportedList, isLoading: exporttedListLoading } = useQuery(
  //   [
  //     'Transactions',
  //     exportFlag,
  //     filterByDuration,
  //     dateFilter,
  //   ],
  //   () => {
  //     return exportWalletList({
  //       exportFlag,
  //       filterByDuration,
  //       dateFilter,
  //     });
  //   },
  // );

  // const filteredTransactions = walletTransactions?.docs?.filter(
  //   (transaction: any) => {
  //     return  transaction.created_at.includes(dateFilter)
  //   }
  // );

  // const {
  //   currentPage: filteredCurrentPage,
  //   setCurrentPage: setFilteredCurrentPage,
  //   pagesCount: filteredPagesCount,
  //   pages: filteredPages,
  // } = usePagination({
  //   pagesCount: filteredTransactions?.length,
  //   limits: {
  //     outer: 3,
  //     inner: 3,
  //   },
  //   initialState: { currentPage: 1 },
  // });

  // const handleFilteredPageChange = (nextPage: number) => {
  //   setFilteredCurrentPage(nextPage);
  // };

  return (
    <PageAnimate>
      <Flex
        // px={{ base: "10px", md: "40px" }}
        flexDir={"column"}
        // maxW="1440px"
        // mx="auto"
        w="full"
        pt={"18px"}
        pb="40px"
      >
        {/* header */}
        <chakra.h1 fontSize={"20px"} color="grays.8" fontWeight={700} my="auto">
          Wallet
        </chakra.h1>

        <Flex mt="24px">
          {/* wallet */}
          <Box w="full" maxW="325px">
            <WalletBalance data={walletBalance} disclosure={topupDisclosure} />
          </Box>

          {/* <CampaignList /> */}
          <Box
            ml="20px"
            w="full"
            bg="white"
            rounded="4px"
            borderWidth={"1px"}
            borderColor="borders.100"
          >
            {/* header */}
            <Flex pr="40px" justify="space-between">
              <Text fontSize={"20px"} color="grays.8" fontWeight={700} p="24px">
                Payment History
              </Text>
              <Stack
                direction={{ base: "column", md: "row" }}
                spacing={"12px"}
                align="center"
                position={"relative"}
                zIndex={3}
              >
                <HStack spacing={2}>
                  <Text
                    fontSize={"xs"}
                    w="full"
                    color="rgba(23, 29, 38, 0.56)"
                    whiteSpace={"nowrap"}
                  >
                    Date by:
                  </Text>

                  <CustomMenu
                    buttonTitle={
                      <HStack textAlign={"left"} spacing={"14px"}>
                        <Text flex={1}>
                          {filterByDuration.charAt(0).toUpperCase() +
                            filterByDuration?.slice(1).replace("_", " ")}
                        </Text>
                        <ChevronDown />
                      </HStack>
                    }
                    buttonProps={{
                      fontSize: "xs",
                      color: "grays.9",
                      type: "button",
                      bg: "white",
                      borderWidth: "1px",
                      borderColor: "#D2D2D4",
                      borderRadius: "4px",
                      py: "10px",
                      px: "12px",
                      minW: "130px",
                      maxW: "130px",
                    }}
                    isSelect
                    itemsSelectData={[
                      {
                        title: "Today",
                        itemSelectProps: {
                          fontSize: "xs",
                          value: "today",
                        },
                      },
                      {
                        title: "Last week",
                        itemSelectProps: {
                          fontSize: "xs",
                          value: "last_week",
                        },
                      },
                      {
                        title: "Last month",
                        itemSelectProps: {
                          fontSize: "xs",
                          value: "last_month",
                        },
                      },
                      {
                        title: "Last Quarter",
                        itemSelectProps: {
                          fontSize: "xs",
                          value: "last_quarter",
                        },
                      },
                      {
                        title: "Last Year",
                        itemSelectProps: {
                          fontSize: "xs",
                          value: "last_year",
                        },
                      },
                    ]}
                    menuSelectProps={{
                      defaultValue: "today",
                      onChange: (date) => {
                        setFilterByDuration(date as string);
                      },
                    }}
                  />
                </HStack>

                <Text fontSize={"xs"} color="grays.8">
                  OR
                </Text>
                <DatePicker
                  // selected={dateFilter}
                  onChange={(date) => setDateFilter(date as [Date, Date])}
                  startDate={dateFilter[0]}
                  endDate={dateFilter[1]}
                  selectsRange
                  // inline
                />

                {/* <CustomButton
                children={
                  <Flex
                    justifyContent={"space-between"}
                    alignItems="center"
                    columnGap=".5em"
                    onClick={() => {
                      console.log(walletTransactions?.docs);
                      //  console.log(filteredTransactions)
                    }}
                  >
                    <ExportIcon />
                    <Text>Export</Text>
                  </Flex>
                }
                padding={"1.5em 3em"}
                border="none"
                size="sm"
              /> */}

                <ButtonPrimary
                  buttonProps={{
                    minHeight: "40px",
                    minWidth: "105px",
                    px: "20px",
                  }}
                  onClick={handleExport}
                >
                  <HStack>
                    <ExportIcon />
                    <Text>Export</Text>
                  </HStack>
                </ButtonPrimary>
              </Stack>
            </Flex>

            {isLoading && <Loader />}

            {/* Empty */}
            {!isLoading &&
              walletTransactions &&
              walletTransactions?.docs?.length === 0 && (
                <ScaleUp delay={0.5} style={{ marginBlockStart: "40px" }}>
                  <Empty
                    height="70vh"
                    title="You haven’t made any transactions yet"
                    buttonText="Top up balance now"
                    icon={
                      <svg
                        width="96"
                        height="96"
                        viewBox="0 0 96 96"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.4"
                          d="M82 40.76H70.44C60.96 40.76 53.24 33.04 53.24 23.56V12C53.24 9.8 51.44 8 49.24 8H32.28C19.96 8 10 16 10 30.28V65.72C10 80 19.96 88 32.28 88H63.72C76.04 88 86 80 86 65.72V44.76C86 42.56 84.2 40.76 82 40.76Z"
                          fill="#7489AA"
                        />
                        <path
                          d="M63.1987 8.84193C61.5587 7.20193 58.7188 8.32193 58.7188 10.6019V24.5619C58.7188 30.4019 63.6787 35.2419 69.7187 35.2419C73.5187 35.2819 78.7987 35.2819 83.3187 35.2819C85.5987 35.2819 86.7987 32.6019 85.1987 31.0019C79.4387 25.2019 69.1187 14.7619 63.1987 8.84193Z"
                          fill="#7489AA"
                        />
                        <path
                          d="M54 55H30C28.36 55 27 53.64 27 52C27 50.36 28.36 49 30 49H54C55.64 49 57 50.36 57 52C57 53.64 55.64 55 54 55Z"
                          fill="#7489AA"
                        />
                        <path
                          d="M46 71H30C28.36 71 27 69.64 27 68C27 66.36 28.36 65 30 65H46C47.64 65 49 66.36 49 68C49 69.64 47.64 71 46 71Z"
                          fill="#7489AA"
                        />
                      </svg>
                    }
                    buttonAction={topupDisclosure.onOpen}
                  />
                </ScaleUp>
              )}

            {!isLoading &&
              walletTransactions?.docs &&
              walletTransactions?.docs?.length > 0 && (
                <>
                  <Box h="62vh" overflowY={"scroll"} className="thinSB">
                    <PaymentHistoryList data={walletTransactions?.docs} />
                  </Box>

                  <HStack justifyContent={"end"} pt={"8px"} pb={"32px"}>
                    <CustomPaginaton
                      pagesCount={pagesCount}
                      currentPage={currentPage}
                      handlePageChange={handlePageChange}
                      pages={pages}
                      numberOfdataToShow={dataToShow}
                      setDataToShow={setDataToShow}
                      // showEntriesSelect
                    />
                  </HStack>
                </>
              )}
          </Box>
        </Flex>
      </Flex>
    </PageAnimate>
  );
};

export default Wallet;
