import React, { FC } from "react";

const ExportIcon: FC<any> = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.0026 17.1654H8.0026C4.3826 17.1654 2.83594 15.6187 2.83594 11.9987V7.9987C2.83594 4.3787 4.3826 2.83203 8.0026 2.83203H12.0026C15.6226 2.83203 17.1693 4.3787 17.1693 7.9987V11.9987C17.1693 15.6187 15.6226 17.1654 12.0026 17.1654ZM8.0026 3.83203C4.92927 3.83203 3.83594 4.92536 3.83594 7.9987V11.9987C3.83594 15.072 4.92927 16.1654 8.0026 16.1654H12.0026C15.0759 16.1654 16.1693 15.072 16.1693 11.9987V7.9987C16.1693 4.92536 15.0759 3.83203 12.0026 3.83203H8.0026Z"
        fill="currentColor"
      />
      <path
        d="M10.0022 12.1741C9.87557 12.1741 9.74891 12.1274 9.64891 12.0274L7.64891 10.0274C7.45557 9.83411 7.45557 9.51411 7.64891 9.32078C7.84224 9.12745 8.16224 9.12745 8.35557 9.32078L10.0022 10.9674L11.6489 9.32078C11.8422 9.12745 12.1622 9.12745 12.3556 9.32078C12.5489 9.51411 12.5489 9.83411 12.3556 10.0274L10.3556 12.0274C10.2556 12.1274 10.1289 12.1741 10.0022 12.1741Z"
        fill="currentColor"
      />
      <path
        d="M10 12.1732C9.72667 12.1732 9.5 11.9465 9.5 11.6732V6.33984C9.5 6.06651 9.72667 5.83984 10 5.83984C10.2733 5.83984 10.5 6.06651 10.5 6.33984V11.6732C10.5 11.9532 10.2733 12.1732 10 12.1732Z"
        fill="currentColor"
      />
      <path
        d="M9.99899 14.1521C8.59232 14.1521 7.17899 13.9255 5.83899 13.4788C5.57899 13.3921 5.43899 13.1055 5.52565 12.8455C5.61232 12.5855 5.89232 12.4388 6.15898 12.5321C8.63898 13.3588 11.3657 13.3588 13.8457 12.5321C14.1057 12.4455 14.3923 12.5855 14.479 12.8455C14.5657 13.1055 14.4257 13.3921 14.1657 13.4788C12.819 13.9321 11.4057 14.1521 9.99899 14.1521Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ExportIcon;
