import {
  UseDisclosureProps,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  chakra,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

const CustomModal: FC<{
  disclosure: UseDisclosureProps;
  children: ReactNode;
  title?: ReactNode;
  titleUnderLine?: boolean;
  isSegment?: boolean;
  isFallback?: boolean;
}> = ({
  disclosure,
  children,
  title,
  titleUnderLine,
  isSegment,
  isFallback,
}) => {
  const { isOpen } = disclosure;

  return (
    <Modal
      // @ts-ignore
      isOpen={isOpen}
      // @ts-ignore
      onClose={disclosure?.onClose}
    >
      <ModalOverlay />
      <ModalContent
        px={isFallback ? { base: "32px" } : { base: "10px", md: "40px" }}
        minWidth={isSegment ? "648px" : isFallback ? "580px" : ""}
      >
        <ModalHeader
          px="0"
          //  px={{ base: '10px', md: '40px' }}
          p={!titleUnderLine ? 0 : ""}
          py={titleUnderLine ? "2" : ""}
          borderBottom={
            titleUnderLine ? "1px solid rgba(0, 102, 255, 0.16)" : ""
          }
          display="flex"
          // justifyContent={titleUnderLine ? 'space-between' : !title ? 'end' : ''}
          justifyContent={!title ? "flex-end" : "space-between"}
          // justifySelf={!title ?  'flex-end' : ''}
        >
          {title}

          <chakra.button
            mt="35px"
            // px='42px'
            // ml='auto'
            onClick={disclosure?.onClose}
            textAlign="right"
            color="primary.500"
            fontSize={"xs"}
            textDecor="underline"
            fontWeight={"bold"}
          >
            Cancel
          </chakra.button>
        </ModalHeader>
        <ModalBody px={0} pb={0}>
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CustomModal;
