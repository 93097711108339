import { useState, FC, useMemo } from "react";
import { Button, chakra } from "@chakra-ui/react";
import SearchableSelect from "../components/UI/form/SearchableSelect";
import { useQuery } from "@tanstack/react-query";
import { getTeammatesData } from "../API/account/teammates";
import { getSMSCampaignExport } from "../API/campaign";
import { useToast } from "@chakra-ui/react";
// import { useRecoilValue } from "recoil";
// import { profileAtom } from "../atoms/user";

// interface emailData {
// value: string
// label: string
// }

const ExportReport: FC<{ close?: () => void }> = ({ close }) => {
  const [email, setEmail] = useState("");
  const toast = useToast();
  // const profileData = useRecoilValue(profileAtom);

  const [myCurrentPage] = useState(1);
  const [dataToShow] = useState(1000);

  const { isLoading, data } = useQuery(
    ["listTeammates", myCurrentPage, dataToShow],
    () => {
      return getTeammatesData(myCurrentPage, dataToShow);
    }
  );

  const teammates = useMemo(() => {
    return data?.docs?.map((list: any) => {
      return {
        label: list?.email,
        value: list?.uid,
      };
    });
  }, [data]);

  const handleExportButtonClick = async () => {
    // if (!selectedOption) {
    //   return;
    // }

    const param = {
      // @ts-ignore
      uid: email?.value,
      channel: "sms",
      // @ts-ignore
      email: email?.label,
      exportFlag: true,
      // phone_number: '2347012766294'
    };

    await getSMSCampaignExport(param)
      .then((exportData) => {
        toast({
          title: "Export successful",
          status: "success",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      })
      .catch((error) => {
        toast({
          title: "Export failed",
          description: error.message,
          status: "error",
          duration: 3000,
          isClosable: true,
          position: "top-right",
        });
      });
    close && close();
  };

  return (
    <chakra.form px="0" mt="24px">
      <SearchableSelect
        label="Teammate mail"
        placeholder="Select teammate mail"
        options={teammates}
        value={email}
        // onChange={(e: { label: string; value: string }) => {
        //     setEmail(e.value)
        // }}
        onChange={(selectedValue: string) => setEmail(selectedValue)}
      />
      <Button
        onClick={() => {
          handleExportButtonClick();
          // console.log(email);
          // console.log(profileData);
        }}
        // type='submit'
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "#B2B6BD",
        }}
        _hover={{
          bg: "grey.20",
        }}
        _focus={{
          bg: "grey.20",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={isLoading}
        isDisabled={!email}
      >
        Export
      </Button>
    </chakra.form>
  );
};

export default ExportReport;
