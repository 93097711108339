import { Box, Flex, Text } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getImportHistory } from "../../../API/contacts";
import Empty from "../../../components/UI/Empty";
import Loader from "../../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import CustomPaginaton from "../../../components/UI/CustomPaginaton";
import ImportHistoryList from "../../../components/contacts/ImportHistoryList";
import { useNavigate } from "react-router-dom";

const ImportHistory = () => {
  const [dataToShow, setDataToShow] = useState(15);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const navigate = useNavigate();

  const { isLoading, data } = useQuery(
    ["importHistoryData", dataToShow, myCurrentPage],
    () => {
      return getImportHistory({
        page: myCurrentPage,
        limit: dataToShow,
      });
    }
  );

  // console.log("we haveee", data);

  const [globalFilter, setGlobalFilter] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <Box bg={"white"} borderRadius={"6px"}>
      {/* Table header */}
      <Box mb={"32px"}>
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Import History
          <Text as="span" color={"grays.12"}></Text>
        </Text>
      </Box>

      <Flex flexDir={"column"} mt="12px" w="full">
        {!isLoading && data?.docs?.length === 0 && (
          <Empty
            title="You haven’t added any contact yet"
            description="Start adding contacts by importing an existing list."
            buttonText="Import Contact"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M88 31.24V64.76C88 76 82.84 83.7199 73.76 86.6399C71.12 87.5599 68.08 88 64.76 88H31.24C27.92 88 24.88 87.5599 22.24 86.6399C13.16 83.7199 8 76 8 64.76V31.24C8 16.68 16.68 8 31.24 8H64.76C79.32 8 88 16.68 88 31.24Z"
                  fill="#7489AA"
                />
                <path
                  d="M73.7603 86.6389C71.1203 87.5589 68.0803 87.999 64.7603 87.999H31.2402C27.9202 87.999 24.8802 87.5589 22.2402 86.6389C23.6402 76.0789 34.6802 67.8789 48.0002 67.8789C61.3202 67.8789 72.3603 76.0789 73.7603 86.6389Z"
                  fill="#7489AA"
                />
                <path
                  d="M62.3197 46.3201C62.3197 54.2401 55.9197 60.6799 47.9997 60.6799C40.0797 60.6799 33.6797 54.2401 33.6797 46.3201C33.6797 38.4001 40.0797 32 47.9997 32C55.9197 32 62.3197 38.4001 62.3197 46.3201Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={() => {
              navigate("/contacts/import");
            }}
          />
        )}

        {isLoading && <Loader />}

        {!isLoading && data?.docs?.length > 0 && (
          <>
            <Box h="70vh" overflowY={"auto"} className="thinSB">
              <ImportHistoryList
                data={data?.docs}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </Box>

            <Box pt={"4px"} pb={"10px"}>
              <CustomPaginaton
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
                numberOfdataToShow={dataToShow}
                setDataToShow={setDataToShow}
                showEntriesSelect
                isSpaced
              />
            </Box>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default ImportHistory;
