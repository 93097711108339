import {
  Flex,
  Input,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Badge,
} from "@chakra-ui/react";
import { FC, useEffect, useMemo, useState } from "react";
import { useDebounce } from "../../../hooks/useDebounce";
import { SingleConversationProps } from "../../../utils/generalProps";
import ChatListItem from "./ChatListItem";
import EmptyChatList from "./EmptyChatList";

const ChatList: FC<{
  data: SingleConversationProps[];
  searchData: SingleConversationProps[];
  handleSearch: any;
}> = ({ data, searchData, handleSearch }) => {
  const [dataToUse, setDataToUse] = useState<SingleConversationProps[]>();
  const backupDataToUse = useMemo(() => {
    return data;
  }, [data]);

  useEffect(() => {
    setDataToUse(searchData ?? data);
  }, [searchData, data]);

  const [search, setSearch] = useState("");
  const debouncedSearchTerm = useDebounce(search, 500);

  useEffect(() => {
    if (debouncedSearchTerm) {
      handleSearch({
        search,
      });
    } else {
      setDataToUse(backupDataToUse);
    }
  }, [debouncedSearchTerm, backupDataToUse, handleSearch, search]);

  const openedMessages = useMemo(() => {
    return dataToUse?.filter((message) => {
      // @ts-ignore
      return message?.status === "opened";
    });
  }, [dataToUse]);

  const closedMessages = useMemo(() => {
    return dataToUse?.filter((message) => {
      // @ts-ignore
      return message?.status === "closed";
    });
  }, [dataToUse]);

  const pendingMessages = useMemo(() => {
    return dataToUse?.filter((message) => {
      // @ts-ignore
      return message?.status === "pending";
    });
  }, [dataToUse]);

  return (
    <>
      {/* Input and filter */}
      <Flex>
        <Input
          w="full"
          placeholder="Start typing..."
          h="24px"
          fontSize={"12px"}
          borderRadius={"4px"}
          borderColor="grays.5"
          _hover={{
            borderColor: "grays.5",
          }}
          color="grays.9"
          _placeholder={{
            color: "grays.5",
          }}
          my="auto"
          type={"search"}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            // if (e.target.value.length === 0) {
            //   setDataToUse(backupDataToUse);
            // }

            // if (e.target.value.length >= 3) {
            //   handleSearch({
            //     search: e.target.value,
            //   });
            // }
          }}
        />

        {/* Filter */}
        {/* <Menu closeOnSelect={false}>
          <MenuButton
            as={IconButton}
            aria-label='Sort'
            icon={
              <chakra.svg
                width='24px'
                height='24px'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z'
                  fill='#979797'
                />
                <path
                  d='M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z'
                  fill='#979797'
                />
                <path
                  d='M14 17.75H10C9.59 17.75 9.25 17.41 9.25 17C9.25 16.59 9.59 16.25 10 16.25H14C14.41 16.25 14.75 16.59 14.75 17C14.75 17.41 14.41 17.75 14 17.75Z'
                  fill='#979797'
                />
              </chakra.svg>
            }
            variant='unstyled'
            p={0}
            my='auto'
            ml='9px'
            maxH='24px'
            minW='unset'
          />
          <MenuList maxW='300px'>
            <MenuOptionGroup defaultValue='List 1' type='radio'>
              <MenuItemOption value='List 1'>
                <HStack>
                  <Box h='16px' w='16px' rounded='full' bg='red.500'></Box>
                  <Text
                    color='grays.9'
                    fontSize={'xs'}
                    textTransform='uppercase'
                    noOfLines={1}
                  >
                    List 1
                  </Text>
                </HStack>
              </MenuItemOption>
              <MenuItemOption value='List 2'>
                <HStack>
                  <Box h='16px' w='16px' rounded='full' bg='yellow.500'></Box>
                  <Text
                    color='grays.9'
                    fontSize={'xs'}
                    textTransform='uppercase'
                    noOfLines={1}
                  >
                    List 2
                  </Text>
                </HStack>
              </MenuItemOption>
              <MenuItemOption value='List 3'>
                <HStack>
                  <Box h='16px' w='16px' rounded='full' bg='blue.500'></Box>
                  <Text
                    color='grays.9'
                    fontSize={'xs'}
                    textTransform='uppercase'
                    noOfLines={1}
                  >
                    List 3
                  </Text>
                </HStack>
              </MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Menu> */}
      </Flex>

      {/* Tabs */}
      <Tabs mt="12px" variant="unstyled" isFitted>
        {/* Tab nav */}
        <TabList bg="backgrounds.100" borderRadius={"6px"} w="full" p="2px">
          <Tab
            borderRadius={"4px"}
            fontSize="xs"
            color="grays.9"
            _selected={{
              bg: "white",
              fontWeight: "medium",
              boxShadow: "0px 0px 8px rgba(26, 26, 26, 0.12)",
            }}
            _focus={{
              zIndex: 1,
              borderColor: "#3182ce",
              boxShadow: "0 0 0 1px #3182ce",
            }}
          >
            Open
            <Badge
              ml="1"
              variant="solid"
              rounded="10px"
              bg={"#0066FF"}
              py="2px"
              px="8px"
              fontSize={"10px"}
            >
              {openedMessages?.length}
            </Badge>
          </Tab>
          <Tab
            borderRadius={"4px"}
            fontSize="xs"
            color="grays.9"
            _selected={{
              bg: "white",
              fontWeight: "medium",
              boxShadow: "0px 0px 8px rgba(26, 26, 26, 0.12)",
            }}
            _focus={{
              zIndex: 1,
              borderColor: "#3182ce",
              boxShadow: "0 0 0 1px #3182ce",
            }}
          >
            Pending
            <Badge
              ml="1"
              bg={"#0066FF"}
              variant="solid"
              rounded="10px"
              py="2px"
              px="8px"
              fontSize={"10px"}
            >
              {pendingMessages?.length}
            </Badge>
          </Tab>
          <Tab
            borderRadius={"4px"}
            fontSize="xs"
            color="grays.9"
            _selected={{
              bg: "white",
              fontWeight: "medium",
              boxShadow: "0px 0px 8px rgba(26, 26, 26, 0.12)",
            }}
            _focus={{
              zIndex: 1,
              borderColor: "#3182ce",
              boxShadow: "0 0 0 1px #3182ce",
            }}
          >
            Closed
            <Badge
              ml="1"
              variant="solid"
              rounded="10px"
              bg={"#0066FF"}
              py="2px"
              px="8px"
              fontSize={"10px"}
            >
              {closedMessages?.length}
            </Badge>
          </Tab>
        </TabList>

        {/* content */}
        <TabPanels>
          {/* opened */}
          <TabPanel
            px={0}
            // h={{ base: 'auto', md: '75vh' }}
            // maxH={{ md: '90vh' }}
            overflowY={{ base: "unset", md: "auto" }}
          >
            {openedMessages?.length === 0 && <EmptyChatList />}

            {/* Item */}
            {openedMessages
              ?.sort(function (a, b) {
                // @ts-ignore
                return new Date(b.updated_at) - new Date(a.updated_at);
              })
              .map((data, index: number) => {
                return <ChatListItem key={index} data={data} />;
              })}
          </TabPanel>

          {/* pending */}
          <TabPanel
            px={0}
            // h={{ base: 'auto', md: '75vh' }}
            // maxH={{ md: '90vh' }}
            overflowY={{ base: "unset", md: "auto" }}
          >
            {pendingMessages?.length === 0 && (
              <EmptyChatList title="No pending messages yet" />
            )}

            {/* Item */}
            {pendingMessages
              ?.sort(function (a, b) {
                // @ts-ignore
                return new Date(b.updated_at) - new Date(a.updated_at);
              })
              ?.map((data, index: number) => {
                return <ChatListItem key={index} data={data} />;
              })}
          </TabPanel>

          {/* closed */}
          <TabPanel
            px={0}
            // h={{ base: 'auto', md: '75vh' }}
            // maxH={{ md: '90vh' }}
            overflowY={{ base: "unset", md: "auto" }}
          >
            {closedMessages?.sort(function (a, b) {
              // @ts-ignore
              return new Date(b.updated_at) - new Date(a.updated_at);
            })?.length === 0 && (
              <EmptyChatList title="No closed messages yet" />
            )}

            {/* Item */}
            {closedMessages?.map((data, index: number) => {
              return <ChatListItem key={index} data={data} />;
            })}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default ChatList;
