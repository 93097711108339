import { Box, Button, chakra, useToast } from "@chakra-ui/react";
import ChakraSelect from "../../../components/UI/form/ChakraSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { inviteTeammates } from "../../../API/account/teammates";
import Success from "../../UI/Success";
import FormInput from "../../UI/form/FormInput";
import { toaster } from "../../../utils/toast";

const AddNewTeammate: FC<{ close?: () => void }> = ({ close }) => {
    const [currentStage, setCurrentStage] = useState<
        "entry" | "processing" | "success"
    >("entry");

    const queryClient = useQueryClient();

    const [first_name, setFirstname] = useState("");
    const [last_name, setLastName] = useState("");
    const [role, setRole] = useState("");
    const [email, setEmail] = useState("");

    const toast = useToast();

    const { mutate, isLoading } = useMutation(inviteTeammates, {
        onSuccess() {
            setCurrentStage("success");
            setFirstname("");
            setLastName("");
            setEmail("");
            setRole("");
            queryClient.invalidateQueries(["listTeammates"]);
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
    });

    return (
        <>
            {currentStage === "entry" ? (
                <Box mt="24px ">
                    <FormInput
                        label="First name"
                        type="text"
                        for="First name"
                        inputProps={{
                            placeholder: "Enter teammate first name",
                            marginBottom: "20px",
                            value: first_name,
                            onChange: (e) => {
                                setFirstname(e.target.value);
                            },
                        }}
                    />
                    <FormInput
                        label="Last name"
                        type="text"
                        for="Last name"
                        inputProps={{
                            placeholder: "Enter teammate last name",
                            marginBottom: "20px",
                            value: last_name,
                            onChange: (e) => {
                                setLastName(e.target.value);
                            },
                        }}
                    />

                    <FormInput
                        label="Email address"
                        type="email"
                        for="Email address"
                        inputProps={{
                            marginBottom: "20px",
                            placeholder: "Enter teammate email address",
                            value: email,
                            onChange: (e) => {
                                setEmail(e.target.value);
                            },
                        }}
                    />
                    <ChakraSelect
                        label="Role"
                        selectProps={{
                            h: "44px",
                            marginTop: "5px",
                            borderColor: "borders.100",
                            fontWeight: 500,
                            color: "grays.9",
                            value: role,
                            placeholder: "Select a Role",
                            onChange: (e) => {
                                setRole(e.target.value);
                            },
                        }}
                    >
                        <chakra.option value={"Admin"}>Admin</chakra.option>
                        <chakra.option value={"Member"}>Member</chakra.option>
                    </ChakraSelect>

                    <Button
                        isDisabled={!first_name || !last_name || !email || !role}
                        my="40px"
                        w="full"
                        colorScheme={"primary"}
                        isLoading={isLoading}
                        onClick={() => {
                            mutate({
                                first_name,
                                last_name,
                                email,
                                role,
                            });
                        }}
                    >
                        Invite teammate
                    </Button>
                </Box>
            ) : currentStage === "success" ? (
                <>
                    <Success
                        containerProps={{
                            maxW: "full",
                            mb: 0,
                            mt: 0,
                        }}
                        title="Invite has been sent!"
                        description=" An email has been sent to your teammate and 
would require it to get access."
                        icon={
                            <svg
                                width="80"
                                height="80"
                                viewBox="0 0 80 80"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    opacity="0.12"
                                    d="M34.9669 1.76956C37.7258 -0.589853 42.2441 -0.589853 45.043 1.76956L51.3607 7.20814C52.5603 8.24788 54.7994 9.0878 56.3988 9.0878H63.1963C67.4347 9.0878 70.9133 12.5669 70.9133 16.8058V23.6039C70.9133 25.1636 71.753 27.443 72.7926 28.6427L78.2307 34.9612C80.5898 37.7205 80.5898 42.2395 78.2307 45.0388L72.7926 51.357C71.753 52.5567 70.9133 54.7962 70.9133 56.3958V63.1942C70.9133 67.4331 67.4347 70.9122 63.1963 70.9122H56.3988C54.8394 70.9122 52.5603 71.7521 51.3607 72.7919L45.043 78.2304C42.2841 80.5898 37.7658 80.5898 34.9669 78.2304L28.6493 72.7919C27.4497 71.7521 25.2105 70.9122 23.6111 70.9122H16.6937C12.4553 70.9122 8.97662 67.4331 8.97662 63.1942V56.3558C8.97662 54.7962 8.13697 52.5567 7.13735 51.357L1.73935 44.9988C-0.579782 42.2394 -0.579782 37.7606 1.73935 35.0012L7.13735 28.6427C8.13697 27.443 8.97662 25.2036 8.97662 23.644V16.8058C8.97662 12.5669 12.4553 9.0878 16.6937 9.0878H23.6111C25.1706 9.0878 27.4497 8.24788 28.6493 7.20814L34.9669 1.76956Z"
                                    fill="#159D6F"
                                />
                                <path
                                    opacity="0.24"
                                    d="M37.0832 17.7167C38.6932 16.34 41.3299 16.34 42.9632 17.7167L46.6499 20.89C47.3499 21.4967 48.6565 21.9867 49.5899 21.9867H53.5565C56.0299 21.9867 58.0599 24.0167 58.0599 26.4901V30.4566C58.0599 31.3666 58.5499 32.6966 59.1565 33.3966L62.3299 37.0833C63.7066 38.6933 63.7066 41.3301 62.3299 42.9634L59.1565 46.65C58.5499 47.35 58.0599 48.6566 58.0599 49.59V53.5567C58.0599 56.03 56.0299 58.06 53.5565 58.06H49.5899C48.6799 58.06 47.3499 58.5501 46.6499 59.1567L42.9632 62.33C41.3532 63.7067 38.7166 63.7067 37.0832 62.33L33.3966 59.1567C32.6966 58.5501 31.3899 58.06 30.4566 58.06H26.4199C23.9465 58.06 21.9166 56.03 21.9166 53.5567V49.5666C21.9166 48.6566 21.4266 47.35 20.8432 46.65L17.6932 42.94C16.3399 41.33 16.3399 38.7167 17.6932 37.1067L20.8432 33.3966C21.4266 32.6966 21.9166 31.39 21.9166 30.48V26.4901C21.9166 24.0167 23.9465 21.9867 26.4199 21.9867H30.4566C31.3666 21.9867 32.6966 21.4967 33.3966 20.89L37.0832 17.7167Z"
                                    fill="#159D6F"
                                />
                                <path
                                    d="M50.6867 37.83C50.2434 37.2 49.4968 36.85 48.6101 36.85H44.0601C43.7567 36.85 43.4768 36.7333 43.2901 36.4999C43.1034 36.2666 43.0101 35.9633 43.0568 35.6366L43.6167 31.9966C43.8501 30.9233 43.1268 29.6866 42.0534 29.3366C41.0501 28.9633 39.8601 29.4766 39.3934 30.1766L34.8667 36.8966V36.0566C34.8667 34.4233 34.1668 33.7466 32.4401 33.7466H31.2967C29.5701 33.7466 28.8701 34.4233 28.8701 36.0566V47.21C28.8701 48.8433 29.5701 49.52 31.2967 49.52H32.4401C34.0734 49.52 34.7734 48.8899 34.8434 47.3966L38.2734 50.0333C38.7401 50.4999 39.7901 50.7566 40.5368 50.7566H44.8534C46.3467 50.7566 47.8401 49.6366 48.1668 48.2599L50.8968 39.9532C51.2001 39.2066 51.1301 38.4366 50.6867 37.83Z"
                                    fill="#159D6F"
                                />
                            </svg>
                        }
                    />

                    <Button
                        onClick={() => setCurrentStage("entry")}
                        w="full"
                        colorScheme={"primary"}
                        mt="32px"
                        mb="40px"
                    >
                        Invite another teammate
                    </Button>
                </>
            ) : null}
        </>
    );
};

export default AddNewTeammate;
