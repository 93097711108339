import API from "../.";

// =========================== Get all Teammates ==============================================

export const getTeammatesData = async (page?: number, limit?: number) => {
  const response = await API.get(
    `/teammates/list/?page=${page}&limit=${limit}`
  );

  return response.data.data;
};

export const getTeammatesCount = async () => {
  const response = await API.get(`/teammates/count`);
  return response.data.data;
};

// =========================== Invite Teammates ==============================================

export const inviteTeammates = async (data: {
  first_name: string;
  last_name: string;
  email: string;
  role: string;
}) => {
  const response = await API.post(`/teammates/create`, {
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    role: data.role.toLowerCase(),
  });
  return response.data.data;
};
// =========================== Update Teammates role ==============================================

export const editTeammate = async (data: {
  first_name: string;
  last_name: string;
  id: number;
}) => {
  const response = await API.patch(`/teammates/${data.id}/edit`, {
    first_name: data.first_name,
    last_name: data.last_name,
  });
  return response.data;
};

// =========================== Edit Teammates Firstname and Lastname ==============================================

export const updateRole = async (data: { role: string; id: number }) => {
  const response = await API.patch(`/teammates/${data.id}/edit`, {
    role: data.role.toLowerCase(),
  });
  return response.data;
};

// =========================== Delete Teammates ==============================================

export const deleteTeammate = async (id: number) => {
  const response = await API.delete(`/teammates/${id}`);
  return response.data;
};

// =========================== Deactivate/Activate Teammates ==============================================

export const deactivateActivateTeammate = async (data: {
  is_active: boolean;
  id: number;
}) => {
  const response = await API.patch(`/teammates/${data.id}/edit`, {
    is_active: data.is_active,
  });
  return response.data;
};

// =========================== Switch Business ====================================================

export const getBusinessList = async () => {
  const response = await API.get("/teammates/businesses/list");

  return response.data.data;
};

// =========================== Update Teammates role ==============================================

export const switchBusiness = async (data: { id: number }) => {
  const response = await API.patch("teammates/businesses/switch", {
    company_id: data.id,
  });
  return response.data;
};
