import { HStack, StackProps, Text, TextProps } from '@chakra-ui/react';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

const Back: FC<{
  path: string;
  title: string;
  containerProps?: StackProps;
  textProps?: TextProps;
}> = ({ path, title, containerProps, textProps }) => {
  return (
    <Link to={path}>
      {' '}
      <HStack spacing={'4px'} {...containerProps}>
        <svg
          width='20'
          height='20'
          viewBox='0 0 20 20'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <path
            d='M12 13.5L8.5 10.25L12 7'
            stroke='#7489AA'
            strokeWidth='1.5'
            strokeLinecap='round'
            strokeLinejoin='round'
          />
        </svg>

        <Text
          fontSize={'xs'}
          {...textProps}
          color={textProps?.color || 'grays.12'}
        >
          {title}
        </Text>
      </HStack>
    </Link>
  );
};

export default Back;
