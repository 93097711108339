import { atom } from 'recoil';

type stageProps = 'sample' | 'upload' | 'map' | 'final' | 'success';

const importContactsAtom = atom<{
  currentStage: stageProps;
  fileData?: any;
  file: any;
  csvDataToUse: any;
  list_uid: string;
}>({
  key: 'importContactsAtom',
  default: {
    currentStage: 'sample',
    fileData: null,
    file: null,
    csvDataToUse: [],
    list_uid: '',
  },
});

export { importContactsAtom };
