import {
    Box,
    Button,
    RadioGroup,
    Text,
    useToast,
} from "@chakra-ui/react";

import { Dispatch, FC, SetStateAction, useState } from "react";
import { useNavigate } from "react-router-dom";
import SingleCard from "./singleCard";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
    getCards,
    getExchangeRate,
    subscribe,
} from "../../../API/account/billings";
import Loader from "../../UI/Loader";
import AddCardModal from "./AddCardModal";
import { toaster } from "../../../utils/toast";

export interface cardValueType {
    id: number;
    last_four_digits: string;
    expiry_date: string;
    card_type: string;
    is_default: boolean;
    type: string;
}
const SelectUpgradePaymentMethod: FC<{
    close?: () => void;
    planId: number;
    amount: number;
    setTitle: Dispatch<SetStateAction<string>>;
    addNewCardDisclosure: any
}> = ({ close, setTitle, planId, amount, addNewCardDisclosure }) => {
    const toast = useToast();
    const { data } = useQuery(["listCards"], getCards);
    const [stage, setStage] = useState("1");
    const [cardValue, setCardValue] = useState("");
    const navigate = useNavigate();
    const { data: exchangeRate } = useQuery(["exchangeRate"], getExchangeRate);

    const subscriptionFee = amount * exchangeRate?.rate;
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "NGN",
    });
    const subscribeData = {
        card_id: Number(cardValue),
        plan_id: planId,
        amount: subscriptionFee,
    };

    const { isLoading, mutate } = useMutation(subscribe, {
        onSuccess(data) {
            const success = { ...data }
            toaster(toast, success)
            setStage("");
            close && close()
            navigate('/account/billings')
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
        retry: false
    });

    const popUp = () => {
        addNewCardDisclosure.onOpen()
        close && close()
    }

    return (
        <Box>
            {stage === "1" && (
                <>
                    <Text fontSize={"12px"} color="grays.12" fontWeight="500" mt={"-5px"}>
                        What card would you like to pay from?
                    </Text>
                    {data?.length === 0 ? (
                        <Loader />
                    ) : (
                        <>
                            <Box mt="24px">
                                <RadioGroup onChange={setCardValue} value={cardValue}>
                                    {data?.map((cardValue: cardValueType) => (
                                        <SingleCard key={cardValue?.id} data={cardValue} />
                                    ))}
                                </RadioGroup>
                            </Box>

                            <Button
                                w="full"
                                rounded="4px"
                                colorScheme={"primary"}
                                my="40px"
                                fontSize={"sm"}
                                onClick={() => {
                                    setStage("2");
                                    setTitle("Subscription payment");
                                }}
                                isDisabled={!cardValue}
                            >
                                Proceed
                            </Button>
                        </>
                    )}

                    <Text
                        mt="0px"
                        mb="20px"
                        textAlign={"center"}
                        fontSize="12px"
                        fontWeight={"500"}
                        color="grays.12"
                    >
                        Would you like to pay with another card?
                        <Text
                            mx="2"
                            textDecor="underline"
                            fontWeight={"500"}
                            as="span"
                            color="primary.500"
                            onClick={popUp}
                            cursor={"pointer"}
                        >
                            Add New Card
                        </Text>
                    </Text>
                </>
            )}
            {stage === "2" && (
                <Box mt="40px" textAlign="center">
                    <Text fontSize={"16px"} color="grays.12" fontWeight="500" mt={"-5px"}>
                        You’re about to make a payment of{" "}
                    </Text>
                    <Text fontSize="32px" fontWeight="900">
                        {formatter.format(subscriptionFee)}
                    </Text>
                    <Text fontSize="16px" color="grays.12" fontWeight="500">
                        for Bodsquare Basic Plan.
                    </Text>
                    <Button
                        disabled={isLoading}
                        w="full"
                        rounded="4px"
                        colorScheme={"primary"}
                        my="40px"
                        fontSize={"sm"}
                        onClick={() => mutate(subscribeData)}
                    >
                        {isLoading ? 'Processing payment' : 'Pay'}
                    </Button>
                </Box>
            )}

            <AddCardModal disclosure={addNewCardDisclosure} />
        </Box>
    );
};

export default SelectUpgradePaymentMethod;
