import React, { FC } from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { Link } from "react-router-dom";

interface TemplateCardProps {
  icon: any;
  name: string;
  description: string;
  view: boolean;
}

const TemplateCard: FC<TemplateCardProps> = ({
  icon,
  name,
  description,
  view,
}) => {
  return (
    <Box
      position={"relative"}
      w={"325px"}
      h={"140px"}
      borderWidth={"1px"}
      borderRadius={"4px"}
      borderColor={"borders.500"}
      _hover={{ borderColor: "primary.500" }}
      //@ts-ignore
      pointerEvents={view ? "" : "none"}
    >
      <Flex
        px={"3"}
        py={"3"}
        fontSize="16px"
        fontWeight={700}
        color={"grays.8"}
        alignItems={"center"}
      >
        {icon}
        {name}
      </Flex>
      <Text px={"10"} fontSize={"12px"} color={"grays.12"} fontWeight={500}>
        {description}
      </Text>
      <Box
        p={"10px"}
        w={"100%"}
        h={"40px"}
        bg={"backgrounds.800"}
        position={"absolute"}
        bottom={"0"}
        m={"auto"}
      >
        {view && (
          <Link to={`/account/templates/${name}`}>
            <Text
              textAlign={"center"}
              textDecor={"underline"}
              color={"primary.500"}
              fontSize={"12px"}
              fontWeight={700}
            >
              View
            </Text>
          </Link>
        )}
        {!view && (
          <Text
            textAlign={"center"}
            color={"grays.12"}
            fontSize={"12px"}
            fontWeight={700}
          >
            Coming Soon
          </Text>
        )}
      </Box>
    </Box>
  );
};

export default TemplateCard;
