import { chakra } from "@chakra-ui/react";
import { FC } from "react";

const Iphone: FC<any> = ({ children }) => {
  return (
    <chakra.svg
      width={437}
      height={444}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M366.144 870.952c9.198-.081 18.282-1.847 26.768-5.455a68.973 68.973 0 0 0 22.701-15.661 69.453 69.453 0 0 0 14.78-23.357c3.003-7.895 4.503-16.287 4.538-24.734.006-1.488 0-2.976 0-4.466V71.018c0-5.39-.504-10.746-1.685-16.01a69.542 69.542 0 0 0-11.174-25.063 69.086 69.086 0 0 0-19.994-18.713 68.778 68.778 0 0 0-25.662-9.413c-4.632-.705-9.269-.778-13.936-.778H78.069c-3.138 0-6.278-.044-9.416.01a68.796 68.796 0 0 0-26.6 5.864c-8.436 3.733-16.092 9.195-22.402 15.948A69.309 69.309 0 0 0 5.19 46.455c-2.773 7.636-4.158 15.7-4.188 23.825-.006 1.582 0 3.165 0 4.747v726.074c0 5.683.586 11.331 1.912 16.861 2.16 9.009 6.124 17.566 11.618 25.01a69.313 69.313 0 0 0 20.203 18.323 68.59 68.59 0 0 0 25.77 9.023c4.455.61 8.908.635 13.39.635h284.587l7.661-.001Z"
        stroke="#7489AA"
        strokeWidth={1.603}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M239.657 38.348c-.002-1.846-1.362-3.5-3.245-3.943-.749-.175-1.549-.117-2.314-.117h-24.289c-3.196 0-6.531-.267-9.719.015-4.297.38-5.308 6.144-1.316 7.776.707.289 1.428.329 2.184.33 1.683.003 3.368 0 5.052 0h17.745c3.853 0 7.727.112 11.578 0 1.839-.053 3.488-1.052 4.095-2.753.152-.42.229-.864.229-1.308ZM265.491 38.307c-.001-2.598-1.672-4.98-4.114-5.855-2.44-.875-5.242-.099-6.886 1.91-1.645 2.007-1.86 4.912-.528 7.14 1.332 2.228 3.988 3.411 6.532 2.906 2.532-.504 4.534-2.6 4.925-5.157.047-.313.071-.629.071-.944Z"
        stroke="#7489AA"
        strokeWidth={1.603}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="a"
        style={{
          maskType: "alpha",
        }}
        maskUnits="userSpaceOnUse"
        x={31}
        y={32}
        width={373}
        height={808}
      >
        <path
          d="M294.843 60.952H144.218c-6.681 0-13.483-.25-19.405-3.771-3.542-2.107-6.311-5.241-8.102-8.951-.976-2.025-1.669-4.18-2.15-6.372-.356-1.628-.459-3.305-.841-4.923-.477-2.02-1.574-3.91-3.639-4.583-.796-.259-1.616-.288-2.444-.289-1.876-.002-3.752 0-5.628 0-10.956 0-21.912-.046-32.868 0-9.218.04-18.216 3.493-25.036 9.735-6.846 6.268-11.172 15.054-11.97 24.32-.168 1.945-.138 3.894-.138 5.844V796.28c0 1.933-.003 3.866 0 5.798.007 5.986 1.272 11.864 3.961 17.228 4.18 8.341 11.486 14.914 20.204 18.173 4.934 1.843 10.01 2.349 15.221 2.349H362.073c3.718 0 7.421.091 11.106-.553 9.129-1.598 17.479-6.68 23.111-14.064 4.96-6.505 7.616-14.527 7.646-22.706.005-1.396 0-2.792 0-4.188V73.622c0-1.41.005-2.82 0-4.228-.032-8.18-2.686-16.203-7.646-22.708-5.632-7.386-13.982-12.467-23.111-14.064-3.422-.6-6.849-.554-10.304-.554h-19.529c-5.026 0-10.053-.011-15.079 0-1.829.004-3.546.403-4.734 1.91-1.755 2.23-1.606 5.405-2.199 8.047-.936 4.174-2.682 8.215-5.583 11.397-2.592 2.842-5.959 4.809-9.601 5.97-1.831.583-3.719.974-5.624 1.22-.495.058-5.683.476-5.683.34Z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#a)">
        <rect
          x={31.994}
          y={32.037}
          width={371.943}
          height={807.791}
          rx={4.142}
          stroke="#7489AA"
          strokeWidth={1.603}
        />
        <rect
          x={31.456}
          y={31.466}
          width={371.946}
          height={807.786}
          rx={4.136}
          // fill="#fff"
          fill="#F5F5F5"
        />
      </g>
      <path
        d="M294.842 60.946H144.217c-6.681 0-13.483-.25-19.404-3.771-3.543-2.107-6.311-5.24-8.102-8.951-.976-2.025-1.669-4.18-2.151-6.372-.356-1.628-.458-3.305-.841-4.923-.477-2.02-1.574-3.91-3.639-4.583-.796-.259-1.615-.288-2.444-.289-1.876-.002-3.752 0-5.628 0-10.955 0-21.912-.046-32.867 0-9.219.04-18.217 3.493-25.036 9.735-6.847 6.268-11.173 15.054-11.97 24.32-.169 1.945-.139 3.894-.139 5.844V796.275c0 1.933-.002 3.866 0 5.798.008 5.986 1.272 11.864 3.962 17.228 4.18 8.341 11.485 14.914 20.203 18.173 4.934 1.844 10.011 2.349 15.222 2.349H362.072c3.718 0 7.422.091 11.106-.553 9.129-1.598 17.479-6.68 23.111-14.064 4.96-6.505 7.616-14.527 7.646-22.706.005-1.396 0-2.792 0-4.188V73.616c0-1.41.005-2.82 0-4.228-.031-8.18-2.686-16.203-7.646-22.708-5.632-7.386-13.982-12.467-23.111-14.064-3.421-.6-6.849-.554-10.304-.554h-19.528c-5.027 0-10.053-.011-15.08 0-1.828.004-3.545.403-4.733 1.91-1.756 2.23-1.607 5.405-2.2 8.047-.936 4.174-2.682 8.215-5.583 11.397-2.591 2.842-5.959 4.809-9.6 5.97-1.831.583-3.72.974-5.625 1.22-.495.058-5.683.476-5.683.34Z"
        stroke="#7489AA"
        strokeWidth={1.603}
        strokeMiterlimit={10}
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      {children}
    </chakra.svg>
  );
};

export default Iphone;
