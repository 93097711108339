import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
  useDisclosure,
  Button,
  useToast,
  Tooltip,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  // MenuItemOption,
  // MenuGroup,
  // MenuOptionGroup,
  // MenuDivider,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import CustomModal from "../../UI/CustomModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { fuzzyFilter } from "../../../utils/table";
import { deleteTemplate } from "../../../API/account/templates";
import { toaster } from "../../../utils/toast";
import { removeTags } from "../../../utils/functions";
import { TemplateIcon } from "../../../assets/svgs/svg";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

export interface templateDataProps {
  id: number;
  uid: string;
  country_uid: string;
  name: string;
}

const TemplatesTable: FC<{
  listsData: templateDataProps[];
  globalFilter: any;
  setGlobalFilter: any;
  channel: string;
}> = ({ listsData, globalFilter, setGlobalFilter, channel }) => {
  const [dataToUpdate, setDataToUpdate] = useState<templateDataProps>({
    id: 0,
    uid: "",
    country_uid: "",
    name: "",
  });

  // console.log("lisssssssst", listsData);

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const deleteDisclosure = useDisclosure();

  const navigate = useNavigate();

  const columns = useMemo<ColumnDef<templateDataProps>[]>(() => {
    const whatsappCols = [
      {
        header: () => <Text>Name</Text>,
        accessorKey: "name",
        id: "name",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Preview",
        accessorKey: "template_body",
        id: "template_body",
        cell: (props: any) => {
          return (
            <Tooltip
              placement="bottom-start"
              label={removeTags(props?.getValue())}
              closeOnClick={false}
            >
              <Text
                cursor={"pointer"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                maxW={"350px"}
              >
                {removeTags(props?.getValue())}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        header: "Language",
        accessorKey: "language",
        id: "language",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        id: "status",
        cell: (props: any) => {
          return (
            <Text
              bg={
                props?.getValue() === "approved"
                  ? "green.200"
                  : props.getValue() === "rejected"
                  ? "danger.100"
                  : "yellow.200"
              }
              borderColor={
                props?.getValue() === "approved"
                  ? "green.100"
                  : props.getValue() === "rejected"
                  ? "danger.200"
                  : "yellow.100"
              }
              borderWidth="0.5px"
              color={
                props?.getValue() === "approved"
                  ? "green.100"
                  : props.getValue() === "rejected"
                  ? "danger.200"
                  : "yellow.100"
              }
              px="8px"
              w="fit-content"
              py="4px"
              borderRadius="50px"
            >
              {props?.getValue()}
            </Text>
          );
        },
      },
      {
        header: " ",
        accessorKey: "status",
        id: "action",
        cell: (props: any) => {
          return (
            <Menu>
              <MenuButton
                as={Text}
                textDecoration={"underline"}
                cursor={"pointer"}
                width={"fit-content"}
              >
                Options
              </MenuButton>
              <MenuList>
                {props?.getValue() !== "approved" ? (
                  <MenuItem
                    onClick={() => {
                      navigate(
                        `/account/templates/${channel}/edit/${props?.row?.original.uid}`
                      );
                    }}
                  >
                    Edit Template
                  </MenuItem>
                ) : null}
                <MenuItem
                  onClick={() => {
                    deleteDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  }}
                >
                  Delete Template
                </MenuItem>
              </MenuList>
            </Menu>
          );
        },
      },
      // {
      //   header: "Action",
      //   accessorKey: "action",
      //   id: "action",
      //   cell: (props: any) => {
      //     return (
      //       <Text
      //         onClick={() => {
      //           deleteDisclosure.onOpen();
      //           setDataToUpdate(props?.row?.original);
      //         }}
      //         textDecor={"underline"}
      //         color={"danger.200"}
      //         cursor={"pointer"}
      //       >
      //         Delete
      //       </Text>
      //     );
      //   },
      // },
    ];
    const smsCols = [
      {
        header: () => <Text>Name</Text>,
        accessorKey: "name",
        id: "name",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Preview",
        accessorKey: "template_body",
        id: "template_body",
        cell: (props: any) => {
          return (
            <Tooltip
              placement="bottom-start"
              label={removeTags(props?.getValue())}
              closeOnClick={false}
            >
              <Text
                cursor={"pointer"}
                overflow={"hidden"}
                whiteSpace={"nowrap"}
                textOverflow={"ellipsis"}
                maxW={"350px"}
                fontSize={"12px"}
                fontWeight={500}
              >
                {removeTags(props?.getValue())}
              </Text>
            </Tooltip>
          );
        },
      },
      {
        header: "Created at",
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text
              fontSize={"12px"}
              fontWeight={500}
              color={"grays.12"}
              w="fit-content"
            >
              {dayjs(props?.getValue()).format("MMM DD, YYYY - h:mm A")}
            </Text>
          );
        },
      },
      {
        header: " ",
        // accessorKey: "action",
        id: "action",
        cell: (props: any) => {
          return (
            <Menu>
              <MenuButton
                as={Text}
                textDecoration={"underline"}
                cursor={"pointer"}
                width={"fit-content"}
              >
                Options
              </MenuButton>
              <MenuList>
                <MenuItem
                  onClick={() => {
                    navigate(
                      `/account/templates/${channel}/edit/${props?.row?.original.uid}`
                    );
                  }}
                >
                  Edit Template
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    deleteDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  }}
                >
                  Delete Template
                </MenuItem>
              </MenuList>
            </Menu>
          );
        },
      },
    ];

    return channel === "Whatsapp" ? whatsappCols : smsCols;
  }, [deleteDisclosure, channel, navigate]);

  const data = listsData;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,

    debugTable: true,
  });
  const queryClient = useQueryClient();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(deleteTemplate, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      queryClient.invalidateQueries(["templateData"]);
      deleteDisclosure.onClose();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <>
      <Box h={"100%"}>
        <TableContainer
          overflowX="unset"
          overflowY="unset"
          borderWidth={"1px"}
          borderColor="borders.200"
          rounded={"8px"}
        >
          <Table variant={"simple"} position={"relative"}>
            <Thead
            // position={"sticky"}
            // top={0}
            // bg={"white"}
            // boxShadow={"sm"}
            >
              {table.getHeaderGroups().map((headerGroup) => {
                return (
                  <Tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <Th
                          key={header.id}
                          textTransform="none"
                          color={"grays.9"}
                          fontSize="xs"
                          letterSpacing={"unset"}
                          fontWeight={500}
                          py={"12px"}
                          colSpan={header.colSpan}
                        >
                          <Box display={"flex"}>
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {header.column.getCanSort() && (
                              <VStack spacing="2px" my="auto" ml="2">
                                <chakra.button
                                  onClick={() => {
                                    header.column.toggleSorting();
                                  }}
                                >
                                  <SortArrowUp width="8px" />
                                </chakra.button>

                                <chakra.button
                                  onClick={() => {
                                    header.column.toggleSorting();
                                  }}
                                >
                                  <SortArrowDown width="8px" />
                                </chakra.button>
                              </VStack>
                            )}
                          </Box>
                        </Th>
                      );
                    })}
                  </Tr>
                );
              })}
            </Thead>
            <Tbody>
              {table.getRowModel().rows.map((row) => (
                <Tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <Td
                      key={cell.id}
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={"medium"}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {/* Delete template */}
      <CustomModal disclosure={deleteDisclosure}>
        <Box
          mt="42px"
          pb="40px"
          textAlign={"center"}
          w="full"
          display={"grid"}
          alignItems="center"
        >
          <TemplateIcon
            style={{
              // paddingTop: '1.5em',
              paddingBottom: "1.5em",
              width: "22em",
              height: "10em",
            }}
          />
          <Text
            mt="5px"
            wordBreak={"break-word"}
            w="15pc"
            fontSize={"20px"}
            fontWeight="bold"
            color="grays.8"
            maxW={"360px"}
            mx="auto"
          >
            Are you sure you want to delete this template?
          </Text>

          <Button
            w="full"
            isLoading={isLoading}
            onClick={() => {
              mutate({
                uid: dataToUpdate?.uid,
                channel: channel,
              });
            }}
            colorScheme={"red"}
            mt="40px"
          >
            Delete Template
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default TemplatesTable;
