import React from 'react';

const ProfileCircle = () => {
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M12.1004 13.2747C12.0837 13.2747 12.0587 13.2747 12.0421 13.2747C12.0171 13.2747 11.9837 13.2747 11.9587 13.2747C10.0671 13.2164 8.65039 11.7414 8.65039 9.92474C8.65039 8.07474 10.1587 6.56641 12.0087 6.56641C13.8587 6.56641 15.3671 8.07474 15.3671 9.92474C15.3587 11.7497 13.9337 13.2164 12.1254 13.2747C12.1087 13.2747 12.1087 13.2747 12.1004 13.2747ZM12.0004 7.80807C10.8337 7.80807 9.89206 8.75807 9.89206 9.91641C9.89206 11.0581 10.7837 11.9831 11.9171 12.0247C11.9421 12.0164 12.0254 12.0164 12.1087 12.0247C13.2254 11.9664 14.1004 11.0497 14.1087 9.91641C14.1087 8.75807 13.1671 7.80807 12.0004 7.80807Z'
        fill='currentColor'
      />
      <path
        d='M12.002 20.9586C9.76031 20.9586 7.61865 20.1253 5.96031 18.6086C5.81031 18.4753 5.74365 18.2753 5.76031 18.0836C5.86865 17.0919 6.48531 16.1669 7.51031 15.4836C9.99365 13.8336 14.0186 13.8336 16.4936 15.4836C17.5186 16.1753 18.1353 17.0919 18.2436 18.0836C18.2686 18.2836 18.1936 18.4753 18.0436 18.6086C16.3853 20.1253 14.2436 20.9586 12.002 20.9586ZM7.06865 17.9169C8.45198 19.0753 10.1936 19.7086 12.002 19.7086C13.8103 19.7086 15.552 19.0753 16.9353 17.9169C16.7853 17.4086 16.3853 16.9169 15.7936 16.5169C13.7436 15.1503 10.2686 15.1503 8.20198 16.5169C7.61031 16.9169 7.21865 17.4086 7.06865 17.9169Z'
        fill='currentColor'
      />
      <path
        d='M12.0013 20.9577C7.05964 20.9577 3.04297 16.941 3.04297 11.9993C3.04297 7.05768 7.05964 3.04102 12.0013 3.04102C16.943 3.04102 20.9596 7.05768 20.9596 11.9993C20.9596 16.941 16.943 20.9577 12.0013 20.9577ZM12.0013 4.29102C7.7513 4.29102 4.29297 7.74935 4.29297 11.9993C4.29297 16.2493 7.7513 19.7077 12.0013 19.7077C16.2513 19.7077 19.7096 16.2493 19.7096 11.9993C19.7096 7.74935 16.2513 4.29102 12.0013 4.29102Z'
        fill='currentColor'
      />
    </svg>
  );
};

export default ProfileCircle;
