import {
  Avatar,
  Box,
  HStack,
  Image,
  Link,
  Text,
  chakra,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { FC } from "react";
import { useSearchParams } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { sendSocialMessage } from "../../../API/conversations";
import { chatAtom } from "../../../atoms/conversation";
import { profileAtom } from "../../../atoms/user";
import { Message } from "../../../utils/generalProps";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import { toaster } from "../../../utils/toast";
dayjs.extend(advancedFormat);

const DisplayMessage: FC<{ type: string; message: any }> = ({
  message,
  type,
}) => {
  let Display = <></>;

  const urlify = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.replace(urlRegex, (url: string) => {
      return `<a target="__blank" style="color: #0066FF; text-decoration:underline !important" href="${url}">${url}</a>`;
    });
  };

  switch (type) {
    case "text":
      Display = <div dangerouslySetInnerHTML={{ __html: urlify(message) }} />;
      break;

    case "image":
      Display = (
        <Link isExternal href={message}>
          <Image
            w="163px"
            h="141px"
            src={message}
            alt={message}
            objectFit="cover"
            rounded="lg"
            borderWidth={"1px"}
          />
        </Link>
      );
      break;

    case "audio":
      Display = <chakra.audio src={message} controls autoPlay></chakra.audio>;

      break;

    case "video":
      Display = <chakra.video src={message} controls></chakra.video>;

      break;

    default:
      <>{message}</>;
  }

  return <>{Display}</>;
};

// 'opened', 'received', '

const MessageStatusIcon = (status: string) => {
  let iconToUse;

  switch (status) {
    case "queued":
      iconToUse = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="6" cy="6" r="5" stroke="#171D26" strokeWidth="0.75" />
          <path
            d="M6 4V5.8662C6 5.94979 6.04178 6.02785 6.11132 6.07422L7.5 7"
            stroke="#171D26"
            strokeWidth="0.75"
            strokeLinecap="round"
          />
        </svg>
      );
      break;

    case "sent":
      iconToUse = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.875 6.43325L4.16997 8.2069C4.57585 8.7628 5.40895 8.752 5.8003 8.18575L9.125 3.375"
            stroke="#171D26"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    case "delivered":
      iconToUse = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 6.05825L2.29497 7.8319C2.70085 8.3878 3.53395 8.377 3.9253 7.81075L7.25 3"
            stroke="#171D26"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.5 7L5.29497 7.8319C5.70085 8.3878 6.53395 8.377 6.9253 7.81075L10.25 3"
            stroke="#171D26"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

      break;

    case "opened":
      iconToUse = (
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 6.05825L2.29497 7.8319C2.70085 8.3878 3.53395 8.377 3.9253 7.81075L7.25 3"
            stroke="#0066FF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M4.5 7L5.29497 7.8319C5.70085 8.3878 6.53395 8.377 6.9253 7.81075L10.25 3"
            stroke="#0066FF"
            strokeWidth="0.75"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
      break;

    default:
      break;
  }

  return iconToUse;
};

const CustomerMessagebox: FC<{ data: Message; contact: string }> = ({
  data,
  contact,
}) => {
  const chatData = useRecoilValue(chatAtom);

  return (
    <HStack mb="24px" spacing={"8px"} align={"flex-start"}>
      <Avatar
        h={"28px"}
        w="28px"
        color="primary.500"
        bg="#EBF3FF"
        name={contact || "Contact"}
        size="sm"
        src={chatData?.conversation?.contact?.profile_picture}
      />

      {/* customer */}
      <Box maxW="448px">
        <Box
          borderTopRadius={"8px"}
          borderTopRightRadius="8px"
          bg="rgba(0, 102, 255, 0.08)"
          py="8px"
          px="16px"
          fontSize={"xs"}
          color="grays.9"
        >
          {/* {data?.message} */}
          <DisplayMessage message={data?.message} type={data?.message_type} />
        </Box>

        <HStack spacing={"4px"} mt="4px">
          <Text fontSize={"xs"} color="grays.10">
            {dayjs(data?.created_at).format("ddd Do MMM, hh:mm A")}
          </Text>
          <svg
            width="2"
            height="2"
            viewBox="0 0 2 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="#99A1AD" />
          </svg>

          <Text fontSize={"xs"} color="grays.10">
            {data?.platform}
          </Text>

          <svg
            width="2"
            height="2"
            viewBox="0 0 2 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="1" cy="1" r="1" fill="#99A1AD" />
          </svg>

          <Text fontSize={"xs"} color="grays.10">
            {chatData?.conversation?.instagram_connection?.page_name ||
              chatData?.conversation?.facebook_connection?.page_name ||
              `Twitter Id: ${chatData?.conversation?.twitter_connection?.id}`}
          </Text>
        </HStack>
      </Box>
    </HStack>
  );
};

const UserMessageBox: FC<{
  data: Message;
  contact: string;
}> = ({ data, contact }) => {
  const queryClient = useQueryClient();
  const [searchParams] = useSearchParams();
  const toast = useToast();

  const chatData = useRecoilValue(chatAtom);

  const { mutate, isLoading } = useMutation(sendSocialMessage, {
    onSuccess() {
      queryClient.invalidateQueries(["allConversations"]);
      queryClient.invalidateQueries([
        `singleChatData${searchParams?.get("uid")}`,
      ]);
    },
    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <Box ml="auto" mb="24px">
      <HStack spacing={"8px"} align={"flex-start"}>
        <Box maxW="448px">
          <Box
            borderTopRadius={"8px"}
            borderTopRightRadius="8px"
            bg="#F6F6F7"
            py="8px"
            px="16px"
            fontSize={"xs"}
            color="grays.9"
          >
            {/* {data?.message} */}
            <DisplayMessage message={data?.message} type={data?.message_type} />
          </Box>

          {data?.status !== "failed" && (
            <HStack spacing={"4px"} mt="4px">
              <Text fontSize={"xs"} color="grays.10">
                {dayjs(data?.created_at).format("ddd Do MMM, hh:mm A")}
              </Text>
              <svg
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="1" cy="1" r="1" fill="#99A1AD" />
              </svg>

              <Text fontSize={"xs"} color="grays.10">
                {data?.platform}
              </Text>
              {MessageStatusIcon(data?.status)}
            </HStack>
          )}
        </Box>

        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="14" cy="14" r="14" fill="#0066FF" fillOpacity="0.08" />
          <path
            d="M11.7485 14.0637C12.3292 14.3805 12.9946 14.5614 13.703 14.5614C14.4113 14.5614 15.0768 14.3815 15.6574 14.0637"
            stroke="#0066FF"
            strokeWidth="1.40615"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5828 18.1327L10.853 19.9063C10.6088 20.1188 10.2285 19.9455 10.2285 19.6221L10.4131 18.1336"
            stroke="#0066FF"
            strokeWidth="1.40615"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12.5827 18.1329H17.5439C18.5719 18.1329 19.4061 17.2988 19.4061 16.2707V9.86218C19.4061 8.83416 18.5719 8 17.5439 8H9.86218C8.83416 8 8 8.83416 8 9.86218V16.2707C8 17.2988 8.83416 18.1329 9.86218 18.1329H10.413"
            stroke="#0066FF"
            strokeWidth="1.40615"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </HStack>

      {data?.status === "failed" && (
        <HStack mt={2}>
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.88 7.96L7.67999 2.2C7.24999 1.425 6.65499 1 5.99999 1C5.34499 1 4.74999 1.425 4.31999 2.2L1.11999 7.96C0.714989 8.695 0.669989 9.4 0.994989 9.955C1.31999 10.51 1.95999 10.815 2.79999 10.815H9.19999C10.04 10.815 10.68 10.51 11.005 9.955C11.33 9.4 11.285 8.69 10.88 7.96Z"
              fill="#D52108"
              fillOpacity="0.14"
            />
            <path
              d="M6 7.375C5.795 7.375 5.625 7.205 5.625 7V4.5C5.625 4.295 5.795 4.125 6 4.125C6.205 4.125 6.375 4.295 6.375 4.5V7C6.375 7.205 6.205 7.375 6 7.375Z"
              fill="#F84F4C"
            />
            <path
              d="M6 9.00027C5.97 9.00027 5.935 8.99527 5.9 8.99027C5.87 8.98527 5.84 8.97527 5.81 8.96027C5.78 8.95027 5.75 8.93527 5.72 8.91527C5.695 8.89527 5.67 8.87527 5.645 8.85527C5.555 8.76027 5.5 8.63027 5.5 8.50027C5.5 8.37027 5.555 8.24027 5.645 8.14527C5.67 8.12527 5.695 8.10527 5.72 8.08527C5.75 8.06527 5.78 8.05027 5.81 8.04027C5.84 8.02527 5.87 8.01527 5.9 8.01027C5.965 7.99527 6.035 7.99527 6.095 8.01027C6.13 8.01527 6.16 8.02527 6.19 8.04027C6.22 8.05027 6.25 8.06527 6.28 8.08527C6.305 8.10527 6.33 8.12527 6.355 8.14527C6.445 8.24027 6.5 8.37027 6.5 8.50027C6.5 8.63027 6.445 8.76027 6.355 8.85527C6.33 8.87527 6.305 8.89527 6.28 8.91527C6.25 8.93527 6.22 8.95027 6.19 8.96027C6.16 8.97527 6.13 8.98527 6.095 8.99027C6.065 8.99527 6.03 9.00027 6 9.00027Z"
              fill="#F84F4C"
            />
          </svg>

          <Text color={"#F84F4C"} fontSize="10px">
            Message not sent
          </Text>

          <Button
            onClick={() => {
              mutate({
                social: data?.platform,
                message_type: data?.message_type,
                message: data?.message,
                conversation_uid: chatData?.conversation?.uid,
              });
            }}
            variant={"unstyled"}
            fontWeight={500}
            textDecor="underline"
            minW={"auto"}
            height="unset"
            fontSize="10px"
            color={"#F84F4C"}
            textTransform="uppercase"
            isLoading={isLoading}
          >
            Resend
          </Button>
        </HStack>
      )}
    </Box>
  );
};

const DisplayChat: FC<{
  Messages: Message[];
  fullname: string;
}> = ({ Messages, fullname }) => {
  const userData = useRecoilValue(profileAtom);

  return (
    <>
      {Messages?.map((message: Message) => {
        if (message?.message_from === "customer") {
          return (
            <CustomerMessagebox
              key={message?.uid}
              data={message}
              contact={fullname.trim() === "" ? "Contact" : fullname}
            />
          );
        } else {
          return (
            <UserMessageBox
              key={message?.uid}
              data={message}
              contact={userData?.name ?? "User"}
            />
          );
        }
      })}
    </>
  );
};

export default DisplayChat;
