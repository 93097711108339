import React, { FC } from "react";

const CardImage: FC<{ type: string }> = ({ type }) => {
  let cardToReturn = <></>;

  switch (type) {
    case "MASTERCARD":
      cardToReturn = (
        <svg
          width="23"
          height="14"
          viewBox="0 0 23 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.179 11.8295C9.99493 12.8275 8.45902 13.43 6.78069 13.43C3.03582 13.43 0 10.4303 0 6.73003C0 3.02972 3.03582 0.0300293 6.78069 0.0300293C8.45902 0.0300293 9.99493 0.632527 11.179 1.63057C12.363 0.632527 13.8989 0.0300293 15.5773 0.0300293C19.3221 0.0300293 22.358 3.02972 22.358 6.73003C22.358 10.4303 19.3221 13.43 15.5773 13.43C13.8989 13.43 12.363 12.8275 11.179 11.8295Z"
            fill="#ED0006"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.179 11.8295C12.6369 10.6006 13.5614 8.77198 13.5614 6.73003C13.5614 4.68807 12.6369 2.85947 11.179 1.63057C12.363 0.632526 13.8989 0.0300293 15.5772 0.0300293C19.3221 0.0300293 22.3579 3.02972 22.3579 6.73003C22.3579 10.4303 19.3221 13.43 15.5772 13.43C13.8989 13.43 12.363 12.8275 11.179 11.8295Z"
            fill="#F9A000"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M11.179 11.8295C12.6369 10.6006 13.5614 8.77199 13.5614 6.73005C13.5614 4.68811 12.6369 2.85952 11.179 1.63062C9.72109 2.85952 8.79663 4.68811 8.79663 6.73005C8.79663 8.77199 9.72109 10.6006 11.179 11.8295Z"
            fill="#FF5E00"
          />
        </svg>
      );
      break;

    case "VISA":
      cardToReturn = (
        <svg
          width="40"
          height="20"
          viewBox="0 0 40 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_2551_32767)">
            <path
              d="M17.3331 15.7093H14.0928L16.1195 3.96094H19.3596L17.3331 15.7093Z"
              fill="#00579F"
            />
            <path
              d="M29.0803 4.25018C28.4412 4.01246 27.4275 3.75 26.174 3.75C22.9741 3.75 20.7208 5.3497 20.707 7.63678C20.6804 9.32419 22.3203 10.2614 23.5468 10.824C24.8004 11.399 25.2266 11.7742 25.2266 12.2867C25.2138 13.0738 24.2136 13.4366 23.2807 13.4366C21.987 13.4366 21.2939 13.2495 20.2405 12.8116L19.8138 12.6239L19.3604 15.2612C20.1203 15.5858 21.5205 15.8738 22.9741 15.8864C26.3741 15.8864 28.5875 14.3115 28.6138 11.8742C28.6267 10.5368 27.7608 9.51207 25.8939 8.67467C24.7606 8.13712 24.0665 7.77466 24.0665 7.22465C24.0798 6.72464 24.6536 6.2125 25.9329 6.2125C26.9863 6.18742 27.7602 6.4248 28.3466 6.66235L28.6396 6.7871L29.0803 4.25018Z"
              fill="#00579F"
            />
            <path
              d="M33.3863 11.5473C33.6531 10.8724 34.68 8.26019 34.68 8.26019C34.6665 8.28527 34.9463 7.5728 35.1063 7.13541L35.3327 8.14773C35.3327 8.14773 35.9463 10.9599 36.0796 11.5473C35.5732 11.5473 34.0263 11.5473 33.3863 11.5473ZM37.386 3.96094H34.8797C34.1067 3.96094 33.5195 4.17323 33.1861 4.93571L28.373 15.7092H31.773C31.773 15.7092 32.3327 14.2591 32.453 13.9468C32.826 13.9468 36.1335 13.9468 36.6133 13.9468C36.7063 14.3593 36.9999 15.7092 36.9999 15.7092H40.0001L37.386 3.96094Z"
              fill="#00579F"
            />
            <path
              d="M11.3867 3.96094L8.21338 11.9722L7.86662 10.3474C7.27994 8.47265 5.44 6.43573 3.38672 5.42292L6.29335 15.6969H9.71986L14.813 3.96094H11.3867Z"
              fill="#00579F"
            />
            <path
              d="M5.26663 3.96094H0.0533341L0 4.19832C4.0667 5.17325 6.75999 7.52329 7.86654 10.3479L6.73323 4.9485C6.54665 4.19815 5.97326 3.98569 5.26663 3.96094Z"
              fill="#FAA61A"
            />
          </g>
          <defs>
            <clipPath id="clip0_2551_32767">
              <rect
                width="40"
                height="12.1364"
                fill="white"
                transform="translate(0 3.75)"
              />
            </clipPath>
          </defs>
        </svg>
      );
      break;
    default:
  }

  return <>{cardToReturn}</>;
};

export default CardImage;
