import React, { FC } from 'react';

const PaperClip: FC = (props) => {
  return (
    <svg
      width={20}
      height={20}
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M16.264 9.9l-6.718 6.717a4.5 4.5 0 01-6.364 0v0a4.5 4.5 0 010-6.364l8.132-8.132a3 3 0 014.242 0v0a3 3 0 010 4.243l-8.232 8.233a1.5 1.5 0 01-2.122 0v0a1.5 1.5 0 010-2.122l6.819-6.818'
        stroke='#50555C'
        strokeWidth={1.5}
        strokeLinecap='round'
      />
    </svg>
  );
};

export default PaperClip;
