import {
  Box,
  Flex,
  HStack,
  Text,
  Link as ChakraLink,
  List,
  ListItem,
  ListIcon,
  Button,
  Center,
  Spinner,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { Link, useSearchParams } from "react-router-dom";
import {
  addFacebookAccount,
  addInstagramAccount,
  addTwitterAccount,
  retrieveConnectionUrl,
} from "../../../API/account/channels";
import AddedSuccessfully from "../../../components/account/channels/AddedSuccessfully";
import { Facebook, Instagram, Twitter } from "../../../icons/Socials";

const AddChannel = () => {
  let [searchParams] = useSearchParams();

  const [currentState, setCurrentState] = useState("creating");

  let dataToUse = useMemo(() => {
    const instagramData = {
      icon: <Instagram width="40px" height="40px" />,
      title: "Connecting Instagram",
      description:
        "This integration allows your team to chat with your customers over Instagram.",
      whatYouNeed: [
        <Text as="span">
          Please ensure your instagram account is set up as
          professional/business account.{" "}
          <ChakraLink
            href="https://guides.bodsquare.com/channels/instagram/convert-to-business-ig-page"
            isExternal
            color="primary.500"
            textDecor={"underline"}
          >
            See Guide Here
          </ChakraLink>
        </Text>,
        <Text as="span">
          Enable "Allow Access to Messages" in Instagram settings.{" "}
          <ChakraLink
            href="https://guides.bodsquare.com/channels/instagram/allow-access-to-messages"
            isExternal
            color="primary.500"
            textDecor={"underline"}
          >
            See Guide here
          </ChakraLink>
        </Text>,
        <Text as="span">
          Ensure you have Admin access to your linked Facebook Page
        </Text>,
      ],
    };

    const facebookData = {
      icon: <Facebook width="40px" height="40px" />,
      title: "Connecting Facebook",
      description:
        "This integration allows your team to chat with your customers over Facebook.",
      whatYouNeed: [
        <Text as="span">Ensure you have a Facebook Account</Text>,
        <Text as="span">
          Ensure you own or have admin access to your Facebook page.
        </Text>,
      ],
    };

    const twitterData = {
      icon: <Twitter width="40px" height="40px" />,
      title: "Connecting Twitter",
      description:
        "This integration allows your team to chat with your customers over Twitter.",
      whatYouNeed: [<Text as="span">Ensure you have a Twitter Account</Text>],
    };
    switch (searchParams.get("ch")) {
      case "Instagram":
        return instagramData;

      case "Facebook":
        return facebookData;

      case "Twitter":
        return twitterData;

      default:
        return {
          icon: <></>,
          title: "",
          description: "",
          whatYouNeed: [],
        };
    }
    //  @ts-ignore
  }, [searchParams]);

  const [urlToUse, setUrlToUse] = useState("");

  // Get connection url
  const { isLoading } = useQuery(
    ["getUrl"],
    () => {
      return retrieveConnectionUrl(searchParams.get("ch")?.toLowerCase());
    },
    {
      onSuccess(data) {
        setUrlToUse(data);
      },
      enabled: !!searchParams.get("ch"),
    }
  );

  // Add twitter account
  const {
    mutate: addFacebookHandler,
    isLoading: addFacebookLoading,
    isSuccess: facebookSuccess,
    isError: facebookError,
  } = useMutation(addFacebookAccount);
  const {
    mutate: addTwitterHandler,
    isLoading: addTwitterLoading,
    isSuccess: twitterSuccess,
    isError: twitterError,
  } = useMutation(addTwitterAccount);
  const {
    mutate: addInstagramHandler,
    isLoading: addInstagramLoading,
    isSuccess: instagramSuccess,
    isError: instagramError,
  } = useMutation(addInstagramAccount);

  useEffect(() => {
    if (searchParams.get("platform") === "facebook") {
      // facebook
      setCurrentState("verifying");

      // @ts-ignore
      const page_id = JSON.parse(searchParams?.get("page_id"))[0]?.id;

      // @ts-ignore
      const page_name = JSON.parse(searchParams?.get("page_id"))[0]?.name;

      addFacebookHandler({
        access_token: searchParams?.get("access_token"),
        page_id,
        page_name,
      });
    }

    // instagram
    if (searchParams.get("platform") === "instagram") {
      setCurrentState("verifying");

      // @ts-ignore
      const page_id = JSON.parse(searchParams?.get("page_id"))[0]?.id;

      // @ts-ignore
      const page_name = JSON.parse(searchParams?.get("page_id"))[0]?.name;

      addInstagramHandler({
        access_token: searchParams?.get("access_token"),
        page_id: page_id || "",
        page_name: page_name || "",
      });
    }

    // twitter
    if (searchParams.get("oauth_token")) {
      setCurrentState("verifying");

      const oauth_token = searchParams.get("oauth_token");
      const oauth_verifier = searchParams.get("oauth_verifier");

      addTwitterHandler({
        auth_token: oauth_token,
        auth_verifier: oauth_verifier,
      });
    }

    return () => {
      setCurrentState("creating");
    };
  }, [
    searchParams,
    addFacebookHandler,
    addInstagramHandler,
    addTwitterHandler,
  ]);

  return (
    // <Flex flexDir={"column"} w="full" h="full">
    <Box mt={"0px"}>
      <Box overflowY={"scroll"} className="thinSB">
        <Link to="/account/channels">
          {" "}
          <HStack spacing={"6px"}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13.5L8.5 10.25L12 7"
                stroke="#1A1A1A"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Text fontSize={"xs"} color="grays.9">
              Back
            </Text>
          </HStack>
        </Link>

        <Box maxW={"360px"} w="full" mx="auto" mt={"18px"} minHeight={"90vh"}>
          {currentState === "creating" && (
            <>
              <Box px={{ md: "14px" }}>
                {/* header */}
                <Flex
                  w="full"
                  flexDir={"column"}
                  pb="20px"
                  borderBottomColor={"rgba(126, 126, 126, 0.24)"}
                  borderBottomWidth="1px"
                >
                  <Box mx="auto">{dataToUse.icon}</Box>
                  <Text
                    mt="12px"
                    fontWeight={700}
                    fontSize="24px"
                    color="grays.9"
                    textAlign={"center"}
                  >
                    {dataToUse.title}
                  </Text>
                  <Text
                    mt="8px"
                    color="grays.9"
                    fontSize={"sm"}
                    textAlign={"center"}
                  >
                    {dataToUse.description}
                  </Text>
                </Flex>

                {/* What you'll need */}
                <Text
                  mt="24px"
                  fontSize={"sm"}
                  fontWeight={500}
                  color="grays.9"
                >
                  What you'll need:
                </Text>

                <List spacing={"24px"} mt="13px">
                  {dataToUse?.whatYouNeed?.map((data, index) => {
                    return (
                      <ListItem
                        key={index}
                        fontSize="sm"
                        color="grays.9"
                        display={"flex"}
                      >
                        <ListIcon
                          as={HiOutlineCheckCircle}
                          color="green.500"
                          w="24px"
                          h="24px"
                        />
                        {data}
                      </ListItem>
                    );
                  })}
                </List>
              </Box>

              <Button
                type="submit"
                isDisabled={isLoading}
                onClick={() => {
                  window.open(urlToUse);
                }}
                w="full"
                rounded="4px"
                bg="primary.500"
                _disabled={{
                  bg: "primary.400",
                }}
                _hover={{
                  bg: "primary.400",
                }}
                _focus={{
                  bg: "primary.400",
                }}
                color={"white"}
                mt="54px"
                fontSize={"sm"}
              >
                Connect with {searchParams.get("ch")}
              </Button>
            </>
          )}

          <>
            {addTwitterLoading && (
              <Center h="50vh" flexDir={"column"}>
                <Spinner color="primary.500" />
                <Text fontSize={"sm"} mt={3}>
                  Adding your twitter account...
                </Text>
              </Center>
            )}

            {addInstagramLoading && (
              <Center h="50vh" flexDir={"column"}>
                <Spinner color="primary.500" />
                <Text fontSize={"sm"} mt={3}>
                  Adding your instagram account...
                </Text>
              </Center>
            )}

            {addFacebookLoading && (
              <Center h="50vh" flexDir={"column"}>
                <Spinner color="primary.500" />
                <Text fontSize={"sm"} mt={3}>
                  Adding your facebook account...
                </Text>
              </Center>
            )}

            {instagramSuccess && <AddedSuccessfully />}

            {facebookSuccess && <AddedSuccessfully />}

            {twitterSuccess && <AddedSuccessfully />}

            {instagramError && (
              <Center h="50vh" fontSize={"sm"}>
                Couldn't add account
              </Center>
            )}
            {twitterError && (
              <Center h="50vh" fontSize={"sm"}>
                Couldn't add account
              </Center>
            )}

            {facebookError && (
              <Center h="50vh" fontSize={"sm"}>
                Couldn't add account
              </Center>
            )}
          </>
        </Box>
      </Box>
    </Box>
  );
};

export default AddChannel;
