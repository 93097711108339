import {
  Box,
  Button,
  Flex,
  Text,
  chakra,
  Stack,
  HStack,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/UI/Loader";
import { Link } from "react-router-dom";
import { usePagination } from "@ajna/pagination";
import { useState } from "react";
import CustomPaginaton from "../../../components/UI/CustomPaginaton";
import { getOptinData } from "../../../API/account/optin";
import ListOptinTable from "../../../components/account/optin/ListOptinTable";
import ChakraSelect from "../../../components/UI/form/ChakraSelect";

const Optin = () => {
  const [dataToShow, setDataToShow] = useState(10);
  const [myCurrentPage, setMyCurrentPage] = useState(1);

  const { isLoading, data } = useQuery(
    ["listOptinData", myCurrentPage, dataToShow],
    () => {
      return getOptinData(myCurrentPage, dataToShow);
    }
  );

  const [globalFilter, setGlobalFilter] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 3,
      inner: 3,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  return (
    <Box w="full" h="full">
      <Flex>
        <Box>
          <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
            Opt-In
          </Text>
          <Text fontSize={"12px"} color="grays.12" fontWeight={"500"}>
            Aquire customers
          </Text>
        </Box>
        <Button
          colorScheme={"primary"}
          ml="auto"
          size={"sm"}
          isDisabled={isLoading}
        >
          <Link to="create"> Create Opt-in</Link>
        </Button>
      </Flex>

      <Box mt="32px">
        {/* Empty */}
        {!isLoading && data?.docs?.length === 0 && (
          <Stack mt="20" justifyContent={"center"} alignItems={"center"}>
            <svg
              width="96"
              height="96"
              viewBox="0 0 96 96"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M56.5209 45.3545C58.0809 38.7945 52.3609 33.0743 45.8009 34.6343C42.7209 35.3943 40.2009 37.9144 39.4409 40.9944C37.8809 47.5544 43.6009 53.2743 50.1609 51.7143C53.2809 50.9543 55.8009 48.4345 56.5209 45.3545Z"
                fill="#7489AA"
              />
              <path
                opacity="0.4"
                d="M24.0798 71.8799C23.3598 71.8799 22.5998 71.6001 22.0398 71.0801C14.2798 63.8801 9.83984 53.7199 9.83984 43.1599C9.83984 22.1199 26.9598 5 47.9998 5C69.0398 5 86.1598 22.1199 86.1598 43.1599C86.1598 53.7999 81.8798 63.64 74.1198 70.96C72.9198 72.08 70.9998 72.0401 69.8798 70.8401C68.7598 69.6401 68.7998 67.7201 69.9998 66.6001C76.5598 60.4401 80.1598 52.16 80.1598 43.2C80.1598 25.48 65.7198 11.04 47.9998 11.04C30.2798 11.04 15.8398 25.48 15.8398 43.2C15.8398 52.24 19.4798 60.56 26.1198 66.72C27.3198 67.84 27.3998 69.76 26.2798 70.96C25.6798 71.56 24.8798 71.8799 24.0798 71.8799Z"
                fill="#7489AA"
              />
              <path
                opacity="0.4"
                d="M64.0001 61.21C63.2801 61.21 62.5201 60.9299 61.9601 60.4099C60.7601 59.2899 60.6801 57.3699 61.8401 56.1699C65.1601 52.6499 67.0001 48.01 67.0001 43.21C67.0001 32.73 58.48 24.25 48.04 24.25C37.6 24.25 29.0801 32.77 29.0801 43.21C29.0801 48.05 30.9201 52.6499 34.2401 56.1699C35.3601 57.3699 35.3201 59.2899 34.1201 60.4099C32.9201 61.5699 31.0001 61.49 29.8801 60.29C25.5201 55.65 23.0801 49.57 23.0801 43.21C23.0801 29.45 34.28 18.25 48.04 18.25C61.8 18.25 73.0001 29.45 73.0001 43.21C73.0001 49.57 70.6001 55.65 66.2001 60.29C65.6001 60.89 64.8001 61.21 64.0001 61.21Z"
                fill="#7489AA"
              />
              <path
                d="M41.2009 66.645L35.4409 73.8052C30.8809 79.5252 34.9209 87.9651 42.2409 87.9651H53.7209C61.0409 87.9651 65.1209 79.4852 60.5209 73.8052L54.7609 66.645C51.3209 62.285 44.6809 62.285 41.2009 66.645Z"
                fill="#7489AA"
              />
            </svg>

            <Text
              textAlign={"center"}
              w={"285px"}
              fontSize={"20px"}
              fontWeight={700}
              color="grays.8"
            >
              Acquire more customers faster with just one click
            </Text>
            <Button
              colorScheme={"primary"}
              ml="auto"
              size={"sm"}
              isDisabled={isLoading}
            >
              <Link to="create"> Create Opt-in</Link>
            </Button>
          </Stack>
        )}

        {isLoading && <Loader />}

        {!isLoading && data?.docs?.length > 0 && (
          <>
            <ListOptinTable
              optinData={data?.docs}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
            />
            <HStack
              spacing={"10px"}
              pt="30px"
              ml="auto"
              w="fit-content"
              justifyContent={"end"}
            >
              <Text color="grays.12" fontWeight={500} fontSize="xs">
                Show
              </Text>
              <ChakraSelect
                selectProps={{
                  h: "24px",
                  borderColor: "grays.12",
                  fontWeight: 500,
                  color: "grays.12",
                  value: Number(dataToShow),
                  onChange: (e) => {
                    setDataToShow(Number(e.target.value));
                  },
                }}
              >
                <chakra.option value={10}>10</chakra.option>
                <chakra.option value={20}>20</chakra.option>
                <chakra.option value={25}>25</chakra.option>
                <chakra.option value={30}>30</chakra.option>
                <chakra.option value={50}>50</chakra.option>
              </ChakraSelect>
              <CustomPaginaton
                pagesCount={pagesCount}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
                pages={pages}
                numberOfdataToShow={dataToShow}
                setDataToShow={setDataToShow}
              />
            </HStack>
          </>
        )}
      </Box>
    </Box>
  );
};

export default Optin;
