import { Box, Flex, HStack, Text, useDisclosure } from "@chakra-ui/react";

import { useQuery } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import SavedCard from "../../../components/account/billings/savedCard";
import { getCards } from "../../../API/account/billings";
import { cardValueType } from "../../../components/account/billings/SelectUpgradePaymentMethod";
import Loader from "../../../components/UI/Loader";
import AddCardModal from "../../../components/account/billings/AddCardModal";

const BillingCard = () => {
  const navigate = useNavigate();

  const { isLoading: loadingCards, data: cardList } = useQuery(
    ["listCards"],
    getCards
  );

  const disclosure = useDisclosure();

  const onAddCardSuccess = () => {
    navigate("/account/billings/cards");
  };

  return (
    <Box w="full" h="full">
      <Box maxW={"7rem"}>
        <Link to="/account/billings">
          {" "}
          <HStack spacing={"0px"}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 13.5L8.5 10.25L12 7"
                stroke="#7489AA"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <Text fontSize={"xs"} color="grays.12">
              Back to Billing
            </Text>
          </HStack>
        </Link>
      </Box>

      {/* Table header */}
      <Box mt={5} mb={6}>
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          Manage Cards
        </Text>
        <Text color={"grays.12"} fontSize={"12px"}>
          Manage your payment details.
        </Text>
      </Box>

      {loadingCards && <Loader />}
      <Flex flexWrap={"wrap"}>
        {!loadingCards &&
          cardList?.length > 0 &&
          cardList?.map((card: cardValueType) => <SavedCard data={card} />)}
        {!loadingCards && (
          <Box m="5px">
            <Box
              onClick={disclosure.onOpen}
              borderRadius="4px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderColor={"borders.500"}
              borderWidth={"1px"}
              w="294px"
              h="144px"
              cursor={"pointer"}
            >
              <>
                <Box
                  mb="5px"
                  display="inline-block"
                  textAlign={"center"}
                  ml="18px"
                  verticalAlign={"middle"}
                >
                  <svg
                    width="40"
                    height="40"
                    viewBox="0 0 40 40"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M30 21.25H10C9.31667 21.25 8.75 20.6833 8.75 20C8.75 19.3167 9.31667 18.75 10 18.75H30C30.6833 18.75 31.25 19.3167 31.25 20C31.25 20.6833 30.6833 21.25 30 21.25Z"
                      fill="#7489AA"
                    />
                    <path
                      d="M20 31.25C19.3167 31.25 18.75 30.6833 18.75 30V10C18.75 9.31667 19.3167 8.75 20 8.75C20.6833 8.75 21.25 9.31667 21.25 10V30C21.25 30.6833 20.6833 31.25 20 31.25Z"
                      fill="#7489AA"
                    />
                  </svg>
                </Box>
                <Text textAlign="center" color={"grays.12"} fontSize={"14px"}>
                  Add new card
                </Text>
              </>
            </Box>
          </Box>
        )}
      </Flex>
      <AddCardModal
        disclosure={disclosure}
        onAddCardSuccess={onAddCardSuccess}
      />
    </Box>
  );
};

export default BillingCard;
