import API from "../.";

export const getOptinData = async (page: number, limit: number) => {
  const response = await API.get(`/opt-in/?page=${page}&size=${limit}`);
  return response.data.data;
};

export const singleOptin = async (id: number) => {
  const response = await API.get(`/opt-in/${id}`);
  return response.data.data;
};

export const validateOptinKeyword = async (keyword: string) => {
  const response = await API.get(`/opt-in/validate-keyword?keyword=${keyword}`);
  return response.data;
};

export const createOptin = async (data: {
  optin_name: string;
  channel: string;
  company_uid: string;
  channel_value: number | string | undefined;
  list_uid: string;
  keyword: string;
  auto_message: string;
}) => {
  const response = await API.post(`/opt-in`, data);

  return response.data;
};
