import {
  Box,
  Flex,
  Heading,
  HStack,
  Input,
  SimpleGrid,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import {
  // createSegments,
  getAllContacts,
  getSegments,
} from "../../API/contacts";
import AddContactToList from "../../components/contacts/AddContactToList";
import ContactsList from "../../components/contacts/ContactsList";
import CustomModal from "../../components/UI/CustomModal";
// import Empty from "../../components/UI/Empty";
import Loader from "../../components/UI/Loader";
import { usePagination } from "@ajna/pagination";
import CustomPaginaton from "../../components/UI/CustomPaginaton";
import { useNavigate } from "react-router-dom";
import { Hamburger } from "../../assets/svgs/svg";
// import CustomButton from "../../components/Ziz_components/Button.component";
import { DropDownMenu } from "../../components/Ziz_components/drop-down/dropDown";
// import FormInput from "../../components/UI/form/FormInput";
// import { removeKeyFromArray, e } from "../../utils/removeKeyFromArray";
import { SingleContactProps } from "../../utils/generalProps";
import { ButtonDropdown, ButtonPrimary } from "../../components/UI/Buttons";
// import CreateSegment from "./Segments/CreateSegment";
import { SingleSegmentProp } from "../../types";
import ProgressLoader from "../../components/UI/ProgressLoader";
// import DeleteContactsModal from "../../components/contacts/DeleteContacts";
// import { deleteBulkContacts, deleteSingleContact } from "../../API/contacts";
// import { getListData } from "../../API/account/list";

// const keys = [
//   "id",
//   "uid",
//   "company_uid",
//   "profile_picture",
//   "first_name",
//   "last_name",
//   "email",
//   "phone_number",
//   "instagram_username",
//   "instagram_id",
//   "facebook_username",
//   "facebook_id",
//   "twitter_username",
//   "twitter_id",
//   "is_blocked",
//   "data_source",
//   "metadata",
//   "created_at",
//   "updated_at",
//   "conversation",
// ] as const;

const Contacts = () => {
  // const [segmentQ, setSegmentQ] = useState({});
  const [segmentName, setSegmentName] = useState("");
  const [segSearchResults, setSegSearchResults] = useState([]);
  const [segmentArr, setSegmentArr] = useState<SingleSegmentProp[]>([]);
  const [showOptions, setShowOptions] = useState(false);
  const [showEntriesData, setShowEntriesData] = useState({
    start: 1,
    end: 15,
    total: 25,
  });
  const [dataToShow, setDataToShow] = useState(15);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const navigate = useNavigate();
  const addContactDisclosure = useDisclosure();
  const exportContactDisclosure = useDisclosure();
  const addToListDisclosure = useDisclosure();
  const deleteAllContactsDisclosure = useDisclosure();
  const createSegmentDisclosure = useDisclosure();

  const { isLoading, data } = useQuery(
    ["allContacts", dataToShow, myCurrentPage],
    () => {
      return getAllContacts({
        page: myCurrentPage,
        limit: dataToShow,
      });
    }
  );

  const tabledata: SingleContactProps[] = data?.docs;

  // const [tableData, setTableData] = useState(tabledata);

  const [addedColumns, setAddedColumns] = useState<
    (keyof SingleContactProps)[]
  >([]);
  const [removedColumns, setRemovedColumns] = useState<
    (keyof SingleContactProps)[]
  >([]);

  const [checked, setChecked] = useState(false);
  const [checked1, setChecked1] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [checked3, setChecked3] = useState(false);
  const [checked4, setChecked4] = useState(false);
  const [checked5, setChecked5] = useState(false);

  const handleRemoveButtonClick = (key: keyof SingleContactProps) => {
    // @ts-ignore
    setRemovedColumns([...removedColumns, key]);
    setAddedColumns(addedColumns.filter((id) => id !== key));
  };

  const handleAddButtonClick = (key: keyof (typeof tabledata)[0]) => {
    const columnExists = addedColumns.includes(key);
    if (!columnExists) {
      setAddedColumns([...addedColumns, key]);
      setRemovedColumns(removedColumns.filter((id) => id !== key));
    }
  };

  const [globalFilter, setGlobalFilter] = useState("");

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: data?.totalPages,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: { currentPage: 1 },
  });

  useEffect(() => {
    const setCurr = (page: number) => {
      if (page === 1) {
        return 1;
      } else {
        return (page - 1) * dataToShow;
      }
    };
    const checkPage = (curr: number, total: number) => {
      let tt = total - curr * dataToShow;
      if (tt >= 0) {
        return curr * dataToShow;
      } else {
        return total;
      }
    };

    if (currentPage && data?.totalDocs) {
      setShowEntriesData((prev) => ({
        ...prev,
        start: setCurr(currentPage),
        end: checkPage(currentPage, data?.totalDocs),
        total: data?.totalDocs,
      }));
    }
  }, [currentPage, data, dataToShow]);

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  // useMemo( async()=>{
  //   setTableData(tableData)
  //   }, [data])

  // useEffect(() => {
  //   const fetchContacts = async () => {
  //     const res = await getAllContacts({
  //       page: myCurrentPage,
  //       limit: dataToShow,
  //     });
  //     const d = res.data;
  //     return d;
  //   };
  //   fetchContacts();
  //   setTableData(tableData);
  // }, [dataToShow, myCurrentPage, tableData]);

  const moreOptions = [
    {
      label: "Delete",
      onClick: () => deleteAllContactsDisclosure.onOpen(),
    },
    {
      label: "Export",
      onClick: () => exportContactDisclosure.onOpen(),
    },
    { label: "Add to list", onClick: () => addToListDisclosure.onOpen() },
  ];

  const userSegments = useQuery(
    ["get_user_segments"],
    () => {
      return getSegments();
    },
    {
      onSuccess: (data) => {
        // console.log("we haveeee", data.docs);
        setSegmentArr(data.docs);
      },
    }
  );

  // const createSegmentMutation = useMutation(createSegments, {
  //   onSuccess: (data) => {
  //     // console.log("we haveeee", data);
  //   },
  // });

  return (
    <>
      {userSegments.isLoading ? <ProgressLoader /> : null}
      <SimpleGrid
        display={"grid"}
        gridTemplateColumns={"15% 85%"}
        bg="white"
        rounded="4px"
        mb={20}
        w="full"
      >
        {/* SEGMENTS */}
        <Box
          py="20px"
          color={"grays.12"}
          borderRight={"1px solid rgba(0, 102, 255, 0.08)"}
        >
          <Heading as={"h1"} pl="6" fontSize={"20px"} fontWeight={700}>
            Segments
          </Heading>

          <HStack
            py={"12px"}
            pl={"24px "}
            pr={"16px"}
            borderTop={"1px solid rgba(0, 102, 255, 0.08)"}
            borderBottom={"1px solid rgba(0, 102, 255, 0.08)"}
            fontSize={"10px"}
            my={"4"}
            justifyContent="space-between"
            alignItems="center"
          >
            <Text>All Contacts</Text>
            <Text>{data?.totalDocs}</Text>
          </HStack>

          <Box>
            <Heading
              px={"24px"}
              py={"12px"}
              as={"h3"}
              color={"grays.8"}
              fontSize={"xs"}
              fontWeight={500}
            >
              All Segments
            </Heading>

            {segmentArr.length === 0 ? (
              <Text
                fontSize={"xs"}
                fontWeight={400}
                py={"12px"}
                pl={"24px "}
                pr={"16px"}
              >
                No segments to show
              </Text>
            ) : (
              <>
                {segmentArr.map((option, index) => (
                  <HStack
                    key={index}
                    py={"12px"}
                    pl={"24px "}
                    pr={"16px"}
                    fontSize={"10px"}
                    justifyContent="space-between"
                    alignItems="center"
                    cursor={"pointer"}
                    _hover={{
                      bg: "#00000005",
                    }}
                    onClick={() => {
                      // a func to get a single segment
                    }}
                  >
                    <Text textAlign={"left"}>{option.name}</Text>
                    <Text textAlign={"end"}>{option.no_of_contacts}</Text>
                  </HStack>
                ))}
              </>
            )}
          </Box>
        </Box>

        {/* TABLE */}
        <Box pb={"40px"}>
          <Box pt="70px">
            {/* segment */}
            {/* <HStack>
              {segSearchResults.length > 0 ? (
                <>
                  <ButtonPrimary
                    buttonProps={{
                      variant: "outline",
                      ml: "20px",
                      my: "16px",
                    }}
                    onClick={() => {
                      setSegSearchResults([]);
                    }}
                  >
                    Clear Segment
                  </ButtonPrimary>

                  <ButtonPrimary
                    buttonProps={{
                      variant: "outline",
                      ml: "20px",
                      my: "16px",
                    }}
                    onClick={() => {
                      let data = [...segSearchResults];
                      // @ts-ignore
                      let arr = data.map((val) => val.uid);

                      let newSeg = { ...segmentQ };
                      // @ts-ignore
                      let newArr = newSeg?.value?.map((item) => {
                        return {
                          field: item.field,
                          operator: item.operator,
                          value: item.value,
                        };
                      });

                      let query = {
                        name: segmentName,
                        contact_uids: arr,
                        condition: {
                          query: {
                            // @ts-ignore
                            operator: segmentQ.operator,
                            value: newArr,
                          },
                        },
                      };

                      createSegmentMutation.mutate(query);
                    }}
                  >
                    Save Segment
                  </ButtonPrimary>
                </>
              ) : (
                <ButtonPrimary
                  buttonProps={{
                    variant: "outline",
                    ml: "20px",
                    my: "16px",
                  }}
                  onClick={() => {
                    // navigate("/contacts/segment_create");
                    createSegmentDisclosure.onOpen();
                  }}
                >
                  Create Segment
                </ButtonPrimary>
              )}
            </HStack> */}

            <HStack
              py={"16px"}
              px={"20px"}
              borderTop={"1px solid rgba(0, 102, 255, 0.08)"}
              borderBottom={"1px solid rgba(0, 102, 255, 0.08)"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Flex alignItems={"center"} columnGap="18px">
                <Heading
                  as={"h2"}
                  fontSize={"20px"}
                  color="grays.9"
                  fontWeight={"700"}
                >
                  {segSearchResults.length > 0
                    ? `${segSearchResults.length} contacts `
                    : "All Contacts"}
                  <Text as="span" ml={"8px"} color={"grays.12"}>
                    {segSearchResults.length > 0
                      ? `match out of ${data?.totalDocs}`
                      : data?.totalDocs}
                  </Text>
                </Heading>

                <HStack spacing={"20px"}>
                  <ButtonDropdown
                    title="Add Contact"
                    list={[
                      {
                        option: "Add Single Contact",
                        onClick: () => addContactDisclosure.onOpen(),
                      },
                      {
                        option: "Import Contact",
                        onClick: () => navigate("/contacts/import"),
                      },
                    ]}
                  />
                  {/* {!showOptions && (
                    <ButtonDropdown
                      isPrimary={false}
                      title="More"
                      list={[
                        {
                          option: "Delete Contacts",
                          onClick: () => handleAddButtonClick("phone_number"),
                        },
                        {
                          option: "Export Contacts",
                          onClick: () => exportContactDisclosure.onOpen(),
                        },
                        {
                          option: "Add to List",
                          onClick: () => addToListDisclosure.onOpen(),
                        },
                      ]}
                    />
                  )} */}
                  {/* add optional buttons */}
                  {showOptions ? (
                    <HStack spacing={"15px"}>
                      {moreOptions.map((ops, index) => (
                        <ButtonPrimary
                          key={index}
                          buttonProps={{
                            variant: "outline",
                          }}
                          onClick={ops.onClick}
                        >
                          {ops.label}
                        </ButtonPrimary>
                      ))}

                      {/* <ButtonPrimary>Export Contacts</ButtonPrimary>
                      <ButtonPrimary>Add to List</ButtonPrimary> */}
                    </HStack>
                  ) : null}
                </HStack>
              </Flex>

              <Flex columnGap="1em" position={"relative"} zIndex={3}>
                <Input
                  type={"search"}
                  borderRadius={"4px"}
                  borderColor="borders.100"
                  _hover={{
                    borderColor: "borders.100",
                  }}
                  color="grays.9"
                  _placeholder={{
                    color: "grays.6",
                  }}
                  h="32px"
                  bg="white"
                  w="full"
                  maxW={"288px"}
                  fontSize="xs"
                  my="auto"
                  placeholder="Search by name, phone number and email"
                  value={globalFilter ?? ""}
                  onChange={(e) => {
                    setGlobalFilter(e.target.value.toString());
                  }}
                />

                {/* @ts-ignore */}
                <DropDownMenu
                  menuOptionStyle={{
                    width: "10em",
                    paddingBottom: ".4em",
                    paddingTop: ".4em",
                  }}
                  customComponent={
                    <Input
                      mt=".4em"
                      mx="auto"
                      py=".2em"
                      type="text"
                      placeholder="Search by data name"
                      _placeholder={{
                        color: "#7489AA",
                        fontSize: "11px",
                      }}
                      w="90%"
                      onChange={(e) => {
                        setGlobalFilter(e.target.value.toString());
                      }}
                    />
                  }
                  MenubtnStyle={{
                    background: "none",
                    border: "none",
                    color: "#7489AA",
                    fontSize: "12px",
                    fontWeight: "500",
                    padding: "0",
                  }}
                  checked={true}
                  floatLeft="-8.5em"
                  svgIcon={
                    <Box>
                      <Hamburger />
                    </Box>
                  }
                  listOptionRole={"button"}
                  menuListOptions={[
                    {
                      name: "Name",
                      onCheck: () => {
                        setChecked5(!checked5);
                        if (checked5 === true) {
                          handleAddButtonClick("first_name");
                        } else if (checked5 === false) {
                          return handleRemoveButtonClick("first_name");
                        }
                      },
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                    {
                      name: "Email",
                      onCheck: () => {
                        setChecked(!checked);
                        if (checked === true) {
                          handleAddButtonClick("email");
                        } else if (checked === false) {
                          return handleRemoveButtonClick("email");
                        }
                      },
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                    {
                      name: "Phone number",
                      // onClick: () => navigate('/contacts/import'),
                      // style: {{}}
                      onCheck: () => {
                        setChecked1(!checked1);
                        if (checked1 === true) {
                          handleAddButtonClick("phone_number");
                        } else if (checked1 === false) {
                          return handleRemoveButtonClick("phone_number");
                        }
                      },
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                    {
                      name: "Channels",
                      // onClick: () => navigate('/contacts/import'),
                      onCheck: () => {
                        setChecked2(!checked2);
                        if (checked2 === true) {
                          handleAddButtonClick("data_source");
                        } else if (checked2 === false) {
                          return handleRemoveButtonClick("data_source");
                        }
                      },
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                    {
                      name: "Data Created",
                      onCheck: () => {
                        setChecked3(!checked3);
                        if (checked3 === true) {
                          handleAddButtonClick("created_at");
                        } else if (checked3 === false) {
                          return handleRemoveButtonClick("created_at");
                        }
                      },
                      // onClick: () => navigate('/contacts/import'),
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                    {
                      name: "Last Updated",
                      // onClick: () => navigate('/contacts/import'),
                      onCheck: () => {
                        setChecked4(!checked4);
                        if (checked4 === true) {
                          handleAddButtonClick("updated_at");
                        } else if (checked4 === false) {
                          return handleRemoveButtonClick("updated_at");
                        }
                      },
                      style: {
                        paddingLeft: ".5em",
                        paddingRight: ".5em",
                      },
                    },
                  ]}
                />
              </Flex>
            </HStack>
          </Box>

          <Flex flexDir={"column"} w="full">
            {isLoading && <Loader />}

            {data?.docs && (
              <>
                <Box
                  h="74vh"
                  overflowY={"auto"}
                  bg={"white"}
                  className="thinSB"
                >
                  <ContactsList
                    setSegmentQ={{}}
                    // setSegmentQ={setSegmentQ}
                    segmentName={segmentName}
                    setSegmentName={setSegmentName}
                    segSearchResults={segSearchResults}
                    setSegSearchResults={setSegSearchResults}
                    showOptions={setShowOptions}
                    data={
                      segSearchResults.length > 0
                        ? segSearchResults
                        : data?.docs
                    }
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    // columnToRemove = {columnToRemove}
                    removedColumns={removedColumns}
                    addedColumns={addedColumns}
                    isLoading={isLoading}
                    addContactDisclosure={addContactDisclosure}
                    deleteAllContactsDisclosure={deleteAllContactsDisclosure}
                    exportContactDisclosure={exportContactDisclosure}
                    addToListDisclosure={addToListDisclosure}
                    createSegmentDisclosure={createSegmentDisclosure}
                  />
                </Box>

                <CustomPaginaton
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  pages={pages}
                  numberOfdataToShow={dataToShow}
                  setDataToShow={setDataToShow}
                  // showEntriesSelect
                  showEntriesData={showEntriesData}
                  showEntries
                />
              </>
            )}
          </Flex>
        </Box>
      </SimpleGrid>

      {/*  ADD CONTACT MODAL */}
      <CustomModal
        titleUnderLine={true}
        disclosure={addContactDisclosure}
        title={
          <Text
            fontWeight={700}
            fontSize="20px"
            color="grays.8"
            mt="35px"
            // px='38px'
          >
            Add Contact
          </Text>
        }
      >
        <AddContactToList close={addContactDisclosure?.onClose} />
      </CustomModal>
    </>
  );
};

export default Contacts;
