import React, { FC, useMemo } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import dayjs from "dayjs";
import { SwipeIn } from "../../animations";

interface listProps {
  id: 1;
  uid: string;
  company_uid: string;
  contact_uid: string;
  list_uid: string;
  created_at: Date;
  updated_at: Date;
  contact: {
    id: number | string;
    uid: string;
    company_uid: string;
    profile_picture: string;
    first_name: string;
    last_name: string;
    email: string;
    phone_number: string;
    instagram_username: string;
    instagram_id: string;
    facebook_username: string;
    facebook_id: string;
    twitter_username: string;
    twitter_id: string;
    is_blocked: boolean | number;
    deleted_at: string;
    data_source: string;
    created_at: Date;
    updated_at: Date;
  };
}

const SettingsListContacts: FC<{
  data: listProps[];
}> = ({ data }) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    return [
      {
        header: () => <Text>Firstname</Text>,
        accessorKey: "contact.first_name",
        id: "contact.first_name",
        cell: (props: any) => {
          return <Text>{props?.getValue() ? props?.getValue() : "---"}</Text>;
        },
      },
      {
        header: () => <Text>Lastname</Text>,
        accessorKey: "contact.last_name",
        id: "contact.last_name",
        cell: (props: any) => {
          return <Text>{props?.getValue() ? props?.getValue() : "---"}</Text>;
        },
      },
      {
        header: () => <Text>Email</Text>,
        accessorKey: "contact.email",
        id: "contact.email",
        cell: (props: any) => {
          return <Text>{props?.getValue() ? props?.getValue() : "---"}</Text>;
        },
      },
      {
        header: () => <Text>Phone number</Text>,
        accessorKey: "contact.phone_number",
        id: "contact.phone_number",
        cell: (props: any) => {
          return <Text>{props?.getValue() ? props?.getValue() : "---"}</Text>;
        },
      },
      {
        header: "Date Created",
        accessorKey: "created_ay",
        id: "created_ay",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("DD/MM/YYYY - hh:mmA")}
            </Text>
          );
        },
      },
    ];
  }, []);

  // const [data, setData] = useState([...defaultData]);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <Box
      h={"52vh"}
      overflowY={"auto"}
      className="thinSB"
      boxShadow={"xs"}
      bg={"white"}
    >
      <TableContainer
        overflowX="unset"
        overflowY="unset"
        borderWidth={"1px"}
        borderColor="borders.200"
        rounded={"8px"}
      >
        <Table variant={"simple"} position={"relative"}>
          <Thead position={"sticky"} top={0} bg={"white"} boxShadow={"xs"}>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    <SwipeIn delay={0.2 * index}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </SwipeIn>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SettingsListContacts;
