import {
  Box,
  Button,
  HStack,
  Text,
  useClipboard,
  chakra,
  useToast,
  Link,
} from "@chakra-ui/react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { generateApiKey, getApiKey } from "../API/account/apiKey";
import Empty from "../components/UI/Empty";
import FormInput from "../components/UI/form/FormInput";
import Loader from "../components/UI/Loader";
import { toaster } from "../utils/toast";

const Apikey = () => {
  const [value, setValue] = React.useState("");
  const { hasCopied, onCopy } = useClipboard(value);

  const queryClient = useQueryClient();

  const toast = useToast();
  const [generateKey, setGenerateKey] = useState(false);

  // fetch api keys
  const { isLoading, data } = useQuery(["apiKey"], getApiKey, {
    onSuccess(data) {
      setValue(data?.api_key);
    },
  });

  // generate api keys
  const { isLoading: generateKeyLoading } = useQuery(
    ["generateKey"],
    generateApiKey,
    {
      enabled: generateKey,
      onSuccess(data) {
        const success = { ...data };
        setGenerateKey(false);
        setValue(data?.api_key);
        queryClient.invalidateQueries(["apiKey"]);
        toaster(toast, success);
      },

      onError(data: any) {
        const errors = { ...data };
        setGenerateKey(false);
        toaster(toaster, errors);
      },
    }
  );

  return (
    <Box w="full" h="full">
      {/* header */}
      <Box maxW={"360px"} w="full">
        <Text fontSize={"20px"} color="grays.9" fontWeight={"700"}>
          API Key
        </Text>
      </Box>

      <Box mt={10}>
        {isLoading && <Loader />}

        {!isLoading && data && value !== "" && (
          <>
            <HStack maxWidth="500px">
              <FormInput
                // label='Business name'
                type="text"
                for="api key"
                inputProps={{
                  placeholder: "Enter api key",
                  value,
                  isReadOnly: true,
                }}
              />

              <Button
                onClick={onCopy}
                colorScheme={"primary"}
                size="sm"
                leftIcon={
                  <>
                    {!hasCopied ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        width="16px"
                        height={"16px"}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.5 8.25V6a2.25 2.25 0 00-2.25-2.25H6A2.25 2.25 0 003.75 6v8.25A2.25 2.25 0 006 16.5h2.25m8.25-8.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-7.5A2.25 2.25 0 018.25 18v-1.5m8.25-8.25h-6a2.25 2.25 0 00-2.25 2.25v6"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        width="16px"
                        height={"16px"}
                      >
                        <path d="M16.5 6a3 3 0 00-3-3H6a3 3 0 00-3 3v7.5a3 3 0 003 3v-6A4.5 4.5 0 0110.5 6h6z" />
                        <path d="M18 7.5a3 3 0 013 3V18a3 3 0 01-3 3h-7.5a3 3 0 01-3-3v-7.5a3 3 0 013-3H18z" />
                      </svg>
                    )}
                  </>
                }
              >
                {hasCopied ? "Copied" : "Copy"}
              </Button>
            </HStack>

            <HStack mt={5}>
              <chakra.svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width="20px"
                height="20px"
                color="primary.500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                />
              </chakra.svg>

              <Link
                href={process.env.REACT_APP_API_DOCS}
                isExternal
                fontSize={"xs"}
                display="block"
                color="primary.500"
                textDecor={"underline"}
              >
                View our documentation
                <Text as="span" ml={1} fontWeight={"bold"}>
                  here
                </Text>
              </Link>
            </HStack>
          </>
        )}

        {!isLoading && !data && value === "" && (
          <Empty
            description={<Text>You currently don’t have an API Key</Text>}
            icon={
              <chakra.svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                width="48px"
                height="48px"
                color="primary.500"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
                />
              </chakra.svg>
            }
            buttonText="Generate API Key"
            buttonAction={() => {
              setGenerateKey(true);
            }}
            buttonProps={{
              isLoading: generateKeyLoading && generateKey,
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Apikey;
