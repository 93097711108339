import { motion } from "framer-motion";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  delay?: number;
  duration?: number;
  style?: {};
}
export function SwipeUp({ children, style, delay = 0, duration = 0.6 }: Props) {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5, y: 20, x: -200 }}
      animate={{ opacity: 1, scale: 1, y: 0, x: 0 }}
      transition={{ duration: duration, delay: delay }}
      style={{ ...style }}
    >
      {children}
    </motion.div>
  );
}
