export const Input = {
  // Styles for the base style
  baseStyle: {
    borderRadius: '8px',
    borderWidth: '1px',
    borderColor: 'grays.5',
    py: ' 14px',
    px: '16px',
    height: '48px',
    _placeholder: {
      color: 'grays.6',
    },
  },
  // Styles for the size variations
  sizes: {
    // sm: {
    //   borderRadius: '4px',
    //   fontSize: { base: 'xs', md: 'sm' },
    //   py: 5,
    //   px: '24px',
    // },
    md: {},
  },
  // Styles for the visual style variations
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {
    // px: '24px',
    // size: 'md',
  },
};
