import { Box } from "@chakra-ui/react";
import { getCookie } from "cookies-next";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_ROUTES, DASH_ROUTES } from "./utils/routes";
import * as Sentry from "@sentry/react";

export const App = () => {
  const loggedIn = !!getCookie("USER_TOKEN");
  const navigate = useNavigate();

  useEffect(() => {
    if (loggedIn) {
      navigate(DASH_ROUTES.CONVERSATIONS);
    } else {
      navigate(AUTH_ROUTES.LOGIN);
    }
  }, [loggedIn, navigate]);

  return <Box textAlign="center" fontSize="xl"></Box>;
};

export default Sentry.withProfiler(App);
