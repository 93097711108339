import {
  Box,
  // chakra,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Flex,
  useDisclosure,
  HStack,
  TableContainer,
  // FormControl,
  // Select,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useQuery } from "@tanstack/react-query";
// import { HiCheckCircle } from 'react-icons/hi';
import { useRecoilState } from "recoil";
import { Add } from "../../../assets/svgs/svg";
import { importContactsAtom } from "../../../atoms/contacts";
import CreateAttribute from "../../account/attributes/CreateAttribute";
import CustomModal from "../../UI/CustomModal";
import SearchableSelect from "../../UI/form/SearchableSelect";
import Checkbox from "../../Ziz_components/CustomCheck.component";
import { getContactAttributes } from "../../../API/contacts";
import { removeUnderscores } from "../../../functions";
import ProgressLoader from "../../UI/ProgressLoader";

// const allProperties = [
//   {
//     label: "Profile picture",
//     value: "profile_picture",
//   },
//   {
//     label: "First name",
//     value: "first_name",
//   },
//   {
//     label: "Last name",
//     value: "last_name",
//   },
//   {
//     label: "Email",
//     value: "email",
//   },
//   {
//     label: "Phone number",
//     value: "phone_number",
//   },
//   {
//     label: "External user ID",
//     value: "external_user_id",
//   },
// ];

const MapContactsStage = () => {
  const [importContactsData, setImportContactsData] =
    useRecoilState(importContactsAtom);

  const [properties, setProperties] = useState<Array<{
    label: string;
    value: string;
  }> | null>(null);
  const firstRow = importContactsData?.fileData[0];

  // returns array of header: key objects
  const testMapping = importContactsData?.fileData?.map((data: any) => {
    let arr = [];

    for (let index = 0; index < data.length; index++) {
      let toUse = {
        [firstRow[index]]: data[index],
      };

      arr.push(toUse);
    }

    return arr;
  });

  // returns
  const Mapped = useMemo(() => {
    let mappedDataToUse = {};

    for (let index = 0; index < testMapping[0].length; index++) {
      mappedDataToUse = {
        ...mappedDataToUse,
        [index + 1]: "",
      };
    }

    return mappedDataToUse;
  }, [testMapping]);

  const [tableData, setTableData] = useState<any>(Mapped);

  const isTableDataEmpty = () => {
    for (var key in tableData) {
      if (tableData[key] !== null && tableData[key] !== "") return false;
    }
    return true;
  };

  const CreateAttributeDisclosure = useDisclosure();

  const { isLoading } = useQuery({
    queryKey: ["contactAttributes"],
    queryFn: getContactAttributes,
    onSuccess(data) {
      let details = data.data.map((item: string) => {
        let label = removeUnderscores(item);
        let value = item;

        return { label, value };
      });
      setProperties(details);
    },
  });

  return (
    <Flex flexDir={"column"} w="full" maxW={"644px"} mx="auto">
      {/* header */}

      <Box textAlign={"center"} mx="auto" mb="30px" maxWidth="375px">
        <Text fontSize={"20px"} fontWeight={700} color="grays.8">
          Map your contact properties
        </Text>
        <Text color={"grays.12"} fontSize="xs" mt="8px">
          Each header below should be matched to a property.
        </Text>
      </Box>

      <Flex
        mb={"8px"}
        width="100%"
        fontWeight="500"
        fontSize="12px"
        color="#0066FF"
        justifyContent={"flex-end"}
        onClick={CreateAttributeDisclosure.onOpen}
      >
        <HStack cursor={"pointer"} spacing={"3px"}>
          <Add />
          <Text>Add New Attribute</Text>
        </HStack>
      </Flex>

      {/* Table */}
      <Box
        // height={"65vh"}
        className="thinSB"
        // overflow={"auto"}
        mb="32px"
        borderWidth={"1px"}
        // borderTopWidth={"0px"}
        borderColor="borders.100"
      >
        <TableContainer
          overflowX="unset"
          overflowY="unset"
          // borderWidth={"1px"}
          // borderBottomWidth="0px"
          // borderColor="borders.200"
        >
          <Table variant={"simple"} position={"relative"}>
            <Thead
              position={"sticky"}
              top={0}
              bg={"white"}
              boxShadow={"sm"}
              zIndex={1}
            >
              <Tr>
                <Th border="0" maxW="15px" px="12px"></Th>
                <Th
                  textTransform="none"
                  color={"grays.9"}
                  fontWeight={500}
                  fontSize="xs"
                  letterSpacing={"unset"}
                  border="0"
                >
                  Header
                </Th>

                <Th
                  textTransform="none"
                  color={"grays.9"}
                  fontWeight={500}
                  fontSize="xs"
                  letterSpacing={"unset"}
                  border="0"
                >
                  Column Info
                </Th>

                <Th
                  textTransform="none"
                  color={"grays.9"}
                  fontWeight={500}
                  fontSize="xs"
                  letterSpacing={"unset"}
                  border="0"
                >
                  Select Properties
                </Th>
              </Tr>
            </Thead>

            <Tbody>
              {testMapping[1]?.map((data: any, index: number) => {
                let header = Object.entries(data)[0][0];
                let columnInfo = Object.entries(data)[0][1];

                return (
                  <Tr key={index}>
                    <Td borderColor="borders.100">
                      {/* @ts-ignore */}
                      {tableData[index + 1] !== "" ? (
                        <Checkbox variant="checked-circle" size="12px" />
                      ) : (
                        <Box
                          boxSize={"16px"}
                          border="1px solid rgba(0, 102, 255, 0.12)"
                          rounded="full"
                          cursor={"pointer"}
                        ></Box>
                      )}
                    </Td>

                    <Td
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={400}
                      borderColor="borders.100"
                      py="6px"
                    >
                      {header}
                    </Td>

                    <Td
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={400}
                      borderColor="borders.100"
                      py="6px"
                    >
                      {/* @ts-ignore */}
                      {columnInfo}
                    </Td>

                    <Td
                      fontSize="xs"
                      color="grays.12"
                      fontWeight={"medium"}
                      borderColor="borders.100"
                      py="12px"
                      zIndex={100}
                      width="17em"
                    >
                      <SearchableSelect
                        height={"28px"}
                        placeholder="Select Property"
                        fontSize="12px"
                        onChange={(e: { label: string; value: string }) => {
                          const newPropertiesToShow = properties?.filter(
                            (property) => {
                              return property?.value !== e.value;
                            }
                          );
                          setProperties(newPropertiesToShow!);

                          setTableData({
                            ...tableData,
                            [`${(index + 1).toString()}`]: e.value,
                          });
                        }}
                        options={properties}
                      />

                      {/* <Select
                    icon={<FaCaretDown />}
                    iconSize={"14px"}
                    placeholder="Select property"
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                    borderColor="borders.100"
                    onChange={(e) => {
                      // const newPropertiesToShow = properties?.filter(
                      //   (property) => {
                      //     return property?.value !== e.target.value;
                      //   }
                      // );
                      // setProperties(newPropertiesToShow);

                      setTableData({
                        ...tableData,
                        [`${(index + 1).toString()}`]: e.target.value,
                      });
                      selectedProperties.set(columnInfo, e.target.value);
                    }}
                  >
                    {properties.map((op, index) => (
                      <chakra.option key={index} value={op.value}>
                        {op.label}
                      </chakra.option>
                    ))}
                  </Select> */}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>

        {isLoading && <ProgressLoader />}
      </Box>

      <Button
        w="full"
        maxW="360px"
        mx="auto"
        mt="40px"
        mb="10px"
        colorScheme={"primary"}
        isDisabled={isTableDataEmpty()}
        onClick={() => {
          const copiedTD = { ...tableData };

          for (const key in copiedTD) {
            if (copiedTD[key] === "") {
              delete copiedTD[key];
            }
          }

          setImportContactsData((prev) => {
            return {
              ...prev,
              csvDataToUse: copiedTD,
              currentStage: "final",
            };
          });
        }}
      >
        Next
      </Button>

      {/* create attr modal */}
      <CustomModal
        disclosure={CreateAttributeDisclosure}
        titleUnderLine={true}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Create new attribute
          </Text>
        }
      >
        <CreateAttribute close={CreateAttributeDisclosure.onClose} />
      </CustomModal>
    </Flex>
  );
};

export default MapContactsStage;
