import API from "../.";

export const getListData = async () => {
  const response = await API.get(`/list/list`);

  return response.data.data;
};

export const createList = async (data: { name: string; color: string }) => {
  const response = await API.post(`/list/create`, data);

  return response.data;
};
export const updateList = async (data: {
  list_uid: string;
  name: string;
  color: string;
}) => {
  const response = await API.put(`/list/edit`, data);

  return response.data;
};

export const deleteList = async (uid: string) => {
  const response = await API.delete(`/list/delete/${uid}`);

  return response.data;
};

// =========================== List Contacts area===============================================

export const getListContactsData = async (
  listId: string | undefined,
  page: number,
  limit: number
) => {
  const response = await API.get(
    `/contact-list/list/${listId}?page=${page}&limit=${limit}`
  );

  return response.data.data;
};

export const createContactForList = async (data: {
  list_uid: string | undefined;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
}) => {
  const response = await API.post(`/contact-list/create`, data);

  return response.data;
};

// get a single contact's lists
export const getSingleContactLists = async (uid: string) => {
  const res = await API.get(`/contact/${uid}/lists`);

  return res.data.data.docs;
};
