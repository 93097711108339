import React, { FC } from 'react'
import { HStack, Box, Text } from '@chakra-ui/react';
import { cardValueType } from "./SelectUpgradePaymentMethod";
import CardImage from '../../../icons/CardImage';

const DefaultCard: FC<{ click?: () => void, data: cardValueType }> = ({ data }) => {
    return (
        <HStack my="2">
            <Box
                p="10px"
                w={"360px"}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <HStack>
                    <CardImage type={data?.card_type} />
                    <Box>
                        <Text fontSize="12px" fontWeight={"700"}>Card ending in {data?.last_four_digits}</Text>

                        <Text fontSize="12px" fontWeight={"500"} color="grays.12">
                            Expires {data?.expiry_date}
                        </Text>
                    </Box>
                </HStack>
            </Box>
        </HStack>
    )
}

export default DefaultCard