import API from "../.";

// =========================== List plans ==============================================

export const getPlansData = async () => {
  const response = await API.get(`/billing/plans/list`);
  return response.data.data;
};

// =========================== Get Exchange Rate ==============================================
export const getExchangeRate = async () => {
  const response = await API.get("billing/get-exchange-rate");
  return response.data.data;
};

// =========================== Get Charged Amount ==============================================
export const getChargedAmount = async () => {
  const response = await API.get("billing/cards/get-charge-amount");
  return response.data.data;
};

// =========================== Get Cards ==============================================
export const getCards = async () => {
  const response = await API.get("billing/cards/list");
  return response.data.data;
};
// =========================== Get Current Plan ==============================================
export const getCurrentPlan = async () => {
  const response = await API.get("billing/subscriptions/show");
  return response.data.data;
};
// =========================== Delete Cards ==============================================
export const deleteCard = async (id: number) => {
  const response = await API.delete(`/billing/cards/${id}`);
  return response.data;
};

// =========================== Subscribe for A Plan ==============================================
export const subscribe = async (data: {
  card_id: number;
  plan_id: number;
  amount: number;
}) => {
  const response = await API.post(`/billing/plans/subscribe`, {
    card_id: data.card_id,
    plan_id: data.plan_id,
    amount: data.amount,
  });
  return response.data;
};

// =========================== Create Card ==============================================
export const createCard = async (data: { provider_transaction_id: string }) => {
  const response = await API.post(`/billing/cards/create`, {
    provider_transaction_id: data.provider_transaction_id,
  });
  return response.data;
};

// =========================== Set Default Card ==============================================
export const setDefaultCard = async (id: number) => {
  const response = await API.patch(`/billing/cards/${id}`);
  return response.data;
};

// =========================== List all Subscriptions ==============================================
export const listAllSubscriptions = async (page: number, limit: number) => {
  const response = await API.get(
    `/billing/subscriptions/list/?page=${page}&limit=${limit}`
  );
  return response.data.data;
};

// =========================== Get Default Card ==============================================
export const getDefaultCard = async () => {
  const response = await API.get("/billing/cards/default");
  return response.data.data;
};
