import { useEffect } from "react";
import {
  Flex,
  Text,
  Box,
  useDisclosure,
  HStack,
  forwardRef,
  Button,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import {
  exportTransactionHistoy,
  filterTransactionHistory,
  getTransactionHistory,
} from "../API/wallet";
// import Empty from "../components/UI/Empty";
import Loader from "../components/UI/Loader";
import CustomPaginaton from "../components/UI/CustomPaginaton";
import { useState } from "react";
import { usePagination } from "@ajna/pagination";
import TransactionHistoryList from "../components/Wallet/TransactionHistoryList";
import CustomMenu from "../components/UI/CustomMenu";
import ChevronDown from "../icons/ChevronDown";
import DatePicker from "react-datepicker";
import CalendarIcon from "../icons/CalendarIcon";
import ExportIcon from "../icons/ExportIcon";
import ChakraSelect from "../components/UI/form/ChakraSelect";
import { getDate } from "../functions";
import dayjs from "dayjs";
import { PageAnimate } from "../components/animations/pageAnimate";

const TransactionHistory = () => {
  const [dataToShow, setDataToShow] = useState(10);
  const [myCurrentPage, setMyCurrentPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [filterByDuration, setFilterByDuration] = useState("this_week");
  const [showEntriesData, setShowEntriesData] = useState({
    start: 1,
    end: 10,
    total: 25,
  });

  const queryClient = useQueryClient();
  const toast = useToast();
  // transaction history query
  const { data: transactionHistory, isLoading } = useQuery(
    ["transactionHistoryData", dataToShow, myCurrentPage],
    () => {
      return getTransactionHistory({
        page: myCurrentPage,
        limit: dataToShow,
      });
    }
  );
  // filter transaction history mutation
  const filterMutation = useMutation({
    mutationFn: filterTransactionHistory,
    onSuccess: (result) => {
      queryClient.setQueryData(
        ["transactionHistoryData", dataToShow, myCurrentPage],
        result.data
      );
    },
  });
  // export transaction history mutation
  const exportMutation = useMutation({
    mutationFn: exportTransactionHistoy,
    onSuccess: (result) => {
      toast({
        title: result.message,
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top-right",
      });
    },
  });

  const { currentPage, setCurrentPage, pagesCount, pages } = usePagination({
    pagesCount: transactionHistory?.totalPages,
    limits: {
      outer: 1,
      inner: 1,
    },
    initialState: { currentPage: 1 },
  });

  const handlePageChange = (nextPage: number): void => {
    setCurrentPage(nextPage);
    setMyCurrentPage(nextPage);
  };

  useEffect(() => {
    const setCurr = (page: number) => {
      if (page === 1) {
        return 1;
      } else {
        return (page - 1) * dataToShow;
      }
    };
    const checkPage = (curr: number, total: number) => {
      let tt = total - curr * dataToShow;
      if (tt >= 0) {
        return curr * dataToShow;
      } else {
        return total;
      }
    };

    if (currentPage && transactionHistory) {
      let tots = transactionHistory?.totalPages * dataToShow;
      setShowEntriesData((prev) => ({
        ...prev,
        start: setCurr(currentPage),
        end: checkPage(currentPage, tots),
        total: tots,
      }));
    }
  }, [currentPage, dataToShow, transactionHistory]);

  const topupDisclosure = useDisclosure();

  const CustomDateInput = forwardRef(({ value, onClick }, ref) => (
    <Button
      variant={"ghost"}
      _hover={{ bg: "#fff" }}
      borderColor={"#0066FF1F"}
      borderWidth={"1px"}
      fontSize={"12px"}
      color={"grays.12"}
      leftIcon={<CalendarIcon />}
      fontWeight={500}
      lineHeight={"16px"}
      bg={"#fff"}
      borderRadius={"4px"}
      px={"20px"}
      py={"12px"}
      ref={ref}
      onClick={onClick}
    >
      {endDate == null ? "02/01/2023 - 22/03/2023" : value}
    </Button>
  ));

  const onDateFilter = (dates: any) => {
    setDataToShow(10);
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    // console.log("start", start, end);
    if (end) {
      filterMutation.mutate({
        startDate: start,
        endDate: end,
        page: 1,
        limit: dataToShow,
      });
    }
  };
  const handleFilter = (type: string) => {
    setDataToShow(10);
    const result = getDate(type);

    filterMutation.mutate({
      startDate: result?.start,
      endDate: result?.end,
      page: 1,
      limit: dataToShow,
    });
  };

  const handleExport = () => {
    if (endDate) {
      exportMutation.mutate({
        startDate: dayjs(startDate).format("YYYY-MM-DD"),
        endDate: dayjs(endDate).format("YYYY-MM-DD"),
      });
    } else {
      const result = getDate(filterByDuration);
      exportMutation.mutate({
        startDate: result.start,
        endDate: result.end,
      });
    }
  };

  return (
    <PageAnimate>
      <Flex
        flexDir={"column"}
        w="full"
        // pt={"20px"}
        pb="40px"
      >
        <HStack py="24px" justifyContent={"space-between"}>
          <Text fontSize={"20px"} color="grays.8" fontWeight={700}>
            Transaction history
          </Text>

          <HStack spacing={"20px"} alignItems={"center"}>
            <HStack>
              <CustomMenu
                buttonTitle={
                  <HStack
                    as={"span"}
                    display="inline-flex"
                    spacing={"8px"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      as="span"
                      fontSize={"xs"}
                      color="grays.12"
                      fontWeight={500}
                    >
                      {filterByDuration.charAt(0).toUpperCase() +
                        filterByDuration?.slice(1).replace("_", " ")}
                    </Text>
                    <ChevronDown fill="#7489AA" />
                  </HStack>
                }
                buttonProps={{
                  _hover: { bg: "#fff" },
                  borderColor: "#0066FF1F",
                  borderWidth: "1px",
                  fontSize: "xs",
                  minHeight: "48px",
                  fontWeight: 500,
                  lineHeight: "16px",
                  bg: "#fff",
                  borderRadius: "4px",
                  px: "20px",
                  py: "12px",
                }}
                isSelect
                itemsSelectData={[
                  {
                    title: "This week",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "this_week",
                      color: "grays.12",
                    },
                  },
                  {
                    title: "Last Week",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "last_week",
                      color: "grays.12",
                    },
                  },

                  {
                    title: "Last Month",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "last_month",
                      color: "grays.12",
                    },
                  },
                  // {
                  //   title: "Last Quarter",
                  //   itemSelectProps: {
                  //     fontSize: "xs",
                  //     value: "last_quarter",
                  //     color: "grays.12",
                  //   },
                  //   onFliter: () => {},
                  // },
                  {
                    title: "Last Year",
                    itemSelectProps: {
                      fontSize: "xs",
                      value: "last_year",
                      color: "grays.12",
                    },
                  },
                ]}
                menuSelectProps={{
                  defaultValue: "this_week",
                  onChange: (value) => {
                    setFilterByDuration(value as string);
                    handleFilter(value as string);
                  },
                }}
              />

              <Text
                fontSize={"xs"}
                fontWeight={500}
                color="grays.12"
                letterSpacing={"-0.03em"}
              >
                OR
              </Text>

              <Box position={"relative"}>
                <DatePicker
                  title="Pick a date range..."
                  selectsRange
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => {
                    // setDateRange(update);
                    onDateFilter(update);
                  }}
                  customInput={<CustomDateInput />}
                />
              </Box>
            </HStack>

            <Box>
              <Button
                leftIcon={<ExportIcon />}
                variant={"solid"}
                colorScheme={"primary"}
                fontSize={"12px"}
                fontWeight={700}
                lineHeight={"16px"}
                borderRadius={"4px"}
                pl={"20px"}
                pr={"24px"}
                py={"10px"}
                onClick={handleExport}
              >
                Export
              </Button>
            </Box>
          </HStack>
        </HStack>

        {/* <CampaignList /> */}
        <Box w="full" bg="white" rounded="4px">
          {isLoading && <Loader />}
          {!isLoading && transactionHistory && (
            <>
              <HStack spacing={"10px"} my={"16px"} ml={"20px"}>
                <Text color="grays.12" fontWeight={500} fontSize="xs">
                  Showing
                </Text>

                <Box>
                  <ChakraSelect
                    selectProps={{
                      h: "24px",
                      px: 0,
                      borderWidth: "1px",
                      rounded: "4px",
                      borderColor: "grays.12",
                      fontWeight: 500,
                      color: "grays.12",
                      fontSize: "xs",
                      _placeholder: {
                        color: "grays.12",
                      },
                      value: Number(dataToShow),
                      onChange: (e) => {
                        dataToShow && setDataToShow(Number(e.target.value));
                      },
                    }}
                  >
                    <chakra.option value={10}>10</chakra.option>
                    <chakra.option value={15}>15</chakra.option>
                    <chakra.option value={20}>20</chakra.option>
                    <chakra.option value={25}>25</chakra.option>
                    <chakra.option value={30}>30</chakra.option>
                  </ChakraSelect>
                </Box>

                <Text color="grays.12" fontWeight={500} fontSize="xs">
                  entries
                </Text>
              </HStack>

              <Box h="70vh" overflowY={"auto"} className="thinSB" bg={"white"}>
                <TransactionHistoryList
                  data={transactionHistory?.docs}
                  isEmpty={transactionHistory?.docs?.length === 0}
                  topupDisclosure={topupDisclosure}
                />
              </Box>

              <Box py={3} px={"24px"}>
                <CustomPaginaton
                  pagesCount={pagesCount}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                  pages={pages}
                  numberOfdataToShow={dataToShow}
                  setDataToShow={setDataToShow}
                  showEntriesData={showEntriesData}
                  showEntries
                />
              </Box>
            </>
          )}
        </Box>
      </Flex>
    </PageAnimate>
  );
};

export default TransactionHistory;
