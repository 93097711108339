import { Box, Button, ButtonProps, HStack, Text } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { ButtonPrimary } from "./Buttons";

const Empty: FC<{
  title?: string;
  description?: ReactNode;
  buttonText?: string;
  buttonAction?: () => void;
  button2Action?: () => void;
  icon?: ReactNode;
  height?: string;
  button2?: boolean;
  button2Props?: any;
  buttonProps?: ButtonProps;
  buttonText2?: string;
  // altBtnBg?:
}> = ({
  title,
  description,
  buttonText,
  buttonAction,
  button2Action,
  icon,
  height = "50vh",
  buttonProps,
  button2,
  button2Props,
  buttonText2,
}) => {
  return (
    <Box
      display={"flex"}
      flexDirection="column"
      alignItems={"center"}
      textAlign={"center"}
      h={height}
    >
      {icon}

      <Text
        mt="20px"
        mb={description ? "9px" : "12px"}
        fontSize={"20px"}
        color="grays.8"
        fontWeight={700}
      >
        {title}
      </Text>
      {description ? (
        <Text mb="32px" fontSize={"14px"} color="grays.12" fontWeight={500}>
          {description}
        </Text>
      ) : null}

      {buttonText &&
        (button2 ? (
          <HStack spacing={button2 && "16px"}>
            <ButtonPrimary
              buttonProps={{
                variant: "outline",
                maxHeight: "40px",
                ...buttonProps,
              }}
              onClick={() => {
                buttonAction && buttonAction();
              }}
            >
              {buttonText}
            </ButtonPrimary>

            <ButtonPrimary
              buttonProps={{
                variant: "solid",
                maxHeight: "40px",
                ...button2Props,
              }}
              onClick={() => {
                button2Action && button2Action();
              }}
            >
              {buttonText2}
            </ButtonPrimary>
          </HStack>
        ) : (
          <Button
            size="sm"
            colorScheme={"primary"}
            onClick={() => {
              buttonAction && buttonAction();
            }}
            {...buttonProps}
          >
            {buttonText}
          </Button>
        ))}
    </Box>
  );
};

export default Empty;
