import {
  Box,
  Button,
  HStack,
  StackProps,
  Text,
  VStack,
} from "@chakra-ui/react";
import { FC } from "react";

const LabelValue: FC<{
  label: string;
  value: string | undefined;
  addBottomBorder?: boolean;
  containerProps?: StackProps;
  showEditButton?: boolean;
  editbuttonAction?: () => void;
}> = ({
  label,
  value,
  addBottomBorder,
  containerProps,
  showEditButton,
  editbuttonAction,
}) => {
  return (
    <Box
      borderBottomWidth={addBottomBorder ? "1px" : ""}
      borderBottomColor="borders.200"
      pb={addBottomBorder ? "16px" : "0"}
      {...containerProps}
      w="full"
    >
      <HStack w="full" spacing={10} role="group">
        <VStack width={"full"} align={"flex-start"} spacing="4px">
          <Text fontSize={"xs"} color="grays.11" fontWeight={500}>
            {label}
          </Text>
          <Text fontSize={"xs"} fontWeight={500} color="grays.8">
            {value}
          </Text>
        </VStack>

        {showEditButton && (
          <Button
            onClick={editbuttonAction}
            size="xs"
            minWidth={"40px"}
            textAlign={"center"}
            variant={"outline"}
            rounded="2px"
            colorScheme="primary"
            borderColor={"primary.500"}
            color={"primary.500"}
            display={"none"}
            _groupHover={{
              display: "block",
            }}
          >
            Edit
          </Button>
        )}
      </HStack>
    </Box>
  );
};

export default LabelValue;
