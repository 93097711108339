import API from ".";

export const exportTransactionHistoy = async (data: {
  startDate: string;
  endDate: string;
}) => {
  const response = await API.get(
    `/wallet/transaction/list?start_date=${data.startDate}&end_date=${data.endDate}&export=true`
  );
  return response.data;
};

export const getCountriesAndCurrencies = async () => {
  const response = await API.get(`/auth/extra-info`);
  return {
    countries: response.data.data.countries,
    currencies: response.data.data.currencies,
  } as {
    countries: Array<{
      id: number;
      uid: string;
      name: string;
      dial_code: string;
      short_code: string;
      alpha_3: string;
      created_at: string;
      updated_at: string;
    }>;
    currencies: Array<{
      id: number;
      uid: string;
      currency: string;
      created_at: string;
      updated_at: string;
    }>;
  };
};

export const getCampaignPricing = async (data: {
  shortCode: string;
  currency: string;
}) => {
  const response = await API.get(`/pricing/${data.shortCode}/${data.currency}`);

  return response.data;
};
