import React, { FC } from "react";
import {
    Box,
    Badge,
    Flex,
    HStack,
    Text,
    useToast,
    useDisclosure,
    Button,
} from "@chakra-ui/react";
import CustomMenu from "../../UI/CustomMenu";
import CustomModal from "../../UI/CustomModal";

import ChevronDown from "../../../icons/ChevronDown";
import Card from "./card";
import { useMutation, useQueryClient } from "@tanstack/react-query";

import { cardValueType } from "./SelectUpgradePaymentMethod";
import { setDefaultCard, deleteCard } from "../../../API/account/billings";
import { toaster } from "../../../utils/toast";

const SavedCard: FC<{ click?: () => void; data: cardValueType }> = ({
    data,
}) => {
    const queryClient = useQueryClient();
    const toast = useToast();
    const disclosure = useDisclosure();

    const { mutate } = useMutation(setDefaultCard, {
        onSuccess(data) {
            const success = { ...data }
            queryClient.invalidateQueries(["listCards"]);
            toaster(toast, success)

        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
    });

    const { isLoading, mutate: deleteCards } = useMutation(deleteCard, {
        onSuccess(data) {
            const success = { ...data }
            queryClient.invalidateQueries(["listCards"]);
            disclosure.onClose();
            toaster(toast, success)
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
            disclosure.onClose();
        },
    });

    return (
        <>
            <Box
                background={"backgrounds.400"}
                h="144px"
                w="294px"
                p="20px"
                borderRadius="4px"
                borderColor={"borders.500"}
                borderWidth="1px"
                m="5px"
            >
                <Flex justifyContent="space-between">
                    {data.is_default && (
                        <Badge
                            borderRadius="50px"
                            px="12px"
                            borderColor="grays.12"
                            borderWidth="1px"
                            fontSize="10px"
                            fontWeight="700"
                            color="grays.12"
                        >
                            DEFAULT
                        </Badge>
                    )}
                    <Flex ml="auto" h={"25px"}>
                        <CustomMenu
                            buttonTitle={
                                <HStack spacing={"5px"}>
                                    <Text color="grays.12" fontSize={"12px"} fontWeight={"500"}>
                                        View options
                                    </Text>
                                    <ChevronDown width="12px" height="12px" />
                                </HStack>
                            }
                            itemsData={[
                                {
                                    title: "Make default card",
                                    itemProps: {
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: "grays.12",
                                        onClick: () => {
                                            mutate(data?.id);
                                        },
                                    },
                                },
                                {
                                    title: "Delete card",
                                    itemProps: {
                                        fontWeight: "500",
                                        fontSize: "12px",
                                        color: "grays.12",
                                        onClick: () => {
                                            disclosure.onOpen();
                                        },
                                    },
                                },
                            ]}
                        />
                    </Flex>
                </Flex>
                <Card data={data} />
            </Box>
            <CustomModal disclosure={disclosure}>
                <Box mt="42px" pb="40px" textAlign={"center"} w="full">
                    <Box display={"inline-flex"}>
                        <svg
                            width="80"
                            height="80"
                            viewBox="0 0 80 80"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                opacity="0.4"
                                d="M73.3327 25.8948V29.9948H6.66602V25.1281C6.66602 17.4948 12.866 11.3281 20.4993 11.3281H53.3327V19.8948C53.3327 24.1281 55.866 26.6615 60.0994 26.6615H69.8993C71.2327 26.6615 72.366 26.4281 73.3327 25.8948Z"
                                fill="#7489AA"
                            />
                            <path
                                d="M6.66602 30V54.8667C6.66602 62.5 12.866 68.6667 20.4993 68.6667H59.4994C67.1327 68.6667 73.3327 62.5 73.3327 54.8667V30H6.66602ZM26.666 57.5H19.9993C18.6327 57.5 17.4993 56.3667 17.4993 55C17.4993 53.6333 18.6327 52.5 19.9993 52.5H26.666C28.0327 52.5 29.166 53.6333 29.166 55C29.166 56.3667 28.0327 57.5 26.666 57.5ZM48.3327 57.5H34.9993C33.6327 57.5 32.4993 56.3667 32.4993 55C32.4993 53.6333 33.6327 52.5 34.9993 52.5H48.3327C49.6994 52.5 50.8327 53.6333 50.8327 55C50.8327 56.3667 49.6994 57.5 48.3327 57.5Z"
                                fill="#7489AA"
                            />
                            <path
                                d="M69.9007 3.33594H60.1007C55.8673 3.33594 53.334 5.86927 53.334 10.1026V19.9026C53.334 24.1359 55.8673 26.6693 60.1007 26.6693H69.9007C74.134 26.6693 76.6673 24.1359 76.6673 19.9026V10.1026C76.6673 5.86927 74.134 3.33594 69.9007 3.33594ZM71.134 18.3026C71.5007 18.7359 71.7007 19.2026 71.7007 19.7693C71.7007 20.2359 71.5007 20.7693 71.134 21.1359C70.3673 21.9026 69.0673 21.9026 68.3007 21.1359L65.0007 17.8359L61.734 21.1693C60.9673 21.9359 59.6673 21.9359 58.8673 21.1359C58.1007 20.3693 58.1007 19.0693 58.8673 18.3026L62.2007 15.0359L58.9007 11.7359C58.134 10.9693 58.1007 9.66927 58.8673 8.86927C59.634 8.06927 60.934 8.1026 61.734 8.9026L65.0007 12.2026L68.334 8.86927C69.1007 8.1026 70.4007 8.1026 71.1673 8.86927C71.534 9.3026 71.7007 9.8026 71.734 10.3359C71.734 10.8026 71.534 11.3359 71.2007 11.7359L67.8673 15.0693L71.134 18.3026Z"
                                fill="#7489AA"
                            />
                        </svg>
                    </Box>
                    <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
                        Are you sure you want to delete this card?
                    </Text>
                    <Button
                        w="full"
                        isLoading={isLoading}
                        onClick={() => {
                            deleteCards(data?.id);
                        }}
                        bg="danger.300"
                        colorScheme={"text.100"}
                        mt="40px"
                    >
                        Delete card
                    </Button>
                </Box>
            </CustomModal>
        </>
    );
};

export default SavedCard;
