import React, { FC, useMemo } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import SortArrowUp from "../../icons/SortArrowUp";
import SortArrowDown from "../../icons/SortArrowDown";
import dayjs from "dayjs";
import Empty from "../UI/Empty";
import { ScaleUp, SwipeIn } from "../animations";

interface listProps {
  id: string | number;
  uid: string;
  company_uid: string;
  wallet_uid: string;
  reference: string;
  provider_reference: string;
  base_currency_amount: number;
  base_currency_amount_before: number;
  base_currency_amount_after: number;
  transaction_business_currency_amount: number;
  transaction_business_currency: string;
  transaction_service_code: string;
  type: string;
  status: string;
  created_at: Date;
  updated_at: Date;
  exchange_rate: {
    current_rate: number;
    current_business_currency: string;
    current_business_amount: number;
    current_business_balance_before: number;
    current_business_balance_after: number;
  };
}

const TransactionHistoryList: FC<any> = ({ data, topupDisclosure }) => {
  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g campaignName and Contacts
  const columns = useMemo<ColumnDef<listProps>[]>(() => {
    const statusColorsToUse = (status: string) => {
      switch (status) {
        case "successful":
          return {
            color: "#159D6F",
            bg: "rgba(21, 157, 111, 0.04)",
          };
        case "pending":
          return {
            color: "rgb(220, 168, 10)",
            bg: "rgba(220, 168, 10, 0.04)",
          };
        case "scheduled":
          return {
            color: "rgb(0, 102, 255)",
            bg: "rgba(0, 102, 255, 0.04)",
          };
        case "draft":
          return {
            color: "rgb(25, 63, 100)",
            bg: "rgba(25, 63, 100, 0.04)",
          };

        default:
      }
    };

    return [
      {
        header: () => <Text>Date & time</Text>,
        accessorKey: "created_at",
        id: "Date & time",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },

      {
        header: "Transaction type",
        accessorKey: "type",
        id: "type",
        cell: (props: any) => {
          return (
            <Box
              bg={statusColorsToUse(props?.getValue())?.bg}
              w="fit-content"
              py="4px"
              px="8px"
              rounded="full"
              borderRadius={"12px"}
              borderWidth={"0.5px"}
              // borderColor={statusColorsToUse(props?.getValue())?.color}
              borderColor={statusColorsToUse("scheduled")?.color}
              // color={statusColorsToUse(props?.getValue())?.color}
              color={statusColorsToUse("scheduled")?.color}
              textTransform="capitalize"
              fontWeight={500}
              fontSize={"xs"}
            >
              {props?.getValue() === "in-progress"
                ? "In Progress"
                : props?.getValue()}
            </Box>
          );
        },
      },

      {
        header: () => <Text>Old Balance</Text>,
        accessorKey: "exchange_rate.current_business_balance_before",
        id: "exchange_rate.current_business_balance_before",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
        accessorFn: (row) =>
          `${
            row?.exchange_rate?.current_business_currency
          } ${row?.exchange_rate?.current_business_balance_before?.toFixed(5)}`,
      },

      {
        header: () => <Text>Amount</Text>,
        accessorKey: "amount",
        id: "amount",
        accessorFn: (row) =>
          `${
            row?.transaction_business_currency
          } ${row?.transaction_business_currency_amount?.toFixed(5)}`,
      },

      {
        header: () => <Text>Wallet Balance</Text>,
        accessorKey: "wallet_balance",
        id: "wallet_balance",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
        accessorFn: (row) =>
          `${
            row?.exchange_rate?.current_business_currency
          } ${row?.exchange_rate?.current_business_balance_after?.toFixed(5)}`,
      },

      {
        header: () => <Text>Transaction Reference</Text>,
        accessorKey: "reference",
        id: "reference",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
    ];
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <TableContainer
      overflowX="unset"
      overflowY="unset"
      borderWidth={"1px"}
      borderBottomWidth="0px"
      borderLeftWidth={"0px"}
      borderTopWidth={"0px"}
      borderRightWidth={"0px"}
      borderColor="rgba(0, 102, 255, 0.08)"
    >
      <Table variant={"simple"} position={"relative"}>
        <Thead position={"sticky"} top={0} bg={"white"} boxShadow={"sm"}>
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Th
                      key={header.id}
                      textTransform="none"
                      color={"grays.12"}
                      fontSize="xs"
                      letterSpacing={"unset"}
                      fontWeight={500}
                      colSpan={header.colSpan}
                    >
                      <Box display={"flex"}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}

                        {header.column.getCanSort() && (
                          <VStack spacing="2px" my="auto" ml="2">
                            <chakra.button
                              onClick={() => {
                                header.column.toggleSorting();
                              }}
                            >
                              <SortArrowUp width="8px" />
                            </chakra.button>

                            <chakra.button
                              onClick={() => {
                                header.column.toggleSorting();
                              }}
                            >
                              <SortArrowDown width="8px" />
                            </chakra.button>
                          </VStack>
                        )}
                      </Box>
                    </Th>
                  );
                })}
              </Tr>
            );
          })}
        </Thead>

        {data.length > 0 ? (
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    <SwipeIn delay={0.2 * index}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </SwipeIn>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        ) : null}
      </Table>

      {data.length === 0 ? (
        <ScaleUp delay={0.5} style={{ marginBlockStart: "40px" }}>
          <Empty
            height={"fit-content"}
            description="You haven’t made any transactions yet"
            buttonText="Top up balance now"
            icon={
              <svg
                width="96"
                height="96"
                viewBox="0 0 96 96"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M72.16 54.2C70.48 55.84 69.52 58.2 69.76 60.72C70.12 65.04 74.08 68.2 78.4 68.2H86V72.96C86 81.24 79.24 88 70.96 88H25.04C16.76 88 10 81.24 10 72.96V46.04C10 37.76 16.76 31 25.04 31H70.96C79.24 31 86 37.76 86 46.04V51.8H77.92C75.68 51.8 73.64 52.68 72.16 54.2Z"
                  fill="#7489AA"
                />
                <path
                  d="M59.4 15.8005V31.0004H25.04C16.76 31.0004 10 37.7605 10 46.0405V31.3606C10 26.6006 12.92 22.3604 17.36 20.6804L49.12 8.68038C54.08 6.84038 59.4 10.4805 59.4 15.8005Z"
                  fill="#7489AA"
                />
                <path
                  d="M90.2392 55.8806V64.1208C90.2392 66.3208 88.4792 68.1207 86.2392 68.2007H78.3992C74.0792 68.2007 70.1192 65.0407 69.7592 60.7207C69.5192 58.2007 70.4792 55.8407 72.1592 54.2007C73.6392 52.6807 75.6791 51.8008 77.9191 51.8008H86.2392C88.4792 51.8808 90.2392 53.6806 90.2392 55.8806Z"
                  fill="#7489AA"
                />
                <path
                  d="M56 51H28C26.36 51 25 49.64 25 48C25 46.36 26.36 45 28 45H56C57.64 45 59 46.36 59 48C59 49.64 57.64 51 56 51Z"
                  fill="#7489AA"
                />
              </svg>
            }
            buttonAction={topupDisclosure.onOpen}
          />
        </ScaleUp>
      ) : null}
    </TableContainer>
  );
};

export default TransactionHistoryList;
