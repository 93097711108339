export const textStyles = {
  h1: {
    fontSize: { base: '3xl', md: '5xl' },
    lineHeight: { base: '35px', md: '52px' },
    fontWeight: 'medium',
  },
  h2: {
    fontSize: { base: '2xl', md: '3xl' },
    fontWeight: 'semibold',
  },
  h3: {
    fontSize: { base: 'xl', md: '2xl' },
  },
  h4: {
    fontSize: { base: 'lg', md: 'xl' },
  },
  subtitle: {
    fontSize: { base: 'md', md: 'lg' },
    lineHeight: { base: '24px', md: '28px' },
  },
  content: {
    fontSize: { base: 'sm', md: 'md' },
  },
  small: {
    fontSize: { base: 'xs' },
  },
};

export const layerStyles = {
  xSpace: {
    px: { base: 8, md: 32 },
  },
  flexHideColumRow: {
    d: { base: 'none', md: 'flex' },
  },
  flexColumRow: {
    flexDir: { base: 'column', md: 'row' },
  },
};
