import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ChakraProvider, ColorModeScript } from "@chakra-ui/react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import customTheme from "./chakra/theme";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { RecoilRoot } from "recoil";
import { logoutHandler } from "./utils/functions";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { getCookie, setCookie } from "cookies-next";
import MainApp from "./main";
import ScrollToTop from "./helpers/ScrollToTop";
import "./index.css";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");

const root = ReactDOM.createRoot(container);
// @ts-ignore
const queryparams = new URL(window.location).searchParams;

const g54Token = queryparams.get("g54-token");
if (g54Token) {
  setCookie("USER_TOKEN", g54Token);
  window.location.replace("/");
}

const loggedIn = !!getCookie("USER_TOKEN");

process.env.NODE_ENV === "production" &&
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY,
    integrations: [new BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry(failureCount, error) {
        return failureCount === 1;
      },
      onError(err: any) {
        const errors = { ...err };
        if (
          errors.response?.data?.message?.includes("token") === "" ||
          errors?.response?.data?.error?.name.includes("token") ||
          loggedIn === false ||
          errors?.response?.status === 401
        ) {
          logoutHandler();
          // console.log("errrrrr", err);
        }
      },
    },
  },
});

root.render(
  <QueryClientProvider client={queryClient}>
    <RecoilRoot>
      <ChakraProvider theme={customTheme}>
        <ColorModeScript />
        <BrowserRouter>
          <ScrollToTop />
          <MainApp />
        </BrowserRouter>
      </ChakraProvider>
    </RecoilRoot>
    <ReactQueryDevtools initialIsOpen={false} position={"top-right"} />
  </QueryClientProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
