import React, { FC, useState } from "react";
import {
  VStack,
  chakra,
  Button,
  useToast,
  Select,
  Box,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import FormInput from "../../UI/form/FormInput";
// import SearchableSelect from "../../UI/form/SearchableSelect";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createContactAttributes } from "../../../API/contacts";
import { toaster } from "../../../utils/toast";
import { FaCaretDown } from "react-icons/fa";
import { removeSpaces } from "../../../functions";

const CreateAttribute: FC<{
  close: () => void;
  queryToInvalidate?: Array<any>;
}> = ({ close, queryToInvalidate }) => {
  const [attributeName, setAttributeName] = useState("");
  const [dataType, setDataType] = useState("text");
  const [description, setDescription] = useState("");

  const toast = useToast();
  const queryClient = useQueryClient();
  const properties = [
    { name: "Text", value: "string" },
    { name: "Number", value: "number" },
    { name: "True/False", value: "boolean" },
    { name: "Date", value: "Date" },
  ];

  const createAttributeMutation = useMutation(createContactAttributes, {
    onSuccess: (data) => {
      const success = { ...data };
      queryClient.invalidateQueries(["contactAttributes"]);
      queryClient.invalidateQueries(queryToInvalidate);
      toaster(toast, success);
      close();
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });
  return (
    <chakra.form
      mt="24px"
      onSubmit={(e) => {
        e.preventDefault();
        let formattedName = removeSpaces(attributeName);
        createAttributeMutation.mutate({
          name: formattedName,
          data_type: dataType,
          description,
        });
      }}
    >
      <VStack align={"flex-start"} spacing="20px">
        <FormInput
          label="Name"
          type="text"
          for="Name"
          inputProps={{
            placeholder: "Enter attribute name",
            onChange: (e) => {
              setAttributeName(e.target.value);
            },
            value: attributeName,
          }}
        />
        {/* <SearchableSelect
          height={"32px"}
          label="Data type"
          placeholder="Select type"
          fontSize="12px"
          options={properties}
          value={dataType}
          
        /> */}
        <Box width={"100%"}>
          <FormControl>
            <FormLabel color={"grays.9"} fontSize={"12px"} fontWeight={700}>
              Data type
            </FormLabel>
            <Select
              width={"100%"}
              fontSize={"sm"}
              color={"grays.12"}
              borderColor={"borders.100"}
              iconSize={"16px"}
              icon={<FaCaretDown />}
              aria-label="select data type"
              placeholder="Select type"
              onChange={(e) => {
                setDataType(e.target.value);
              }}
              defaultValue={dataType}
            >
              {properties.map((option, index) => (
                <chakra.option
                  key={index}
                  value={option.value}
                  color={"grays.9"}
                >
                  {option.name}
                </chakra.option>
              ))}
            </Select>
          </FormControl>
        </Box>

        <FormInput
          label="Description"
          type="textarea"
          for="Description"
          textareaProps={{
            placeholder: "Describe your attribute here",
            onChange: (e) => {
              setDescription(e.target.value);
            },
            value: description,
          }}
        />
      </VStack>

      <Button
        type="submit"
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "#B2B6BD",
        }}
        _hover={{
          bg: "grey.20",
        }}
        _focus={{
          bg: "grey.20",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={createAttributeMutation.isLoading}
        isDisabled={!attributeName || !description}
      >
        Add attribute
      </Button>
    </chakra.form>
  );
};

export default CreateAttribute;
