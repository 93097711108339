import React, { FC } from "react";

export const EmptyTemplateIcon: FC<any> = (props) => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.46 45.5H16.54C20.54 45.5 22.5 43.64 22.5 39.8V8.2C22.5 4.36 20.56 2.5 16.54 2.5H8.46C4.46 2.5 2.5 4.36 2.5 8.2V39.8C2.5 43.64 4.44 45.5 8.46 45.5ZM31.46 27.5H39.54C43.54 27.5 45.5 25.64 45.5 21.8V8.2C45.5 4.36 43.56 2.5 39.54 2.5H31.46C27.46 2.5 25.5 4.36 25.5 8.2V21.8C25.5 25.64 27.44 27.5 31.46 27.5ZM39.54 45.5H31.46C27.44 45.5 25.5 43.64 25.5 39.8V36.2C25.5 32.36 27.46 30.5 31.46 30.5H39.54C43.56 30.5 45.5 32.36 45.5 36.2V39.8C45.5 43.64 43.54 45.5 39.54 45.5Z"
        fill="#7489AA"
      />
    </svg>
  );
};
