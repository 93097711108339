import { Center, Text } from '@chakra-ui/react';
import React from 'react';

const AddedSuccessfully = () => {
  return (
    <Center
      flexDir={'column'}
      textAlign='center'
      mt='64px'
      mb='84px'
      maxW={'204px'}
      mx='auto'
    >
      <svg
        width='112'
        height='112'
        viewBox='0 0 112 112'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='56' cy='56' r='56' fill='#1D960C' fillOpacity='0.08' />
        <circle cx='56' cy='56' r='32' fill='#1D960C' fillOpacity='0.08' />
        <path
          d='M54.3864 60.2267C54.1198 60.2267 53.8664 60.12 53.6798 59.9334L50.4531 56.7067C50.0664 56.32 50.0664 55.68 50.4531 55.2934C50.8398 54.9067 51.4798 54.9067 51.8664 55.2934L54.3864 57.8134L60.1198 52.08C60.5064 51.6934 61.1464 51.6934 61.5331 52.08C61.9198 52.4667 61.9198 53.1067 61.5331 53.4934L55.0931 59.9334C54.9064 60.12 54.6531 60.2267 54.3864 60.2267Z'
          fill='#1D960C'
        />
        <path
          d='M55.9995 70.3337C55.1595 70.3337 54.3195 70.0537 53.6662 69.4937L51.5595 67.6803C51.3462 67.4937 50.8129 67.307 50.5329 67.307H48.2395C46.2662 67.307 44.6662 65.707 44.6662 63.7337V61.4537C44.6662 61.1737 44.4795 60.6537 44.2929 60.4403L42.4929 58.3203C41.3995 57.027 41.3995 54.987 42.4929 53.6937L44.2929 51.5737C44.4795 51.3603 44.6662 50.8403 44.6662 50.5603V48.267C44.6662 46.2937 46.2662 44.6937 48.2395 44.6937H50.5462C50.8262 44.6937 51.3595 44.4937 51.5729 44.3203L53.6795 42.507C54.9862 41.387 57.0262 41.387 58.3329 42.507L60.4395 44.3203C60.6529 44.507 61.1862 44.6937 61.4662 44.6937H63.7329C65.7062 44.6937 67.3062 46.2937 67.3062 48.267V50.5337C67.3062 50.8137 67.5062 51.347 67.6929 51.5603L69.5062 53.667C70.6262 54.9737 70.6262 57.0137 69.5062 58.3203L67.6929 60.427C67.5062 60.6403 67.3062 61.1737 67.3062 61.4537V63.7203C67.3062 65.6937 65.7062 67.2937 63.7329 67.2937H61.4662C61.1862 67.2937 60.6529 67.4937 60.4395 67.667L58.3329 69.4803C57.6795 70.0537 56.8395 70.3337 55.9995 70.3337ZM48.2395 46.6937C47.3729 46.6937 46.6662 47.4003 46.6662 48.267V50.547C46.6662 51.307 46.3062 52.2803 45.8129 52.8537L44.0129 54.9737C43.5462 55.5203 43.5462 56.467 44.0129 57.0137L45.8129 59.1337C46.3062 59.7203 46.6662 60.6803 46.6662 61.4403V63.7203C46.6662 64.587 47.3729 65.2937 48.2395 65.2937H50.5462C51.3195 65.2937 52.2929 65.6537 52.8795 66.1603L54.9862 67.9737C55.5329 68.4403 56.4929 68.4403 57.0395 67.9737L59.1462 66.1603C59.7329 65.667 60.7062 65.2937 61.4795 65.2937H63.7462C64.6129 65.2937 65.3195 64.587 65.3195 63.7203V61.4537C65.3195 60.6803 65.6795 59.707 66.1862 59.1203L67.9995 57.0137C68.4662 56.467 68.4662 55.507 67.9995 54.9603L66.1862 52.8537C65.6795 52.267 65.3195 51.2937 65.3195 50.5203V48.267C65.3195 47.4003 64.6129 46.6937 63.7462 46.6937H61.4795C60.7062 46.6937 59.7329 46.3337 59.1462 45.827L57.0395 44.0137C56.4929 43.547 55.5329 43.547 54.9862 44.0137L52.8795 45.8403C52.2929 46.3337 51.3062 46.6937 50.5462 46.6937H48.2395Z'
          fill='#1D960C'
        />
      </svg>

      <Text mt='26px' color='grays.8' fontSize={'20px'} fontWeight='bold'>
        Successful
      </Text>
      <Text
        mt='9px'
        fontSize={'sm'}
        fontWeight='medium'
        color='grays.12'
        maxW={'360px'}
      >
        Your social registration has been successfully completed.
      </Text>
    </Center>
  );
};

export default AddedSuccessfully;
