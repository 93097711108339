import { atom } from 'recoil';
import { ChatDataProps } from '../utils/generalProps';

const chatAtom = atom<ChatDataProps>({
  key: 'chatAtom',
  default: {
    conversation: {
      id: '',
      uid: '',
      company_uid: '',
      contact_uid: '',
      channel: '',
      last_message: '',
      message_type: '',
      no_of_unseen_messages: '',
      connection_uid: '',
      status: '',
      created_at: '',
      updated_at: '',
      contact: {
        id: 235,
        uid: '',
        company_uid: '',
        profile_picture: '',
        first_name: '',
        last_name: null,
        email: null,
        phone_number: '',
        instagram_username: null,
        instagram_id: null,
        facebook_username: null,
        facebook_id: null,
        twitter_username: '',
        twitter_id: '',
        is_blocked: '',
        deleted_at: null,
        data_source: null,
        metadata: null,
        created_at: '',
        updated_at: '',
      },
      facebook_connection: null,
      instagram_connection: null,
      twitter_connection: {
        id: 63,
        uid: '',
        company_uid: '',
        twitter_secret: '',
        access_token: '',
        access_token_secret: '',
        payload: '',
        status: '',
        deleted_at: null,
        created_at: '',
        updated_at: '',
      },
    },
    messages: [],
  },
});

export { chatAtom };
