import React, { FC, useMemo, useState } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  HStack,
  VStack,
  Box,
  useDisclosure,
  Button,
  useToast,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import CustomMenu from "../../UI/CustomMenu";
// import ChevronDown from "../../../icons/ChevronDown";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import CustomModal from "../../UI/CustomModal";
import dayjs from "dayjs";
import ChakraSelect from "../../../components/UI/form/ChakraSelect";
import FormInput from "../../UI/form/FormInput";

import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  deleteTeammate,
  deactivateActivateTeammate,
  updateRole,
  editTeammate,
} from "../../../API/account/teammates";
import { fuzzyFilter } from "../../../utils/table";
import { toaster } from "../../../utils/toast";
import { ArrowDown } from "../../../assets/svgs/svg";

export interface actualTeammatesProps {
  is_active: boolean;
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  email: string;
  first_name: string;
  last_name: string;
  is_owner: boolean;
  date_added: Date;
  created_at: Date;
  updated_at: Date;
}

const TeammatesTable: FC<{
  teammatesData: actualTeammatesProps[];
  globalFilter: any;
  setGlobalFilter: any;
}> = ({ teammatesData, globalFilter, setGlobalFilter }) => {
  const [role, setRole] = useState<string>("");
  const [dataToUpdate, setDataToUpdate] = useState<actualTeammatesProps>({
    id: 0,
    uid: "",
    company_uid: "",
    name: "",
    is_owner: false,
    last_name: "",
    first_name: "",
    created_at: new Date(),
    updated_at: new Date(),
    date_added: new Date(),
    email: "",
    is_active: true,
  });

  const [first_name, setFirstName] = useState<string>("");
  const [last_name, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  const [sorting, setSorting] = React.useState<SortingState>([]);

  const changeRoleDisclosure = useDisclosure();
  const deleteDisclosure = useDisclosure();
  const editDisclosure = useDisclosure();
  const deactivateDisclosure = useDisclosure();

  const setEdits = (val: any) => {
    setDataToUpdate(val);
    setFirstName(val?.first_name);
    setLastName(val?.last_name);
    setEmail(val?.email);
  };

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<actualTeammatesProps>[]>(() => {
    return [
      {
        header: () => <Text>Full name</Text>,
        accessorKey: "fullname",
        id: "fullname",
        accessorFn: (row) => `${row.first_name || ""} ${row.last_name || ""}`,
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Email",
        accessorKey: "email",
        id: "email",
        cell: (props: any) => {
          return <Text>{props?.getValue()}</Text>;
        },
      },
      {
        header: "Role",
        accessorKey: "is_owner",
        id: "is_owner",
        cell: (props: any) => {
          return <Text>{props?.getValue() ? "Admin" : "Member"}</Text>;
        },
      },
      {
        header: "Date added",
        accessorKey: "date_added",
        id: "date_added",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("DD/MM/YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "is_active",
        id: "is_active",
        cell: (props: any) => {
          return (
            <Text
              bg={props?.getValue() ? "green.200" : "danger.100"}
              borderColor={props?.getValue() ? "green.100" : "danger.200"}
              borderWidth="0.5px"
              color={props?.getValue() ? "green.100" : "danger.200"}
              px="8px"
              w="fit-content"
              py="4px"
              borderRadius="50px"
            >
              {props?.getValue() ? "Active" : "Inactive"}
            </Text>
          );
        },
      },
      {
        header: "Actions",
        id: "actions",
        cell: (props: any) => (
          <CustomMenu
            buttonTitle={
              <HStack spacing={"14px"}>
                <Text fontWeight={"medium"}>View actions</Text>
                <ArrowDown width="14px" height="14px" />
              </HStack>
            }
            itemsData={[
              {
                title: props?.row?.original.is_active
                  ? "Deactivate account"
                  : "Activate account",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    deactivateDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  },
                },
              },
              {
                title: "Change role",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    changeRoleDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                    setRole(props?.row?.original.is_owner ? "admin" : "member");
                  },
                },
              },
              {
                title: "Edit teammate",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    editDisclosure.onOpen();
                    setEdits(props?.row?.original);
                  },
                },
              },
              {
                title: "Delete teammate",
                itemProps: {
                  fontWeight: "medium",
                  onClick: () => {
                    deleteDisclosure.onOpen();
                    setDataToUpdate(props?.row?.original);
                  },
                },
              },
            ]}
          />
        ),
      },
    ];
  }, [
    deactivateDisclosure,
    changeRoleDisclosure,
    deleteDisclosure,
    editDisclosure,
  ]);

  const data = teammatesData;
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,

    debugTable: true,
  });

  // delete single list
  const queryClient = useQueryClient();

  const toast = useToast();

  const { mutate: deleteTeammateHandler, isLoading } = useMutation(
    deleteTeammate,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["listTeammates"]);
        deleteDisclosure.onClose();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );
  const { isLoading: isLoadingUpdate, mutate: editTeammateHandler } =
    useMutation(editTeammate, {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["listTeammates"]);
        editDisclosure.onClose();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    });
  const { isLoading: updatingRole, mutate: updateRoleHandler } = useMutation(
    updateRole,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["listTeammates"]);
        changeRoleDisclosure.onClose();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );
  const { isLoading: isDeactivating, mutate: deactivateHandler } = useMutation(
    deactivateActivateTeammate,
    {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["listTeammates"]);
        deactivateDisclosure.onClose();
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    }
  );

  return (
    <>
      <TableContainer
        borderWidth={"1px"}
        borderColor="borders.200"
        rounded={"8px"}
      >
        <Table variant={"simple"}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      {/* Deactivate/Activate teammate disclosure */}
      <CustomModal disclosure={deactivateDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Box display={"inline-flex"}>
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M70.2992 71.6641C70.2992 72.5974 69.5659 73.3307 68.6325 73.3307H11.3659C10.4326 73.3307 9.69922 72.5974 9.69922 71.6641C9.69922 57.8641 23.2992 46.6641 39.9992 46.6641C43.4325 46.6641 46.7659 47.1306 49.8326 48.0306C47.8659 50.364 46.6659 53.3974 46.6659 56.6641C46.6659 59.1641 47.3658 61.5307 48.5992 63.5307C49.2658 64.6641 50.1325 65.6973 51.1325 66.564C53.4659 68.6973 56.5659 69.9974 59.9992 69.9974C63.7325 69.9974 67.0992 68.464 69.4992 65.9974C70.0326 67.7974 70.2992 69.6974 70.2992 71.6641Z"
                fill="#7489AA"
              />
              <path
                d="M72.9331 53.4698C72.5998 52.1698 72.0664 50.8697 71.3331 49.7031C70.8331 48.8364 70.1664 48.0031 69.4331 47.2364C67.0331 44.8364 63.8998 43.5365 60.6998 43.3699C57.0664 43.1365 53.3665 44.4698 50.5665 47.2364C47.9332 49.8698 46.5998 53.3698 46.6664 56.8698C46.6998 60.2032 48.0332 63.5366 50.5665 66.1032C52.3332 67.8699 54.4998 69.0365 56.7998 69.5698C58.0665 69.9032 59.3998 70.0364 60.7331 69.9364C63.8998 69.8031 66.9997 68.5366 69.4331 66.1032C72.8664 62.6699 74.0331 57.8364 72.9331 53.4698ZM65.3331 62.0031C64.3665 62.9698 62.7665 62.9698 61.7998 62.0031L59.9664 60.1698L58.1998 61.9366C57.2331 62.9032 55.6331 62.9032 54.6664 61.9366C53.6998 60.9366 53.6998 59.3699 54.6664 58.4032L56.4331 56.6365L54.7332 54.9698C53.7665 53.9698 53.7665 52.4031 54.7332 51.4031C55.7332 50.4364 57.2997 50.4364 58.2997 51.4031L59.9664 53.1031L61.7331 51.3366C62.6997 50.3699 64.2664 50.3699 65.2664 51.3366C66.2331 52.3033 66.2331 53.9032 65.2664 54.8699L63.4997 56.6365L65.3331 58.4698C66.2998 59.4364 66.2998 61.0364 65.3331 62.0031Z"
                fill="#7489AA"
              />
              <path
                d="M40.0007 39.9974C49.2054 39.9974 56.6673 32.5355 56.6673 23.3307C56.6673 14.126 49.2054 6.66406 40.0007 6.66406C30.7959 6.66406 23.334 14.126 23.334 23.3307C23.334 32.5355 30.7959 39.9974 40.0007 39.9974Z"
                fill="#7489AA"
              />
            </svg>
          </Box>
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            {dataToUpdate?.is_active
              ? "Deactivate teammate"
              : "Activate teammate"}
          </Text>
          <Text
            mt="5px"
            fontSize={"sm"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
            mx="auto"
          >
            {dataToUpdate?.is_active
              ? "Once you deactivate a teammate, that person will not be able to access the platform temporarily."
              : "Once you activate a teammate, that person will be granted access back to the platform."}
          </Text>

          <Button
            w="full"
            isLoading={isDeactivating}
            onClick={() => {
              deactivateHandler({
                is_active: !dataToUpdate?.is_active,
                id: dataToUpdate?.id,
              });
            }}
            bg={dataToUpdate?.is_active ? "danger.300" : "primary.500"}
            colorScheme={"text.100"}
            mt="40px"
          >
            {dataToUpdate?.is_active
              ? "Deactivate teammate"
              : "Activate teammate"}
          </Button>
        </Box>
      </CustomModal>

      {/* Change role disclosure */}
      <CustomModal
        disclosure={changeRoleDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Change role
          </Text>
        }
      >
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Text
            color="grays.12"
            fontSize={"14px"}
            w={"350px"}
            textAlign={"left"}
            fontWeight="500"
            mb={"6"}
          >
            Changing a teammate's role would change their permissions and access
          </Text>
          <ChakraSelect
            label="Role"
            selectProps={{
              h: "44px",
              marginTop: "5px",
              borderColor: "borders.100",
              color: "grays.9",
              value: role,
              placeholder: "Select a Role",
              onChange: (e) => {
                setRole(e.target.value);
              },
            }}
          >
            <chakra.option value="admin">Admin</chakra.option>
            <chakra.option value="member">Member</chakra.option>
          </ChakraSelect>

          <Button
            w="full"
            isLoading={updatingRole}
            onClick={() => {
              updateRoleHandler({
                role: role,
                id: dataToUpdate?.id,
              });
            }}
            colorScheme={"text.100"}
            bg="primary.500"
            mt="40px"
          >
            Change role
          </Button>
        </Box>
      </CustomModal>

      {/* Edit teammates */}
      <CustomModal
        disclosure={editDisclosure}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px" px="38px">
            Edit teammate
          </Text>
        }
      >
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Box>
            <FormInput
              label="First name"
              type="text"
              for="First name"
              inputProps={{
                placeholder: "Enter teammate first name",
                marginBottom: "20px",
                value: first_name,
                onChange: (e) => {
                  setFirstName(e.target.value);
                },
              }}
            />
            <FormInput
              label="Last name"
              type="text"
              for="Last name"
              inputProps={{
                placeholder: "Enter teammate last name",
                marginBottom: "20px",
                value: last_name,
                onChange: (e) => {
                  setLastName(e.target.value);
                },
              }}
            />
            <FormInput
              label="Email address"
              type="text"
              disabled={true}
              for="Email"
              inputProps={{
                placeholder: "Enter teammate last name",
                marginBottom: "20px",
                value: email,
              }}
            />
          </Box>
          <Button
            w="full"
            isLoading={isLoadingUpdate}
            isDisabled={!first_name || !last_name}
            colorScheme={"primary"}
            onClick={() => {
              editTeammateHandler({
                first_name: first_name,
                last_name: last_name,
                id: dataToUpdate?.id,
              });
            }}
            mt="40px"
          >
            Update
          </Button>
        </Box>
      </CustomModal>

      {/* Delete teammate */}
      <CustomModal disclosure={deleteDisclosure}>
        <Box mt="42px" pb="40px" textAlign={"center"} w="full">
          <Box display={"inline-flex"}>
            <svg
              width="80"
              height="80"
              viewBox="0 0 80 80"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M70.2992 71.6641C70.2992 72.5974 69.5659 73.3307 68.6325 73.3307H11.3659C10.4326 73.3307 9.69922 72.5974 9.69922 71.6641C9.69922 57.8641 23.2992 46.6641 39.9992 46.6641C43.4325 46.6641 46.7659 47.1306 49.8326 48.0306C47.8659 50.364 46.6659 53.3974 46.6659 56.6641C46.6659 59.1641 47.3658 61.5307 48.5992 63.5307C49.2658 64.6641 50.1325 65.6973 51.1325 66.564C53.4659 68.6973 56.5659 69.9974 59.9992 69.9974C63.7325 69.9974 67.0992 68.464 69.4992 65.9974C70.0326 67.7974 70.2992 69.6974 70.2992 71.6641Z"
                fill="#7489AA"
              />
              <path
                d="M72.9331 53.4698C72.5998 52.1698 72.0664 50.8697 71.3331 49.7031C70.8331 48.8364 70.1664 48.0031 69.4331 47.2364C67.0331 44.8364 63.8998 43.5365 60.6998 43.3699C57.0664 43.1365 53.3665 44.4698 50.5665 47.2364C47.9332 49.8698 46.5998 53.3698 46.6664 56.8698C46.6998 60.2032 48.0332 63.5366 50.5665 66.1032C52.3332 67.8699 54.4998 69.0365 56.7998 69.5698C58.0665 69.9032 59.3998 70.0364 60.7331 69.9364C63.8998 69.8031 66.9997 68.5366 69.4331 66.1032C72.8664 62.6699 74.0331 57.8364 72.9331 53.4698ZM65.3331 62.0031C64.3665 62.9698 62.7665 62.9698 61.7998 62.0031L59.9664 60.1698L58.1998 61.9366C57.2331 62.9032 55.6331 62.9032 54.6664 61.9366C53.6998 60.9366 53.6998 59.3699 54.6664 58.4032L56.4331 56.6365L54.7332 54.9698C53.7665 53.9698 53.7665 52.4031 54.7332 51.4031C55.7332 50.4364 57.2997 50.4364 58.2997 51.4031L59.9664 53.1031L61.7331 51.3366C62.6997 50.3699 64.2664 50.3699 65.2664 51.3366C66.2331 52.3033 66.2331 53.9032 65.2664 54.8699L63.4997 56.6365L65.3331 58.4698C66.2998 59.4364 66.2998 61.0364 65.3331 62.0031Z"
                fill="#7489AA"
              />
              <path
                d="M40.0007 39.9974C49.2054 39.9974 56.6673 32.5355 56.6673 23.3307C56.6673 14.126 49.2054 6.66406 40.0007 6.66406C30.7959 6.66406 23.334 14.126 23.334 23.3307C23.334 32.5355 30.7959 39.9974 40.0007 39.9974Z"
                fill="#7489AA"
              />
            </svg>
          </Box>
          <Text color="grays.8" fontSize={"20px"} fontWeight="bold">
            Delete teammate
          </Text>
          <Text
            mt="5px"
            fontSize={"sm"}
            fontWeight="medium"
            color="grays.12"
            maxW={"360px"}
            mx="auto"
          >
            Once you delete a teammate, that person will not be able to access
            the platform anymore.
          </Text>

          <Button
            w="full"
            isLoading={isLoading}
            onClick={() => {
              deleteTeammateHandler(dataToUpdate?.id);
            }}
            bg="danger.300"
            colorScheme={"text.100"}
            mt="40px"
          >
            Delete teammate
          </Button>
        </Box>
      </CustomModal>
    </>
  );
};

export default TeammatesTable;
