import {
  Box,
  Button,
  chakra,
  Flex,
  Text,
  FormLabel,
  Select,
  Input,
  VStack,
  // useToast,
} from "@chakra-ui/react";
import { useState } from "react";
import { Add, Close } from "../../../assets/svgs/svg";
import FormInput from "../../../components/UI/form/FormInput";
import { useMutation } from "@tanstack/react-query";
import { searchContactsFn } from "../../../API/contacts";
import { FaCaretDown } from "react-icons/fa";
import { removeSpaces, removeUnderscores } from "../../../functions";

const operatorList = [
  { label: "Equals to", value: "=" },
  { label: "Not equals to", value: "!=" },
  { label: "In", value: "IN" },
  { label: "Not in", value: "NIN" },
  { label: "Greater than", value: ">" },
  { label: "Less than", value: "<" },
  { label: "That contains", value: "~" },
  { label: "That does not contain", value: "!~" },
  { label: "That starts with", value: "^" },
  { label: "That ends with", value: "$" },
];
const CreateSegment = ({
  close,
  setSegmentName,
  segmentName,
  attributesArr,
  setSegSearchResults,
  setSegmentQ,
}: {
  close: () => void;
  segmentName: string;
  setSegmentName: any;
  attributesArr: Array<string>;
  setSegSearchResults: any;
  setSegmentQ: any;
}) => {
  // const [showBtn, setShowBtn] = useState(true);
  // const [segmentName, setSegmentName] = useState("");
  const [segmentQuery, setSegmentQuery] = useState([
    {
      operator: "OR",
      value: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ],
    },
  ]);
  // const toast = useToast();

  const searchContactsMutation = useMutation(searchContactsFn, {
    onSuccess: (data) => {
      // console.log("tweeeee", data);

      // if (data?.docs.length === 0) {
      //   toast({
      //     // title: 'No contacts found',
      //     description: "No contacts found",
      //     status: "warning",
      //     position: "top-right",
      //     duration: 3000,
      //     isClosable: true,
      //   });
      // }
      setSegSearchResults(data?.docs);
      close && close();
    },
  });

  const handleFormChange = (
    index: number,
    value: string,
    curr: string,
    currIndex?: number
  ) => {
    let data = [...segmentQuery];
    setSegmentQ(segmentQuery[0]);

    if (curr === "operator") {
      data[index] = {
        ...data[index],
        operator: value,
      };
    } else if (curr === "value-field" && currIndex !== undefined) {
      data[index].value[currIndex] = {
        ...data[index].value[currIndex],
        field: value,
      };
    } else if (curr === "value-operator" && currIndex !== undefined) {
      data[index].value[currIndex] = {
        ...data[index].value[currIndex],
        operator: value,
      };
    } else if (curr === "value-value" && currIndex !== undefined) {
      data[index].value[currIndex] = {
        ...data[index].value[currIndex],
        value: value,
      };
    }
  };

  const addSegQuery = () => {
    let newCondition = {
      operator: "",
      value: [
        {
          field: "",
          operator: "",
          value: "",
        },
      ],
    };

    setSegmentQuery([...segmentQuery, newCondition]);
    // setShowBtn(false);
  };

  const addSegFilter = (firstIndex: number) => {
    let newFilter = {
      field: "",
      operator: "",
      value: "",
    };

    let segs = [...segmentQuery];

    segs[firstIndex].value = [...segs[firstIndex].value, newFilter];

    setSegmentQuery(segs);
  };

  const removeSeg = (firstIndex: number) => {
    let segs = [...segmentQuery];

    let result = segs?.filter((item, index) => index !== firstIndex);
    // setShowBtn(true);

    setSegmentQuery(result);
  };

  const removeFil = (nu1: number, nu2: number) => {
    let segs = [...segmentQuery];
    segs[nu1].value.splice(nu2, 1);
    setSegmentQuery(segs);
  };

  return (
    <Box py={"22px"} userSelect={"none"}>
      <chakra.form
        onSubmit={(e) => {
          e.preventDefault();

          if (segmentQuery[0].value.length > 1) {
            let data = [...segmentQuery];
            let arr = data[0].value.map((item) => {
              return {
                field: item.field,
                operator: item.operator,
                value: item.value,
              };
            });
            let query = {
              operator: segmentQuery[0].operator,
              value: arr,
            };
            searchContactsMutation.mutate({
              // @ts-ignore
              query: query,
            });
          } else {
            let query = {
              field: segmentQuery[0].value[0].field,
              operator: segmentQuery[0].value[0].operator,
              value: segmentQuery[0].value[0].value,
            };
            searchContactsMutation.mutate({
              query: query,
            });
          }
        }}
      >
        <FormInput
          label="Name"
          type="text"
          for="Name"
          inputProps={{
            placeholder: "Enter segment name",
            onChange: (e) => {
              setSegmentName(e.target.value);
            },
            value: segmentName,
          }}
        />
        <FormLabel fontWeight={"bold"} fontSize="sm" pt="24px" mb="6px">
          Condition
        </FormLabel>

        {segmentQuery?.map((segQuery, index) => (
          <Box key={index} pt={"10px"}>
            {index === 1 ? (
              <Text fontWeight={"bold"} fontSize="sm">
                and
              </Text>
            ) : null}
            <Box
              px={"1.2em"}
              py={"1.2em"}
              border="1px solid rgba(0, 102, 255, 0.16)"
              borderRadius="4px"
              borderLeft="5px #0066FF solid"
              position={"relative"}
            >
              {index === 1 ? (
                <VStack
                  opacity={0.6}
                  alignItems={"center"}
                  justifyContent={"center"}
                  cursor={"pointer"}
                  onClick={() => removeSeg(index)}
                >
                  <Close
                    style={{
                      position: "absolute",
                      right: ".8em",
                    }}
                  />
                </VStack>
              ) : null}
              <Flex
                columnGap={".4em"}
                alignItems="center"
                color="#7489AA"
                fontSize="12px"
                fontWeight="500"
              >
                <Text>Match</Text>
                <Box>
                  <Select
                    fontSize={"xs"}
                    fontWeight={600}
                    icon={<FaCaretDown />}
                    iconSize={"16px"}
                    minHeight={"12px"}
                    height={"26px"}
                    color={"grays.9"}
                    onChange={(e) => {
                      handleFormChange(index, e.target.value, "operator");
                    }}
                    defaultValue={segmentQuery[index].operator}
                  >
                    <chakra.option value="OR">Any</chakra.option>
                    <chakra.option value="AND">All</chakra.option>
                  </Select>
                </Box>
                <Text>of the conditions</Text>
              </Flex>

              <Box mt=".5em" display="grid">
                {segmentQuery[index].value.map((fil, ind: number) => (
                  <Flex
                    key={ind}
                    mb={"4px"}
                    height="3rem"
                    width="full"
                    border="1px solid rgba(0, 102, 255, 0.16)"
                    borderRadius="5px"
                    columnGap=".7em"
                    padding=".6em"
                    alignItems="center"
                    position="relative"
                  >
                    {/* FIELD */}
                    <Select
                      isRequired
                      variant="filled"
                      placeholder="Field"
                      maxWidth={"120px"}
                      noOfLines={1}
                      fontSize={"xs"}
                      fontWeight={600}
                      icon={<FaCaretDown />}
                      iconSize={"16px"}
                      minHeight={"12px"}
                      height={"26px"}
                      onChange={(e) => {
                        handleFormChange(
                          index,
                          e.target.value,
                          "value-field",
                          ind
                        );
                      }}
                    >
                      {attributesArr.map((attr, i) => (
                        <chakra.option key={i} value={removeSpaces(attr)}>
                          {removeUnderscores(attr)}
                        </chakra.option>
                      ))}
                    </Select>

                    {/* OPERATOR */}
                    <Select
                      isRequired
                      variant="filled"
                      placeholder="Operator"
                      maxWidth={"120px"}
                      noOfLines={1}
                      fontSize={"xs"}
                      fontWeight={600}
                      icon={<FaCaretDown />}
                      iconSize={"16px"}
                      minHeight={"12px"}
                      height={"26px"}
                      onChange={(e) => {
                        handleFormChange(
                          index,
                          e.target.value,
                          "value-operator",
                          ind
                        );
                      }}
                    >
                      {operatorList.map((op, i) => (
                        <chakra.option key={i} value={op.value}>
                          {op.label}
                        </chakra.option>
                      ))}
                    </Select>

                    {/* VALUE */}
                    <Input
                      isRequired
                      variant="filled"
                      mr={"27px"}
                      placeholder="Value"
                      height={"26px"}
                      fontSize={"xs"}
                      onChange={(e) => {
                        handleFormChange(
                          index,
                          e.target.value,
                          "value-value",
                          ind
                        );
                      }}
                    />

                    {ind !== 0 ? (
                      <VStack
                        opacity={0.6}
                        alignItems={"center"}
                        justifyContent={"center"}
                        cursor={"pointer"}
                        onClick={() => removeFil(index, ind)}
                      >
                        <Close
                          style={{
                            position: "absolute",
                            right: ".8em",
                          }}
                        />
                      </VStack>
                    ) : null}
                  </Flex>
                ))}
              </Box>

              <Button
                onClick={() => {
                  addSegFilter(index);
                }}
                mt="12px"
                variant="secondary"
                display="flex"
                justifyContent={"center"}
                size="xs"
                padding=" 0.3em .5em"
                border="1px solid rgba(0, 102, 255, 0.16)"
              >
                <Add />
                <Text>Add Filter</Text>
              </Button>
            </Box>
          </Box>
        ))}

        {/* HIDES ADDITIONAL CONDITION */}
        {false ? (
          <Button
            onClick={() => {
              addSegQuery();
            }}
            mt="10px"
            variant="secondary"
            display="flex"
            justifyContent={"center"}
            size="xs"
            padding=" 0.3em .5em"
            border="1px solid rgba(0, 102, 255, 0.16)"
          >
            <Add />
            <Text>Add conditional group</Text>
          </Button>
        ) : null}

        <Button
          _disabled={{
            bg: "#B2B6BD",
          }}
          _hover={{
            bg: "grey.20",
          }}
          _focus={{
            bg: "grey.20",
          }}
          color={"white"}
          w="full"
          mt="40px"
          mb="10px"
          isDisabled={!segmentName}
          colorScheme={"primary"}
          type="submit"
          isLoading={searchContactsMutation.isLoading}
        >
          Save segment
        </Button>
      </chakra.form>
    </Box>
  );
};

export default CreateSegment;
