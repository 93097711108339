import React, { useState } from "react";
import { useEditor, EditorContent } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import Mention from "@tiptap/extension-mention";
import { ReactRenderer } from "@tiptap/react";
import tippy from "tippy.js";
import MentionList from "./MentionList";
import {
  Box,
  HStack,
  Input,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { EmojiBox } from "../../campaigns/SmsCapaign";
import EmojiPicker from "emoji-picker-react";
import { MsgLink } from "../../../assets/svgs/svg";
import { ButtonDropdown, ButtonPrimary } from "../Buttons";
import CustomModal from "../CustomModal";
import AddLink from "../../campaigns/NewCampaign/components/AddLink";
import { useRecoilState } from "recoil";
import { tipTapAtom } from "../../../atoms/tiptap";
import { removeSpacesAndJoin } from "../../../functions";

export const TiptapBox = ({
  channel,
  setPlainText,
  onChange,
  addLinkDisclosure,
}: {
  addLinkDisclosure: any;
  setPlainText?: React.Dispatch<React.SetStateAction<string>>;
  onChange?: (html: string) => void;
  channel?: string;
}) => {
  const [showPicker, setShowPicker] = useState(false);
  const [currAttr, setCurrAttr] = useState("");
  const [fallbackValue, setFallbackValue] = useState("");
  const [fallbacks, setFallbacks] = useState<
    { attr: string; fallback: string }[]
  >([]);

  const [tiptapValue, setTiptapValue] = useRecoilState(tipTapAtom);
  const addFallbackDisclosure = useDisclosure();
  const toast = useToast();

  const suggestion = {
    allowSpaces: true,
    decorationClass: "suggestion-style",
    items: ({ query }: { query: string }) => {
      return tiptapValue.attributes
        .map((attr) => attr.front)
        .filter((item) => item.toLowerCase().startsWith(query.toLowerCase()));
      // .slice(0, 5);
    },

    render: () => {
      let component: any;
      let popup: any;

      return {
        onStart: (props: any) => {
          component = new ReactRenderer(MentionList, {
            props,
            editor: props.editor,
          });

          if (!props.clientRect) {
            return;
          }

          popup = tippy("body", {
            getReferenceClientRect: props.clientRect,
            appendTo: () => document.body,
            content: component.element,
            showOnCreate: true,
            interactive: true,
            trigger: "manual",
            placement: "bottom-start",
          });
        },

        onUpdate(props: any) {
          component.updateProps(props);

          if (!props.clientRect) {
            return;
          }

          popup[0].setProps({
            getReferenceClientRect: props.clientRect,
          });
        },

        onKeyDown(props: any) {
          if (props.event.key === "Escape") {
            popup[0].hide();

            return true;
          }

          return component.ref?.onKeyDown(props);
        },

        onExit() {
          popup[0].destroy();
          component.destroy();
        },
      };
    },
  };

  const findAttrsReplaceForBE = (str: string) => {
    const arrForBE = tiptapValue.rawAttributes.map((attr) => {
      // let rep3 = removeUnderscores(attr);
      // let rep2 = removeSpacesAndJoin(rep3);

      // if (tiptapValue.fallbacks[rep2] as {[key: string]: string;}) {
      //   // @ts-ignore
      //   return `{{${attr}: ${tiptapValue.fallbacks[rep2]}}}`;
      // } else {
      return `{{${attr}}}`;
      // }
    });

    const arrOnFE = tiptapValue.attributes.map((attr) => {
      // console.log(attr);
      return "@" + attr.front;
    });

    const arr = arrForBE.map((attr, index) => {
      return { be: attr, fe: arrOnFE[index] };
    });

    let newStr = arr.reduce((acc: string, curr: { be: string; fe: string }) => {
      let op = new RegExp(curr.fe, "g");
      let nuStr = acc.replaceAll(op, curr.be);
      return nuStr;
    }, str);

    return newStr;
  };

  // console.log("fallllllersssss!", fallbacks);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        paragraph: {
          HTMLAttributes: {
            class: "tiptap-box-paragraph",
          },
        },
      }),
      Mention.configure({
        HTMLAttributes: {
          class: "attr-mention",
        },

        suggestion: suggestion,
        renderLabel({ options, node }) {
          return `${options.suggestion.char}${
            node.attrs.label
              ? removeSpacesAndJoin(node.attrs.label)
              : removeSpacesAndJoin(node.attrs.id)
          }`;
        },
      }),
    ],
    content: tiptapValue.content,
    onUpdate: ({ editor }) => {
      if (fallbacks.length > 0) {
        let fbs = fallbacks.reduce((acc, curr) => {
          acc = {
            ...acc,
            [curr.attr]: curr.fallback,
          };
          return acc;
        }, {} as { [key: string]: string });

        // console.log("what is fbs", fbs);
        setTiptapValue((prev) => ({
          ...prev,
          fallbacks: fbs,
        }));
      }

      let html = findAttrsReplaceForBE(editor.getHTML());
      setTiptapValue((prev) => ({
        ...prev,
        content: html,
      }));

      onChange && onChange(html);
    },
    editorProps: {
      handleClickOn: (view, pos, node) => {
        // console.log("handleClickOn", node.attrs.id);
        if (node.attrs.id) {
          setCurrAttr(node.attrs.id);
          addFallbackDisclosure.onOpen();
          setFallbackValue("");
        }
      },
    },
  });

  const onEmojiClick = (emojiObject: any, event: any) => {
    // console.log("emojiiiiiii", emojiObject.emoji);
    // setTemplate((prevInput: any) => prevInput + emojiObject.emoji);
    // setContent((prev: any) => prev + emojiObject.emoji);
    editor?.chain().focus().insertContent(emojiObject.emoji).run();

    setShowPicker(false);
  };

  return (
    <Box position={"relative"}>
      <Box
        className="textbox-attribute"
        position={"absolute"}
        zIndex={10}
        top={`67px`}
        left={"-54px"}
      >
        <ButtonDropdown
          isAttr
          menuButtonProps={{
            bg: "gray.200",
            color: "#222",
            boxShadow: "md",
            _hover: {
              bg: "gray.200",
            },
            _active: {
              bg: "gray.200",
            },
          }}
          title="@"
          list={tiptapValue.attributes.map(({ front }) => {
            return {
              option: front,
              onClick: () => {
                editor
                  ?.chain()
                  .focus()
                  .createParagraphNear()
                  .insertContent(
                    `<span data-type="mention" class="attr-mention" data-id="${front}" contenteditable="false"></span>`
                  )
                  .run();
              },
            };
          })}
          menuItemProps={{
            _hover: {
              bg: "gray.200",
            },
          }}
        />
      </Box>

      <Box>
        <EmojiBox
          position="relative"
          display="flex"
          columnGap=".7em"
          alignItems="center"
          p="0.75em 1.5em "
          borderBottomWidth={"1px"}
          borderColor="borders.100"
        >
          <img
            alt="u"
            className="emoji-icon"
            src="https://icons.getbootstrap.com/assets/icons/emoji-smile.svg"
            onClick={() => setShowPicker((val) => !val)}
          />
          {showPicker && (
            <EmojiPicker
              // @ts-ignore
              width={"70%"}
              onEmojiClick={onEmojiClick}
            />
          )}
          <MsgLink onClick={addLinkDisclosure.onOpen} />
          <Box
            style={{
              position: "absolute",
              left: "-3em",
              top: "67px",
              zIndex: 3,
            }}
          ></Box>
        </EmojiBox>
      </Box>

      <Box
        px={"13px"}
        pt={"10px"}
        className="textbox-container thinSB"
        position={"relative"}
        minHeight={"220px"}
        height={"min(60vh, 370px)"}
        overflowY={"scroll"}
      >
        <EditorContent editor={editor} />
      </Box>

      {/* ADD FALLBACK VALUE */}
      <CustomModal
        isFallback
        disclosure={addFallbackDisclosure}
        // titleUnderLine={true}
        title={
          <Text fontSize={"16px"} color="grays.8" mt="35px">
            Add a fallback value
          </Text>
        }
      >
        <Box mt={"21px"} mb={"20px"}>
          <Box border="2px solid #F5F5F5" borderRadius={"6px"} mb={"12px"}>
            <Text
              px={"16px"}
              py={"8px"}
              bg={"#F5F5F5"}
              fontSize={"sm"}
              fontWeight={500}
            >
              {`@ Recipient's`}{" "}
              <Text as="span" fontWeight={700}>
                {currAttr}
              </Text>{" "}
              would be inserted here
            </Text>

            <HStack>
              <Text
                borderRight={"2px solid #F5F5F5"}
                fontWeight={500}
                fontSize={"14px"}
                pl={"8px"}
              >
                Fallback
              </Text>
              <Input
                placeholder={`if we can't find ${currAttr}`}
                value={fallbackValue}
                onChange={(e) => {
                  setFallbackValue(e.target.value);
                }}
              />
            </HStack>
          </Box>

          <HStack justifyContent={"center"} pt={"16px"}>
            <ButtonPrimary
              isDisabled={!fallbackValue}
              buttonProps={{
                width: "50%",
              }}
              onClick={() => {
                let newValue = { attr: currAttr, fallback: fallbackValue };
                let cleanArr = fallbacks.filter(
                  (item) => item?.attr !== newValue.attr
                );
                setFallbacks([...cleanArr, newValue]);
                toast({
                  title: "Fallback saved",
                  position: "top-right",
                  status: "success",
                  duration: 3000,
                  isClosable: true,
                });
                addFallbackDisclosure.onClose();
              }}
            >
              Save
            </ButtonPrimary>
          </HStack>
        </Box>
      </CustomModal>

      {/* ADD LINK */}
      <CustomModal
        disclosure={addLinkDisclosure}
        titleUnderLine={true}
        title={
          <Text fontSize={"20px"} color="grays.8" mt="35px">
            Add a short link
          </Text>
        }
      >
        <AddLink
          channel={channel || "sms"}
          close={addLinkDisclosure.onClose}
          editor={editor}
        />
      </CustomModal>
    </Box>
  );
};
