import {
  Box,
  Button,
  Center,
  FormLabel,
  Wrap,
  WrapItem,
  chakra,
  useToast,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { updateList } from "../../../API/account/list";
import { toaster } from "../../../utils/toast";
import FormInput from "../../UI/form/FormInput";

interface listProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  color: string;
  no_of_contacts: number;
  created_at: Date;
  updated_at: Date;
}

const EditList: FC<{ data: listProps; close?: () => void }> = ({
  data,
  close,
}) => {
  const allColors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#03A9F4",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#8BC34A",
    "#CDDC39",
    "#FEC200",
    "#FF9800",
    "#FF5722",
    "#795548",
    "#9E9E9E",
    "#607D8B",
  ];

  const queryClient = useQueryClient();

  const [selectedColor, setSelectedColor] = useState(data?.color);
  const [name, setName] = useState(data?.name);

  const toast = useToast();

  const { mutate, isLoading } = useMutation(updateList, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      setName("");
      setSelectedColor("");
      queryClient.invalidateQueries(["listData"]);
      close && close();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <Box mt="24px ">
      <FormInput
        label="List name"
        type="text"
        for="List name"
        inputProps={{
          placeholder: "Enter list name",
          value: name,
          onChange: (e) => {
            setName(e.target.value);
          },
        }}
      />

      <Box mt="16px">
        <FormLabel
          fontWeight={"bold"}
          fontSize="sm"
          color="grays.9"
          htmlFor="list color"
          mb="4px"
          display="flex"
        >
          Select color
        </FormLabel>

        <Wrap
          spacing={"16px"}
          p="20px"
          borderWidth={"1px"}
          borderColor="borders.100"
          rounded="4px"
        >
          {allColors?.map((color) => {
            return (
              <WrapItem key={color}>
                <chakra.button
                  onClick={() => {
                    setSelectedColor(color);
                  }}
                >
                  <Center w="40px" h="40px" bg={color} rounded="2px">
                    {selectedColor === color && (
                      <svg
                        width="26"
                        height="26"
                        viewBox="0 0 26 26"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="1.5"
                          y="1.5"
                          width="23"
                          height="23"
                          rx="5.5"
                          stroke="white"
                          strokeWidth="3"
                          strokeLinejoin="round"
                        />
                        <rect
                          x="8"
                          y="8"
                          width="10"
                          height="10"
                          rx="4"
                          fill="white"
                        />
                      </svg>
                    )}
                  </Center>
                </chakra.button>
              </WrapItem>
            );
          })}
        </Wrap>
      </Box>

      <Button
        isDisabled={!selectedColor || !name}
        my="40px"
        w="full"
        colorScheme={"primary"}
        isLoading={isLoading}
        onClick={() => {
          mutate({
            list_uid: data?.uid,
            name,
            color: selectedColor,
          });
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default EditList;
