import {
  Button,
  chakra,
  useToast,
  Box,
  HStack,
  Text,
  Radio,
  Avatar,
  RadioGroup,
} from "@chakra-ui/react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getBusinessList, switchBusiness } from "../API/account/teammates";
import AuthLayout from "../components/general/AuthLayout";
import { DASH_ROUTES } from "../utils/routes";
import Loader from "../components/UI/Loader";
import { toaster } from "../utils/toast";

const SwitchBusiness = () => {
  const { isLoading: gettingBusinessList, data } = useQuery(
    ["businessData"],
    getBusinessList
  );
  const [val, setVal] = useState("");

  const queryClient = useQueryClient();

  const navigate = useNavigate();
  const toast = useToast();

  const { mutate, isLoading } = useMutation(switchBusiness, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      navigate(DASH_ROUTES.CONVERSATIONS);
      queryClient.invalidateQueries(["profileData"]);
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <AuthLayout title="Switch Business" description="">
      {gettingBusinessList ? (
        <Loader height={"26vh"} />
      ) : (
        <chakra.form
          mt="24px"
          onSubmit={(e) => {
            e.preventDefault();
            mutate({
              id: Number(val),
            });
          }}
        >
          <Box h={"120px"} mb={8} overflowY={"auto"}>
            <RadioGroup
              defaultValue={data?.company_id}
              onChange={(e) => setVal(e)}
            >
              {!gettingBusinessList &&
                data?.map((data: any) => (
                  <Box
                    key={data?.id}
                    my={4}
                    borderRadius={"6px"}
                    borderColor={data?.id === Number(val) ? "brand.main" : ""}
                    borderWidth={"1px"}
                  >
                    <HStack
                      h={"72px"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      px={3}
                    >
                      <HStack>
                        <Avatar
                          bg="backgrounds.avatar"
                          fontSize={4}
                          color="brand.main"
                          fontWeight={"500"}
                          name={data?.name.toString()}
                        />
                        <Text>{data?.name}</Text>
                      </HStack>
                      <HStack>
                        {data?.is_default && (
                          <Text
                            bg={"backgrounds.avatar"}
                            borderColor={"grays.12"}
                            borderWidth="0.5px"
                            color={"grays.12"}
                            px="8px"
                            fontSize={"12px"}
                            w="fit-content"
                            py="4px"
                            borderRadius="50px"
                          >
                            {"Default"}
                          </Text>
                        )}
                        <Radio
                          size="md"
                          value={data?.company_id}
                          color="primary.500"
                        />
                      </HStack>
                    </HStack>
                  </Box>
                ))}
            </RadioGroup>
          </Box>

          <Button
            type="submit"
            isLoading={isLoading}
            w="full"
            colorScheme={"primary"}
            mt="40px"
          >
            Proceed
          </Button>
        </chakra.form>
      )}
    </AuthLayout>
  );
};

export default SwitchBusiness;
