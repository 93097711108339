import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  delay?: number;
  duration?: number;
  style?: {};
  fullWidth?: boolean;
}
export function DropIn({
  children,
  fullWidth,
  style,
  delay = 0,
  duration = 0.5,
}: Props) {
  return (
    <Box
      overflow={"hidden"}
      width={fullWidth ? "100%" : "fit-content"}
      height={"100%"}
    >
      <motion.div
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        // exit={{ opacity: 0, y: -30 }}
        transition={{ duration: duration, delay: delay }}
        style={{ width: fullWidth ? "100%" : "fit-content", ...style }}
      >
        {children}
      </motion.div>
    </Box>
  );
}
