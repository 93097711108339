import {
  Box,
  FormControl,
  FormControlProps,
  FormLabel,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { FC, useState } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { fonts } from "../../../chakra/foundations/fonts";
import { EditIcon } from "../../../assets/svgs/svg";

interface Props {
  label: string;
  isEditable?: boolean;
  formControlProps?: FormControlProps;
}

const CustomPhoneInput: FC<PhoneInputProps & Props> = (props) => {
  const [toggle, setToggle] = useState(false);

  return (
    <VStack align={"flex-start"} w="100%" spacing={-1}>
      <FormControl {...props?.formControlProps}>
        <FormLabel
          fontWeight={"bold"}
          fontSize="sm"
          color="grays.9"
          mb="4px"
          display="flex"
        >
          {props?.label}
        </FormLabel>
        <HStack justifyContent={"space-between"} w={"full"}>
          <PhoneInput
            country={"ng"}
            disabled={toggle}
            // inputProps={{
            //   onblur: () => {
            //     setToggle(false);
            //   },
            // }}
            containerStyle={{
              fontFamily: fonts.body,
            }}
            inputStyle={{
              width: "100%",
              borderRadius: "8px",
              height: !toggle ? "48px" : "28px",
              borderColor: !toggle ? "white" : "rgba(0, 102, 255, 0.16)",
              border: toggle ? "none" : "1px solid rgba(0, 102, 255, 0.16)",
            }}
            dropdownStyle={{
              borderRadius: "8px",
            }}
            buttonStyle={{
              borderTopLeftRadius: "8px",
              borderBottomLeftRadius: "8px",
              borderColor: toggle ? "white" : "rgba(0, 102, 255, 0.16)",
              backgroundColor: "white",
            }}
            searchStyle={{
              // width: '100%',
              borderColor: "rgba(0, 102, 255, 0.16)",
              backgroundColor: "white",
            }}
            {...props}
            enableSearch
          />

          {props.isEditable ? (
            <Box cursor={"pointer"}>
              <EditIcon
                onClick={() => {
                  setToggle(!toggle);
                }}
              />
            </Box>
          ) : null}
        </HStack>
      </FormControl>
    </VStack>
  );
};

export default CustomPhoneInput;
