import React, { FC } from 'react'
import { Text, Button, useToast } from "@chakra-ui/react";
import CustomModal from '../../UI/CustomModal';
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import { useRecoilValue } from "recoil";
import { profileAtom } from "../../../atoms/user";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { createCard, getChargedAmount } from "../../../API/account/billings";
import { toaster } from '../../../utils/toast';



const AddCardModal: FC<{ disclosure: any, onAddCardSuccess?: any }> = ({ disclosure, onAddCardSuccess }) => {
    const toast = useToast();
    const profileData = useRecoilValue(profileAtom);

    const { data: chargedAmt } = useQuery(["getCharged"], getChargedAmount)

    const { isLoading, mutate } = useMutation(createCard, {

        onSuccess(data) {
            const success = { ...data }
            queryClient.invalidateQueries(["listCards"]);
            disclosure.onClose();
            onAddCardSuccess();
            toaster(toast, success)
        },

        onError(data: any) {
            const errors = { ...data };
            toaster(toast, errors)
        },
        retry: false
    })
    const config = {
        public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
        tx_ref: Date.now().toString(),
        amount: chargedAmt?.amount,
        currency: chargedAmt?.currency,
        payment_options: 'card,account,banktransfer,ussd',
        customer: {
            email: profileData?.email,
            phone_number: profileData?.phone_number,
            name: profileData?.name,
        },
        customizations: {
            title: "Bodsquare",
            description: "Bodsquare",
            logo: "https://res.cloudinary.com/bodsquare/image/upload/v1665087732/Assets/512_x_512_ankmtu.png",
        },
    };
    // @ts-ignore
    const handleFlutterPayment = useFlutterwave(config);
    const queryClient = useQueryClient();
    return (
        <CustomModal
            disclosure={disclosure}
        >
            <Text textAlign={"center"} mt={"30px"} fontWeight={"700"} fontSize={"20px"} color="grays.9">Add Card</Text>
            <Text textAlign={"center"} fontSize={"14px"} color="grays.12" fontWeight="500" mt={"5px"}>
                To add and verify your card NGN {chargedAmt?.amount} will be charge.
            </Text>
            <Button
                isLoading={isLoading}
                w="full"
                rounded="4px"
                colorScheme={"primary"}
                my="40px"
                fontSize={"sm"}
                onClick={() => {
                    disclosure && disclosure.onClose();
                    handleFlutterPayment({
                        callback: (response) => {
                            closePaymentModal();
                            disclosure && disclosure.onOpen();
                            mutate({
                                provider_transaction_id: response?.transaction_id?.toString(),
                            });
                        },
                        onClose: () => {
                        },
                    })
                }
                }
            >
                Proceed
            </Button>
        </CustomModal>
    )
}

export default AddCardModal