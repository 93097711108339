import {
  Box,
  Button,
  FormLabel,
  Text,
  VStack,
  // chakra,
  HStack,
  useToast,
  Input,
} from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import { FC, useState } from "react";
// import DateTimePicker from "react-datetime-picker";
import { useSearchParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { createCampaign, updateCampaign } from "../../../API/campaign";
import { InfoIcon } from "../../../assets/svgs/svg";
import { createCampaignAtom } from "../../../atoms/campaigns";
import { timeZones } from "../../../utils/data";
import { toaster } from "../../../utils/toast";
import ChakraSelect from "../../UI/form/ChakraSelect";
import SearchableSelect from "../../UI/form/SearchableSelect";
import { tipTapAtom } from "../../../atoms/tiptap";

// const ChakraDateTime = chakra(DateTimePicker);

const SendScheduleStage: FC = () => {
  const [currentStageData, setCurrentStageData] =
    useRecoilState(createCampaignAtom);
  const tiptapValue = useRecoilValue(tipTapAtom);

  const setStart = (value: Date) => {
    // console.log("yooooooooo2", value);
    setCurrentStageData((prevData) => {
      return {
        ...prevData,
        data: {
          ...prevData?.data,
          send_date: value,
        },
      };
    });
  };

  const [searchParams] = useSearchParams();

  const [timeToSend, setTimeToSend] = useState<string>("Immediately");

  const toast = useToast();
  const queryClient = useQueryClient();

  const { mutate: updateCampaignHandler, isLoading: updateCampaignLoading } =
    useMutation(updateCampaign, {
      onSuccess(data) {
        const success = { ...data };
        toaster(toast, success);
        queryClient.invalidateQueries(["allCampaignsData"]);
        setCurrentStageData((prevData) => {
          return {
            ...prevData,
            currentStage: "success",
            newCampaignId: data?.data?.uid,
          };
        });
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    });

  const { mutate: createCampaignHandler, isLoading: createCampaignLoading } =
    useMutation(createCampaign, {
      onSuccess(data) {
        const success = { ...data };
        setCurrentStageData((prevData) => {
          return {
            ...prevData,
            currentStage: "success",
            newCampaignId: data?.data?.uid,
          };
        });
        toaster(toast, success);
        queryClient.invalidateQueries(["allCampaignsData"]);
      },

      onError(data: any) {
        const errors = { ...data };
        toaster(toast, errors);
      },
    });

  const allTimeZones = timeZones?.map((timezone: string) => {
    return {
      label: timezone,
      value: timezone,
    };
  });

  return (
    <Box w="full" maxW={"480px"} mx="auto">
      {/* header */}
      <Text fontSize={"20px"} fontWeight={700} color="grays.8">
        Send or schedule
      </Text>
      {/* form */}
      <VStack align={"flex-start"} spacing="16px" mt="24px">
        <ChakraSelect
          label="When do you want to send out this campaign?"
          selectProps={{
            onChange: (e) => {
              setTimeToSend(e.target.value);
            },
          }}
        >
          <option value="Immediately">Immediately</option>
          <option value="Later">At a specific time</option>
        </ChakraSelect>

        {timeToSend === "Later" && (
          <>
            {/* Select a time & date */}
            <Box w="full">
              <FormLabel
                fontWeight={"bold"}
                fontSize="sm"
                color="grays.9"
                htmlFor="Choose channel"
                mb="4px"
                display="flex"
              >
                Select a time & date
              </FormLabel>

              <HStack w="full">
                {/* <ChakraDateTime
                  borderRadius={"4px"}
                  borderColor="borders.100"
                  borderWidth={"1px"}
                  color="grays.9"
                  w="full"
                  _placeholder={{
                    color: "grays.6",
                  }}
                  h="44px"
                  bg="white"
                  onChange={setStart}
                  value={currentStageData?.data?.send_date}
                  px="3"
                  fontSize={"sm"}
                /> */}
                <Input
                  placeholder="Select Date and Time"
                  size="md"
                  type="datetime-local"
                  // value={dayjs(currentStageData?.data?.send_date).format()}
                  onChange={(e) => {
                    let tt = dayjs(e.target.value).toDate();
                    setStart(tt);
                  }}
                />
              </HStack>
            </Box>

            {/* What timezone should be used? */}
            <SearchableSelect
              label="What timezone should be used?"
              placeholder="Select timezone"
              options={allTimeZones}
              value={allTimeZones?.filter((timezone) => {
                return timezone?.value === currentStageData?.data?.timezone;
              })}
              onChange={(e: { label: string; value: string }) => {
                setCurrentStageData((prevData) => {
                  return {
                    ...prevData,
                    data: {
                      ...prevData?.data,
                      timezone: e.value,
                    },
                  };
                });
              }}
            />
          </>
        )}

        {currentStageData.data.channel === "Whatsapp" ? (
          <Box
            display="flex"
            w="full"
            px="1.1em"
            py="0.6em"
            borderRadius="2px"
            bg="rgba(116, 137, 170, 0.08)"
            style={{
              marginTop: ".8em",
            }}
            // textAlign='center'
            wordBreak="break-word"
            fontSize="10px"
            fontWeight="500"
            columnGap=".8em"
            color="#171D26"
            // justify='space-between'
          >
            <InfoIcon />
            <Text>
              After reaching 6,000 messages/day, any additional messages will be{" "}
              <br /> queued and sent in the next 24 hours window.
            </Text>
          </Box>
        ) : null}
      </VStack>

      <Button
        isLoading={createCampaignLoading || updateCampaignLoading}
        onClick={() => {
          if (searchParams?.get("uid")) {
            updateCampaignHandler({
              ...currentStageData?.data,
              campaign_uid: searchParams?.get("uid"),
            });
          } else {
            createCampaignHandler({
              ...currentStageData?.data,
              fallbacks: tiptapValue.fallbacks,
            });
          }
        }}
        w="full"
        mt={"40px"}
        mb="10px"
        colorScheme={"primary"}
        isDisabled={
          !currentStageData?.data?.campaign_name ||
          !currentStageData?.data?.list_uid ||
          !(
            currentStageData?.data?.sms_sender_uid ||
            currentStageData?.data?.email_sender_uid ||
            currentStageData?.data?.sender_number
          ) ||
          !currentStageData?.data?.message_body ||
          !currentStageData?.data?.channel
        }
      >
        {timeToSend === "Immediately" ? "Send now" : "Schedule campaign"}
      </Button>
    </Box>
  );
};

export default SendScheduleStage;
