import React, { FC } from "react";

export const ContactDataIcon: FC<any> = (props) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16 22.75H8C4.35 22.75 2.25 20.65 2.25 17V7C2.25 3.35 4.35 1.25 8 1.25H16C19.65 1.25 21.75 3.35 21.75 7V17C21.75 20.65 19.65 22.75 16 22.75ZM8 2.75C5.14 2.75 3.75 4.14 3.75 7V17C3.75 19.86 5.14 21.25 8 21.25H16C18.86 21.25 20.25 19.86 20.25 17V7C20.25 4.14 18.86 2.75 16 2.75H8Z"
        fill="currentColor"
      />
      <path
        d="M18.5 9.25H16.5C14.98 9.25 13.75 8.02 13.75 6.5V4.5C13.75 4.09 14.09 3.75 14.5 3.75C14.91 3.75 15.25 4.09 15.25 4.5V6.5C15.25 7.19 15.81 7.75 16.5 7.75H18.5C18.91 7.75 19.25 8.09 19.25 8.5C19.25 8.91 18.91 9.25 18.5 9.25Z"
        fill="currentColor"
      />
      <path
        d="M9.99994 17.7475C9.80994 17.7475 9.61994 17.6776 9.46994 17.5276L7.46994 15.5276C7.17994 15.2376 7.17994 14.7575 7.46994 14.4675L9.46994 12.4675C9.75994 12.1775 10.2399 12.1775 10.5299 12.4675C10.8199 12.7575 10.8199 13.2376 10.5299 13.5276L9.05994 14.9975L10.5299 16.4675C10.8199 16.7575 10.8199 17.2376 10.5299 17.5276C10.3799 17.6776 10.1899 17.7475 9.99994 17.7475Z"
        fill="currentColor"
      />
      <path
        d="M13.9999 17.7475C13.8099 17.7475 13.6199 17.6776 13.4699 17.5276C13.1799 17.2376 13.1799 16.7575 13.4699 16.4675L14.9399 14.9975L13.4699 13.5276C13.1799 13.2376 13.1799 12.7575 13.4699 12.4675C13.7599 12.1775 14.2399 12.1775 14.5299 12.4675L16.5299 14.4675C16.8199 14.7575 16.8199 15.2376 16.5299 15.5276L14.5299 17.5276C14.3799 17.6776 14.1899 17.7475 13.9999 17.7475Z"
        fill="currentColor"
      />
    </svg>
  );
};
