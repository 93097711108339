import { Center, Text } from '@chakra-ui/react';

const EmptyChatData = () => {
  return (
    <Center flexDir={'column'} h='full'>
      <svg
        width='96'
        height='96'
        viewBox='0 0 96 96'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <path
          opacity='0.4'
          d='M73.8794 67.3201L75.4394 79.96C75.8394 83.28 72.2794 85.5999 69.4394 83.8799L52.6794 73.9199C50.8394 73.9199 49.0394 73.8001 47.2794 73.5601C50.2394 70.0801 51.9994 65.6799 51.9994 60.9199C51.9994 49.5599 42.1594 40.3601 29.9994 40.3601C25.3594 40.3601 21.0794 41.68 17.5194 44C17.3994 43 17.3594 42 17.3594 40.96C17.3594 22.76 33.1594 8 52.6794 8C72.1994 8 87.9994 22.76 87.9994 40.96C87.9994 51.76 82.4394 61.3201 73.8794 67.3201Z'
          fill='#7489AA'
        />
        <path
          d='M52 60.9199C52 65.6799 50.24 70.0801 47.28 73.5601C43.32 78.3601 37.04 81.4399 30 81.4399L19.56 87.6399C17.8 88.7199 15.56 87.24 15.8 85.2L16.8 77.3201C11.44 73.6001 8 67.6399 8 60.9199C8 53.8799 11.76 47.68 17.52 44C21.08 41.68 25.36 40.3601 30 40.3601C42.16 40.3601 52 49.5599 52 60.9199Z'
          fill='#7489AA'
        />
      </svg>

      <Text
        maxW='325px'
        fontWeight={'medium'}
        color='grays.12'
        mt='20px'
        textAlign={'center'}
      >
        Your conversations would show up here when a chat is opened.
      </Text>
    </Center>
  );
};

export default EmptyChatData;
