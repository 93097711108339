import { Box } from "@chakra-ui/react";

export default function Container({
  children,
  fullHeight,
}: {
  children: React.ReactNode;
  fullHeight?: boolean;
}) {
  return (
    <Box
      className="__container"
      width={"100%"}
      maxWidth={"1440px"}
      mx={"auto"}
      px={{ base: "20px", lg: "40px" }}
      height={fullHeight ? "100%" : "fit-content"}
    >
      {children}
    </Box>
  );
}
