import { Box, Flex, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

const DataComp: FC<{
  name: string;
  value: ReactNode;
  showBottomBorder?: boolean;
}> = ({ name, value, showBottomBorder = true }) => {
  return (
    <Flex
      py='16px'
      px='20px'
      borderBottomWidth={showBottomBorder ? '1px' : '0px'}
      borderColor='borders.100'
    >
      <Text
        w='50%'
        my='auto'
        fontSize='xs'
        color='grays.12'
        fontWeight={'medium'}
      >
        {name}
      </Text>

      <Box
        w='50%'
        my='auto'
        fontSize='xs'
        color='grays.12'
        fontWeight={'medium'}
      >
        {value}
      </Box>
    </Flex>
  );
};

export default DataComp;
