import React from "react";

const TemplatesIcon = () => {
    return (
        <svg
            width="18"
            height="18"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M6.2025 17.0625H3.1725C1.665 17.0625 0.9375 16.365 0.9375 14.925V3.075C0.9375 1.635 1.6725 0.9375 3.1725 0.9375H6.2025C7.71 0.9375 8.4375 1.635 8.4375 3.075V14.925C8.4375 16.365 7.7025 17.0625 6.2025 17.0625ZM3.1725 2.0625C2.22 2.0625 2.0625 2.3175 2.0625 3.075V14.925C2.0625 15.6825 2.22 15.9375 3.1725 15.9375H6.2025C7.155 15.9375 7.3125 15.6825 7.3125 14.925V3.075C7.3125 2.3175 7.155 2.0625 6.2025 2.0625H3.1725Z"
                fill="currentColor"
            />
            <path
                d="M14.8275 10.3125H11.7975C10.29 10.3125 9.5625 9.615 9.5625 8.175V3.075C9.5625 1.635 10.2975 0.9375 11.7975 0.9375H14.8275C16.335 0.9375 17.0625 1.635 17.0625 3.075V8.175C17.0625 9.615 16.3275 10.3125 14.8275 10.3125ZM11.7975 2.0625C10.845 2.0625 10.6875 2.3175 10.6875 3.075V8.175C10.6875 8.9325 10.845 9.1875 11.7975 9.1875H14.8275C15.78 9.1875 15.9375 8.9325 15.9375 8.175V3.075C15.9375 2.3175 15.78 2.0625 14.8275 2.0625H11.7975Z"
                fill="currentColor"
            />
            <path
                d="M14.8275 17.0625H11.7975C10.29 17.0625 9.5625 16.365 9.5625 14.925V13.575C9.5625 12.135 10.2975 11.4375 11.7975 11.4375H14.8275C16.335 11.4375 17.0625 12.135 17.0625 13.575V14.925C17.0625 16.365 16.3275 17.0625 14.8275 17.0625ZM11.7975 12.5625C10.845 12.5625 10.6875 12.8175 10.6875 13.575V14.925C10.6875 15.6825 10.845 15.9375 11.7975 15.9375H14.8275C15.78 15.9375 15.9375 15.6825 15.9375 14.925V13.575C15.9375 12.8175 15.78 12.5625 14.8275 12.5625H11.7975Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default TemplatesIcon;
