import { atom } from 'recoil';
import { ProfileDataProps } from '../utils/generalProps';

const profileAtom = atom<ProfileDataProps>({
  key: 'profileAtom',
  default: {
    id: '',
    uid: '',
    name: '',
    email: '',
    address: '',
    phone_number: '',
    industry: '',
    description: '',
    referral_channel: '',
    referral: '',
    code: '',
    is_email_verified: false,
    is_phone_number_verified: false,
    fcm_token: '',
    remember_token: '',
    is_active: false,
    deleted_at: new Date(),
    created_at: new Date(),
    updated_at: new Date(),
    currency: '',
  },
});

export { profileAtom };
