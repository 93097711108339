import React, { FC, useMemo } from "react";

import {
  Table,
  Thead,
  Tbody,
  chakra,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  VStack,
  Box,
} from "@chakra-ui/react";

import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import SortArrowUp from "../../../icons/SortArrowUp";
import SortArrowDown from "../../../icons/SortArrowDown";
import dayjs from "dayjs";
import { fuzzyFilter } from "../../../utils/table";
import { SwipeIn } from "../../animations";
// import { useNavigate } from 'react-router-dom';
// import { DownloadButton } from '../../../utils/Download';
// import IssueLinker from '../../Ziz_components/IssueLinker';
// import BillingReceipt from './billingInvoice';

export interface actualListProps {
  id: number;
  uid: string;
  company_uid: string;
  name: string;
  color: string;
  no_of_contacts: number;
  created_at: Date;
  updated_at: Date;
  [x: string]: any;
}

const BillingsTable: FC<{
  billingsData: actualListProps[];
  globalFilter: any;
  setGlobalFilter: any;
  [x: string]: any;
}> = ({ billingsData, globalFilter, setGlobalFilter, onView }) => {
  // Default export is a4 paper, portrait, using millimeters for units

  const [sorting, setSorting] = React.useState<SortingState>([]);

  // accessor functions are for when you need to concatenate data e.g firstname and lastname
  const columns = useMemo<ColumnDef<actualListProps>[]>(() => {
    return [
      {
        header: () => <Text>Invoice</Text>,
        accessorKey: "invoice_id",
        id: "invoice_id",
        cell: (props: any) => {
          return <Text>{props?.row?.original?.invoice_id}</Text>;
        },
      },
      {
        header: "Billing date",
        accessorKey: "created_at",
        id: "created_at",
        cell: (props: any) => {
          return (
            <Text>
              {dayjs(props?.getValue()).format("MMM DD YYYY - hh:mmA")}
            </Text>
          );
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        id: "status",
        cell: (props: any) => {
          return (
            <Text
              bg={"green.200"}
              borderColor={"green.100"}
              borderWidth="0.5px"
              color={"green.100"}
              px="8px"
              w="fit-content"
              borderRadius="50px"
            >
              Paid
            </Text>
          );
        },
      },
      {
        header: "Amount",
        accessorKey: "amount",
        id: "amount",
        cell: (props: any) => {
          return <Text>${props?.row?.original.plan.amount}</Text>;
        },
      },
      {
        header: "Plan",
        accessorKey: "plans",
        id: "plans",
        cell: (props: any) => {
          return <Text>{props?.row?.original.plan.name} plan</Text>;
        },
      },
      // {
      //   header: "Payment method",
      //   accessorKey: "payment_method",
      //   id: "payment_method",
      //   cell: (props: any) => {
      //     return (
      //       <Text fontSize={12} fontWeight={"medium"} variant={"unstyled"}>
      //         Card ending with{" "}
      //         {props?.row?.original?.card?.last_four_digits || "****"}{" "}
      //       </Text>
      //     );
      //   },
      // },
      {
        header: " ",
        accessorKey: "",
        id: "payment_method",
        cell: (props: any) => {
          return (
            <Text
              fontSize={"12px"}
              fontWeight={"medium"}
              variant={"unstyled"}
              onClick={onView}
              textDecoration="underline"
              textUnderlineOffset={"3px"}
              cursor={"pointer"}
            >
              Download
            </Text>
          );
        },
      },
    ];
  }, [onView]);
  // }, []);

  const data = billingsData;

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      globalFilter,
    },
    globalFilterFn: fuzzyFilter,
    onSortingChange: setSorting,
    getFilteredRowModel: getFilteredRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onGlobalFilterChange: setGlobalFilter,

    debugTable: true,
  });

  // const [selectedIssues] = useState<string[]>([]);

  // function handleLinkIssues() {
  //   // perform linking of issues using selectedIssues array
  //   console.log('Linking issues:', selectedIssues);
  // }

  return (
    <>
      <TableContainer
        overflowX="unset"
        overflowY="unset"
        borderWidth={"1px"}
        borderColor="borders.200"
        rounded={"8px"}
      >
        <Table variant={"simple"} position={"relative"}>
          <Thead position={"sticky"} top={0} bg={"white"} boxShadow={"sm"}>
            {table.getHeaderGroups().map((headerGroup) => {
              return (
                <Tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <Th
                        key={header.id}
                        textTransform="none"
                        color={"grays.12"}
                        fontSize="xs"
                        letterSpacing={"unset"}
                        fontWeight={500}
                        colSpan={header.colSpan}
                      >
                        <Box display={"flex"}>
                          {header.isPlaceholder
                            ? null
                            : flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}

                          {header.column.getCanSort() && (
                            <VStack spacing="2px" my="auto" ml="2">
                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowUp width="8px" />
                              </chakra.button>

                              <chakra.button
                                onClick={() => {
                                  header.column.toggleSorting();
                                }}
                              >
                                <SortArrowDown width="8px" />
                              </chakra.button>
                            </VStack>
                          )}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody>
            {table.getRowModel().rows.map((row) => (
              <Tr key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <Td
                    key={cell.id}
                    fontSize="xs"
                    color="grays.12"
                    fontWeight={"medium"}
                  >
                    <SwipeIn delay={0.2 * index}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </SwipeIn>
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};

export default BillingsTable;
