import React, { FC } from 'react'
import { Box, Flex, Text, useDisclosure, chakra } from "@chakra-ui/react";
import CustomModal from '../../UI/CustomModal';
import CreateOptinForm from './CreateOptinForm';


interface OptinCardProps {
    icon: any;
    name: string;
    description: string;
    view: boolean;
}

const OptinCard: FC<OptinCardProps> = ({ icon, name, description, view }) => {
    const disclosure = useDisclosure();

    return (
        <>
            <Box position={"relative"} w={"325px"} h={"136px"} borderWidth={"1px"} borderRadius={"4px"} borderColor={"borders.500"} _hover={{ borderColor: "primary.500" }}>
                <Flex px={"3"} py={"3"} fontSize="16px" fontWeight={700} color={"grays.8"} alignItems={'center'} >{icon}{name}</Flex>
                <Text px={"10"} fontSize={'12px'} color={"grays.12"} fontWeight={500}>{description}</Text>
                <chakra.button onClick={disclosure.onOpen}>
                    <Box p={"10px"} w={"100%"} h={"40px"} bg={"backgrounds.400"} position={"absolute"} bottom={"0"} m={"auto"}>
                        {view ?
                            <Text textAlign={"center"} textDecor={"underline"} color={"primary.500"} fontSize={"12px"} fontWeight={700}>Create {name} Opt-In</Text> :
                            <Text textAlign={"center"} color={"grays.12"} fontSize={"12px"} fontWeight={700}>Coming Soon</Text>
                        }
                    </Box>
                </chakra.button>
            </Box>

            <CustomModal disclosure={disclosure}
                title={
                    <Text fontSize={'20px'} color='grays.8' mt='35px' px='38px'>
                        Create WhatsApp Opt-In
                    </Text>
                }
            >
                <CreateOptinForm
                    close={disclosure?.onClose}
                />
            </CustomModal>
        </>
    )
}

export default OptinCard