import React from "react";
import { Link } from "react-router-dom";
import { HStack, Text, Box } from "@chakra-ui/react";
import OptinCard from "../../../components/account/optin/OptinCard";
import WhatsappIcon from "../../../icons/WhatsappIcon";

const CreateOptin = () => {
  return (
    <Box w="full" h="full">
      <Link to="/account/optin">
        {" "}
        <HStack spacing={"4px"}>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 13.5L8.5 10.25L12 7"
              stroke="#7489AA"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <Text fontSize={"xs"} color="grays.12">
            Back to Opt-In
          </Text>
        </HStack>
      </Link>
      <Text mt={"5"} fontSize={"20px"} color="grays.9" fontWeight={"700"}>
        Create Opt-In
      </Text>
      <Text mb={6} fontSize={"12px"} color="grays.12" fontWeight={"500"}>
        Select the what type of Opt-In you’d like to create
      </Text>
      <OptinCard
        name="Whatsapp"
        description="Acquire your customers through whatsapp in one click"
        icon={<WhatsappIcon />}
        view={true}
      />
    </Box>
  );
};

export default CreateOptin;
