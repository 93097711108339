import { Center, CenterProps, Text } from '@chakra-ui/react';
import React, { FC, ReactNode } from 'react';

interface Props {
  title: string;
  description?: string;
  containerProps?: CenterProps;
  icon?: ReactNode;
}

const Success: FC<Props> = ({ title, description, containerProps, icon }) => {
  return (
    <Center
      flexDir={'column'}
      textAlign='center'
      mt='64px'
      mb='84px'
      mx='auto'
      maxW={containerProps?.maxW || containerProps?.maxWidth || '204px'}
      {...containerProps}
    >
      {icon}

      <Text mt='26px' color='grays.8' fontSize={'20px'} fontWeight='bold'>
        {title}
      </Text>

      {description && (
        <Text
          mt='9px'
          fontSize={'sm'}
          fontWeight='medium'
          color='grays.12'
          maxW={'360px'}
        >
          {description}
        </Text>
      )}
    </Center>
  );
};

export default Success;
