import React from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import CustomButton from '../components/Ziz_components/Button.component';

const DownloadPDFButton: React.FC<{componentRef: any; _filename: string}> = ({ componentRef, _filename }) => {
  const handleDownloadPDF = () => {
    html2canvas(componentRef.current).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');
    //   @ts-ignore
      pdf.addImage(imgData, 'PNG', 0, 0);
      pdf.save(`${_filename}.pdf`);
    });
  };

  return <CustomButton onClick = {handleDownloadPDF} children = 'Download Receipt' />
};

export default DownloadPDFButton;