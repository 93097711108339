import { extendTheme } from '@chakra-ui/react';
import { colors } from './foundations/colors';
import { fontSizes, fonts } from './foundations/fonts';
import { textStyles, layerStyles } from './foundations/textLayerStyles';
import { Button } from './components/Button';
import { Input } from './components/Input';
import { styles } from './styles';
import { Badge } from './components/Badge';

const customTheme = extendTheme({
  colors,
  fonts,
  fontSizes,
  textStyles,
  layerStyles,
  styles,
  components: {
    Button,
    Badge,
    Input,
  },
});

export default customTheme;
