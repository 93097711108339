import { atom } from "recoil";
import { campaignStageProps } from "../pages/Campaigns/NewCampaign";

export interface CreateCampaignProps {
  currentStage: campaignStageProps;
  data: {
    campaign_name: string;
    list_uid: string;
    sms_sender_uid: string;
    email_sender_uid: string;
    whatsapp_sender_uid: string;
    message_body: string;
    email: string;
    subject: string;
    template_uid: string;
    timezone: string | undefined;
    send_date: Date | undefined;
    channel: string;
    builder: string;
    sender_number: string;
    sms_body: string;
    link_uid: string;
  };
  newCampaignId: string;
}

const createCampaignAtom = atom<CreateCampaignProps>({
  key: "createCampaignAtom",
  default: {
    currentStage: "Getting Started",
    data: {
      campaign_name: "",
      list_uid: "",
      sms_sender_uid: "",
      email_sender_uid: "",
      whatsapp_sender_uid: "",
      message_body: "",
      email: "",
      subject: "",
      template_uid: "",
      timezone: undefined,
      send_date: undefined,
      channel: "",
      builder: "",
      sender_number: "",
      sms_body: "",
      link_uid: "",
    },
    newCampaignId: "",
  },
});

export { createCampaignAtom };
