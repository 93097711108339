import React from "react";

export const OvalIcon = () => {
  return (
    <svg
      width="30"
      height="26"
      viewBox="6 8 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_dd_4117_68648)">
        <path
          d="M5 13C5 10.7909 6.79086 9 9 9H15C20.5228 9 25 13.4772 25 19V25C25 27.2091 23.2091 29 21 29H15C9.47715 29 5 24.5228 5 19V13Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_4117_68648"
          x="0"
          y="0"
          width="30"
          height="33"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="-4" />
          <feGaussianBlur stdDeviation="2.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.708333 0 0 0 0 0.770833 0 0 0 0 1 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4117_68648"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.06 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_4117_68648"
            result="effect2_dropShadow_4117_68648"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_4117_68648"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
