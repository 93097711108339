import { chakra, VStack, Button, useToast } from "@chakra-ui/react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FC, useState } from "react";
import { updateSingleContact } from "../../API/contacts";
import { SingleContactProps } from "../../utils/generalProps";
import { toaster } from "../../utils/toast";
import CustomPhoneInput from "../UI/form/CustomPhoneInput";
import FormInput from "../UI/form/FormInput";
// import SearchableSelect from '../UI/form/SearchableSelect';

const EditContact: FC<{
  data: SingleContactProps;
  close?: () => void;
  queriesToUpdate?: string[];
}> = ({ data, close, queriesToUpdate }) => {
  const [firstName, setFirstName] = useState(data?.first_name);
  const [lastName, setLastName] = useState(data?.last_name);
  const [email, setEmail] = useState(data?.email);
  const [phoneNumber, setPhoneNumber] = useState(data?.phone_number);

  const toast = useToast();
  const queryClient = useQueryClient();

  const {
    // mutate,
    isLoading,
  } = useMutation(updateSingleContact, {
    onSuccess(data) {
      const success = { ...data };
      toaster(toast, success);
      if (queriesToUpdate && queriesToUpdate?.length > 0) {
        queryClient.invalidateQueries([...queriesToUpdate]);
      } else {
        queryClient.invalidateQueries(["allContacts"]);
      }

      close && close();
    },

    onError(data: any) {
      const errors = { ...data };
      toaster(toast, errors);
    },
  });

  return (
    <chakra.form
      mt="24px"
      onSubmit={(e) => {
        e.preventDefault();
        // mutate({
        //   uid: data?.uid,
        //   first_name: firstName,
        //   last_name: lastName,
        //   email: email,
        //   phone_number: phoneNumber,
        // });
      }}
    >
      <VStack align={"flex-start"} spacing="20px">
        <FormInput
          label="First name"
          type="text"
          for="First name"
          inputProps={{
            placeholder: "Enter First name",
            value: firstName,
            onChange: (e) => {
              setFirstName(e.target.value);
            },
          }}
          formControlProps={{
            isRequired: true,
          }}
        />
        <FormInput
          label="Last name"
          type="text"
          for="Last name"
          inputProps={{
            placeholder: "Enter Last name",
            value: lastName,

            onChange: (e) => {
              setLastName(e.target.value);
            },
          }}
          formControlProps={{
            isRequired: true,
          }}
        />

        <FormInput
          label="Email address"
          type="email"
          for="Email address"
          inputProps={{
            placeholder: "Enter email address",
            value: email,
            onChange: (e) => {
              setEmail(e.target.value);
            },
          }}
        />

        <CustomPhoneInput
          label="Phone number"
          value={phoneNumber}
          onChange={(phone) => {
            setPhoneNumber(phone);
          }}
        />

        {/* <SearchableSelect label='List' placeholder='Select list option' /> */}
      </VStack>

      <Button
        type="submit"
        w="full"
        rounded="4px"
        bg="primary.500"
        _disabled={{
          bg: "primary.400",
        }}
        _hover={{
          bg: "primary.400",
        }}
        _focus={{
          bg: "primary.400",
        }}
        color={"white"}
        my="40px"
        fontSize={"sm"}
        isLoading={isLoading}
        isDisabled={!firstName || !lastName || (!email && !phoneNumber)}
      >
        Update account
      </Button>
    </chakra.form>
  );
};

export default EditContact;
