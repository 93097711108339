import {
  Box,
  Text,
  SimpleGrid,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  // useToast,
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import {
  getCampaignPricing,
  getCountriesAndCurrencies,
} from "../../../API/pricing";
import { ScaleDown } from "../../../components/animations/ScaleDown";
import { ButtonPrimary } from "../../../components/UI/Buttons";
import { SingleDropdownSelect } from "../../../components/UI/Inputs";
import Loader from "../../../components/UI/Loader";

interface CurrencyProp {
  id: number;
  uid: string;
  currency: string;
  created_at: string;
  updated_at: string;
}
interface CountryProp {
  id: number;
  uid: string;
  name: string;
  dial_code: string;
  short_code: string;
  alpha_3: string;
  created_at: string;
  updated_at: string;
}
const Pricing = () => {
  const [currency, setCurrency] = useState<string | undefined>(undefined);
  const [countryCode, setCountryCode] = useState<string | undefined>(undefined);
  const [currencies, setCurrencies] = useState<CurrencyProp[] | []>([]);
  const [countryList, setCountryList] = useState<CountryProp[] | []>([]);
  const [smsPrice, setSmsPrice] = useState(5.1);
  const [whatsappPrice, setWhatsappPrice] = useState(2.9);
  const [curr, setCurr] = useState("USD");
  // const toast = useToast();

  const pricingInfo = useQuery(["user_pricing"], getCountriesAndCurrencies, {
    onSuccess: (data) => {
      setCurrencies([...data.currencies]);
      setCountryList([...data.countries]);
    },
  });

  const userPricing = useMutation({
    mutationFn: getCampaignPricing,
    onSuccess: (data) => {
      setSmsPrice(data.data.sms);
      setWhatsappPrice(data.data.whatsapp);
    },
    onError: () => {
      // toast({
      //   status: "error",
      //   title: "Try again",
      // });
    },
  });

  const handleChange = () => {
    if (countryCode && currency) {
      userPricing.mutate({
        shortCode: countryCode!,
        currency: currency!,
      });
      setCurr(currency);
    }
  };

  return (
    <Box maxWidth={"620px"}>
      <Box mb={"32px"}>
        <Heading fontSize={"20px"} color="grays.9" fontWeight={700} mb={"8px"}>
          Pricing
        </Heading>
        <Text color="grays.12" fontSize={"12px"} fontWeight={500}>
          Filter the pricing based on country and currency
        </Text>
      </Box>

      <SimpleGrid columns={2} spacing={5} fontSize="xs" mb={"8px"}>
        <FormControl>
          <FormLabel fontSize={"12px"} fontWeight={700}>
            Country
          </FormLabel>
          <SingleDropdownSelect
            options={countryList.map((item) => ({
              label: item.name,
              value: item.short_code,
            }))}
            setCountry={setCountryCode}
          />
        </FormControl>

        <FormControl>
          <FormLabel fontSize={"12px"} fontWeight={700}>
            Cuurency
          </FormLabel>
          <SingleDropdownSelect
            options={currencies.map((item) => ({
              label: item.currency,
              value: item.currency,
            }))}
            setCurrency={setCurrency}
          />
        </FormControl>
      </SimpleGrid>

      <HStack mb={"24px"} justifyContent={"end"}>
        <ButtonPrimary onClick={handleChange} isLoading={userPricing.isLoading}>
          Check
        </ButtonPrimary>
      </HStack>

      <Box>
        {pricingInfo.isLoading && <Loader />}

        <AnimatePresence>
          {!userPricing.isLoading && !pricingInfo.isLoading && (
            <ScaleDown normal>
              <Box
                px={"24px"}
                py={"20px"}
                bg="#0066FF0A"
                boxShadow={"md"}
                transition="all 0.7s ease-in-out"
                _hover={{
                  boxShadow: "sm",
                }}
              >
                <Text fontSize="16px" fontWeight={700} mb={"16px"}>
                  SMS Campaign Pricing
                </Text>
                <Text
                  color="grays.12"
                  fontSize={"12px"}
                  fontWeight={500}
                  mb={"2px"}
                >
                  Starts at
                </Text>
                <Text color={"primary.500"} fontSize={"24px"} fontWeight={700}>
                  {`${curr} ${Number(smsPrice).toFixed(2)}`}
                </Text>
              </Box>

              <Box px={"24px"} py={"20px"} boxShadow={"sm"}>
                <Text fontSize="16px" fontWeight={700} mb={"16px"}>
                  WhatsApp Campaign Pricing
                </Text>
                <Text
                  color="grays.12"
                  fontSize={"12px"}
                  fontWeight={500}
                  mb={"2px"}
                >
                  Starts at
                </Text>
                <Text color={"primary.500"} fontSize={"24px"} fontWeight={700}>
                  {`${curr} ${Number(whatsappPrice).toFixed(2)}`}
                </Text>
              </Box>
            </ScaleDown>
          )}
        </AnimatePresence>
      </Box>
    </Box>
  );
};

export default Pricing;
