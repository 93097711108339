import React from "react";
import { Button, ButtonProps } from "@chakra-ui/react";

interface Props extends ButtonProps {
  children: React.ReactNode;
  variant?: "primary" | "secondary" | "primary2";
  size?: "xs" | "sm" | "md" | "lg";
  isLoading?: boolean;
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  style?: any;
  // hoverColor?: string
}

function CustomButton(props: Props) {
  const {
    children,
    variant,
    size,
    isLoading,
    disabled,
    onClick,
    style,
    ...rest
  } = props;

  let colorScheme = "white";
  let bg = "#0066FF";
  let hoverBg = "blue.600";
  let border = "gray";

  if (variant === "secondary") {
    colorScheme = "gray";
    bg = "white";
    hoverBg = "gray.100";
  }
  if (variant === "primary2") {
    colorScheme = "#0066FF";
    bg = "white";
    hoverBg = "gray.100";
    border = "#0066FF";
  }

  return (
    <Button
      colorScheme={colorScheme}
      color={colorScheme}
      bg={bg}
      _hover={{ bg: hoverBg }}
      size={size || "md"}
      isLoading={isLoading}
      disabled={disabled}
      onClick={onClick}
      style={style}
      border={`1px solid ${border}`}
      {...rest}
    >
      {children}
    </Button>
  );
}

export default CustomButton;
