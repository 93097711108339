import React, { FC, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Text,
  Flex,
  Button,
  Center,
  Avatar,
  useDisclosure,
  HStack,
  useToast,
} from "@chakra-ui/react";
import Iphone from "../UI/Iphone";
import styled from "@emotion/styled";
import { TiptapBox } from "../UI/Tiptap";
import { useQuery } from "@tanstack/react-query";
import { getContactAttributes } from "../../API/contacts";
import {
  removeHTMLTags,
  removeSpacesAndJoin,
  removeUnderscores,
} from "../../functions";
import { toaster } from "../../utils/toast";
import ProgressLoader from "../UI/ProgressLoader";
import { useRecoilState } from "recoil";
import { tipTapAtom } from "../../atoms/tiptap";
import { useSearchParams } from "react-router-dom";

interface smsCampaignProps {
  currentStageData: any;
  setCurrentStageData: any;
}

const SmsCapaign: FC<smsCampaignProps> = ({
  currentStageData,
  setCurrentStageData,
}) => {
  // const [content, setContent] = React.useState(
  //   currentStageData?.data?.sms_body
  // );
  // console.log("wallllaaaaa", currentStageData);
  const [isReady, setIsReady] = React.useState(false);
  const [searchParams] = useSearchParams();

  const [tiptapValue, setTiptapValue] = useRecoilState(tipTapAtom);
  // console.log("shhhhhow tiptap", tiptapValue);
  // const [plainText, setPlainText] = React.useState("");
  const toast = useToast();

  useEffect(() => {
    if (!searchParams.get("uid")) {
      setTiptapValue((prev) => ({
        ...prev,
        content: currentStageData?.data?.sms_body,
      }));
    }
  }, [currentStageData?.data?.sms_body, setTiptapValue, searchParams]);

  const contactAttributes = useQuery({
    queryKey: ["get_contact_attributes2"],
    queryFn: getContactAttributes,
    retry: 5,

    onSuccess: (data) => {
      // setRawAttributes(data.data);
      setTiptapValue((prev) => ({
        ...prev,
        rawAttributes: data.data,
      }));
      let arr: { front: string; back: string }[] = [];
      for (let i = 0; i < data?.data.length; i++) {
        let str3 = data.data[i];
        let str2 = removeUnderscores(data.data[i]);
        let str = removeSpacesAndJoin(str2);
        arr.push({ front: str, back: str3 });
      }
      // setAttributes(arr);
      setTiptapValue((prev) => ({
        ...prev,
        attributes: arr,
      }));
      setIsReady(true);
      // console.log("doneeeeeee", attributes);
    },

    onError: (data: any) => {
      const errors = { ...data };
      toaster(toast, errors);
      setIsReady(true);
    },
  });

  const addLinkDisclosure = useDisclosure();

  // console.log("we haveeeee", currentStageData?.data);

  return (
    <>
      {contactAttributes.isLoading ? <ProgressLoader /> : null}

      <Box position={"relative"}>
        <SimpleGrid columns={{ base: 1, md: 2 }}>
          {/* Message editor */}
          <Box
            h={"490px"}
            position="relative"
            borderWidth={"1px"}
            borderColor="borders.100"
            w="full"
            className="thinSB"
          >
            {/* header */}
            <Flex
              p="24px"
              borderBottomWidth={"1px"}
              borderBottomColor="borders.100"
            >
              <Text fontSize={"20px"} color="grays.8" fontWeight={700}>
                Message Editor
              </Text>

              {/* Text count */}
              <Box
                ml="auto"
                px="10px"
                py="5px"
                borderWidth={"1px"}
                borderColor="borders.100"
                bg="borders.200"
                rounded="4px"
              >
                <Text fontSize={"10px"} fontWeight={400}>
                  {" "}
                  {160 -
                    (currentStageData?.data?.message_body?.length % 160)}{" "}
                  CHARACTERS REMAINING{" "}
                  <Text as="span" color="grays.8">
                    |{" "}
                  </Text>
                  <Text as="span" fontWeight={500}>
                    {Math.floor(
                      currentStageData?.data?.message_body?.length / 160
                    ) + 1}{" "}
                    PAGE(S)
                  </Text>
                </Text>
              </Box>
            </Flex>

            <Box position="relative">
              {isReady ? (
                <TiptapBox
                  channel="sms"
                  // setPlainText={setPlainText}
                  addLinkDisclosure={addLinkDisclosure}
                  onChange={(val) => {
                    setCurrentStageData((prevData: any) => {
                      return {
                        ...prevData,
                        data: {
                          ...prevData?.data,
                          message_body: val + " " + tiptapValue.linkObject.link,
                          link_uid: tiptapValue.linkObject.link_uid,
                        },
                      };
                    });
                  }}
                />
              ) : null}
            </Box>
          </Box>

          {/* Message preview */}
          <Box h={"490px"} overflowY={"hidden"} className="thinSB">
            <Flex
              borderWidth={"1px"}
              pt="18px"
              borderColor={"borders.100"}
              w="full"
              overflow={"hidden"}
            >
              <Box mx="auto" textAlign={"center"}>
                <Text
                  mb="4px"
                  fontSize={"20px"}
                  color="grays.8"
                  fontWeight={500}
                >
                  Preview
                </Text>

                <Box pos="relative" transform={"scale(0.95)"}>
                  <Iphone />
                  <Box
                    pos="absolute"
                    top={16}
                    bottom={0}
                    left="33px"
                    w="full"
                    maxW={"370px"}
                  >
                    {/* header */}
                    <Center
                      h="123px"
                      flexDir={"column"}
                      textAlign="center"
                      borderBottomWidth={"1px"}
                    >
                      <Avatar name="R" w="51px" h="51px" />

                      <Text mt="4px">YOUR SENDER ID</Text>
                    </Center>

                    <Flex height={"100%"} mt="6px" flexDir={"column"} bg="#Fff">
                      {/* message info */}
                      <Box textAlign={"center"} fontWeight={500} pt={"12px"}>
                        <Text
                          textAlign={"center"}
                          fontSize="12px"
                          color="grays.12"
                        >
                          Text Message
                        </Text>
                        <Text
                          textAlign={"center"}
                          fontSize="12px"
                          color="grays.12"
                        >
                          Today 13:45
                        </Text>
                      </Box>

                      {/* Message bubble */}
                      <Box
                        mt="18px"
                        borderRadius={"19px"}
                        p="16px"
                        bg="#E9E9EB"
                        // maxW="258px"
                        w="90%"
                        ml="10px"
                        height={"150px"}
                        overflowY={"auto"}
                        className="thinSB"
                      >
                        <Text
                          fontSize={"sm"}
                          color="grays.8"
                          textAlign={"left"}
                        >
                          {/* {currentStageData?.data?.message_body} */}
                          {/* <TipTapReadOnly content={tiptapValue.content} /> */}
                          {removeHTMLTags(currentStageData?.data?.message_body)}
                        </Text>
                      </Box>
                    </Flex>
                  </Box>
                </Box>
              </Box>
            </Flex>
          </Box>
        </SimpleGrid>

        <HStack justifyContent={"end"}>
          <Button
            float="right"
            isDisabled={!currentStageData?.data?.message_body}
            onClick={() => {
              if (tiptapValue.linkObject.link) {
                // setContent((prev: any) => prev + link);
                setTiptapValue((prev) => ({
                  ...prev,
                  content: prev.content + " " + tiptapValue.linkObject.link,
                }));
              }
              setCurrentStageData((prevData: any) => {
                return {
                  ...prevData,
                  currentStage: "Recipients",
                };
              });
            }}
            mt="40px"
            mb="10px"
            w="full"
            maxW={"360px"}
            colorScheme={"primary"}
          >
            Continue to recipients
          </Button>
        </HStack>
      </Box>
    </>
  );
};

export default SmsCapaign;

export const EmojiBox = styled(Box)`
  aside {
    z-index: 5 !important;
    position: absolute !important;
    top: 2em !important;
  }
`;
